import React from "react";

export const ToasterIcon = ({ width }) => {
  return (
    <svg
      id="ToasterIcon"
      width={width || 26}
      height={(width * 26) / 22 || 22}
      viewBox="0 0 348.16 348.16"
    >
      <g>
        <g>
          <path
            d="M297.472,182.784c-3.072,0-5.12,2.048-5.12,5.12v18.944c0,3.072,2.048,5.12,5.12,5.12c3.072,0,5.12-2.048,5.12-5.12
			v-18.944C302.592,184.832,300.544,182.784,297.472,182.784z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M297.472,229.376c-8.192,0-14.848,6.656-14.848,14.848c0,8.192,6.656,14.848,14.848,14.848
			c8.192,0,14.848-6.656,14.848-14.848C312.32,236.032,305.664,229.376,297.472,229.376z M297.472,248.832
			c-2.56,0-4.608-2.048-4.608-4.608s2.048-4.608,4.608-4.608s4.608,2.048,4.608,4.608S300.032,248.832,297.472,248.832z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,95.232H91.648c-46.08,0-83.456,37.376-83.456,83.456v145.408c0,3.072,2.048,5.12,5.12,5.12h18.432v3.584
			c0,8.704,5.632,15.36,13.312,15.36h43.52c7.168,0,13.312-7.168,13.312-15.36v-3.584H238.08v3.584
			c0,8.704,5.632,15.36,13.312,15.36h43.52c7.168,0,13.312-7.168,13.312-15.36v-3.584h26.624c3.072,0,5.12-2.048,5.12-5.12V178.688
			C339.456,133.12,302.08,95.232,256,95.232z M88.576,337.92h-43.52c-1.024,0-3.072-2.048-3.072-5.12v-3.584h49.152v3.584h0.512
			C91.648,335.872,90.112,337.92,88.576,337.92z M294.4,337.92h-43.52c-1.024,0-3.072-2.048-3.072-5.12v-3.584h49.152v3.584h0.512
			C297.472,335.872,295.936,337.92,294.4,337.92z M329.216,318.976H18.432V178.688c0-40.448,32.768-73.216,73.216-73.216H256
			c40.448,0,73.216,32.768,73.216,73.216V318.976z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M255.488,120.32H92.16c-3.072,0-5.12,2.048-5.12,5.12v16.896c0,3.072,2.048,5.12,5.12,5.12h162.816
			c3.072,0,5.12-2.048,5.12-5.12V125.44C260.608,122.88,258.048,120.32,255.488,120.32z M97.28,137.216v-6.656h152.576v6.656H97.28z
			"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M119.296,43.008c11.776,0,21.504-9.728,21.504-21.504S131.072,0,119.296,0c-3.072,0-5.12,2.048-5.12,5.12
			c0,3.072,2.048,5.12,5.12,5.12c6.144,0,11.264,5.12,11.264,11.264c0,6.144-5.12,11.264-11.264,11.264
			c-11.776,0-21.504,9.728-21.504,21.504s9.728,21.504,21.504,21.504c3.072,0,5.12-2.048,5.12-5.12c0-3.072-2.048-5.12-5.12-5.12
			c-6.144,0-11.264-5.12-11.264-11.264S113.152,43.008,119.296,43.008z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M176.64,43.008c11.776,0,21.504-9.728,21.504-21.504S188.416,0,176.64,0c-3.072,0-5.12,2.048-5.12,5.12
			c0,3.072,2.048,5.12,5.12,5.12c6.144,0,11.264,5.12,11.264,11.264c0,6.144-5.12,11.264-11.264,11.264
			c-11.776,0-21.504,9.728-21.504,21.504s9.728,21.504,21.504,21.504c3.072,0,5.12-2.048,5.12-5.12c0-3.072-2.048-5.12-5.12-5.12
			c-6.144,0-11.264-5.12-11.264-11.264S170.496,43.008,176.64,43.008z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M233.984,43.008c11.776,0,21.504-9.728,21.504-21.504S245.76,0,233.984,0c-3.072,0-5.12,2.048-5.12,5.12
			c0,3.072,2.048,5.12,5.12,5.12c6.144,0,11.264,5.12,11.264,11.264c0,6.144-5.12,11.264-11.264,11.264
			c-11.776,0-21.504,9.728-21.504,21.504s9.728,21.504,21.504,21.504c3.072,0,5.12-2.048,5.12-5.12c0-3.072-2.048-5.12-5.12-5.12
			c-6.144,0-11.264-5.12-11.264-11.264S227.84,43.008,233.984,43.008z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
