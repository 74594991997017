import { isSameDay } from "date-fns";

interface EventData {
  date: string;
  isComplete: boolean;
}

export const isMissingEventData = (
  array: EventData[] | null | undefined,
  dateToCheck: string
): boolean => {
  if (!array || array.length === 0) {
    return true; // If the array is null, undefined, or empty, the data is missing
  }

  return !array?.find((element) => {
    return isSameDay(new Date(element?.date), new Date(dateToCheck)) && element?.isComplete;
  });
};
