interface FilterItem {
  filtered: boolean;
}

interface FilterType {
  filters: FilterItem[];
}

export const areFiltersApplied = (filters: FilterType[]): boolean => {
  let filterApplied = false;
  filters.forEach((filterType) => {
    const refined = filterType.filters.filter((item) => item.filtered);
    if (refined.length > 0) {
      filterApplied = true;
    }
  });
  return filterApplied;
};
