import { postHubPairingMode } from "@intelligentlilli/api-layer";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useOpenHubPairing = ({
  hubId,
  installationComplete,
  frequencyOfRepairing = 150000, // 2.5 minutes
}) => {
  const server = useSelector((state) => state.session.server);
  useEffect(() => {
    if (!installationComplete) {
      console.log("trigger hub pairing mode");
      postHubPairingMode(server, hubId, "web");
    }
    const interval = setInterval(() => {
      if (!installationComplete) {
        console.log("inside the postHubPairingMode interval");
        postHubPairingMode(server, hubId, "web");
      }
    }, frequencyOfRepairing); // re-open pairing mode every X seconds
    return () => clearInterval(interval);
  }, [installationComplete, frequencyOfRepairing, server, hubId]);
};
