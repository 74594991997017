import { isSameDay } from "date-fns";

interface BehaviorDataPoint {
  ts: Date;
  dayRating: number | null;
  expectedMax: number | null;
  expectedMin: number | null;
  value: number | null;
}

// Clean all the behaviour data. 
// We want to ensure there is an entry for each day in the period even if it is null
export const cleanBehaviourData = (
  data: BehaviorDataPoint[] | undefined,
  dates: Date[] | undefined
): BehaviorDataPoint[] | [] => {
  if (!data && !dates || !dates) return [];

  return dates.map((date) => {
    const dataPoint = data?.filter((dataPoint) =>
      isSameDay(dataPoint?.ts, date)
    )?.[0];
    return (
      dataPoint || {
        date: date,
        dayRating: null,
        expectedMax: null,
        expectedMin: null,
        ts: date,
        value: null,
      }
    );
  });
};