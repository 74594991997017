import styles from "./styles.module.scss";

const SensorsHeader = ({ isHub, loading }) => {
  const title = isHub ? "Connect the Lilli hub" : "Connect the sensors";
  const subtitle = isHub
    ? "To begin the installation, you must first connect the Lilli hub."
    : "Each sensor in your kit has a unique matching code, such as M1. Use this code to correctly match the sensors.";
  const hubIsConnecting =
    "This can take up to 5 minutes. Please don't exit this page until the installation is complete.";
  const sensorIsConnecting = "Checking sensor connection...";
  return (
    <div className={styles.header}>
      <div className={styles.title}>{loading ? "Connecting..." : title}</div>
      <div className={styles.subtitle}>
        {loading && isHub
          ? hubIsConnecting
          : loading && !isHub
          ? sensorIsConnecting
          : subtitle}
      </div>
    </div>
  );
};

export default SensorsHeader;
