import React from "react";
import theme from "../../theme.scss";

export const BreakfastWindowIcon = ({ width, color }) => {
  return (
    <svg
      width={"21" || width}
      height={"21" || width}
      viewBox="0 0 21 21"
      fill="none"
      id="BreakfastWindowIcon"
    >
      <mask
        id="mask0_438_484"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="16"
        height="16"
      >
        <circle
          cx="10.6069"
          cy="10.6066"
          r="7.375"
          transform="rotate(-150 10.6069 10.6066)"
          fill="#D9D9D9"
          stroke={theme.neutral7}
          strokeWidth="0.25"
        />
      </mask>
      <g mask="url(#mask0_438_484)">
        <path
          d="M10.5506 10.7983L11.1425 17.4147C11.1487 17.4839 11.1404 17.5536 11.1181 17.6194L10.6983 18.8604C10.6226 19.0843 10.4003 19.2246 10.1655 19.1966L2.90414 18.3306C2.65381 18.3007 2.46486 18.0892 2.46336 17.8371L2.39274 5.99404C2.39034 5.59163 2.84008 5.35139 3.17321 5.57714L10.3331 10.4289C10.4574 10.5131 10.5372 10.6487 10.5506 10.7983Z"
          fill={color || theme.primary3}
        />
      </g>
      <circle cx="10.6067" cy="10.6064" r="7" stroke={theme.neutral4} />
    </svg>
  );
};
