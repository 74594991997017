import { UpArrow, DownArrow } from "../../Styles/Icons/DesignSystem";
import styles from "./styles.module.scss";

export const SortArrows = () => {
  return (
    <div className={styles.sort_arrows_container}>
      <UpArrow />
      <DownArrow />
    </div>
  );
};
