import { TimerIcon } from "../../Styles/Icons/DesignSystem";

export const futureReportTypes = [
  {
    title: "Bathroom report",
    id: "bathroom_report",
    icon: TimerIcon,
    available: false,
    sections: [
      {
        title: "Daily living",
        questions: [
          "How active is Russ during the day? ",
          "What is Russ’ wake up time? ",
        ],
      },
    ],
  },
  {
    title: "Eating and drinking report",
    id: "eating_and_drinking_report",
    icon: TimerIcon,
    available: false,
    sections: [
      {
        title: "Daily living",
        questions: [
          "How active is Russ during the day? ",
          "What is Russ’ wake up time? ",
        ],
      },
    ],
  },
  {
    title: "Time outside report",
    id: "time_outside_report",
    icon: TimerIcon,
    available: false,
    sections: [
      {
        title: "Daily living",
        questions: [
          "How active is Russ during the day? ",
          "What is Russ’ wake up time? ",
        ],
      },
    ],
  },
];
