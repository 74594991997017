export const DAYTTIME_QUESTIONS_AND_ANSWERS = [
  {
    key: "overview",
    question: "Overview",
    answer: [
      "Day-time activity is measured as the time someone has spent moving around their home during typical day-time hours.",
      "By understanding their usual movement patterns over time, you can detect any changes that could indicate a change in their care needs.",
    ],
  },
  {
    key: "unexpected",
    question: "What would Unexpected day-time activity look like?",
    answer: [
      "Lilli flags 'Unexpected' when the service user's movement is different than usual. For example:",
      "John has had Lilli for a few weeks and the usual amount of time he spends moving around his house during the day is around 4 hours. One day, Lilli flagged John as Unexpected when he only moved for 1 hour in the daytime.",
    ],
  },
  {
    key: "at risk",
    question: "What would At risk day-time activity look like?",
    answer: [
      "Lilli flags 'At risk' when there has been no monitored movement for longer than usual during the daytime, or when there has been a lot of movement during the night. For example:",
      "Lilli has learnt that John usually moves a few times around lunchtime, however, there has been no movement all day.",
      "Lilli has flagged this as 'At risk' because it would have expected to see some movement over this time and prompts that you might need to check on John and that the devices are working correctly.",
    ],
  },
];
