import React from "react";
import styles from "./styles.module.scss";
import { AwaitingInstallIcon } from "../../Styles/Icons/DesignSystem/AwaitingInstallIcon";

const AwaitingInstallButton = ({
  numberAwaitingInstall,
  setSelectedFilter,
  setSelectedTab,
}) => {
  const numAwaitingInstall =
    numberAwaitingInstall > 0
      ? `${numberAwaitingInstall} Users awaiting install`
      : "No users awaiting install";

  return (
    <div
      className={styles.awaiting_install_button_container}
      onClick={() => {
        setSelectedTab("users");
        setSelectedFilter("incomplete");
      }}
    >
      <div className={styles.awaiting_install_icon_container}>
        <AwaitingInstallIcon />
      </div>
      <div className={styles.awaiting_install_text_content_container}>
        <div className={styles.awaiting_install_text}>{numAwaitingInstall}</div>
        <div className={styles.click_to_view_text}>Click to view</div>
      </div>
    </div>
  );
};

export default AwaitingInstallButton;
