import React from "react";
import styles from "./styles.module.scss";
// Forms
import { Form, Field } from "react-final-form";
import FormError from "../../Components/FormError";

const DeviceIdForm = (props) => {
  return (
    <Form
      onSubmit={(values) => props.onSubmit(values.id)}
      validate={(values) => {
        const errors = {};
        if (!values.id) {
          errors.id = "Required";
        }
        if (values.id?.length !== 16) {
          errors.id = "Device ID should be 16 digits long";
        }
        // create a regex that only allows numbers from 0 to 9 and capital letters from A to F as this is the format of the device ID
        const regex = /^[A-F0-9]+$/;
        if (!regex.test(values.id)) {
          errors.id =
            "ID should only contain numbers from 0 to 9 and capital letters from A to F";
        }
        return errors;
      }}
      render={({ handleSubmit }) => (
        <form
          className={styles.form}
          id="qr-code-install"
          // the submit button is further up the DOM tree in InstallLinkDevice using document.getElementById https://final-form.org/docs/react-final-form/faq
          onSubmit={handleSubmit}
        >
          <Field name="id">
            {({ input, meta }) => (
              <div className={styles.input}>
                <p>
                  If you cannot scan your QR code enter the 16 digit number
                  above it here:
                </p>
                <label>
                  Enter the 16 digit code:
                  <input {...input} type="text" />
                </label>

                {meta.error && meta.touched && (
                  <span className={styles.error}>{meta.error}</span>
                )}
              </div>
            )}
          </Field>
          {props.error && <FormError error={props.error} />}
        </form>
      )}
    />
  );
};

export default DeviceIdForm;
