export const isValidStartAndEndTime = (start, end) => {
  // Regular expression to validate the time format "HH:MM"
  const timePattern = /^([01]?[0-9]|2[0-3]):([0-5]?[0-9])$/;

  // Validate format of both start and end times
  if (!timePattern.test(start) || !timePattern.test(end)) {
    return false;
  }
  return true;
};
