import React from "react";
import styles from "./styles.module.scss";
import { useViewport } from "../ViewportProvider";
import { format } from "date-fns";

// Seperate component for the frontdoor tooltip as it splits events into door open and door closed
const FrontDoorTooltip = ({ events }) => {
  const sortedEvents = events.sort(
    (a, b) => new Date(a.time) - new Date(b.time)
  );
  return (
    <div>
      {sortedEvents.map((event, index) => (
        <div
          className={`${styles.tooltip_time} ${
            index % 2 === 0 && styles.tooltip_time_even
          }`}
          key={index}
        >
          <div>{format(new Date(event.time), "HH:mm")}</div>
          <div className={styles.tooltip_time_right}>
            {event.status === "opened" ? "Open" : "Close"}
          </div>
        </div>
      ))}
    </div>
  );
};

const EventLogTooltip = ({
  entranceDoor = false,
  tooltipEvents,
  coordinatesSquare,
  time,
}) => {
  const { height, width } = useViewport();
  // Where there isn't enough screen space to have the tooltip aligned with the left hand side of the square we should align it with the right
  const rightAlign = width - coordinatesSquare.left < 168;
  return (
    <div
      className={styles.tooltip}
      style={{
        bottom: height - coordinatesSquare?.top + 2,
        left: rightAlign
          ? // take away the width of the tooltip and add the width of the square to right align
            coordinatesSquare?.left - 158 + 29
          : coordinatesSquare?.left,
      }}
    >
      <div className={styles.tooltip_top}>
        <div className={styles.tooltip_top_title}>Event time(s)</div>
        <div className={styles.tooltip_top_times}>
          {time.startTime} - {time.endTime}
        </div>
      </div>
      {entranceDoor ? (
        <FrontDoorTooltip events={tooltipEvents} />
      ) : (
        <div>
          {tooltipEvents?.map((event, index) => (
            <div
              className={`${styles.tooltip_time} ${
                index % 2 === 0 && styles.tooltip_time_even
              }`}
              key={index}
            >
              <div>{format(new Date(event), "HH:mm")}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventLogTooltip;
