import {
  addHours,
  differenceInMilliseconds,
  millisecondsToHours,
  millisecondsToMinutes,
  startOfDay,
} from "date-fns";
import { filterEventsByTimeStamp } from "../filterEventsByTimeStamp";

export const bathroomDayVisits = (bathroomData, title, onClick) => {
  const bathingEvents = bathroomData?.[0]?.activityData.reduce((acc, curr) => {
    if (curr.eventType === "hygiene") {
      return acc + 1;
    }
    return acc;
  }, 0);

  const bathroomVisits = bathroomData?.[0]?.activityData.reduce((acc, curr) => {
    if (curr.eventType === "toilet") {
      return acc + 1;
    }
    return acc;
  }, 0);

  if (!bathroomData?.[0]?.activityData) {
    return {
      offline: true,
      title: "Bathroom",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }
  return {
    title: title,
    icon: "BATHROOM",
    onClick: onClick,
    children: {
      type: "SPLIT_INSIGHT",
      left: { context: "Bathing event", number: bathingEvents },
      right: { context: "Bathroom visit", number: bathroomVisits },
    },
  };
};

export const bathroomNightVisits = (bathroomData, title, onClick, date) => {
  const endDateString = startOfDay(new Date(date));
  const midnight = endDateString.toISOString();
  const morning = addHours(endDateString, 6).toISOString();
  const earlyHourBathroomEvents = filterEventsByTimeStamp(
    bathroomData?.[0]?.activityData,
    "originalStart",
    midnight,
    morning
  );

  if (!earlyHourBathroomEvents) {
    return {
      offline: true,
      title: "Bathroom",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }

  const bathingEvents = earlyHourBathroomEvents.reduce((acc, curr) => {
    if (curr.eventType === "hygiene" && true) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const bathroomVisits = earlyHourBathroomEvents.reduce((acc, curr) => {
    if (curr.eventType === "toilet" && true) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return {
    title: title,
    icon: "SLEEP",
    onClick: onClick,
    children: {
      type: "SPLIT_INSIGHT",
      left: { context: "Bathing event", number: bathingEvents },
      right: { context: "Bathroom visit", number: bathroomVisits },
    },
  };
};

export const bathroomDayDuration = (bathroomData, title, onClick) => {
  const totalDuration = bathroomData?.[0]?.activityData.reduce((acc, curr) => {
    const { startedAt, endedAt } = curr;
    const startedAtDate = new Date(startedAt);
    const endedAtDate = new Date(endedAt);
    const eventDuration = differenceInMilliseconds(endedAtDate, startedAtDate);
    return acc + eventDuration;
  }, 0);

  const totalHours = millisecondsToHours(totalDuration);
  const remainingMinutes = millisecondsToMinutes(totalDuration) % 60;

  return {
    title: title,
    icon: "BATHROOM",
    onClick: onClick,
    children: {
      type: "BATHROOM_DURATION",
      hours: totalHours,
      minutes: remainingMinutes,
    },
  };
};

export const bathingDuration = (bathroomData, title, onClick) => {
  const totalDuration = bathroomData?.[0]?.activityData.reduce((acc, curr) => {
    if (curr.eventType === "hygiene") {
      const { startedAt, endedAt } = curr;
      const startedAtDate = new Date(startedAt);
      const endedAtDate = new Date(endedAt);
      const eventDuration = differenceInMilliseconds(
        endedAtDate,
        startedAtDate
      );
      return acc + eventDuration;
    }
    return acc;
  }, 0);

  const totalBathingHours = millisecondsToHours(totalDuration);
  const remainingBathingMinutes = millisecondsToMinutes(totalDuration) % 60;

  return {
    title: title,
    icon: "BATHROOM",
    onClick: onClick,
    children: {
      type: "BATHING_DURATION",
      hours: totalBathingHours,
      minutes: remainingBathingMinutes,
    },
  };
};
