import React from "react";
import styles from "./styles.module.scss";

const Checkbox = ({ input }) => (
  <label className={styles.container}>
    <input type="checkbox" {...input} />
    <span className={styles.checkmark}></span>
  </label>
);

export default Checkbox;
