import React from "react";
import Event from "../Event";
import styles from "./styles.module.scss";
import { isAfter } from "date-fns";

const SectionTitle = ({ sectionTitle }) => (
  <div>
    <div className={styles.section_title}>{sectionTitle}</div>
    <div className={styles.hr} />
  </div>
);

const EventLogSection = ({
  currentTab,
  events,
  isCurrentDay,
  currentTime,
  start,
  end,
  sectionTitleFull,
  sectionTitlePartial,
}) => {
  // Return the event log with splitting into time periods
  return (
    <>
      {!isCurrentDay && <SectionTitle sectionTitle={sectionTitleFull} />}
      {isCurrentDay && (
        <SectionTitle
          sectionTitle={
            isAfter(currentTime, end) ? sectionTitleFull : sectionTitlePartial
          }
        />
      )}
      {events?.length > 0 ? (
        events.map((event, index) => (
          <Event
            key={`${currentTab}-${event.type}-${event.timePeriod}-${index}`}
            event={event.type}
            frequency={1}
            timePeriod={event.timePeriod}
            duration={event.duration}
          />
        ))
      ) : (
        <div className={styles.no_events}>No events</div>
      )}
    </>
  );
};

export default EventLogSection;
