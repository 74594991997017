export const filterObject = (
  obj: Record<string, any>,
  predicate: (key: string, value: any) => boolean
) => {
  var result: Record<string, any> = {},
    key: PropertyKey;

  for (key in obj) {
    if (obj.hasOwnProperty(key) && predicate(key, obj[key])) {
      result[key] = obj[key];
    }
  }

  return result;
};
