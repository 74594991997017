// DateTime
import { subDays, subMonths, startOfDay } from "date-fns";
import { DateTypes } from "../../types/Dates/dateTypes";

export const getFromDate = (
  endDate: Date,
  dateType: DateTypes
): Date | boolean => {
  return endDate && dateType === "week"
    ? subDays(endDate, 6)
    : dateType === "month"
    ? subMonths(endDate, 1)
    : startOfDay(endDate);
};
