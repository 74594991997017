import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useViewport } from "../ViewportProvider";
// Components
import { BATHROOM_QUESTIONS_AND_ANSWERS } from "./howItWorks";
import BehaviourRange from "../BehaviourRange";
import HowTheBehaviourWorks from "../HowTheBehaviourWorks";
import ComparisonModalSimple from "../ComparisonModalSimple";
import BehaviourBathroomDay from "../BehaviourBathroomDay";
// Utils
import {
  learnMore,
  bathroomRangeComparison,
} from "../../Services/Utils/Insights";
import { useFade } from "../../Services/Hooks";

const BehaviourBathroomNew = ({
  bathroomEventLogData,
  bathroomInference,
  previousDataPeriod,
  dateType,
  from,
  to,
  prevEndDate,
  prevStartDate,
  activeTab,
  changePage,
}) => {
  const { width } = useViewport();
  const onMobileView = width < 900;

  const filtersToRender = [
    {
      key: "bathroom",
      value: "Bathroom visits only",
      relatedBehaviour: "bathroom",
    },
    {
      key: "bathing",
      value: "Bath/Shower events",
      relatedBehaviour: "bathroom",
    },
    {
      key: "bathroomNighttime",
      value: "Bathroom visits at night",
      relatedBehaviour: "bathroom",
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filtersToRender[0]);
  const [showLearnMore, setShowLearnMore] = useState(false);

  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  // Generate the required insights
  const insight1 = bathroomRangeComparison(
    bathroomInference,
    dateType,
    previousDataPeriod,
    prevStartDate,
    prevEndDate,
    from,
    to,
    () => setShowAnimation(true)
  );

  const insight2 = learnMore(() => setShowLearnMore(true));
  const insights = [insight1, insight2];

  if (dateType === "day") {
    return (
      <div className={styles.bathroom_behaviour_new}>
        <BehaviourBathroomDay
          bathroomInference={bathroomInference}
          events={bathroomEventLogData}
          date={to}
          howItWorks={BATHROOM_QUESTIONS_AND_ANSWERS}
        />
      </div>
    );
  } else {
    return (
      <>
        {!showLearnMore && (
          <BehaviourRange
            data={bathroomInference}
            from={from}
            to={to}
            prevEndDate={prevEndDate}
            prevStartDate={prevStartDate}
            previousDataPeriod={previousDataPeriod}
            activeTab={activeTab}
            filtersToRender={filtersToRender}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            changePage={changePage}
            isMobile={onMobileView}
            dateType={dateType}
            howItWorks={BATHROOM_QUESTIONS_AND_ANSWERS}
            insights={insights}
          />
        )}
        {showLearnMore && (
          <HowTheBehaviourWorks
            behaviour="Bathroom"
            questionsAndAnswers={BATHROOM_QUESTIONS_AND_ANSWERS}
            hide={() => setShowLearnMore(false)}
          />
        )}
        {isVisible && (
          <ComparisonModalSimple
            hide={() => {
              setShowAnimation(false);
            }}
            behaviour={"Bathroom"}
            showAnimation={showAnimation}
            dateType={dateType}
            currentData={insight1.currentDataModal}
            previousData={insight1.previousDataModal}
          />
        )}
      </>
    );
  }
};

export default BehaviourBathroomNew;
