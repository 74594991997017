import React from "react";

import styles from "./styles.module.scss";
// Components
import Page from "../../Components/Page";
import SettingsHeader from "../../Components/SettingsHeader";
import UserNotificationSettings from "../../Components/UserNotificationSettings";

const Settings = () => {
  return (
    <Page>
      <div className={styles.content}>
        <SettingsHeader />
        <UserNotificationSettings />
      </div>
    </Page>
  );
};

export default Settings;
