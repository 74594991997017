import React from "react";

export const UpArrow = ({
  color,
  percentChange,
  width,
  height,
  strokeWidth,
}) => {
  const WIDTH = 8;
  const HEIGHT = 5;
  return (
    <svg
      id="UpArrow"
      width={percentChange ? percentChange * WIDTH : width ? width : WIDTH}
      height={percentChange ? percentChange * HEIGHT : height ? height : HEIGHT}
      viewBox="0 0 8 5"
      stroke={color ? color : "none"}
      strokeWidth={strokeWidth ? strokeWidth : 0.5}
    >
      <path
        d="M3.14559 0.979114L0.75497 3.36974C0.657964 3.46733 0.603516 3.59933 0.603516 3.73693C0.603516 3.87453 0.657964 4.00653 0.75497 4.10412C0.803388 4.15293 0.860993 4.19168 0.924461 4.21812C0.98793 4.24456 1.05601 4.25818 1.12476 4.25818C1.19352 4.25818 1.26159 4.24456 1.32506 4.21812C1.38853 4.19168 1.44614 4.15293 1.49455 4.10412L3.87997 1.70828C3.92839 1.65946 3.98599 1.62072 4.04946 1.59428C4.11293 1.56783 4.18101 1.55422 4.24976 1.55422C4.31852 1.55422 4.38659 1.56783 4.45006 1.59428C4.51353 1.62072 4.57113 1.65946 4.61955 1.70828L7.00497 4.10412C7.10235 4.20219 7.23471 4.25756 7.37292 4.25805C7.51113 4.25854 7.64387 4.2041 7.74195 4.10672C7.84002 4.00934 7.8954 3.87698 7.89588 3.73877C7.89637 3.60056 7.84194 3.46782 7.74455 3.36974L5.35393 0.979114C5.06096 0.686509 4.66383 0.522155 4.24976 0.522155C3.8357 0.522155 3.43856 0.686509 3.14559 0.979114V0.979114Z"
        fill={color || "#454545"}
      />
    </svg>
  );
};
