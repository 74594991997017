import React from "react";
import styles from "./styles.module.scss";
// Components
import NewStyleModal from "../NewStyleModal";
import ComparisonBreakdown from "../ComparisonBreakdown";

const ComparisonModalSimple = ({
  currentData,
  previousData,
  hide,
  showAnimation,
  dateType,
  behaviour,
}) => {
  const title = `${behaviour} activity compared to last ${dateType}`;
  const context = "Here you can explore the details of this insight";
  const footnote = "";
  const shouldHideForNow = true;

  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={hide}
      title={title}
      context={context}
      useFade={true}
      showAnimation={showAnimation}
    >
      <div className={styles.comparison_modal_wrapper}>
        <div className={styles.comparison_modal}>
          <div className={styles.breakdown}>
            <ComparisonBreakdown data={previousData} dateType={dateType} />
          </div>
          <div className={styles.breakdown}>
            <ComparisonBreakdown
              isCurrentPeriod
              data={currentData}
              dateType={dateType}
            />
          </div>
        </div>
        {!shouldHideForNow && <p className={styles.footnote}>{footnote}</p>}
      </div>
    </NewStyleModal>
  );
};

export default ComparisonModalSimple;
