import React from "react";
import styles from "./styles.module.scss";

const HowItWorksAnswers = ({ selected }) => {
  const title = selected.question;
  const answers = selected.answer;

  return (
    <div className={styles.answers_container}>
      <div className={styles.answers_header}>
        <h2>{title}</h2>
      </div>

      <div className={styles.answers_content}>
        {answers.map((value, index) => (
          <div className={styles.answer} key={`answer-${index}`}>
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorksAnswers;
