// Get the initials from a device userstring property
export const getInitials = (name: string | undefined): string => {
  if (!name) {
    return "";
  }
  const names = name?.split(" ").filter((i) => i.length >= 1);
  let initials = "";
  names?.forEach((name) => {
    const regexForLetters = /^[A-Za-z]+$/;
    if (regexForLetters.test(name[0])) {
      initials += name[0];
    }
  });
  return initials;
};

// Get the initials from a first name and surname string.
export const getInitialsFromNames = (
  forenames: string,
  surname: string
): string => {
  // Firstly handle where there is no data
  if (!forenames || !surname) {
    return "";
  }
  let initials = "";
  // Ignore middle names so that initials don't overflow their containers
  initials += forenames[0];
  initials += surname[0];
  return initials.toUpperCase();
};
