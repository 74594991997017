import React from "react";
import styles from "../onboardingform.module.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import FormError from "../../Components/FormError";
import CTAButton from "../../Components/CTAButton";
// utils
import { isHTML } from "../../Services/Utils";
import theme from "../../Styles/theme.scss";

const UserNotesForm = ({ onSubmit, error }) => {
  return (
    <Form
      onSubmit={(values, form) =>
        onSubmit(values.usernote, new Date().toISOString(), () => form.reset())
      }
      validate={(values) => {
        const errors = {};
        // This is super simple, but should dissuate people from trying HTML injection
        if (isHTML(values.usernote)) {
          errors.usernote = "No HTML allowed in notes";
        }
        if (values.usernote?.length > 350) {
          errors.usernote = "350 characters max";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <Field name="usernote">
            {({ input, meta }) => (
              <div data-private className={styles.textarea}>
                <textarea
                  {...input}
                  style={{
                    backgroundColor: theme.neutral1,
                    border: `1px solid ${theme.neutral2}`,
                    borderRadius: "2px",
                    fontFamily: "Inter",
                  }}
                />
                {meta.error && meta.touched && (
                  <span className={styles.error}>{meta.error}</span>
                )}
              </div>
            )}
          </Field>
          {error && <FormError error={error} />}
          <CTAButton
            type="submit"
            disabled={submitting || pristine || !valid}
            className={styles.submit}
            style={{ padding: "8px 16px" }}
            cypresstestid="Update-Service-User-Notes-Btn-Submit"
          >
            Save note
          </CTAButton>
        </form>
      )}
    />
  );
};

export default UserNotesForm;
