import { useEffect, useState } from "react";
// UTILS
import {
  dateToTimestamp,
  sortByTimestamp,
  extractRiskLevel,
  extractHourlyTemperatureData,
  extractDailyTemperatureData,
} from "@intelligentlilli/lilli-utils";
// DateTime
import { endOfDay, startOfDay, isSameDay } from "date-fns";

const extractSentences = (dailyResults, from, to) =>
  sortByTimestamp(
    dailyResults
      ?.map(({ date, sentences }) => ({ ts: dateToTimestamp(date), sentences }))
      .filter(({ ts }) => ts >= from && ts <= to)
      .filter(({ sentences }) => !!sentences)
      .flatMap(({ sentences }) => sentences) || []
  );

export const usePrepareTemperatureData = (
  tempDailyResults,
  startDateString,
  endDateString,
  dateType
) => {
  const [temperatureRisk, setTemperatureRisk] = useState();
  const [temperatureData, setTemperatureData] = useState();
  const [oneDayOfTemperatureData, setOneDayOfTemperatureData] = useState();
  const [temperatureSentences, setTemperatureSentences] = useState();

  useEffect(() => {
    const endDate = endDateString && endOfDay(new Date(endDateString));
    const startDate = startDateString && startOfDay(new Date(startDateString));
    const singleDayOfData = tempDailyResults?.find((item) =>
      isSameDay(new Date(item.date), endDate)
    );
    // Extract the relevant sentences for each behavour in the date range
    // Note: bathroom sentences are not currently generated by the backend - the sentences are formed from existing data.
    const temperatureSentences =
      dateType === "day"
        ? extractSentences(tempDailyResults, startDate, endDate)
        : [];

    setTemperatureSentences(temperatureSentences);
    setOneDayOfTemperatureData(singleDayOfData);
    setTemperatureData(
      dateType === "day"
        ? extractHourlyTemperatureData(
            "averageTemperature",
            tempDailyResults,
            startDate,
            endDate
          )
        : extractDailyTemperatureData(
            "averageTemperature",
            tempDailyResults,
            startDate,
            endDate
          )
    );
    setTemperatureRisk(extractRiskLevel(tempDailyResults, startDate, endDate));
  }, [tempDailyResults, startDateString, endDateString, dateType]);

  return {
    temperatureRisk,
    temperatureData,
    oneDayOfTemperatureData,
    temperatureSentences,
  };
};
