import { startOfDay, addHours } from "date-fns";
import {
  filterEventsByTimeStamp,
  getMinAndMax,
  getBucketNumber,
  getColorGradient,
} from "../";
import {
  getBathroomNighttimeFirstLine,
  getBathroomEventsFirstLine,
  getBathOrShowerEventsFirstLine,
} from "./";
import theme from "../../../Styles/theme.scss";

export const handleBathroom = (data, filterName) => {
  const updatedBathroomData = data?.map((day) => {
    const numberOfBathingEvents = day?.activityData?.filter(
      (bathroomEvent) => bathroomEvent?.eventType === "hygiene"
    )?.length;
    const numberOfToiletEvents = day?.activityData?.filter(
      (bathroomEvent) => bathroomEvent?.eventType === "toilet"
    )?.length;
    const toiletAndHygeine = numberOfBathingEvents + numberOfToiletEvents;
    const endDateString = startOfDay(new Date(day?.date));
    const midnight = endDateString.toISOString();
    const morning = addHours(endDateString, 6).toISOString();
    const numEarlyHourEvents = filterEventsByTimeStamp(
      day?.activityData,
      "originalStart",
      midnight,
      morning
    )?.length;
    return {
      ...day,
      total: toiletAndHygeine,
      bathing: numberOfBathingEvents,
      toilet: numberOfToiletEvents,
      earlyHourEvents: numEarlyHourEvents,
    };
  });

  const BATHROOM_MIN_VALUE = getMinAndMax(updatedBathroomData, "toilet").min;
  const BATHROOM_MAX_VALUE = getMinAndMax(updatedBathroomData, "toilet").max;

  return updatedBathroomData?.map((day) => {
    const commonBathroomObj = {
      date: day?.date,
      dayRating: day?.dayRating,
      expectedMin: day?.expectedMin,
    };
    const totalBathroom = {
      value: day?.total,
      firstLineText: getBathroomEventsFirstLine(day?.total, "bathroom"),
      secondLineText: null,
      isHighlighted: day?.total > 0,
      highlightColour: null,
      noData: getBathroomEventsFirstLine(day?.total, "bathroom") === "No data",
    };

    const total = {
      ...commonBathroomObj,
      ...totalBathroom,
    };

    switch (filterName) {
      case "total":
        return total;
      case "bathroom":
        return {
          ...commonBathroomObj,
          value: day.toilet,
          maxToiletValue: BATHROOM_MAX_VALUE,
          minToiletValue: BATHROOM_MIN_VALUE,
          firstLineText: getBathroomEventsFirstLine(day.toilet, "bathroom"),
          secondLineText: null,
          isHighlighted: day.toilet > 0,
          highlightColour: getColorGradient(
            getBucketNumber(day.toilet, BATHROOM_MAX_VALUE),
            theme.primary3
          ),
          noData:
            getBathroomEventsFirstLine(day.toilet, "bathroom") === "No data",
        };
      case "noBathingEvents":
        return {
          ...commonBathroomObj,
          value: day.bathing,
          firstLineText: getBathOrShowerEventsFirstLine(day.bathing, "bathing"),
          secondLineText: null,
          isHighlighted: day.bathing === 0,
          highlightColour: null,
          noData:
            getBathOrShowerEventsFirstLine(day.bathing, "bathing") ===
            "No data",
        };
      case "bathing":
        return {
          ...commonBathroomObj,
          value: day.bathing,
          firstLineText: getBathOrShowerEventsFirstLine(day.bathing, "bathing"),
          secondLineText: null,
          isHighlighted: day.bathing > 0,
          highlightColour: null,
          noData:
            getBathOrShowerEventsFirstLine(day.bathing, "bathing") ===
            "No data",
        };
      case "bathroomNighttime":
        return {
          ...commonBathroomObj,
          value: day?.earlyHourEvents,
          firstLineText: getBathroomNighttimeFirstLine(
            day?.earlyHourEvents,
            "bathroom visits"
          ),
          secondLineText: null,
          isHighlighted: day?.earlyHourEvents > 0,
          highlightColour: null,
          noData:
            getBathroomNighttimeFirstLine(day?.earlyHourEvents) === "No data",
        };
      default:
        return total;
    }
  });
};
