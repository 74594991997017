import {
  differenceInDays,
  addDays,
  isBefore,
  isSameDay,
  isAfter,
  format,
} from "date-fns";

interface DoorEvent {
  date: string;
  openedAt: string[];
  closedAt: string[];
  isComplete: boolean;
}

export const getCombinedMainDoorActivity = (
  mainDoorActivityData: DoorEvent[] | null | undefined,
  resData: {
    hubId: number;
    hubTimezoneString: string;
    closedAt?: string[];
    openedAt?: string[];
  },
  startDate: Date,
  endDate: Date
): DoorEvent[] => {
  // Step 1: Get an array of dates between the start and endTimes with initial object structure
  const dailyData: DoorEvent[] = [];
  const diff = differenceInDays(endDate, startDate);
  for (let i = 0; i <= diff; i++) {
    dailyData.push({
      date: format(addDays(startDate, i), "yyyy-MM-dd"),
      openedAt: [],
      closedAt: [],
      isComplete: !isSameDay(new Date(), addDays(startDate, i)), // if the date is the current date then the data is incomplete
    });
  }

  // Step 2: copy the door events that need to be added to the existing data
  const newOpenedAt = resData.openedAt ? [...resData.openedAt] : [];
  const newClosedAt = resData.closedAt ? [...resData.closedAt] : [];

  // Step 3: add the opened door events to the daily data array
  while (newOpenedAt.length > 0) {
    const doorEvent = newOpenedAt.shift(); // take the first event from the array
    // Find the correct date in the dailyData array to add it to
    const indexOfDate = dailyData.findIndex((day) =>
      isSameDay(new Date(day.date), new Date(doorEvent!)) 
      // The "!" above is a type assertion that tells TypeScript to treat the expression to its left as if it had a non-null or non-undefined type.
      // It implies that you, as the developer, are asserting that the value will not be null or undefined at runtime. 
      // We know that the value will not be null or undefined because we check for this on line 45. 
    );
    // Insert the event if the index was found
    if (indexOfDate >= 0) {
      dailyData[indexOfDate].openedAt.push(doorEvent!);
    }
  }
  // Step 4: repeat the above step for door closed events
  while (newClosedAt.length > 0) {
    const doorEvent = newClosedAt.shift();
    const indexOfDate = dailyData.findIndex((day) =>
      isSameDay(new Date(day.date), new Date(doorEvent!))
    );
    if (indexOfDate >= 0) {
      dailyData[indexOfDate].closedAt.push(doorEvent!);
    }
  }

  // Step 5: Create a copy of the old state data and ensure it is in date order
  const oldData = mainDoorActivityData ? [...mainDoorActivityData] : [];
  oldData.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  // Step 6: Split the old data into days before and days after the new data
  const dataBefore: DoorEvent[] = [];
  const dataAfter: DoorEvent[] = [];

  while (oldData.length > 0 && isBefore(new Date(oldData[0].date), startDate)) {
    const day = oldData.shift();
    dataBefore.push(day!);
  }
  while (oldData.length > 0) {
    const day = oldData.shift();
    if (isAfter(new Date(day!.date), endDate)) {
      dataAfter.push(day!);
    }
  }

  // Step 7: Combine all the data and return
  return [...dataBefore, ...dailyData, ...dataAfter];
};
