import React from "react";
// Utils
import theme from "../../theme.scss";

export const NoDataNewIcon = ({ width, viewbox, color, percentChange }) => {
  const RATIO = 16 / 20;
  return (
    <svg
      width={(percentChange && percentChange * 20) || width || 20}
      height={(percentChange && percentChange * 16) || width * RATIO || 16}
      viewBox={viewbox || "0 0 20 16"}
      id="NoDataNewIcon"
    >
      <path
        d="M11.6661 13.8333C11.6661 14.75 10.9161 15.5 9.99942 15.5C9.08276 15.5 8.33276 14.75 8.33276 13.8333C8.33276 12.9167 9.08276 12.1667 9.99942 12.1667C10.9161 12.1667 11.6661 12.9167 11.6661 13.8333ZM19.6578 4.63333L19.4328 4.4C18.1744 3.14167 16.6994 2.15833 15.0578 1.48333C14.4244 1.225 13.6911 1.525 13.4244 2.16667C13.1661 2.80833 13.4661 3.53333 14.1078 3.8C15.4411 4.34167 16.6328 5.14167 17.6578 6.16667L17.8411 6.35833C18.0828 6.61667 18.4161 6.74167 18.7494 6.74167C19.0578 6.74167 19.3744 6.625 19.6161 6.4C20.1161 5.925 20.1328 5.13333 19.6578 4.63333ZM6.57442 2.175C6.31609 1.53333 5.58276 1.23333 4.94109 1.49167C3.29942 2.15833 1.83276 3.14167 0.56609 4.40833L0.341091 4.64167C-0.133909 5.14167 -0.117243 5.93333 0.382757 6.40833C0.624424 6.64167 0.932757 6.75 1.24942 6.75C1.58276 6.75 1.90776 6.61667 2.15776 6.36667L2.34109 6.175C3.36609 5.15 4.56609 4.35 5.89109 3.80833C6.53276 3.55 6.83276 2.81667 6.57442 2.175ZM9.99942 10.5C10.6911 10.5 11.2494 9.94167 11.2494 9.25V1.75C11.2494 1.05833 10.6911 0.5 9.99942 0.5C9.30776 0.5 8.74942 1.05833 8.74942 1.75V9.25C8.74942 9.94167 9.30776 10.5 9.99942 10.5ZM4.94942 7.95L4.53276 8.36667C4.29109 8.60833 4.16609 8.93333 4.16609 9.25C4.16609 9.56667 4.29109 9.89167 4.53276 10.1333C4.77442 10.375 5.09942 10.5 5.41609 10.5C5.73276 10.5 6.05776 10.375 6.29942 10.1333L6.71609 9.71667C6.95776 9.475 7.08276 9.15 7.08276 8.83333C7.08276 8.51667 6.95776 8.19167 6.71609 7.95C6.22442 7.45833 5.43276 7.45833 4.94942 7.95ZM15.0494 7.95C14.5578 7.45833 13.7661 7.45833 13.2828 7.95C13.0411 8.19167 12.9161 8.51667 12.9161 8.83333C12.9161 9.15 13.0411 9.475 13.2828 9.71667L13.6994 10.1333C13.9411 10.375 14.2661 10.5 14.5828 10.5C14.8994 10.5 15.2244 10.375 15.4661 10.1333C15.7078 9.89167 15.8328 9.56667 15.8328 9.25C15.8328 8.93333 15.7078 8.60833 15.4661 8.36667L15.0494 7.95Z"
        fill={color || theme.neutral7}
      />
    </svg>
  );
};
