import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { AutoSizer } from "react-virtualized";
import { mobileBreakpoint } from "../../Services/config";
// Utils
import { getAgeFromDobString } from "@intelligentlilli/lilli-utils";
import { format } from "date-fns";
// Components
import { useViewport } from "../ViewportProvider";
import ServiceUserData from "../ServiceUserData";
import CareId from "../CareId";
import SquareAvatar from "../SquareAvatar";
import SecondaryButton from "../SecondaryButton";
import Line from "../Line";
// Icons
import {
  DownArrow,
  EditProfileIcon,
  EyeIcon,
  UpArrow,
} from "../../Styles/Icons/DesignSystem";
import { AssignmentIcon } from "../../Styles/Icons";

const getConditionsToRender = (conditions, widthAvailable) => {
  const spaceRequiredForEndNumber = 20;
  const gapAndPaddingPerCondition = 15;
  const characterWidth = 6;

  const calcRequredWidth = (conditions1) =>
    conditions1.length * gapAndPaddingPerCondition +
    spaceRequiredForEndNumber +
    conditions1.reduce((acc, condition) => acc + condition.length, 0) *
      characterWidth;

  if (widthAvailable < spaceRequiredForEndNumber) {
    return [];
  }

  const conditionsCopy = conditions ? [...conditions] : [];
  let widthRequired = calcRequredWidth(conditionsCopy);
  while (widthRequired > widthAvailable) {
    conditionsCopy.pop();
    widthRequired = calcRequredWidth(conditionsCopy);
  }
  const numberRemoved = conditions.length - conditionsCopy.length;

  if (numberRemoved) {
    if (numberRemoved === conditions.length) {
      conditionsCopy.push(`${numberRemoved} conditions`);
    } else {
      conditionsCopy.push(`+${numberRemoved}`);
    }
  }
  return conditionsCopy;
};

export { getConditionsToRender };

const ServiceUserInfo = ({
  serviceUser,
  setEditProfile,
  setNewReport,
  setEditNotes,
  pdf,
  isInstallerOnly,
}) => {
  const { width } = useViewport();
  const onMobile = width <= mobileBreakpoint;

  const [expanded, setExpanded] = useState(false);

  const rawCareType = serviceUser?.serviceUserProperties?.careType;
  const rawConditions = serviceUser?.serviceUserProperties?.conditions;

  const hubInstallationDate = serviceUser?.ts;
  const hasConditions = !!rawConditions;
  const hasCareTypes = !!rawCareType;

  const conditions = hasConditions ? rawConditions?.split(", ") : [];
  const careTypes = hasCareTypes ? rawCareType.split(", ") : [];
  const DOB = serviceUser?.serviceUserProperties?.dateOfBirth;
  // Create a regex to ensure the date is in the format YYYY-MM-DD
  const dateRegex = new RegExp(
    /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
  );
  const dateIsValid = dateRegex.test(DOB);
  const age = dateIsValid
    ? getAgeFromDobString(serviceUser?.serviceUserProperties?.dateOfBirth)
    : "? yrs";

  const hasHardware =
    serviceUser?.serviceUserProperties?.installationStatus === "complete";

  const hasDOB = age != null;
  const careID =
    serviceUser?.serviceUserProperties?.mosaicID ||
    serviceUser?.serviceUserProperties?.careId ||
    serviceUser?.hubProperties?.mosaicId ||
    serviceUser?.hubProperties?.careID ||
    "Not set";

  return (
    <div
      className={`${
        expanded && onMobile ? styles.wrapper : styles.wrapper_collapsed
      }`}
    >
      <div className={styles.SUInfo}>
        <div data-private className={styles.SUInfo_left}>
          <div className={styles.SUInfo_bio}>
            <SquareAvatar photo={serviceUser?.photo} />
            <div className={styles.SUInfo_bio_name}>
              <h1>
                {hasDOB
                  ? serviceUser?.userstring + ", " + age
                  : serviceUser?.userstring}
              </h1>
              <div className={styles.careId}>
                <CareId careID={careID} />
              </div>
              {expanded && onMobile && (
                <div className={styles.grey_text}>Conditions:</div>
              )}
              {!expanded && (
                <AutoSizer>
                  {({ width }) => (
                    <div className={styles.mobile_conditions}>
                      {getConditionsToRender(conditions, width).map(
                        (condition, index) => {
                          return (
                            <div
                              className={styles.mobile_conditions_condition}
                              key={index}
                            >
                              {condition}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </AutoSizer>
              )}
            </div>
          </div>
          <div className={styles.conditions}>
            <ServiceUserData serviceUser={serviceUser} pdf={pdf} />
          </div>
        </div>
        {/* Larger than mobile view */}
        {!isInstallerOnly && (
          <div className={styles.buttons}>
            <SecondaryButton
              startIcon={<EditProfileIcon colour={theme.primary6} />}
              className={styles.button}
              onClick={setEditProfile}
            >
              Edit profile
            </SecondaryButton>

            {hasHardware && (
              <SecondaryButton
                startIcon={<AssignmentIcon />}
                className={styles.button}
                onClick={setNewReport}
              >
                New report
              </SecondaryButton>
            )}

            <SecondaryButton
              startIcon={<EyeIcon colour={theme.primary6} />}
              className={styles.button}
              onClick={setEditNotes}
            >
              View/Add notes
            </SecondaryButton>
          </div>
        )}

        <SecondaryButton
          className={styles.expand}
          onClick={() => {
            setExpanded((prevExpanded) => !prevExpanded);
          }}
        >
          {expanded && onMobile ? (
            <UpArrow color="#979797" width={16} height={10} />
          ) : (
            <DownArrow color="#979797" width={16} height={10} />
          )}
        </SecondaryButton>
      </div>
      {expanded && onMobile && (
        <>
          <div
            data-private
            className={styles.care_type_and_conditions_container}
          >
            <div className={styles.mobile_conditions_wrap}>
              {conditions.map((condition, index) => (
                <div className={styles.mobile_conditions_condition} key={index}>
                  {condition}
                </div>
              ))}
              {!hasConditions && (
                <div className={styles.none_listed}>None listed</div>
              )}
            </div>
            <div className={`${styles.grey_text} ${styles.margin_bottom}`}>
              Care type:
            </div>
            {hasCareTypes && (
              <div className={styles.mobile_conditions_wrap}>
                {careTypes.map((condition, index) => (
                  <div className={styles.mobile_conditions_care} key={index}>
                    {condition}
                  </div>
                ))}
              </div>
            )}
            {!hasCareTypes && (
              <div className={styles.none_listed}>None listed</div>
            )}
          </div>
        </>
      )}
      {!expanded && <div className={styles.animationSpacer} />}
      <div data-private className={styles.care_contacts_container}>
        <div className={styles.dark_grey_text}>Care contacts</div>
        <Line />
        <div className={`${styles.row} ${styles.margin}`}>
          <div className={styles.neutral7_text}>Next of kin</div>
          <div className={styles.grey_text}>
            {serviceUser?.serviceUserProperties.nextOfKin || "None"}
          </div>
        </div>
        <div className={`${styles.row} ${styles.margin}`}>
          <div className={styles.neutral7_text}>Doctor</div>
          <div className={styles.grey_text}>
            {serviceUser?.serviceUserProperties.doctor || "None"}
          </div>
        </div>
        <Line />
        <div
          className={`${styles.row} ${styles.thin_grey_text} ${styles.margin_top}`}
        >
          <div>Care ID: {careID || "unknown"}</div>
          <div>
            User created:{" "}
            {hubInstallationDate
              ? `${format(new Date(hubInstallationDate), "dd.MM.yyyy")}`
              : "unknown"}
          </div>
        </div>
      </div>
    </div>
  );
};

ServiceUserInfo.propTypes = {
  serviceUser: PropTypes.object,
  hubId: PropTypes.string,
  setEditProfile: PropTypes.func,
  profilePhoto: PropTypes.string,
};

export default ServiceUserInfo;
