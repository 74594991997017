export const get12hFrom24h = (startTime, endTime) => {
  const hours = {
    "0:00": "12am",
    "1:00": "1am",
    "2:00": "2am",
    "3:00": "3am",
    "4:00": "4am",
    "5:00": "5am",
    "6:00": "6am",
    "7:00": "7am",
    "8:00": "8am",
    "9:00": "9am",
    "10:00": "10am",
    "11:00": "11am",
    "12:00": "12pm",
    "13:00": "1pm",
    "14:00": "2pm",
    "15:00": "3pm",
    "16:00": "4pm",
    "17:00": "5pm",
    "18:00": "6pm",
    "19:00": "7pm",
    "20:00": "8pm",
    "21:00": "9pm",
    "22:00": "10pm",
    "23:00": "11pm",
  };

  return `${hours[startTime]} - ${hours[endTime]}`;
};
