import React from "react";
import styles from "./styles.module.scss";

const InsightBathroomDuration = ({ hours, minutes }) => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <h2>
          {hours > 0 && `${hours} hour${hours === 1 ? "" : "s"} `}
          {`${minutes} min${minutes === 1 ? "" : "s"}`}
        </h2>
        <div>spent in the bathroom today</div>
      </div>
    </div>
  );
};

export default InsightBathroomDuration;
