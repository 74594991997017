import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Field } from "react-final-form";

const OneTimePasscode = ({ fieldName }) => {
  const inputRefs = useRef([]);

  const handlePaste = (e, input) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6).split("");
    input.onChange(pastedData);
  };

  const handleKeyDown = (e, input, index) => {
    if (e.key === "Backspace" && index > 0 && !input.value[index]) {
      // If backspace is pressed and the current input is empty, clear the preceding input
      const newDigits = [...input.value];
      newDigits[index - 1] = "";
      input.onChange(newDigits);
      inputRefs.current[index - 1].focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      // Focus the previous input when the left arrow key is pressed
      inputRefs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      // Focus the next input when the right arrow key is pressed
      inputRefs.current[index + 1].focus();
    }
  };

  const handleChange = (e, input, index) => {
    const newDigits = [...input.value];
    newDigits[index] = e.target.value;
    input.onChange(newDigits);
    if (e.target.value && index < 5) {
      // Automatically focus the next input if the current one is filled
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <Field name={fieldName} parse={(value) => value.slice(0, 6)}>
      {({ input, meta }) => (
        <div className={styles.digits}>
          <div class={styles.six_digit_input}>
            {Array.from({ length: 6 }).map((_, index) => (
              <input
                className={styles.otp_field}
                key={index}
                type="text"
                maxLength={1}
                value={input.value[index] || ""}
                onChange={(e) => handleChange(e, input, index)}
                onPaste={(e) => handlePaste(e, input)}
                onKeyDown={(e) => handleKeyDown(e, input, index)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          <div>
            {meta.error && meta.touched && (
              <span className={styles.error}>{meta.error}</span>
            )}
          </div>
        </div>
      )}
    </Field>
  );
};

export default OneTimePasscode;
