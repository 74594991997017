import React from "react";
import styles from "./styles.module.scss";

const Toggle = ({
  containerClassName,
  optionClassName,
  optionFocusedClassName,
  clickRight,
  clickLeft,
  leftSelected,
  leftText,
  rightText,
}) => {
  return (
    <div className={containerClassName ? containerClassName : styles.container}>
      <button
        onClick={() => clickLeft()}
        className={
          !leftSelected
            ? `${optionClassName ? optionClassName : styles.option}`
            : `${
                optionFocusedClassName
                  ? optionFocusedClassName
                  : styles.option_focused
              }`
        }
      >
        <div className={!leftSelected ? styles.text : styles.text_focused}>
          {leftText}
        </div>
      </button>
      <button
        onClick={() => clickRight()}
        className={
          leftSelected
            ? `${optionClassName ? optionClassName : styles.option}`
            : `${
                optionFocusedClassName
                  ? optionFocusedClassName
                  : styles.option_focused
              }`
        }
      >
        <div className={leftSelected ? styles.text : styles.text_focused}>
          {rightText}
        </div>
      </button>
    </div>
  );
};

export default Toggle;
