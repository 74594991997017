import React, { useState } from "react";
import styles from "./styles.module.scss";
import Backdrop from "../../Backdrop";
import DateRangePicker from "../../DateRangePicker";
import AltSpinner from "../../Spinners/AltSpinner";

const Step3 = ({
  availableDateRange,
  dateRange,
  setDateRange,
  showValidationError,
  dateRangeError,
}) => {
  const [showPicker, setShowPicker] = useState(false);

  return (
    <>
      {availableDateRange === null && (
        <div className={styles.timeframe}>
          <h2>
            Sorry, we were unable to retrieve the available date range for this
            report type.
          </h2>
          <p>Please try again later.</p>
        </div>
      )}
      {availableDateRange === "loading" && (
        <div className={styles.loading}>
          <AltSpinner large />
          <h1>Retrieving available date range...</h1>
          <p>Please wait while we retrieve the available date range.</p>
        </div>
      )}
      {availableDateRange && availableDateRange !== "loading" && (
        <div className={styles.timeframe}>
          <Backdrop
            show={showPicker}
            onClick={() => setShowPicker(false)}
            className={styles.backdrop}
          />
          <h2>Select a timeframe for your report</h2>

          <DateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            showPicker={showPicker}
            minDate={
              new Date(availableDateRange.availableTimeRanges[0].startDate)
            }
            maxDate={
              new Date(availableDateRange.availableTimeRanges[0].endDate)
            }
            setShowPicker={setShowPicker}
            highlightRequired={
              dateRange.dateYetToBeSelected && showValidationError
            }
          />
          {dateRange.dateYetToBeSelected && showValidationError && (
            <p className={styles.required}>Required</p>
          )}
          {dateRangeError && (
            <p className={styles.required}>
              Behaviour reports can have a maximum range of 31 days
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default Step3;
