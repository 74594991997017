// Function for toggling filters on and off
export const toggleFilter = (id, type, setFilters) => {
  setFilters((state) =>
    state.map((filterObject) => {
      if (filterObject.type === type) {
        return {
          ...filterObject,
          filters: filterObject.filters.map((filter) => {
            if (filter.id === id) {
              return { ...filter, filtered: !filter.filtered };
            }
            return filter;
          }),
        };
      }
      return filterObject;
    })
  );
};
