import React from "react";
import theme from "../../theme.scss";

export const FAQIcon = ({ width, colour }) => {
  return (
    <svg width={width || 30} viewBox="0 0 50 50" id="FAQIcon">
      <path
        d="M28.1247 13.5414C28.1247 14.3702 27.7955 15.165 27.2095 15.7511C26.6234 16.3371 25.8285 16.6664 24.9997 16.6664C24.1709 16.6664 23.3761 16.3371 22.79 15.7511C22.204 15.165 21.8747 14.3702 21.8747 13.5414C21.8747 12.7126 22.204 11.9177 22.79 11.3317C23.3761 10.7456 24.1709 10.4164 24.9997 10.4164C25.8285 10.4164 26.6234 10.7456 27.2095 11.3317C27.7955 11.9177 28.1247 12.7126 28.1247 13.5414ZM49.9997 39.583V25.708C50.0775 19.2861 47.7229 13.0724 43.4087 8.31464C39.0946 3.55689 33.1403 0.60728 26.7414 0.0580356C23.1698 -0.191844 19.5863 0.328327 16.2332 1.58335C12.88 2.83838 9.83559 4.79899 7.30582 7.33251C4.77604 9.86604 2.81995 12.9134 1.5699 16.2684C0.319845 19.6233 -0.195013 23.2077 0.0601614 26.7789C0.980995 40.0164 12.6706 49.9997 27.256 49.9997H39.5831C42.3447 49.9964 44.9923 48.8979 46.9451 46.9451C48.8979 44.9923 49.9964 42.3447 49.9997 39.583ZM26.4581 4.21637C31.8049 4.68936 36.7746 7.16827 40.3687 11.155C43.9628 15.1418 45.915 20.341 45.8331 25.708V39.583C45.8331 41.2406 45.1746 42.8304 44.0025 44.0025C42.8304 45.1746 41.2407 45.833 39.5831 45.833H27.256C14.6852 45.833 4.99975 37.708 4.21849 26.4914C4.01339 23.6342 4.39969 20.7655 5.35325 18.0644C6.30681 15.3633 7.80715 12.8879 9.76052 10.7928C11.7139 8.69765 14.0783 7.02786 16.7061 5.88772C19.3339 4.74758 22.1686 4.1616 25.0331 4.16637C25.506 4.16637 25.9831 4.18512 26.4581 4.21637ZM29.1664 37.4997V24.9997C29.1664 23.8946 28.7274 22.8348 27.946 22.0534C27.1646 21.272 26.1048 20.833 24.9997 20.833H22.9164C22.3639 20.833 21.834 21.0525 21.4433 21.4432C21.0526 21.8339 20.8331 22.3638 20.8331 22.9164C20.8331 23.4689 21.0526 23.9988 21.4433 24.3895C21.834 24.7802 22.3639 24.9997 22.9164 24.9997H24.9997V37.4997C24.9997 38.0522 25.2192 38.5821 25.6099 38.9728C26.0006 39.3635 26.5305 39.583 27.0831 39.583C27.6356 39.583 28.1655 39.3635 28.5562 38.9728C28.9469 38.5821 29.1664 38.0522 29.1664 37.4997Z"
        fill={colour || theme.white}
      />
    </svg>
  );
};
