const getRiskStatus = (sentenceString) => {
  const string = sentenceString?.toLowerCase();
  const keywords = ["at risk", "unexpected"];
  return keywords.find((keyword) => string?.includes(keyword)) || null;
};

const getBehaviour = (string2) => {
  const keywords = [
    "movement",
    "sustenance",
    "temperature",
    "independence",
    "bathroom",
    "sleep",
  ];
  const result = keywords.find((keyword) => string2?.includes(keyword)) || null;
  return result;
};

export const formatNotificationsResponse = (rawNotifications) => {
  const result = rawNotifications.flatMap((notification) => {
    return notification.sentences.map((sentence) => ({
      ...notification,
      sentence: sentence,
      riskStatus: getRiskStatus(sentence),
      behaviour: getBehaviour(sentence),
    }));
  });
  return result;
};
