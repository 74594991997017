import { getHoursAndMinutesAsString } from "../getHoursAndMinutesAsString";

const filterAfterAndBeforeHour = (
  arrayOfObjs,
  afterTimeAsNumber,
  beforeTimeAsNumber
) => {
  return arrayOfObjs?.filter((obj) => {
    // Extract the time portion from the timestamp
    const time = new Date(obj?.ts);
    return (
      time.getHours() >= afterTimeAsNumber &&
      time.getHours() < beforeTimeAsNumber
    );
  });
};

export const getTimeOutsideDuringTheDay = (independenceData, userString) => {
  // peice together 6am to 10pm data using today's data only
  const tenPM = 22;
  const sixAM = 6;
  const todaySixAmToTenPM =
    filterAfterAndBeforeHour(independenceData, sixAM, tenPM) || [];

  // sum the time outside at night
  const timeOutsideDuringTheDay = todaySixAmToTenPM.reduce((acc, curr) => {
    return acc + (curr?.value || 0);
  }, 0);
  // convert the time outside at night to a string
  const timeOutsideDuringTheDayAsString = getHoursAndMinutesAsString(
    timeOutsideDuringTheDay,
    false
  );
  const didNotLeaveTheHouse = timeOutsideDuringTheDay === 0;

  const dataPresent = !!independenceData;
  if (!dataPresent) {
    return {
      offline: true,
      title: "Time outside at night",
      icon: "OFFLINE",
      context: "No data available for this insight",
    };
  }

  return {
    title: "Time outside during the day",
    headline: timeOutsideDuringTheDayAsString,
    icon: "INDEPENDENCE-DAY",
    context: didNotLeaveTheHouse
      ? `Since 6am today, ${userString} did not leave the house.`
      : `Since 6am today, ${userString} left the house for ${timeOutsideDuringTheDayAsString}.`,
  };
};
