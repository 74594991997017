import React from "react";
import styles from "../onboardingform.module.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import FormError from "../../Components/FormError";
import CTAButton from "../../Components/CTAButton";
// utils
import { isValidName } from "../../Services/Utils";

const ChangeNameForm = (props) => (
  <Form
    onSubmit={(values) => {
      props.onSubmit(values.forenames, values.surname);
    }}
    validate={(values) => {
      const errors = {};
      if (!values.forenames) {
        errors.forenames = "Required";
      }
      if (values.forenames && !isValidName(values.forenames)) {
        errors.forenames = "Invalid forename";
      }
      if (!values.surname) {
        errors.surname = "Required";
      }
      if (values.surname && !isValidName(values.surname)) {
        errors.surname = "Invalid surname";
      }
      return errors;
    }}
    render={({ handleSubmit, submitting, valid, values }) => (
      <form
        className={styles.form}
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <div>
          <Field name="forenames" initialValue={props.forenames}>
            {({ input, meta }) => (
              <div className={styles.input}>
                <label>
                  Forename(s)
                  <input {...input} type="text" />
                </label>
                {meta.error && meta.touched && (
                  <span className={styles.error}>{meta.error}</span>
                )}
              </div>
            )}
          </Field>
          <Field name="surname" initialValue={props.surname}>
            {({ input, meta }) => (
              <div className={styles.input}>
                <label>
                  Surname
                  <input {...input} type="text" />
                </label>
                {meta.error && meta.touched && (
                  <span className={styles.error}>{meta.error}</span>
                )}
              </div>
            )}
          </Field>
          {props.error && <FormError error={props.error} />}
        </div>

        <CTAButton
          type="submit"
          disabled={
            submitting ||
            !valid ||
            (values.surname === props.surname &&
              values.forenames === props.forenames)
          }
          className={styles.submit}
          cypresstestid="Change-Name-Btn-Submit"
        >
          Update name
        </CTAButton>
      </form>
    )}
  />
);

export default ChangeNameForm;
