import {
  AtRiskIcon as AtRiskIconOld,
  UnexpectedIcon as UnexpectedIconOld,
  ExpectedIcon as ExpectedIconOld,
} from "../../../Styles/Icons";
import {
  AtRiskIcon,
  UnexpectedIcon,
  ExpectedIcon,
} from "../../../Styles/Icons/DesignSystem";

export const RiskLevelIcon = {
  EXPECTED: ExpectedIconOld,
  UNEXPECTED: UnexpectedIconOld,
  AT_RISK: AtRiskIconOld,
};

// Add the new design system icons for risk level. The RiskLevelIcon object can be deleted once all risk icons are switched over to the new design system
export const RiskLevelIconNew = {
  EXPECTED: ExpectedIcon,
  UNEXPECTED: UnexpectedIcon,
  AT_RISK: AtRiskIcon,
};
