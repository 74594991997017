import React from "react";
import theme from "../../theme.scss";
import { LightBulbIcon } from "./LightBulbIcon";

export const LearnMoreIcon = () => {
  return (
    <div
      style={{
        width: "60px",
        minWidth: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.support2,
        borderRadius: "50%",
      }}
    >
      <LightBulbIcon />
    </div>
  );
};
