import CTAButton from "../CTAButton";
import theme from "../../Styles/theme.scss";

const InstallationGoBackButton = ({
  label,
  onClick,
  disabled,
  isDesktop,
  textColor,
  marginRight,
  marginTop,
  marginBottom,
  icon,
}) => {
  return (
    <CTAButton
      style={{
        width: "100%",
        padding: "12px",
        marginRight: `${marginRight || "0px"}`,
        marginTop: `${marginTop || "0px"}`,
        marginBottom: `${marginBottom || "0px"}`,
        backgroundColor: "white",
        color: `${textColor || `${theme.neutral7}`}`,
        fontWeight: `${isDesktop ? "400" : "500"}`,
        borderRadius: "8px",
        border: `1px solid ${theme.primary3}`,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {label || "Go back"}
    </CTAButton>
  );
};

export default InstallationGoBackButton;
