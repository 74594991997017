import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// API
import {
  postHubAssignDevice,
  postHubProperty,
  // postDecommissionHub,
} from "@intelligentlilli/api-layer";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setRefreshOrganisationHubs,
  setRefreshUser,
} from "../../../State/slices/session-slice";
import {
  updateServiceUser,
  updateServiceUserProperties,
} from "../../../State/slices/serviceUsers-slice";
// Utils
import { getInitials } from "@intelligentlilli/lilli-utils";
// Components
import Page from "../../../Components/Page";
import { ManuallyEnterQRCode, ScanQRCode } from "../../../Components/InstallQR";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import InstallQRLoading from "../../../Components/InstallQRLoading";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
// Icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";
import InstallationNextButton from "../../../Components/InstallationNextButton";

const InstallLinkDevice = () => {
  const { id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  // sizes
  const { width } = useViewport();
  const isMobile = width <= 600;
  const isBiggerThan500 = width > 500;
  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const selectedSuObject = serviceUsers.filter((i) => i.id === parseInt(id));
  const indexOfSU = serviceUsers.findIndex(
    (serviceUser) => serviceUser.id.toString() === id
  );
  const selectedSUName = selectedSuObject?.[0]?.userstring || "user";
  const serviceUserInitials = getInitials(selectedSUName) || "";

  // Local state
  const [devicestringError, setDevicestringError] = useState();
  const [stage, setStage] = useState("start");
  const [deviceId, setDeviceId] = useState(false);
  const [brokenQRCode, setBrokenQRCode] = useState(false);

  // redux state
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);

  const onLinkDevice = async (deviceId) => {
    try {
      // API call passing the device string and hubId
      setStage("loading");
      if (selectedSuObject?.[0]?.devicestring) {
        // await postDecommissionHub(server, id, "web");
      }
      const res = await postHubAssignDevice(server, id, deviceId, "web");
      if (res.ok) {
        setStage("success");
        setDeviceId(deviceId);
        postHubProperty(server, id, "InstallationStatus", "started", "web");
      } else {
        setDevicestringError(res.body.error);
        if (res.status === 401) {
          navigate("/login");
        }
      }
    } catch (error) {
      setStage("fail");
      setDeviceId(false);
    }
  };

  return (
    <Page className={styles.page}>
      <InstallationCard
        className={`${
          stage === "start"
            ? styles.start_screen_flex_direction
            : styles.flex_direction
        }`}
      >
        {stage === "start" && ( // "I.e. Just arrived onto the page, has not attempted a link"
          <div>
            <div className={styles.header}>
              <h2>Connect to Lilli kit</h2>
              <p>Scan the QR code on the bottom of the Lilli kit</p>
            </div>
            <div className={styles.start}>
              <div className={styles.card_left}>
                <ScanQRCode
                  devicestringError={devicestringError}
                  onLinkDevice={onLinkDevice}
                />
              </div>
              <div className={styles.card_right}>
                <ManuallyEnterQRCode
                  brokenQRCode={brokenQRCode}
                  setBrokenQRCode={setBrokenQRCode}
                  onLinkDevice={onLinkDevice}
                  devicestringError={devicestringError}
                  buttonClass={styles.buttons}
                  isDesktop={isBiggerThan500}
                />
              </div>
            </div>
            <div className={styles.install_flow_buttons}>
              <InstallationGoBackButton
                label={"Exit install"}
                onClick={() => navigate("/install")}
                isDesktop={!isMobile}
              />
              <InstallationNextButton
                label="Next"
                isDesktop={!isMobile}
                type="submit"
                onClick={() => {
                  // as the form is separate from the submit button, further down the DOM tree, you have to submit the form this way: https://final-form.org/docs/react-final-form/faq
                  document
                    .getElementById("qr-code-install")
                    .dispatchEvent(
                      new Event("submit", { cancelable: true, bubbles: true })
                    );
                }}
              />
            </div>
          </div>
        )}
        {stage === "loading" && (
          <div className={styles.qr_loading_container}>
            <div>
              <h2 className={styles.connecting_user_to_hub}>Connecting...</h2>
              <p>
                Please wait while we assign this Lilli user to the Lilli kit.
              </p>
            </div>
            <InstallQRLoading initials={serviceUserInitials} />
            <InstallationGoBackButton
              label="Cancel"
              onClick={() => navigate("/install")}
              isDesktop={!isMobile}
              marginTop={"24px"}
            />
          </div>
        )}
        {stage === "success" && (
          <div className={styles.card_right}>
            <InstallationSplash
              icon={<ThumbsUpIcon color={theme.expected2} />}
              headers={["Great!", "Your Lilli user is connected"]}
              message={"Let's get your hub connected"}
              goBackButtonLabel={"Exit install"}
              goBackOnClick={() => navigate("/install")}
              nextButtonLabel={"Continue"}
              nextButtonOnClick={() => {
                dispatch(
                  updateServiceUserProperties({
                    index: indexOfSU,
                    key: "installationStatus",
                    value: "started",
                  })
                );
                dispatch(
                  updateServiceUser({
                    index: indexOfSU,
                    key: "devicestring",
                    value: `${deviceId}`,
                  })
                );
                navigate(`/install/sensors/${id}/${deviceId}`);
              }}
              isDesktop={!isMobile}
            />
          </div>
        )}
        {stage === "fail" && (
          <div className={styles.card_right}>
            <InstallationSplash
              icon={<ThumbsDownIcon color={theme.risk3} />}
              headers={["Uh-oh!", "Something went wrong"]}
              message={
                "Let's try again, but if the problem continues, contact "
              }
              email={"support@intelligentlilli.com"}
              goBackButtonLabel={"Exit install"}
              goBackOnClick={() => {
                dispatch(setRefreshUser(true));
                dispatch(setRefreshOrganisationHubs(true));
                navigate("/install");
              }}
              nextButtonLabel={"Try again"}
              nextButtonOnClick={() => setStage("start")}
              isDesktop={!isMobile}
            />
          </div>
        )}
      </InstallationCard>
    </Page>
  );
};

export default InstallLinkDevice;
