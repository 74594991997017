import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";

const BurgerMenuIcon = (props) => (
  <div className={styles.burgerMenu_icon} onClick={props.onClick}>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

BurgerMenuIcon.propTypes = {
  onClick: PropTypes.func,
};

export default BurgerMenuIcon;
