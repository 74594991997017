import React from "react";
import theme from "../../theme.scss";

export const CalendarIcon = ({ width, colour }) => {
  return (
    <svg
      width={width || 18}
      height={width || 18}
      viewBox="0 0 18 18"
      fill="none"
      id="CalendarIcon"
    >
      <g>
        <path
          d="M14.25 1.5H13.5V0.75C13.5 0.551088 13.421 0.360322 13.2803 0.21967C13.1397 0.0790176 12.9489 0 12.75 0C12.5511 0 12.3603 0.0790176 12.2197 0.21967C12.079 0.360322 12 0.551088 12 0.75V1.5H6V0.75C6 0.551088 5.92098 0.360322 5.78033 0.21967C5.63968 0.0790176 5.44891 0 5.25 0C5.05109 0 4.86032 0.0790176 4.71967 0.21967C4.57902 0.360322 4.5 0.551088 4.5 0.75V1.5H3.75C2.7558 1.50119 1.80267 1.89666 1.09966 2.59966C0.396661 3.30267 0.00119089 4.2558 0 5.25L0 14.25C0.00119089 15.2442 0.396661 16.1973 1.09966 16.9003C1.80267 17.6033 2.7558 17.9988 3.75 18H14.25C15.2442 17.9988 16.1973 17.6033 16.9003 16.9003C17.6033 16.1973 17.9988 15.2442 18 14.25V5.25C17.9988 4.2558 17.6033 3.30267 16.9003 2.59966C16.1973 1.89666 15.2442 1.50119 14.25 1.5ZM1.5 5.25C1.5 4.65326 1.73705 4.08097 2.15901 3.65901C2.58097 3.23705 3.15326 3 3.75 3H14.25C14.8467 3 15.419 3.23705 15.841 3.65901C16.2629 4.08097 16.5 4.65326 16.5 5.25V6H1.5V5.25ZM14.25 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V7.5H16.5V14.25C16.5 14.8467 16.2629 15.419 15.841 15.841C15.419 16.2629 14.8467 16.5 14.25 16.5Z"
          fill={colour || theme.neutral5}
        />
        <path
          d="M9 12.375C9.62132 12.375 10.125 11.8713 10.125 11.25C10.125 10.6287 9.62132 10.125 9 10.125C8.37868 10.125 7.875 10.6287 7.875 11.25C7.875 11.8713 8.37868 12.375 9 12.375Z"
          fill={colour || theme.neutral5}
        />
        <path
          d="M5.24988 12.375C5.8712 12.375 6.37488 11.8713 6.37488 11.25C6.37488 10.6287 5.8712 10.125 5.24988 10.125C4.62856 10.125 4.12488 10.6287 4.12488 11.25C4.12488 11.8713 4.62856 12.375 5.24988 12.375Z"
          fill={colour || theme.neutral5}
        />
        <path
          d="M12.7501 12.375C13.3714 12.375 13.8751 11.8713 13.8751 11.25C13.8751 10.6287 13.3714 10.125 12.7501 10.125C12.1288 10.125 11.6251 10.6287 11.6251 11.25C11.6251 11.8713 12.1288 12.375 12.7501 12.375Z"
          fill={colour || theme.neutral5}
        />
      </g>
    </svg>
  );
};
