import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useParams } from "react-router-dom";
// State
import { useDispatch, useSelector } from "react-redux";
// Components
import Card from "../Card";
import AltSpinner from "../Spinners/AltSpinner";
import SecondaryButton from "../SecondaryButton";
import Sensor from "../Sensor";
import { EditIcon } from "../../Styles/Icons";
// Hooks
import { useGetSensorStatus } from "../../Services/Hooks";
import PrimaryButton from "../PrimaryButton";
import { postHubProperty } from "@intelligentlilli/api-layer";
// State
import { setLoading } from "../../State/slices/session-slice";
import { setSensors } from "../../State/slices/sensors-slice";
import { updateServiceUser } from "../../State/slices/serviceUsers-slice";

const DevicesOverview = (props) => {
  const { id } = useParams(); // Get the service user id from the url parameter

  // redux
  const dispatch = useDispatch();
  const sensors = useSelector((state) => state.sensors);
  const server = useSelector((state) => state.session.server);

  // On load clear any existing sensor data from the sensors state as it may relate to another service user and may be out of date.
  useEffect(() => {
    dispatch(setSensors(null));
  }, [dispatch]);

  // Extracting service user data
  const serviceUser = props.serviceUser;
  // Extracting sensors/devices from the sensors list
  const devicesLessHub = sensors?.devices?.filter(
    (device) => device.defaultName !== "SquidZigBee"
  );

  // Remove any sensors from the list that have been removed
  const activeDevices = devicesLessHub?.filter(
    (device) => !device.name.includes("REMOVED")
  );

  // local state
  const [editingNotes, setEditingNotes] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [notes, setNotes] = useState(
    serviceUser?.serviceUserProperties?.installationNotes || ""
  );

  // Trigger the sensor status fetching
  const { loading, fetchSensorStatus, error } = useGetSensorStatus({
    devicestring: serviceUser.devicestring,
    id: id,
    continuous: false,
  });

  // Data manipulations
  const firstSetOfSensors = getHalfASetOfSensors(activeDevices, 1);
  const secondSetOfSensors = getHalfASetOfSensors(activeDevices, 2);

  // Update installation notes
  const updateNotes = (note) => {
    dispatch(setLoading(true));
    postHubProperty(server, id, "InstallationNotes", note, "web")
      .then((res) => {
        if (res.ok) {
          // Update redux notes for the service user
          dispatch(
            updateServiceUser({
              index: props.indexOfSU,
              key: "serviceUserProperties",
              value: {
                ...serviceUser?.serviceUserProperties,
                installationNotes: notes,
              },
            })
          );
          // If the API call previously failed and now works cancel the error message
          setShowErrorMessage(false);
          // Close the editing box
          setEditingNotes(false);
        } else {
          setShowErrorMessage(true);
          setNotes(serviceUser?.serviceUserProperties?.installationNotes || "");
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setShowErrorMessage(true);
        setNotes(serviceUser?.serviceUserProperties?.installationNotes || "");
      });
  };

  return (
    <>
      {!sensors?.criticalError && (
        <Card style={styles.card}>
          <h1>Devices</h1>
          <hr />
          <div className={styles.installationNotes}>
            <h2>Installation Notes:</h2>
            {showErrorMessage && (
              <div className={styles.error}>
                Error installation notes failed to save
              </div>
            )}
            {!editingNotes && (
              <p data-private>
                {serviceUser?.serviceUserProperties?.installationNotes ||
                  "No notes added yet"}
              </p>
            )}
            {!editingNotes && (
              <SecondaryButton
                style={{ fontSize: 12 }}
                startIcon={<EditIcon style={{ width: 11 }} />}
                onClick={() => setEditingNotes(true)}
              >
                Edit Installation notes
              </SecondaryButton>
            )}
            <div />
            {editingNotes && (
              <div>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Add installation notes"
                  maxLength={600}
                  style={{
                    height: "180px",
                    width: "100%",
                    resize: "none",
                    borderRadius: 5,
                    padding: 12,
                    boxSizing: "border-box",
                    fontFamily: "Inter",
                    border: "0.72px solid #B8B8B8",
                    fontSize: theme.baseFontSize,
                  }}
                />
                <div className={styles.installationNotes_buttons}>
                  <SecondaryButton
                    style={{ color: theme.risk3 }}
                    onClick={() => {
                      setEditingNotes(false);
                      setShowErrorMessage(false);
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => updateNotes(notes)}
                    style={{ marginLeft: 32, padding: 0 }}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </div>
            )}
            <PrimaryButton
              onClick={fetchSensorStatus}
              style={{ padding: "6px 12px", width: 210 }}
              disabled={loading}
            >
              {loading ? "Testing..." : "Test sensor connections"}
            </PrimaryButton>
          </div>
          {loading && (
            <div className={styles.loading_sensors}>
              <AltSpinner style={{ alignSelf: "center" }} />
              <div>Connecting to sensors</div>
            </div>
          )}
          {!loading && (
            <div className={styles.devices}>
              <div className={styles.devices_left}>
                <div className={styles.sensors}>
                  {/* MAIN HUB */}
                  {sensors?.hub && (
                    <Sensor device={sensors.hub} ignoreLocation />
                  )}

                  {/* FIRST SET OF SENSORS */}
                  {firstSetOfSensors?.map((device, index) => (
                    <Sensor device={device} key={index} ignoreLocation />
                  ))}
                </div>
              </div>

              <div className={styles.devices_right}>
                {/* SECOND SET OF SENSORS */}
                <div className={styles.sensors}>
                  {secondSetOfSensors?.map((device, index) => (
                    <Sensor device={device} key={index} ignoreLocation />
                  ))}
                </div>
              </div>
            </div>
          )}
          {!loading && error && <div>{error}</div>}
        </Card>
      )}

      {sensors?.criticalError && (
        <Card style={styles.devices}>
          <div className={styles.error}>
            <div>Cannot detect lilli hub</div>
            <a
              href="mailto: support@intelligentlilli.com"
              target="_blank"
              rel="noreferrer"
            >
              Contact support
            </a>
          </div>
        </Card>
      )}
    </>
  );
};

export default DevicesOverview;

// Helper functions
const getHalfASetOfSensors = (sensorsArray, which_half) => {
  if (sensorsArray) {
    const sensorCount = sensorsArray?.length;
    const half = Math.floor(sensorCount / 2);
    if (which_half === 1) {
      const firstHalf = sensorsArray?.slice(0, half);
      return firstHalf;
    } else if (which_half === 2) {
      const secondHalf = sensorsArray?.slice(half, sensorCount);
      return secondHalf;
    }
  } else {
    return;
  }
};
