const getDoorEvents = (events) => {
  return events?.filter(
    (event) => event?.type === "door opened" || event?.type === "door closed"
  );
};

// nighttime tab displays events from yesterday 10pm to today 10pm
export const nighttimeDoorEvents = (nighttimeEvents) => {
  const doorEvents = getDoorEvents(nighttimeEvents);
  const headline =
    doorEvents?.length > 0
      ? `${doorEvents.length} door events`
      : "0 door events";

  return {
    title: "Front door events at night",
    headline: headline,
    context: "between 10pm and 6am",
    icon: "DOOR",
  };
};
