export const filtersToRender = [
  {
    key: "dayTimeActivity",
    value: "Day-time activity",
    relatedBehaviour: "day-time",
  },
  {
    key: "nightTimeActivity",
    value: "Night-time activity",
    relatedBehaviour: "night-time",
  },
  {
    key: "noBathingEvents",
    value: "No bathing events",
    relatedBehaviour: "bathroom",
  },
  {
    key: "sustenance",
    value: "Missed meals",
    relatedBehaviour: "sustenance",
  },
  {
    key: "noTimeOutside",
    value: "No time outside",
    relatedBehaviour: "independence",
  },
  {
    key: "doorOpenedAtNight",
    value: "Door opened at night",
    relatedBehaviour: "independence",
  },
  {
    key: "temperature",
    value: "Concerning temperature",
    relatedBehaviour: "temperature",
  },
];
