import React from "react";
import styles from "./styles.module.scss";
import DashboardTab from "../DashboardTab";
import { useGetUserRole } from "../../Services/Hooks/useGetUserRole";
import { reportsBreakpoint } from "../../Services/config";
import { useViewport } from "../ViewportProvider";

const DashboardTabs = ({ setSelectedTab, selectedTab }) => {
  // Get the width of the viewport for responsive design
  const { width } = useViewport();
  const desktopView = width > reportsBreakpoint;
  const desktopTabs = ["users", "reports", "staff", "friends & family"];
  const mobileTabs = ["users", "staff", "friends & family"];
  const tabs = desktopView ? desktopTabs : mobileTabs;
  const installerOnlyTabs = ["users"];
  const { userIsJustAnInstaller } = useGetUserRole();
  const tabsToShow = userIsJustAnInstaller ? installerOnlyTabs : tabs;

  return (
    <div className={styles.search_tabs_container}>
      {tabsToShow.map((tabName) => (
        <DashboardTab
          key={tabName}
          selectedTab={selectedTab}
          title={tabName}
          setSelectedTab={setSelectedTab}
        />
      ))}
    </div>
  );
};

export default DashboardTabs;
