import { SMARTPLUG_INSTRUCTIONS } from "./smartplugInstructions";
import { DOOR_INSTRUCTIONS } from "./doorSensorInstructions";
import { HUB_INSTRUCTIONS } from "./hubInstructions";
import {
  MOTION_INSTRUCTIONS,
  MOTION_SENSOR_INSTALLATION_OPTIONS,
} from "./motionInstructions";

const MOTION_INSTALLTION_OPTIONS =
  "There are three options for setup. Please select your preferred method:";

export const INSTALL_INSTRUCTIONS = {
  h1: {
    title: "Lilli hub",
    locationInstructions: "To be placed in a central location.",
    guide: {
      header: {
        alt: "Lilli Hub",
        image: "/images/installation/hub/hub.png",
        title: "Guide: Lilli Hub",
        width: 120,
      },
      instructions: HUB_INSTRUCTIONS,
    },
  },
  m1: {
    title: "Movement sensor",
    locationInstructions: "Place above waist in an area with high traffic.",
    guide: {
      header: {
        alt: "Movement sensor",
        image: "/images/installation/sensors/motion/motion.png",
        title: "Guide: Movement sensor",
        description: MOTION_INSTALLTION_OPTIONS,
        width: 180,
      },
      instructions: MOTION_INSTRUCTIONS,
      options: MOTION_SENSOR_INSTALLATION_OPTIONS,
    },
  },
  m2: {
    title: "Bathroom sensor",
    locationInstructions:
      "Place at any height as close to the bathing area as possible.",
    guide: {
      header: {
        imageAltName: "Movement sensor",
        image: "/images/installation/sensors/motion/motion.png",
        title: "Guide: Bathroom sensor",
        width: 180,
        description: MOTION_INSTALLTION_OPTIONS,
      },
      instructions: MOTION_INSTRUCTIONS,
      options: MOTION_SENSOR_INSTALLATION_OPTIONS,
    },
  },
  m3: {
    title: "Bedroom sensor",
    locationInstructions:
      "Place outside the bedroom door to capture entering/exiting the bedroom.",
    guide: {
      header: {
        imageAltName: "Movement sensor",
        image: "/images/installation/sensors/motion/motion.png",
        title: "Guide: Bedroom sensor",
        width: 180,
        description: MOTION_INSTALLTION_OPTIONS,
      },
      instructions: MOTION_INSTRUCTIONS,
      options: MOTION_SENSOR_INSTALLATION_OPTIONS,
    },
  },
  d1: {
    title: "Main door sensor",
    locationInstructions:
      "Place on the door that the Lilli user uses to enter and exit the property.",
    guide: {
      header: {
        imageAltName: "Door sensor",
        image: "/images/installation/sensors/door/door-labeled.png",
        title: "Guide: Main door sensor",
        width: "auto",
      },
      instructions: [
        {
          hint: [
            "This sensor is to be placed on the door that the Lilli user uses the most to enter and exit the property day-to-day.",
          ],
        },
        ...DOOR_INSTRUCTIONS,
      ],
    },
  },
  d2: {
    title: "Fridge door sensor",
    locationInstructions: "Place on the fridge or cupboard door.",
    guide: {
      header: {
        imageAltName: "Door sensor",
        image: "/images/installation/sensors/door/door-labeled.png",
        width: "auto",
        title: "Guide: Fridge door sensor",
      },
      instructions: [
        {
          hint: [
            "This is intended for the fridge door. If this isn’t possible it can be used on the cupboard door to capture eating events.",
            "Optimal location is towards the top of the door to limit interference.",
          ],
        },
        ...DOOR_INSTRUCTIONS,
      ],
    },
  },
  p1: {
    title: "Smart plug",
    locationInstructions: "To be plugged in with kitchen appliances.",
    guide: {
      header: {
        imageAltName: "Smart plug sensor",
        image: "/images/installation/sensors/plug/plug.png",
        title: "Guide: Smart plug sensor",
        height: 150,
      },
      instructions: SMARTPLUG_INSTRUCTIONS,
    },
  },
};
