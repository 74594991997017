import React from "react";
import theme from "../../theme.scss";

export const CopyIcon = ({ colour }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" id="CopyIcon">
      <path
        d="M9.66648 12.9445H4.11113C2.57782 12.9426 1.33514 11.7 1.33331 10.1667V4.6113C1.33514 3.078 2.57782 1.83532 4.11113 1.8335H9.66648C11.1998 1.83532 12.4425 3.078 12.4443 4.6113V10.1667C12.4425 11.7 11.1998 12.9426 9.66648 12.9445ZM4.11113 2.94467C3.19057 2.94467 2.44449 3.69075 2.44449 4.6113V10.1667C2.44449 11.0872 3.19057 11.8333 4.11113 11.8333H9.66648C10.587 11.8333 11.3331 11.0872 11.3331 10.1667V4.6113C11.3331 3.69075 10.587 2.94467 9.66648 2.94467H4.11113ZM14.6664 12.389V5.16702C14.6664 4.86025 14.4177 4.61156 14.1109 4.61156C13.8042 4.61156 13.5555 4.86025 13.5555 5.16702V12.389C13.5555 13.3096 12.8094 14.0556 11.8888 14.0556H4.66684C4.36008 14.0556 4.11139 14.3043 4.11139 14.6111C4.11139 14.9179 4.36008 15.1666 4.66684 15.1666H11.8888C13.4221 15.1647 14.6648 13.9221 14.6666 12.3888L14.6664 12.389Z"
        fill={colour || theme.neutral7}
      />
    </svg>
  );
};
