// Source: https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd/7091965#7091965

export const getAgeFromDobString = (
  dateString: string | undefined
): string | null => {
  if (dateString === undefined) {
    return null;
  } else {
    // valid arguments include "1962-12-30" or "1962/12/30"
    const today = new Date();
    const birthDate = new Date(dateString);
    const month = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return `${age}`;
  }
};
