import React from "react";
import styles from "./styles.module.scss";

const LoadingBubbles = () => {
  return (
    <div className={styles.loading_bubbles_container}>
      <div className={`${styles.bubble}`}></div>
      <div className={`${styles.bubble} ${styles.delay_1}`}></div>
      <div className={`${styles.bubble} ${styles.delay_2}`}></div>
    </div>
  );
};

export default LoadingBubbles;
