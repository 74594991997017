import React from "react";

export const Avatar2 = ({ width, primaryColour, secondaryColour }) => {
  return (
    <svg width={width || 40} viewBox="0 0 40 40" fill="none" id="Avatar2">
      <rect width="40" height="40" rx="3.27273" fill={primaryColour} />
      <line
        x1="11.9796"
        y1="40"
        x2="11.9796"
        y2="0"
        stroke={secondaryColour}
        strokeWidth="0.687273"
      />
      <line
        x1="19.5962"
        y1="40"
        x2="19.5962"
        y2="0"
        stroke={secondaryColour}
        strokeWidth="1.13091"
      />
      <line
        x1="29.5966"
        y1="40"
        x2="29.5966"
        y2="0"
        stroke={secondaryColour}
        strokeWidth="1.57455"
      />
    </svg>
  );
};
