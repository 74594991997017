import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setStaff(_, action) {
      return action.payload;
    },
    updateStaffMember(state, action) {
      const { index, key, value } = action.payload;
      state[index][key] = value;
    },
  },
});

export const { setStaff, updateStaffMember } = staffSlice.actions;

export default staffSlice.reducer;
