import React from "react";
import styles from "./styles.module.scss";
import { BriefcaseIcon } from "../../Styles/Icons";

const StaffPill = (props) => {
  return (
    <div className={`${styles.pill} ${props.large && styles.large}`}>
      <BriefcaseIcon
        style={
          props.large
            ? { fontSize: 16, marginBottom: "3px" }
            : { fontSize: 11, marginBottom: "1px" }
        }
      />
      <div>Staff</div>
    </div>
  );
};

export default StaffPill;
