import { formatBathroomInference } from "./formatBathroomInference";

interface BathroomInference {
  date: string;
  isComplete: boolean;
  activityData: Event[];
}

interface Event {
  startedAt: string;
  endedAt: string;
}

export const getCombinedBathroomInference = (
  serviceUserData: { bathroomInference?: BathroomInference[] | null },
  resData: {
    bathroomActivities: Event[] | null;
    hubId: number;
    startTime: string; // "2022-09-27"
    endTime: string; // "2022-09-30"
  },
  startDate: string,
  endDate: string
): BathroomInference[] | undefined => {
  // Step 1 - Get the old state data
  const oldState = serviceUserData?.bathroomInference || [];

  // Step 2 - format the response data so each event belongs to a particular date
  const newData = formatBathroomInference(resData, startDate, endDate);

  // Step 3 - Combine old state and new response data and remove duplicates
  const combinedModelInference = new Map<string, BathroomInference>();

  // Add old state data
  oldState.forEach((item) => {
    // Add old data to the map with date as the key
    combinedModelInference.set(item.date, item);
  });

  // Add new formatted response data
  newData.forEach((item) => {
    // Always overwrite old data with new data if the date already exists
    combinedModelInference.set(item.date, item);
  });

  // Convert the Map back to an array
  const noDuplicates = Array.from(combinedModelInference.values());
  noDuplicates.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
  return noDuplicates;
};
