import React from "react";
import styles from "./styles.module.scss";

const SensorSticker = ({ labelType, colour, label }) => {
  return (
    <div
      className={styles.sensor_sticker}
      style={{
        backgroundColor: labelType === "colour" && colour,
      }}
    >
      {labelType === "alphanumeric" && label !== "unknown"
        ? label.toUpperCase()
        : ""}
    </div>
  );
};

export default SensorSticker;
