import { get12hFrom24h } from "./";

export const getTimePeriodAndDetail = (sentence1, selectedBehaviour1) => {
  const mealTimes = {
    breakfast: "5am - 11am",
    lunch: "12pm - 2pm",
    dinner: "5pm - 9pm",
  };

  let detail = sentence1;
  let time;

  if (selectedBehaviour1 === "sustenance") {
    const timePeriod = sentence1.split("at")?.[1]?.split("time")?.[0]?.trim(); // breakfast, lunch, dinner
    time = timePeriod && mealTimes[timePeriod];
    detail = sentence1.trim();
  }

  if (
    selectedBehaviour1 === "movement" ||
    selectedBehaviour1 === "temperature" ||
    selectedBehaviour1 === "independence"
  ) {
    const start = sentence1.split("between")?.[1]?.split("and")?.[0]?.trim(); // 7:00
    const end = sentence1.split("between")?.[1]?.split("and")?.[1]?.trim(); // 18:00
    time = start && end && get12hFrom24h(start, end);
    detail = sentence1.split("between")?.[0]?.trim() || sentence1;
  }

  if (selectedBehaviour1 === "sleep") {
    const part1 = sentence1.split(".")?.[0]?.trim();
    const part2 = sentence1.split(".")?.[1]?.trim();
    detail = `${part1}\n${part2}`;
  }

  return { time, detail };
};
