import React, { useState } from "react";
import { useViewport } from "../ViewportProvider";
// Components
import BehaviourNighttimeDayView from "../BehaviourNighttimeDayView";
import BehaviourRange from "../BehaviourRange";
import HowTheBehaviourWorks from "../HowTheBehaviourWorks";
import MovementComparisonModal from "../MovementComparisonModal";
import { NIGHTTIME_QUESTIONS_AND_ANSWERS } from "./howItWorks";
// Utils
import {
  learnMore,
  nightTimeDayExpectedDuration,
  nightTimeDayLastMovementHour,
  movementRangeComparison,
  nighttimeDoorEvents,
  getTimeOutsideAtNight,
} from "../../Services/Utils/Insights";
import { getEventLogData } from "../../Services/Utils/EventLog";
import { isToday } from "date-fns";
// Hooks
import { useFade } from "../../Services/Hooks";

const BehaviourNightTime = ({
  nighttimeMovementData,
  movementData,
  previousDataPeriod,
  independenceData,
  independenceDataPrevious,
  dateType,
  from,
  to,
  prevEndDate,
  prevStartDate,
  activeTab,
  changePage,
  serviceUser,
}) => {
  const { width } = useViewport();
  const onMobileView = width < 900;
  const filtersToRender = [
    {
      key: "nightTimeActivity",
      value: "Night-time activity",
      relatedBehaviour: "night-time",
    },
    {
      key: "aboveExpectedNightTime",
      value: "Above expected activity",
      relatedBehaviour: "night-time",
    },
    {
      key: "belowExpectedNightTime",
      value: "Below expected activity",
      relatedBehaviour: "night-time",
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filtersToRender[0]);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150);

  // Eventlog
  const { allMotionEvents } = getEventLogData(
    nighttimeMovementData?.eventLog,
    true
  );
  // Daytime insights
  const daysMovementData = movementData[0];
  const insight1 = nightTimeDayExpectedDuration(
    daysMovementData,
    serviceUser?.userstring,
    null
  );
  const dayInsights = [insight1];
  const isCurrentDay = isToday(to);
  if (!isCurrentDay) {
    const prevDaysMovementData = previousDataPeriod[0];
    const insight2 = nightTimeDayLastMovementHour(prevDaysMovementData);
    dayInsights.push(insight2);
  }
  const insight3 = nighttimeDoorEvents(allMotionEvents, to);
  dayInsights.push(insight3);
  const learnMoreInsight = learnMore(() => setShowLearnMore(true));
  // time outside at night
  if (dateType === "day") {
    const timeOutsideAtNight = getTimeOutsideAtNight(
      independenceData,
      independenceDataPrevious,
      serviceUser?.serviceUserProperties?.forenames || serviceUser?.userstring
    );
    dayInsights.push(timeOutsideAtNight);
  }
  dayInsights.push(learnMoreInsight);

  // Range View Insights
  const rangeComparison = movementRangeComparison(
    movementData,
    dateType,
    previousDataPeriod,
    prevStartDate,
    prevEndDate,
    from,
    to,
    "night-time",
    () => setShowAnimation(true)
  );
  const rangeViewInsights = [rangeComparison, learnMoreInsight];

  if (dateType === "day") {
    return (
      <BehaviourNighttimeDayView
        allMotionEvents={allMotionEvents}
        movementData={movementData}
        howItWorks={NIGHTTIME_QUESTIONS_AND_ANSWERS}
        insights={dayInsights}
        showLearnMore={showLearnMore}
        setShowLearnMore={setShowLearnMore}
      />
    );
  } else {
    return (
      <>
        {!showLearnMore && (
          <BehaviourRange
            data={movementData}
            from={from}
            to={to}
            prevEndDate={prevEndDate}
            prevStartDate={prevStartDate}
            previousDataPeriod={previousDataPeriod}
            activeTab={activeTab}
            filtersToRender={filtersToRender}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            changePage={changePage}
            isMobile={onMobileView}
            dateType={dateType}
            howItWorks={NIGHTTIME_QUESTIONS_AND_ANSWERS}
            insights={rangeViewInsights}
          />
        )}
        {showLearnMore && (
          <HowTheBehaviourWorks
            behaviour="Night-time"
            questionsAndAnswers={NIGHTTIME_QUESTIONS_AND_ANSWERS}
            hide={() => setShowLearnMore(false)}
          />
        )}
        {isVisible && (
          <MovementComparisonModal
            hide={() => {
              setShowAnimation(false);
            }}
            showAnimation={showAnimation}
            dateType={dateType}
            currentData={rangeComparison.currentDataModal}
            previousData={rangeComparison.previousDataModal}
            behaviourName={"Night-time movement"}
          />
        )}
      </>
    );
  }
};

export default BehaviourNightTime;
