import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { format, parseISO } from "date-fns";
// Components
import PrimaryButton from "../PrimaryButton";
import { KebabMenuIcon } from "../../Styles/Icons/DesignSystem";
// import { openInNewTab } from "@intelligentlilli/lilli-utils";
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "../../Styles/Icons/DesignSystem";

const Report = ({
  report,
  setCloseConfirmModal,
  setReportToDelete,
  moveModalUp,
}) => {
  const navigate = useNavigate();
  // local state
  const [showMenu, setShowMenu] = useState(null);

  // Extract required report information to display
  const dateReportWasCreated = report.created_at
    ? format(parseISO(report.created_at), "dd-LL-yy")
    : "";

  const startDate =
    report.start_at && format(parseISO(report.start_at), "dd-LL-yy");

  // We add one less day than than the number of days as the start and end dates are inclusive in the report
  const endDate = report.end_at && format(parseISO(report.end_at), "dd-LL-yy");

  const onDelete = (e) => {
    // Prevent the click event bubbling up to the parent element
    e.stopPropagation();
    setCloseConfirmModal(false);
    setReportToDelete(report);
  };

  return (
    <tr
      className={styles.report}
      cypresstestid="single_report"
      onClick={() => {
        if (report.report_type === "general-report") {
          navigate(`/pdf-preview/${report.id}`);
        } else {
          navigate(`/pdf-previewV2/${report.request_id}`);
        }
      }}
      onMouseLeave={() => {
        setShowMenu(null);
      }}
    >
      <td className={`${styles.report_element} fs-mask`}>
        {report?.userstring}
      </td>
      <td className={`${styles.report_element} fs-mask`}>{report?.title}</td>
      <td className={styles.report_element}>{dateReportWasCreated}</td>

      <td className={styles.report_element}>{startDate}</td>

      <td className={styles.report_element}>{endDate}</td>

      <td className={styles.report_element_menu}>
        <button
          className={showMenu ? styles.kebab_menu_hover : styles.kebab_menu}
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu(report.request_id || report.id);
          }}
          onMouseOver={() => {
            setShowMenu(report.request_id || report.id);
          }}
        >
          <KebabMenuIcon color={theme.neutral6} />
        </button>
        {showMenu && (
          <div
            className={moveModalUp ? styles.menu_above : styles.menu_below}
            onMouseLeave={() => {
              setShowMenu(null);
            }}
          >
            <PrimaryButton
              onClick={(e) => onDelete(e)}
              label="Delete"
              backgroundColour={theme.white}
              startIcon={<DeleteIcon colour={theme.neutral7} width={14} />}
              hoverColour={theme.neutral2}
              padding={"4px 16px 4px 16px"}
              style={{ textWrap: "noWrap" }}
            >
              Delete report
            </PrimaryButton>
            {/* <PrimaryButton
              onClick={() => openInNewTab(report.url)}
              label="Delete"
              backgroundColour={theme.white}
              startIcon={<DownloadIcon width={12} colour={theme.neutral7} />}
              hoverColour={theme.neutral2}
            >
              Download as PDF
            </PrimaryButton> */}
          </div>
        )}
      </td>
    </tr>
  );
};

export default Report;
