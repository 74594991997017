import { endOfDay, startOfDay } from "date-fns";
import { getDaytimeEvents, getEarlyHoursEvents } from "./";

export const getMidnightToMidnightEvents = (
  allEvents,
  urlEndDateString,
  morningStart
) => {
  // Filter the events into the time periods
  const earlyHoursEvents = getEarlyHoursEvents(allEvents, morningStart);
  const {
    morningEvents,
    afternoonEvents,
    eveningEvents,
    afternoonStart,
    eveningStart,
  } = getDaytimeEvents(allEvents, urlEndDateString, morningStart);

  const formattedEarlyHoursEvents = {
    events: earlyHoursEvents,
    start: startOfDay(new Date(morningStart)),
    end: new Date(morningStart),
    sectionTitleFull: "Early hours: 12am - 6am",
    sectionTitlePartial: "Early hours: 12am - now",
  };
  const formattedMorningEvents = {
    events: morningEvents,
    start: new Date(morningStart),
    end: new Date(afternoonStart),
    sectionTitleFull: "Morning: 6am - 12pm",
    sectionTitlePartial: "Morning: 6am - now",
  };
  const formattedAfternoonEvents = {
    events: afternoonEvents,
    start: new Date(afternoonStart),
    end: new Date(eveningStart),
    sectionTitleFull: "Afternoon: 12pm - 6pm",
    sectionTitlePartial: "Afternoon: 12pm - now",
  };
  const formattedEveningEvents = {
    events: eveningEvents,
    start: new Date(eveningStart),
    end: endOfDay(new Date(urlEndDateString)),
    sectionTitleFull: "Evening: 6pm - 12am",
    sectionTitlePartial: "Evening: 6pm - now",
  };

  return [
    formattedEarlyHoursEvents,
    formattedMorningEvents,
    formattedAfternoonEvents,
    formattedEveningEvents,
  ];
};
