import React from "react";
import theme from "../../../Styles/theme.scss";
import styles from "./styles.module.scss";
import PrimaryButton from "../../PrimaryButton";
import { BackIcon } from "../../../Styles/Icons/DesignSystem";

const Buttons = ({
  firstStep,
  setCurrentStepIndex,
  nextButtonText,
  onClick,
}) => {
  return (
    <div>
      {!firstStep && (
        <PrimaryButton
          onClick={() => {
            setCurrentStepIndex((prev) => prev - 1);
          }}
          style={{ borderRadius: 8 }}
          backgroundColour={theme.white}
          className={styles.backButton}
          border={`1px solid ${theme.primary3}`}
          padding={"9px 15px"}
          startIcon={<BackIcon colour={theme.neutral7} />}
        >
          Back
        </PrimaryButton>
      )}
      <PrimaryButton
        style={{ marginLeft: 16, borderRadius: 8 }}
        className={styles.button}
        border={`1px solid ${theme.primary3}`}
        padding={"9px 15px"}
        onClick={onClick}
      >
        {nextButtonText || "Next step"}
      </PrimaryButton>
    </div>
  );
};

export default Buttons;
