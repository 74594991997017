import React from "react";
import styles from "./styles.module.scss";
import { capitalize } from "@intelligentlilli/lilli-utils";
import PrimaryButton from "../PrimaryButton";

const DashboardTab = ({ title, selectedTab, setSelectedTab }) => {
  const isSelected = selectedTab === title;
  return (
    <PrimaryButton
      className={isSelected ? styles.is_selected : styles.dashboard_tab}
      onClick={() => setSelectedTab(title)}
    >
      {capitalize(title)}
    </PrimaryButton>
  );
};

export default DashboardTab;
