export const removeUnderscore = (string) => {
  return string.split("_").join(" ");
};

// input = ['sustenance,movement,independence'] ==> output = [{sustenance: true, movement: true, ... }]
export const getArrayOfReportOptions = (arrayOfObjects) => {
  return arrayOfObjects
    .filter((option) => Object.values(option)[0])
    .map((item) => Object.keys(item).join(""));
  // Example of argument passed in:
  // [{sustenance: false}, {movement: false}, {independence: true}, {nighttime_activity: true}]
  // Function will return: ["independence", "nighttime_activity"];
};

export const updateOptions = (arrayOfObjects, name, value) => {
  const result = arrayOfObjects.map((option) => {
    if (Object.keys(option).includes(name)) {
      option[name] = value;
    }
    return option;
  });
  return result;
};

export const getSelectedOptions = (selectedReport) => {
  // This function works with the 'options' returned from the database
  // And manipulates it so that it can work with the form.

  // Step 1: Create all available options
  const initialOptions = [
    { movement: false },
    { sustenance: false },
    { independence: false },
    { nighttime_activity: false },
    { temperature: false },
  ];

  // Step 2: If the selected report does not have 'options', set all options as false (as above)
  if (
    !selectedReport?.options ||
    selectedReport?.options?.length === 0 ||
    !selectedReport ||
    selectedReport.length === 0
  ) {
    return initialOptions;
  }

  // Step 3
  const optionsFromDatabase = Object.keys(
    selectedReport?.options?.map((item) => {
      var newObj = { [item]: true };
      return newObj;
    })[0]
  ); // returns ["sustenance,movement,independence,nighttime_activity"]

  // Step 3
  const prettyOptionsFromDB = new Set(optionsFromDatabase[0].split(",")); // returns {"sustenance", "movement"}

  // Step 4
  const formattedOptions = initialOptions.map((option) => {
    const optionKey = Object.keys(option)[0];
    var isSelected = prettyOptionsFromDB.has(optionKey); // Traverse a Set using '.has' (equivalent of '.includes' for array)
    var newObj = {};
    newObj[optionKey] = isSelected;
    return newObj;
  }); // returns [{sustenance: true, movement: true, ... }]
  return formattedOptions;
};
