import { createSlice } from "@reduxjs/toolkit";
import { augmentSensorData, reorderSensorsArray } from "../../Services/Utils";

const initialState = null;

const sensorsSlice = createSlice({
  name: "sensors",
  initialState,
  reducers: {
    setInitialSensors(_, action) {
      const { devices } = action.payload;
      return {
        devices: devices,
        hub: devices[0],
      };
    },
    setSensors(_, action) {
      if (action.payload === null) {
        return null;
      }
      const { devices, hubId } = action.payload;
      // Augment the API data with the additional frontend context
      const augmentedData = augmentSensorData(devices);
      // For simplicity in the consumer pages extract the hub data into it's own variable
      const hub = augmentedData?.find(
        (device) => device.defaultName === "SquidZigBee"
      );
      // Filter out any removed sensors
      const devicesCopy = augmentedData && [...augmentedData];
      const devicesWithoutRemoved = devicesCopy?.filter(
        (device) => !device.name.includes("REMOVED")
      );
      // Reorder them such that the main hub and the smart plug are first and second
      const reorderedSensors = reorderSensorsArray(devicesWithoutRemoved);
      return {
        devices: reorderedSensors,
        hubId: hubId,
        hub: hub,
      };
    },
    updateSensor(state, action) {
      const { index, key, value } = action.payload;
      state.devices[index][key] = value;
    },
  },
});

export const { setInitialSensors, setSensors, updateSensor } =
  sensorsSlice.actions;

export default sensorsSlice.reducer;
