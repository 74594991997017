export const mergeMovementV3DayAndNightEventLog = (
  eventLogOne = {},
  eventLogTwo = {}
) => {
  const combinedLogs = {};

  // Ensure both arguments are objects before proceeding
  if (typeof eventLogOne !== "object" || eventLogOne === null) eventLogOne = {};
  if (typeof eventLogTwo !== "object" || eventLogTwo === null) eventLogTwo = {};

  // Merge keys from both logs
  const allKeys = new Set([
    ...Object.keys(eventLogOne),
    ...Object.keys(eventLogTwo),
  ]);

  allKeys.forEach((key) => {
    const valueOne = eventLogOne[key];
    const valueTwo = eventLogTwo[key];

    // Default to an empty object for undefined or null values
    if (
      (typeof valueOne === "object" &&
        valueOne !== null &&
        !(valueOne instanceof Array)) ||
      (typeof valueTwo === "object" &&
        valueTwo !== null &&
        !(valueTwo instanceof Array))
    ) {
      // Handle nested objects
      combinedLogs[key] = {};
      const subKeys = new Set([
        ...Object.keys(valueOne || {}),
        ...Object.keys(valueTwo || {}),
      ]);
      subKeys.forEach((subKey) => {
        combinedLogs[key][subKey] = [
          ...(valueOne?.[subKey] || []),
          ...(valueTwo?.[subKey] || []),
        ].sort();
      });
    } else {
      // Handle direct arrays, defaulting to empty arrays for undefined or null values
      combinedLogs[key] = [...(valueOne || []), ...(valueTwo || [])].sort();
    }
  });

  return combinedLogs;
};
