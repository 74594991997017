import React from "react";
import { RadialChart, makeVisFlexible } from "react-vis";
import Chart from "../Chart";

const FlexRadialChart = makeVisFlexible(RadialChart);

const PieChartGeneric = ({
  sensors,
  innerRadius,
  radius,
  small,
  className,
  padAngle,
}) => {
  const data = sensors?.map((i) => {
    return {
      angle: i.value,
      label: i.label,
      color: i.color,
    };
  });

  // Filter the data array based on whether the risk status is zero or not. This is to fix a bug in the pie chart where a segment
  // with zero angle shows as a line.
  const filteredData = data?.filter((risk) => risk?.angle > 0);

  const INNER_RADIUS = 40;
  const OUTER_RADIUS = 85;

  return (
    <Chart small={small} className={className}>
      <FlexRadialChart
        data={filteredData}
        innerRadius={innerRadius || (small ? 12 : INNER_RADIUS)}
        radius={radius || (small ? 25 : OUTER_RADIUS)}
        padAngle={padAngle || 0.07} // gap between the slices
        colorType="literal"
      ></FlexRadialChart>
    </Chart>
  );
};

export default PieChartGeneric;
