// Utility function to get query parameters from the url
export const getUrlParameter = (parameterName: string): string | null => {
  const x = document.location.search
    .substr(1)
    .split("&")
    .filter((item) => {
      const param = item.split("=");
      return param[0] === parameterName ? true : false;
    })[0];
  return x ? decodeURIComponent(x.split("=")[1]) : null;
};
