import React from "react";
import styles from "./styles.module.scss";

const ButtonSpinner = ({ white }) => {
  return (
    <div className={`${styles.lds_spinner} ${white && styles.white}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ButtonSpinner;
