// Helper function - filter the service users by the search term
export const getSearchResults = (searchTerm, serviceUsers) => {
  const inputValue = searchTerm?.trim().toLowerCase();
  const inputLength = searchTerm?.trim().length;
  const matchingServiceUsers = [];

  // Function to check a user's (service user or staff member) name against the search term
  const checkMatchingName = (username, forenames, surname) =>
    username?.toLowerCase()?.slice(0, inputLength) === inputValue ||
    forenames?.toLowerCase()?.slice(0, inputLength) === inputValue ||
    surname?.toLowerCase()?.slice(0, inputLength) === inputValue;

  serviceUsers?.forEach((serviceUser) => {
    const serviceUserCopy = JSON.parse(JSON.stringify(serviceUser));
    if (
      checkMatchingName(
        serviceUser?.userstring,
        serviceUser?.serviceUserProperties?.forenames,
        serviceUser?.serviceUserProperties?.surname
      )
    ) {
      // Create a deep copy of the service user so that it can be modified.
      matchingServiceUsers.unshift(serviceUserCopy);
    }
  });
  return matchingServiceUsers;
};
