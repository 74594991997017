import React from "react";
import styles from "./styles.module.scss";
import QRCode from "react-qr-code";

function NativeAppDownload() {
  return (
    <div className={styles.page}>
      <div className={styles.bodyContainer}>
        <div className={styles.title}>App now available</div>
        <p>
          The friends and family feature is available through our mobile app.
        </p>
        <br />
        <p>
          Join your loved ones care circle by clicking the links or scanning the
          QR codes below to download.
        </p>
        <div className={styles.qrContainer}>
          <div className={styles.qrInnerContainer}>
            <img
              style={{ marginBottom: "16px" }}
              alt="android logo"
              src="/images/icons/android_logo.png"
              height={40}
            />
            <div
              style={{
                marginBottom: "20px",
                marginRight: "20px",
                border: "1px solid #A5A5A5",
                borderRadius: "8px",
                padding: "10px",
                paddingBottom: "4px",
              }}
            >
              <QRCode
                value="https://play.google.com/store/apps/details?id=com.lilli_native_app"
                size={110}
              />
            </div>
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.lilli_native_app"
              }
              className={styles.appStoreLink}
            >
              View on play store
            </a>
          </div>
          <div className={styles.qrInnerContainer}>
            <img
              alt="apple logo"
              src="/images/icons/apple_logo.png"
              style={{ marginBottom: "16px" }}
              height={40}
            />
            <div
              style={{
                marginBottom: "20px",
                marginRight: "20px",
                border: "1px solid #A5A5A5",
                borderRadius: "8px",
                padding: "10px",
                paddingBottom: "4px",
              }}
            >
              <QRCode
                value="https://apps.apple.com/gb/app/lilli/id1619957201"
                size={110}
              />
            </div>
            <a
              href={"https://apps.apple.com/gb/app/lilli/id1619957201"}
              className={styles.appStoreLink}
            >
              View on app store
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NativeAppDownload;
