import React from "react";
import theme from "../../theme.scss";

export const TimeOutsideDaytimeIcon = ({ height }) => {
  const ratioWtoH = 22 / 23;

  return (
    <svg
      width={height * ratioWtoH || 22}
      height={height || 23}
      viewBox="0 0 22 23"
      fill="none"
    >
      <rect width="22" height="23" fill={theme.neutral7} />
      <g id="Icons">
        <rect
          x="-219"
          y="-262"
          width="765"
          height="1027"
          rx="10"
          fill={theme.white}
        />
        <g id="Icon/Complete">
          <rect
            width="672"
            height="112"
            transform="translate(-181 -78)"
            fill={theme.white}
          />
          <g id="Icon-TimeOutsideDaytime">
            <g id="Group 229">
              <g id="Group 230">
                <path
                  id="Vector"
                  d="M8.07501 12.9345C10.8364 12.9345 13.0751 10.6958 13.0751 7.93447C13.0751 5.17311 10.8364 2.93442 8.07501 2.93442C5.31365 2.93442 3.07495 5.17311 3.07495 7.93447C3.07769 10.6947 5.31482 12.9318 8.07501 12.9345ZM8.07501 4.60123C9.91604 4.60123 11.4082 6.09383 11.4082 7.93447C11.4082 9.77512 9.91565 11.2677 8.07501 11.2677C6.23436 11.2677 4.74176 9.77512 4.74176 7.93447C4.74176 6.09383 6.23436 4.60123 8.07501 4.60123Z"
                  fill={theme.neutral7}
                />
                <path
                  id="Vector_2"
                  d="M8.07526 14.6011C3.93498 14.6058 0.579868 17.9609 0.57518 22.1012C0.57518 22.5614 0.948196 22.9344 1.40836 22.9344C1.86852 22.9344 2.24157 22.5614 2.24157 22.1012C2.24157 18.8797 4.85332 16.268 8.07484 16.268C11.2964 16.268 13.9081 18.8797 13.9081 22.1012C13.9081 22.5614 14.2812 22.9344 14.7413 22.9344C15.2015 22.9344 15.5745 22.5614 15.5745 22.1012C15.5699 17.9609 12.2147 14.6058 8.07446 14.6011H8.07526Z"
                  fill={theme.neutral7}
                />
              </g>
              <g id="Subtract">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.497 7.24756C19.0144 7.24756 20.2445 6.01744 20.2445 4.5C20.2445 2.98257 19.0144 1.75244 17.497 1.75244C15.9795 1.75244 14.7494 2.98257 14.7494 4.5C14.7494 6.01744 15.9795 7.24756 17.497 7.24756ZM17.497 6.90411C18.8247 6.90411 19.9011 5.82776 19.9011 4.5C19.9011 3.17224 18.8247 2.09589 17.497 2.09589C16.1692 2.09589 15.0929 3.17224 15.0929 4.5C15.0929 5.82776 16.1692 6.90411 17.497 6.90411Z"
                  fill={theme.neutral7}
                />
                <path
                  d="M19.879 4.17287H21.0972C21.278 4.17287 21.4248 4.31925 21.4248 4.5C21.4248 4.68075 21.2783 4.82728 21.0975 4.82728H19.879M18.9753 2.60396L19.5867 1.54879C19.6723 1.38952 19.6128 1.19113 19.4535 1.10536C19.301 1.02327 19.111 1.07421 19.02 1.22136L18.4084 2.27466M16.5853 2.27477L15.9744 1.22074C15.8792 1.067 15.6775 1.01943 15.5237 1.11456C15.3766 1.20571 15.3257 1.39566 15.4077 1.54802L16.0191 2.60366M15.115 4.17257H13.8961C13.7154 4.17257 13.5688 4.3191 13.5688 4.49985C13.5688 4.68059 13.7154 4.82713 13.8961 4.82713H15.1149M16.0183 6.39575L15.4077 7.45121C15.3221 7.61048 15.3817 7.80887 15.5409 7.89464C15.6934 7.97673 15.8834 7.92579 15.9744 7.77864L16.585 6.72511M18.4083 6.72538L19.0191 7.77926C19.1142 7.933 19.316 7.98057 19.4698 7.88544C19.6169 7.79429 19.6678 7.60434 19.5858 7.45198L18.9743 6.39677M20.2445 4.5C20.2445 6.01744 19.0144 7.24756 17.497 7.24756C15.9795 7.24756 14.7494 6.01744 14.7494 4.5C14.7494 2.98257 15.9795 1.75244 17.497 1.75244C19.0144 1.75244 20.2445 2.98257 20.2445 4.5ZM19.9011 4.5C19.9011 5.82776 18.8247 6.90411 17.497 6.90411C16.1692 6.90411 15.0929 5.82776 15.0929 4.5C15.0929 3.17224 16.1692 2.09589 17.497 2.09589C18.8247 2.09589 19.9011 3.17224 19.9011 4.5Z"
                  stroke={theme.neutral7}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
