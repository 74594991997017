// Behaviours
import { cleanBehaviourData } from "./src/Behaviours/cleanBehaviourData";
import { calcBehaviourRisk } from "./src/Behaviours/calcBehaviourRisk";
import { formatBathroomInference } from "./src/Behaviours/formatBathroomInference";
import { extractHourlyTemperatureData, extractDailyTemperatureData } from "./src/Behaviours/extractTemperatureData";
import { extractHourlySustenanceData, extractDailySustenanceData, ProcessedSustenanceDayData, ProcessedSustenanceRangeData } from "./src/Behaviours/extractSustenanceData";
import { extractHourlyIndependenceData, extractDailyIndependenceData } from "./src/Behaviours/extractIndependenceData";
import { extractEvents } from "./src/Behaviours/extractEvents";
import {
  extractRiskLevel,
  extractIndependenceRiskLevel,
} from "./src/Behaviours/extractRiskLevel";
import { fixBehaviourData } from "./src/Behaviours/fixBehaviourData";
import { isMissingEventData } from "./src/Behaviours/isMissingEventData";
import { prepareMovementV3 } from "./src/Behaviours/prepareMovementV3";
// Redux helpers
import { getCombinedBehaviourAnalysisData } from "./src/Behaviours/getCombinedBehaviourAnalysisData";
import { getCombinedBathroomInference } from "./src/Behaviours/getCombinedBathroomInference";
import { getCombinedMainDoorActivity } from "./src/Behaviours/getCombinedMainDoorActivity";
import { getCombinedRiskScoreData } from "./src/Behaviours/getCombinedRiskScoreData";
// Constants
import {
  BehaviourType,
  BehaviourTypeText,
  BehaviourContext,
} from "./src/Constants/behaviours";
import {
  RiskStatus,
  RiskNumber,
  RiskLevel,
  RiskLevelText,
  RiskLevelDescriptions,
  getRiskColour,
} from "./src/Constants/risks";
// Dates
import {
  sortByTimestamp,
  dateToTimestamp,
  dateHourToTimestamp,
} from "./src/Dates/dateManipulations";
import { reverseDate } from "./src/Dates/reverseDate";
import { getFromDate } from "./src/Dates/getFromDate";
// Filters
import { areFiltersApplied } from "./src/Filters/areFiltersApplied";
import { filterObject } from "./src/Filters/filterObject";
// Formatting
import { camelize } from "./src/Formatting/camelize";
import { capitalize } from "./src/Formatting/capitalize";
import { formatUserHubs } from "./src/Formatting/formatUserHubs";
import {
  getInitials,
  getInitialsFromNames,
} from "./src/Formatting/getInitials";
// Images
import { getCroppedImg } from "./src/Images/getCroppedImg";
// No Data
import { getBinIntervals } from "./src/NoData/getBinIntervals";
import { getDatesWithNoData } from "./src/NoData/getDatesWithNoData";
import { getNoDataBins } from "./src/NoData/getNoDataBins";
import { getNullFlightPath } from "./src/NoData/getNullFlightPath";
import { getNullLines } from "./src/NoData/getNullLines";

// Reports
import { downloadReport, openInNewTab } from "./src/Reports/downloadReport";
// Risk Score
import { calcOverallRisk } from "./src/RiskScore/calcOverallRisk";
import { getDailyRiskValues } from "./src/RiskScore/getDailyRiskValues";
import { getRiskDates } from "./src/RiskScore/getRiskDates";
// Sort
import { sortByObjectProperty } from "./src/Sort/sortByObjectProperty";
import { sortHigh, sortLow } from "./src/Sort/sortLilliUsers";
// URL
import { getServiceUserPageURL } from "./src/URL/getServiceUserPageURL";
import { getUrlParameter } from "./src/URL/getUrlParameter";
// Validation
import { checkDOB } from "./src/Validation/checkDOB";
import { hasWhiteSpace } from "./src/Validation/hasWhiteSpace";
// Assorted
import { countOccurrences } from "./src/countOccurances";
import { getAgeFromDobString } from "./src/getAgeFromDobString";
// Assorted
import { deepCopyArray } from "./src/deepCopyArray";
import { getDayViewData } from "./src/getDayViewData";
import { getSearchSuggestions } from "./src/getSearchSuggestions";
import { getServiceUserById } from "./src/getServiceUserById";
import { sliceIntoChunks } from "./src/sliceIntoChunks";
import { userHasReadNotification } from "./src/userHasReadNotification";

export {
  // Behaviours
  calcBehaviourRisk,
  cleanBehaviourData,
  // temperature processing
  extractHourlyTemperatureData, 
  extractDailyTemperatureData,
  // sustenance processing
  extractHourlySustenanceData, 
  extractDailySustenanceData,
  ProcessedSustenanceDayData, 
  ProcessedSustenanceRangeData,
  // independence processing
  extractHourlyIndependenceData, 
  extractDailyIndependenceData,
  // other
  extractEvents,
  extractRiskLevel,
  extractIndependenceRiskLevel,
  formatBathroomInference,
  fixBehaviourData,
  getCombinedBathroomInference,
  getCombinedBehaviourAnalysisData,
  getCombinedMainDoorActivity,
  getCombinedRiskScoreData,
  isMissingEventData,
  prepareMovementV3,
  // Constants
  BehaviourType,
  BehaviourTypeText,
  BehaviourContext,
  RiskStatus,
  RiskNumber,
  RiskLevel,
  RiskLevelText,
  RiskLevelDescriptions,
  getRiskColour,
  // Dates
  sortByTimestamp,
  dateToTimestamp,
  dateHourToTimestamp,
  getFromDate,
  reverseDate,
  // Filters
  areFiltersApplied,
  filterObject,
  // Formatting
  camelize,
  capitalize,
  formatUserHubs,
  getInitials,
  getInitialsFromNames,
  // Images
  getCroppedImg,
  // No Data,
  getBinIntervals,
  getDatesWithNoData,
  getNoDataBins,
  getNullFlightPath,
  getNullLines,
  // Reports
  downloadReport,
  openInNewTab,
  // Risk Score
  calcOverallRisk,
  getDailyRiskValues,
  getRiskDates,
  // Sort
  sortByObjectProperty,
  sortHigh,
  sortLow,
  // URL
  getServiceUserPageURL,
  getUrlParameter,
  // Validation
  checkDOB,
  hasWhiteSpace,
  // Assorted utils not in a folder
  countOccurrences,
  deepCopyArray,
  getAgeFromDobString,
  getDayViewData,
  getSearchSuggestions,
  getServiceUserById,
  sliceIntoChunks,
  userHasReadNotification,
};
