import React from "react";
// Components
import Page from "../../Components/Page";

function QRCodePage() {
  return (
    <Page>
      <p>You have scanned the QR code on a Lilli device.</p>

      <p>
        For more information about the Lilli devices please{" "}
        <a href="http://www.intelligentlilli.com">visit our website.</a>
      </p>
      <p>
        To see the data for this device, please log into your Lilli account.
      </p>
    </Page>
  );
}

export default QRCodePage;
