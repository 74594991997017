export const getAvgOrCountOfEvents = (
  data,
  key,
  sumOrAverage,
  behaviourChosen
) => {
  const doesNotHaveData =
    data?.length === 0 ||
    !data ||
    data?.filter((i) => i?.[key] !== null).length === 0;

  if (doesNotHaveData) {
    return undefined;
  }

  const valuesArr = [...data]
    .map((i) => i?.[key])
    ?.filter((value) => value !== null);

  const count = [...valuesArr].reduce(
    (firstVal, secondVal) => firstVal + secondVal,
    0
  );
  const avg = count / valuesArr.length;
  const result = sumOrAverage === "count" ? count : avg;

  // For independence, return time in the form of hours
  if (behaviourChosen === "independence") {
    const totalTime = (result / 60).toFixed(1);
    if (totalTime.split(".")[1] === "0") {
      return totalTime.split(".")[0]; // i.e. show 1 if 1.0
    } else {
      return totalTime;
    }
  }
  return Math.round(result);
};

export const avgOrCountLabel = (
  behaviourSelection,
  avgOrCount,
  dateTypeSelected
) => {
  const behaviour =
    behaviourSelection === "overview" ? "movement" : behaviourSelection;

  const BEHAVIOUR_LABELS = {
    sleep: {
      label: "Disturbance",
    },
    sustenance: {
      label: "Event",
    },
    fridge: {
      label: "Fridge Event",
    },
    kettle: {
      label: "Kettle Event",
    },
    movement: {
      label: "Movement",
    },
    independence: {
      label: "Hours Outside",
    },
    temperature: {
      label: "Avg. Temperature",
    },
  };

  let label = BEHAVIOUR_LABELS[behaviour].label;
  // Independence is returned in the form of hours
  if (behaviourSelection === "independence" && avgOrCount === "1") {
    return "Hour Outside";
  }
  // The temperature label never requires adjustment so it can be returned here
  // If the independence value (in hours) is not 1, then return the label as is.
  if (
    behaviourSelection === "temperature" ||
    behaviourSelection === "independence"
  ) {
    return label;
  }
  if (avgOrCount !== 1) {
    label = `${label}s`;
  }
  if (dateTypeSelected !== "day") {
    label = `Avg. ${label}`;
  }
  return label;
};
