import { useEffect } from "react";

const TitledRoute = ({ title, children }) => {
  // Set the title of the browser tab
  useEffect(() => {
    document.title = `Lilli - ${title}`;
  });
  return children;
};

export default TitledRoute;
