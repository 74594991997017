export const RiskStatus = Object.freeze({
  EXPECTED: "EXPECTED",
  UNEXPECTED: "UNEXPECTED",
  AT_RISK: "AT_RISK",
});

export const RiskNumber = {
  EXPECTED: 0,
  UNEXPECTED: 1,
  AT_RISK: 2,
};

export const RiskLevel = ["EXPECTED", "UNEXPECTED", "AT_RISK"];

export const RiskLevelText = {
  EXPECTED: "Expected",
  UNEXPECTED: "Unexpected",
  AT_RISK: "At risk",
};

// New
export const RiskLevelDescriptions = {
  EXPECTED: "Users who have no significant changes in their behaviour",
  UNEXPECTED:
    "Users who have recently shown a slight change in their normal behaviour",
  AT_RISK: "Users who have recently shown a change in their normal behaviour",
};

// Getting the correct colour for the risk level
export const getRiskColour = (
  riskValue: string,
  variables: {
    [key: string]: string;
  }
) => {
  if (riskValue === RiskStatus.AT_RISK) {
    return variables.red;
  } else if (riskValue === RiskStatus.UNEXPECTED) {
    return variables.orange;
  } else {
    return variables.green;
  }
};
