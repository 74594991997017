// Source: https://www.the-art-of-web.com/javascript/validate-date/
export const checkDOB = (dateOfBirth: string): string | boolean => {
  const minYear = 1900;
  const maxYear = new Date().getFullYear();

  // regular expression to match required date format
  const re = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
  const regs = dateOfBirth.match(re);

  if (RegExp(/<\w{1,10}>|<script/gi).test(dateOfBirth)) {
    return "No HTML allowed";
  }

  if (dateOfBirth !== "") {
    if (regs) {
      // The + sign is a shorthand way of converting a string to a number in JavaScript.
      // It converts regs[1], which is a string representing the day part of the date, into a number. 
      // This conversion is performed because the subsequent comparisons involve numerical comparisons, 
      // and using the + sign is a concise way to ensure that the values are treated as numbers.
      if (+regs[1] < 1 || +regs[1] > 31) {
        return "Invalid value for day: " + regs[1];
      } else if (+regs[2] < 1 || +regs[2] > 12) {
        return "Invalid value for month: " + regs[2];
      } else if (+regs[3] < minYear || +regs[3] > maxYear) {
        return (
          "Invalid value. Year must be between " +
          minYear +
          " and " +
          maxYear +
          "."
        );
      }
    } else {
      return "Invalid date format: " + dateOfBirth;
    }
  } else {
    return false;
  }
  return true;
};
