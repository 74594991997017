import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss"; // generic form styles
import { Form, Field } from "react-final-form";
import FormError from "../../Components/FormError";
import FormContainer from "../../Components/FormContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import PasswordChecklist from "../../Components/PasswordChecklist";
import ResetPasswordMessage from "../../Components/ResetPasswordMessage";
import FormInput from "../../Components/FormInput";

const ResetPasswordForm = ({
  title,
  onSubmit,
  resetPasswordError,
  resetPasswordSuccess,
  email,
  navigate,
  setResetPasswordError,
  setResetPasswordSuccess,
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isPasswordTooShort, setIsPasswordTooShort] = useState(true);
  const [isMatchingPasswords, setIsMatchingPasswords] = useState(false);
  const [isPasswordWithNumber, setIsPasswordWithNumber] = useState(false);
  const [isPasswordWithLetter, setIsPasswordWithLetter] = useState(false);

  const meetsAllCritera =
    !isPasswordTooShort &&
    isMatchingPasswords &&
    isPasswordWithNumber &&
    isPasswordWithLetter;

  const createNewPasswordText = email
    ? `Create a new password for ${email}`
    : "Create a new password";

  useEffect(() => {
    setIsPasswordTooShort(password?.length < 12 ? true : false);
    setIsMatchingPasswords(
      password === confirmPassword && password !== "" ? true : false
    );
    setIsPasswordWithNumber(RegExp(/[0-9]/).test(password) ? true : false);
    setIsPasswordWithLetter(RegExp(/[a-zA-Z]/).test(password) ? true : false);
  }, [password, confirmPassword]);

  return (
    <FormContainer>
      <img height="53px" src="/logo/lilli-logo-teal.svg" alt="Lilli" />
      <h1>{title || "Set Password"}</h1>
      {!resetPasswordError && !resetPasswordSuccess && (
        <div>{createNewPasswordText}</div>
      )}
      {resetPasswordError ? (
        <ResetPasswordMessage
          isResetPasswordSuccess={false}
          message={resetPasswordError}
        />
      ) : resetPasswordSuccess ? (
        <ResetPasswordMessage isResetPasswordSuccess={true} />
      ) : (
        <>
          <PasswordChecklist
            isPasswordTooShort={isPasswordTooShort}
            isPasswordWithNumber={isPasswordWithNumber}
            isPasswordWithLetter={isPasswordWithLetter}
            isMatchingPasswords={isMatchingPasswords}
          />
          <Form
            onSubmit={(values) => {
              onSubmit(values.password);
            }}
            validate={(values) => {
              const errors = {};
              if (!values.password) {
                errors.password = "Required";
              }
              if (values.password?.length < 12) {
                errors.password = "Password must have at least 12 characters";
              }
              if (values.password?.length > 60) {
                errors.password = "Password is too long";
              }
              if (!RegExp(/[0-9]/).test(values.password)) {
                errors.password = "Password must contain at least one number";
              }
              if (!RegExp(/[a-zA-Z]/).test(values.password)) {
                errors.password = "Password must contain at least one letter";
              }
              if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Passwords must match";
              }
              return errors;
            }}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                <Field name="password">
                  {({ input, meta }) => {
                    return (
                      <>
                        <FormInput
                          input={input}
                          label="New password"
                          placeholder="Enter your password"
                          isPassword={true}
                          showPassword={showPassword}
                          setShowPassword={setShowPassword}
                          meetsAllCritera={!meta.error}
                          setValue={setPassword}
                          hasInput={password !== ""}
                          isActive={meta.active}
                        />
                        {meta.error &&
                          meta.touched &&
                          !meta.active &&
                          input.value !== "" && (
                            <FormError
                              error={meta.error}
                              className={styles.form_input_error}
                            />
                          )}
                      </>
                    );
                  }}
                </Field>
                <Field name="confirmPassword">
                  {({ input, meta }) => {
                    return (
                      <>
                        <FormInput
                          input={input}
                          label="Confirm new password"
                          placeholder="Enter your password"
                          isPassword={true}
                          showPassword={showConfirmPassword}
                          setShowPassword={setShowConfirmPassword}
                          meetsAllCritera={!meta.error}
                          setValue={setConfirmPassword}
                          hasInput={confirmPassword !== ""}
                          isActive={meta.active}
                        />
                        {meta.error &&
                          meta.touched &&
                          !meta.active &&
                          input.value !== "" && (
                            <FormError
                              error={meta.error}
                              className={styles.form_input_error}
                            />
                          )}
                      </>
                    );
                  }}
                </Field>
                <PrimaryButton
                  type="submit"
                  disabled={submitting || !meetsAllCritera}
                  cypresstestid="Reset-Password-Btn-Submit"
                >
                  Set password
                </PrimaryButton>
              </form>
            )}
          />
        </>
      )}
      <div className={styles.back_to_login_button}>
        <SecondaryButton
          style={{ marginTop: "15px" }}
          onClick={() => {
            navigate("/login");
            setResetPasswordError(false);
            setResetPasswordSuccess(false);
          }}
        >
          Back to login
        </SecondaryButton>
      </div>
    </FormContainer>
  );
};

export default ResetPasswordForm;
