import React from "react";
import styles from "./styles.module.scss";
import { useViewport } from "../ViewportProvider";
import CalendarDay from "../CalendarDay";
import DaysOfTheWeek from "../DaysOfTheWeek";

const CalendarWeek = ({ data, selection, isMobile, changePage, activeTab }) => {
  return (
    <div className={styles.one_week}>
      {data?.map((day, index) => (
        <CalendarDay
          noData={day.noData}
          riskLevel={day.dayRating}
          firstLineText={day.firstLineText}
          secondLineText={day.secondLineText}
          isHighlighted={day.isHighlighted}
          highlightColour={day.highlightColour}
          isBlank={day.isBlank}
          date={day.date}
          key={index}
          selection={selection}
          isMobile={isMobile}
          changePage={changePage}
          activeTab={activeTab}
        />
      ))}
    </div>
  );
};

const Calendar = ({
  selection,
  chunkedData,
  isMobile,
  changePage,
  activeTab,
}) => {
  const { width } = useViewport();
  return (
    <div className={styles.movement_calendar}>
      <DaysOfTheWeek isMobile={width < 1440} />
      <div className={styles.weeks}>
        {chunkedData.map((week, index) => (
          <CalendarWeek
            key={index}
            selection={selection}
            data={week}
            isMobile={isMobile}
            changePage={changePage}
            activeTab={activeTab}
          />
        ))}
      </div>
    </div>
  );
};

export default Calendar;
