import {
  isSameDay,
  addDays,
  differenceInDays,
  startOfDay,
  endOfDay,
  format,
} from "date-fns";

interface Event {
  startedAt: string;
  endedAt: string;
}

interface DayData {
  date: string;
  activityData: Event[];
  isComplete: boolean;
}

/*
 Step 1: Get a series of dates between the start and endTimes
 that has the initial skeleton of the data structure we eventually need
*/
const getSkeletonDataStructure = (startDate: string, endDate: string): DayData[] => {
  const allDates: DayData[] = [];
  const start = new Date(startDate);
  const diff = differenceInDays(new Date(endDate), start);
  for (let i = 0; i <= diff; i++) {
    allDates.push({
      date: addDays(start, i).toISOString(),
      activityData: [],
      isComplete: !isSameDay(new Date(), addDays(start, i)),
    });
  }
  return allDates;
};

/*
  Step 2: 
  Modify the bathroomEvents array so that an event object which traverses two days
  is split into two separate objects / daysa
*/
const adjustForTwoDayTraversal = (rawResData: { bathroomActivities: Event[] | null }) => {
  const cleanedBathroomEvents =
    rawResData?.bathroomActivities?.filter(
      (i) => i !== null && i !== undefined
    ) || []; // filter undefined or null
 
  const adjustedArr: Event[] = [];
  cleanedBathroomEvents?.forEach((event) => {
    const baseEvent = {
      ...event,
      originalStart: event?.startedAt,
      originalEnd: event?.endedAt,
    };
    if (isSameDay(new Date(event?.startedAt), new Date(event?.endedAt))) {
      adjustedArr.push(baseEvent);
    } else {
      adjustedArr.push({
        ...baseEvent,
        endedAt: endOfDay(new Date(event?.startedAt)).toISOString(),
      });
      adjustedArr.push({
        ...baseEvent,
        startedAt: startOfDay(new Date(event?.endedAt)).toISOString(),
      });
    }
  });
  return adjustedArr;
};

/*
  Step 3: The final format - group the events by date
*/
const groupByDate = (dateSkeleton: DayData[], adjustedBathroomEvents: Event[]) => {
  const newArr = dateSkeleton?.map((day) => {
    const filteredEvents = adjustedBathroomEvents?.filter((event) => {
      return isSameDay(new Date(day?.date), new Date(event?.startedAt));
    });

    return {
      ...day,
      date: format(new Date(day.date), "yyyy-MM-dd"),
      activityData: filteredEvents,
    };
  });
  return newArr;
};

export const formatBathroomInference = (
  rawApiRes: { bathroomActivities: Event[] | null },
  startDate: string,
  endDate: string
) => {
  const structuredDates = getSkeletonDataStructure(startDate, endDate);
  const adjustedEvents = adjustForTwoDayTraversal(rawApiRes);
  const grouped = groupByDate(structuredDates, adjustedEvents);
  return grouped;
};

