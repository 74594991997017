// Note:
// Using the generic type <T> to make the functions more flexible; 
// it allows you to pass an array of objects of any type. 
// The keyof T is used to ensure that the key parameter is a valid key of the objects in the array.

export const sortHigh = <T>(list: T[], key: keyof T): T[] => {
  if (!list) return ([] as unknown) as T[];
  const sortedList = [...list];
  return sortedList.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

export const sortLow = <T>(list: T[], key: keyof T): T[] => {
  if (!list) return ([] as unknown) as T[];
  const sortedList = [...list];
  return sortedList.sort((a, b) => {
    if (a[key] < b[key]) {
      return 1;
    }
    if (a[key] > b[key]) {
      return -1;
    }
    return 0;
  });
};
