import React, { useState } from "react";
import styles from "./styles.module.scss";
// API
import { postForgottenPassword, postLogin } from "@intelligentlilli/api-layer";
// Components
import LoginForm from "../../Forms/LoginForm";
import TwoFAForm from "../../Forms/TwoFAForm";
import ForgotPasswordForm from "../../Forms/ForgotPasswordForm";
import GuestPage from "../../Components/GuestPage";
import FormContainer from "../../Components/FormContainer";
// State
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../State/slices/user-slice";
import {
  setLoading,
  setLoadingBaseData,
  setFetchInitialState,
} from "../../State/slices/session-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { CLIENT } from "../../Services/config";

function LoginPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const routePostLogin = location.state?.initialURL?.replace(CLIENT, "");

  // Redux State
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);
  // Local state
  const [forgotPassword, setForgotPassword] = useState(false);
  const [lockedOut, setLockedOut] = useState(false);
  const [email, setEmail] = useState("");

  const [isForgotPasswordSuccess, setIsForgotPasswordSuccess] = useState(false);
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState(false);

  const [loginError, setLoginError] = useState(null);
  const [show2FA, setShow2FA] = useState(false);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [show2FAMessage, setShow2FAMessage] = useState(false);

  // Handling user logging in
  const onSubmitLogin = ({ username, password, token }) => {
    dispatch(setLoadingBaseData(1));
    postLogin({
      server,
      username,
      password,
      token,
      deviceID: null, // (for mobile only),
      origin: "web",
    })
      .then((res) => {
        const twoFA_reason = res?.body?.reason;
        // dispatch(setLoading(false));
        if (!res.ok) {
          dispatch(setLoadingBaseData(0));
          if (res === undefined) {
            setLoginError(
              "Login failed. Cannot contact login server. Try refreshing the browser."
            );
            setShow2FA(false);
            setShow2FAMessage(null);
          } else if (res.status === 401) {
            switch (true) {
              case twoFA_reason === "locked_out":
                setShow2FA(false);
                setForgotPassword(true);
                setLockedOut(true);
                break;
              case twoFA_reason === "Token required":
                setShow2FAMessage(null);
                setShow2FA(true);
                break;
              case twoFA_reason === "Token expired":
                setShow2FA(false);
                setShow2FAMessage(null);
                setUsername(null);
                setPassword(null);
                setLoginError("Token expired. Please start again.");
                break;
              case twoFA_reason === "Incorrect token":
                setShow2FA(true);
                setShow2FAMessage("Incorrect token");
                break;
              case twoFA_reason === "Temporarily locked out":
                setShow2FA(false);
                setLoginError(
                  "Too many attempts. Please try again in 5 minutes."
                );
                break;
              default:
                setShow2FA(false);
                setLoginError(
                  "Incorrect username or password. Please try again."
                );
            }
          } else {
            setLoginError("Login failed.");
            setShow2FA(false);
          }
        } else {
          setShow2FA(false);
          // Initially set the user to true to allow the <AuthenticatedRoute> to be passed. The user will be fetched properly in useFetchInitialData.js
          dispatch(setUser(true));
          // Fetching the initial app data
          dispatch(setFetchInitialState(true));
          // If the user arrived at the app via a email link with a date to view
          if (routePostLogin && routePostLogin !== "/login") {
            navigate(routePostLogin);
          }
          // Otherwise send them straight to the dashboard
          else {
            navigate("/");
          }
        }
      })
      .catch((err) => {
        console.log("error:", err);
        dispatch(setLoading(false));
      });
  };

  // Handling user forgetting password
  const onSubmitForgotPassword = (email) => {
    dispatch(setLoading(true));
    postForgottenPassword(server, email, "web")
      .then((res) => {
        if (!res.ok) {
          dispatch(setLoading(false));
          if (res === undefined) {
            setForgotPasswordMessage(
              "Lilli servers are down. Please try again later."
            );
          } else if (res.status === 422) {
            setForgotPasswordMessage(res.body.reason);
          } else {
            setForgotPasswordMessage(
              "An unexpected error occured, password cannot be reset."
            );
          }
          setLockedOut(false);
          setIsForgotPasswordSuccess(false);
        } else {
          dispatch(setLoading(false));
          setLockedOut(false);
          setIsForgotPasswordSuccess(true);
          setForgotPasswordMessage(null);
          setForgotPassword(true);
        }
      })
      .catch((err) => {
        // TODO handle failure cases better
        console.log("Forgot Password Error:", err);
        setIsForgotPasswordSuccess(false);
        setForgotPasswordMessage(
          "An unexpected error occured, password cannot be reset"
        );
        setForgotPassword(true);
        dispatch(setLoading(false));
      });
  };

  return (
    <GuestPage>
      <FormContainer>
        <img height="53px" src="/logo/lilli-logo-teal.svg" alt="Lilli" />
        {forgotPassword ? (
          <h1>Forgot Password</h1>
        ) : show2FA ? (
          <h1>Two-factor authentication</h1>
        ) : (
          <h1>Login</h1>
        )}

        {forgotPassword && lockedOut ? (
          <div className={styles.lockout}>
            <p>Password entered incorrectly too many times.</p>
            <p>Please follow these steps to recover your password.</p>
          </div>
        ) : forgotPassword &&
          !lockedOut &&
          !isForgotPasswordSuccess &&
          !forgotPasswordMessage ? (
          <p>Please enter your email to reset your password</p>
        ) : null}

        {forgotPassword ? (
          <ForgotPasswordForm
            onSubmit={onSubmitForgotPassword}
            navigate={navigate}
            setEmail={setEmail}
            email={email}
            isForgotPasswordSuccess={isForgotPasswordSuccess}
            forgotPasswordMessage={forgotPasswordMessage}
          />
        ) : show2FA ? (
          <TwoFAForm
            username={username}
            password={password}
            onSubmit={onSubmitLogin}
            show2FAMessage={show2FAMessage}
            setShow2FA={setShow2FA}
          />
        ) : (
          <LoginForm
            onSubmit={onSubmitLogin}
            error={loginError}
            forgotPassword={() => {
              setForgotPassword(true);
              setLoginError(null);
            }}
            setUsername={setUsername}
            setPassword={setPassword}
          />
        )}
      </FormContainer>
    </GuestPage>
  );
}

export default LoginPage;
