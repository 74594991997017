import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { mobileBreakpoint } from "../../Services/config";
// State
import { useDispatch, useSelector } from "react-redux";
import { setShowSideDrawer } from "../../State/slices/session-slice";
// Components
import ProfileIcon from "../ProfileIcon";
import BurgerMenuIcon from "../BurgerMenuIcon";
import { useViewport } from "../ViewportProvider";
// Icons
import IconButton from "@mui/material/IconButton";
import { BellIcon } from "../../Styles/Icons/DesignSystem";
// Utils
import { userHasReadNotification } from "@intelligentlilli/lilli-utils";

const Header = ({ navigate }) => {
  // Get the width of the users screen for responsive js
  const { width } = useViewport();
  const onMobile = width <= mobileBreakpoint;

  // Fetch the user from redux state
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const notifications = user?.notifications?.notificationsData || [];
  // Check whether ther are any new notifications
  const unreadNotifications = notifications?.filter(
    (notification) =>
      !userHasReadNotification(notification, `${user.key}`) &&
      !notification?.hasBeenReadLocally
  );

  return (
    <header className={styles.header}>
      <div className={styles.header_left}>
        {onMobile && (
          <div className={styles.header_left_logo}>
            <BurgerMenuIcon onClick={() => dispatch(setShowSideDrawer(true))} />
          </div>
        )}
      </div>
      <div className={styles.header_right}>
        {!!user && (
          <>
            <div className={styles.header_element}>
              <IconButton
                onClick={() => navigate("/notifications")}
                size="large"
              >
                <BellIcon width={22} colour={theme.neutral4} />
              </IconButton>
              {unreadNotifications?.length > 0 && (
                <div className={styles.header_element_dot} />
              )}
            </div>
            <div className={styles.header_profile}>
              <ProfileIcon user={user} onClick={() => navigate("/profile")} />
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
