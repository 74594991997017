import React, { useState } from "react";
import styles from "./styles.module.scss";
import PrimaryButton from "../PrimaryButton";
import { AddIcon } from "../../Styles/Icons";
import CreateLilliUserModal from "../CreateLilliUserModal";
// Hooks
import { useFade, useGetUserRole } from "../../Services/Hooks";

const DashboardButtonsUsers = () => {
  const [addingServiceUserError, setAddingServiceUserError] = useState(null);
  // Custom hooks
  const { userIsJustAnInstaller } = useGetUserRole();
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  return userIsJustAnInstaller ? null : (
    <div className={styles.dashboard_buttons}>
      <PrimaryButton
        onClick={() => {
          setShowAnimation(true);
          setAddingServiceUserError(null);
        }}
        className={styles.new_user_button}
        startIcon={<AddIcon />}
      >
        New user
      </PrimaryButton>

      {isVisible && (
        <CreateLilliUserModal
          addingServiceUserError={addingServiceUserError}
          setAddingServiceUserError={setAddingServiceUserError}
          setAddingServiceUser={setShowAnimation}
          showAnimation={showAnimation}
        />
      )}
    </div>
  );
};

export default DashboardButtonsUsers;
