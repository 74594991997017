import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import theme from "../../Styles/theme.scss";
import { MoonIcon, SunIcon } from "../../Styles/Icons/DesignSystem";
import { useViewport } from "../../Components/ViewportProvider";
import { mobileBreakpoint } from "../../Services/config";

// This function takes a polar coordinnate and converts it to cartesian coordinates
const polarToCartesian = (center, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees * 2 * Math.PI) / 360; // Use this to get 24 hour clock
  return {
    x: center - radius * Math.sin(angleInRadians),
    y: center + radius * Math.cos(angleInRadians),
  };
};

const DayIndependence = ({ outData }) => {
  const { width } = useViewport();
  const onMobile = width < mobileBreakpoint;
  const chartWidth = onMobile ? 250 : 310; // Consider making this responsive
  const strokeWidth = 17; // The width of the clock circle
  const diameter = chartWidth - strokeWidth; // Reduce the diameter by the stroke width of the clock circle so that it fits within the chart width
  const center = chartWidth / 2;
  const radius = diameter / 2;
  const hours = 24; // 24 hour clock
  const completeCircle = 360; // degrees
  // Create an array for the hours and minutes so that it can be used to generate svg elements
  const completeCircleArray = new Array(completeCircle).fill(1);
  const hoursArray = new Array(hours).fill(1);

  const firstOutEvent = outData[0];

  // local state to hold the data required for the tooltip
  const [tooltipData, setTooltipData] = useState(
    firstOutEvent && {
      timeOutsideInMinutes: firstOutEvent?.value,
      timeRange: `${format(new Date(firstOutEvent?.x0), "HH:mm")} - ${format(
        new Date(firstOutEvent?.x),
        "HH:mm"
      )}`,
    }
  );
  const [indexOfSelectedArch, setIndexOfSelectedArch] = useState();

  // Pre select the first out event for the tooltip
  useEffect(() => {
    setIndexOfSelectedArch();
    setTooltipData();
  }, [outData]);

  // The clock svg component renders the grey circle for the clock by mapping over the minutes array
  const clock = completeCircleArray.map((_, index) => {
    const point = polarToCartesian(center, radius, index);
    return (
      <line
        stroke={theme.neutral2}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        key={index}
        x1={point.x}
        x2={point.x}
        y1={point.y}
        y2={point.y}
      />
    );
  });

  // The hour ticks svg are generated here
  const hourTicks = hoursArray.map((_, index) => {
    const start = polarToCartesian(center, radius - 30, index * 15);
    const end = polarToCartesian(center, radius - 15, index * 15);
    const time = polarToCartesian(center, radius - 25, index * 15);

    // Create smaller ticks for the half hour marks
    const startHalfHour = polarToCartesian(
      center,
      radius - 20,
      index * 15 + 7.5
    );
    const endHalfHour = polarToCartesian(center, radius - 15, index * 15 + 7.5);

    return (
      <g key={index}>
        {/* We exclude hour ticks where numbers are displayed (0, 6, 12, 18) */}
        {index % 6 !== 0 && (
          <line
            stroke={theme.neutral2}
            strokeWidth={1.5}
            strokeLinecap="round"
            x1={start.x}
            x2={end.x}
            y1={start.y}
            y2={end.y}
          />
        )}
        <line
          stroke={theme.neutral2}
          strokeWidth={1.5}
          strokeLinecap="round"
          x1={startHalfHour.x}
          x2={endHalfHour.x}
          y1={startHalfHour.y}
          y2={endHalfHour.y}
        />
        <text
          textAnchor="middle"
          fontSize="12"
          fontWeight="normal"
          fill={theme.neutral5}
          alignmentBaseline="central"
          x={time.x}
          y={time.y}
        >
          {index % 6 === 0 ? index : ""}
        </text>
      </g>
    );
  });

  // Function for getting the correct tooltip data when a particular segment is selected
  const showAbsenceToolTip = (absencesDuringDay, indexOfTimeOutside) => {
    const timeOutsideStart = new Date(
      absencesDuringDay?.[indexOfTimeOutside]?.x0
    );
    const timeOutsideEnd = new Date(absencesDuringDay?.[indexOfTimeOutside]?.x);
    setTooltipData({
      timeOutsideInMinutes: absencesDuringDay?.[indexOfTimeOutside]?.value,
      timeRange: `${format(timeOutsideStart, "HH:mm")} - ${format(
        timeOutsideEnd,
        "HH:mm"
      )}`,
    });
    setIndexOfSelectedArch(indexOfTimeOutside);
  };

  // Getting the blue line on the clock to show time outside
  // 1- Convert time to its position in degrees (dateTime) => number (degrees)
  const getDegreesFromDateTime = (dateTime) => {
    const oneHourInDegrees = 15; // 360/24
    const oneMinuteInDegrees = 0.25;
    // get degrees
    const hour = dateTime && parseInt(format(new Date(dateTime), "HH"));
    const minutes = dateTime && parseInt(format(new Date(dateTime), "mm"));
    // get total degrees
    const totalDegrees = hour * oneHourInDegrees + minutes * oneMinuteInDegrees;
    return totalDegrees;
  };

  // 2- getAbsencesAsDegrees([{x, x0},...]) => [{x.start, x.end}]
  const getAbsencesAsDegrees = (absencesDuringDay) => {
    let result = [];

    absencesDuringDay.forEach((i) => {
      const startDateTime = i?.x0;
      const endDateTime = i?.x;
      let start = getDegreesFromDateTime(startDateTime);
      let end = getDegreesFromDateTime(endDateTime);
      result.push({ start, end });
    });

    return result;
  };

  // 3- Get the Arch as represented by start & end times
  const getArch = (
    absencesDuringDay,
    startTimeInDegrees,
    endTimeInDegrees,
    indexOfTimeOutside
  ) => {
    // Get the circumference of our clock face
    const circumference = Math.PI * diameter;
    // Set the stroke width of the arc (this is the additional width added to the arch when we specify strokeLinecap="round")
    const strokeWidth = 9;
    // Find the number of stroke widths that make up the circumference
    const numStrokeWidthsInCircumference = circumference / strokeWidth;
    // Get the equivalent degrees for one stroke width. Dividing by two as only half of the width is beyond the point
    const degreeAdjustmentForStrokeWidth =
      360 / numStrokeWidthsInCircumference / 2;
    // Adjust both the start and end angles to account for the stroke width
    const adjustedStartAngle =
      startTimeInDegrees + degreeAdjustmentForStrokeWidth;
    const adjustedEndAngle = endTimeInDegrees - degreeAdjustmentForStrokeWidth;
    // Find the start point of the arch adjusting for the stroke width
    const startPoint = polarToCartesian(center, radius, adjustedStartAngle);
    // Find the end point of the arch adjusting for the stroke width
    const endPoint = polarToCartesian(center, radius, adjustedEndAngle);
    // Where an arc is larger than 180 degrees we need to tell the path to take the "longer" route.
    const largeArcFlag = adjustedEndAngle - adjustedStartAngle > 180 ? 1 : 0;

    return [
      <path
        d={`M ${startPoint.x} ${startPoint.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endPoint.x} ${endPoint.y}`}
        stroke={
          indexOfSelectedArch === indexOfTimeOutside
            ? theme.independence
            : theme.primary3
        }
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill-opacity="0"
        cursor="pointer"
        onMouseEnter={() =>
          showAbsenceToolTip(absencesDuringDay, indexOfTimeOutside)
        }
        onMouseLeave={() => {
          setIndexOfSelectedArch();
          setTooltipData();
        }}
        key={startTimeInDegrees}
      />,
    ];
  };

  // 4- Generate the arches for the clock
  const durationOutsideArches = getAbsencesAsDegrees(outData).map((i, index) =>
    getArch(outData, i.start, i.end, index)
  );

  // The total time outside, shown at the centre of the clock
  const toolTip = ((tooltipData) => {
    const hrsOutside = Math.floor(tooltipData?.timeOutsideInMinutes / 60);
    const minOutside = tooltipData?.timeOutsideInMinutes % 60;
    return (
      <svg x={-10} y={0}>
        <text
          textAnchor="middle"
          fontSize={36}
          fontWeight={500}
          alignmentBaseline="central"
          x={center - (hrsOutside > 9 ? 30 : 20)}
          y={center - 10}
        >
          {hrsOutside}
        </text>
        <text
          textAnchor="middle"
          fontSize={10}
          alignmentBaseline="central"
          x={center}
          y={center - 3}
        >
          Hr{hrsOutside !== 1 && "s"}
        </text>
        <text
          textAnchor="middle"
          fontSize={24}
          fontWeight={500}
          alignmentBaseline="central"
          x={center + 26}
          y={center - 6}
        >
          {minOutside}
        </text>
        <text
          textAnchor="middle"
          fontSize={10}
          fontWeight="400"
          alignmentBaseline="central"
          x={center + (minOutside > 9 ? 52 : 47)}
          y={center - 2}
        >
          min{minOutside !== 1 && "s"}
        </text>
        {tooltipData?.timeRange && (
          <text
            textAnchor="middle"
            fontSize={10}
            fontWeight={500}
            alignmentBaseline="central"
            x={center + 12}
            y={center + 16}
          >
            {tooltipData?.timeRange}
          </text>
        )}
      </svg>
    );
  })(tooltipData);

  return (
    <svg width={chartWidth} height={chartWidth}>
      <g>
        {clock}
        {hourTicks}
        {outData.length > 0 && durationOutsideArches}
        {tooltipData && toolTip}
      </g>
      <svg x={center - 6} y={center - 82}>
        <SunIcon colour={theme.neutral7} size={12} />
      </svg>
      <svg x={center - 6} y={center + 70}>
        <MoonIcon colour={theme.neutral7} size={12} />
      </svg>
      {outData.length === 0 && (
        <text
          textAnchor="middle"
          fontSize={16}
          fontWeight={500}
          alignmentBaseline="central"
          x={center}
          y={center}
        >
          No time outside
        </text>
      )}
    </svg>
  );
};

export default DayIndependence;
