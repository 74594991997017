export function reorderSensorsArray(devices) {
  // Define the desired order of defaultNames
  const desiredOrder = ["SquidZigBee", "Smart Plug Mini UK"];
  // Create a map of desired order indices for quick lookup
  const orderMap = desiredOrder.reduce((map, name, index) => {
    map[name] = index;
    return map;
  }, {});

  // Sort the devices based on the order map, putting unmatched devices at the end
  return devices?.sort((a, b) => {
    const orderA =
      orderMap[a.defaultName] !== undefined
        ? orderMap[a.defaultName]
        : Infinity;
    const orderB =
      orderMap[b.defaultName] !== undefined
        ? orderMap[b.defaultName]
        : Infinity;
    return orderA - orderB;
  });
}
