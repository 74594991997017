import React from "react";
import styles from "./styles.module.scss";
// components
import LoadingBubbles from "../LoadingBubbles";

const InstallQRLoading = ({ initials }) => {
  return (
    <div className={styles.qr_loading_container}>
      <div className={styles.user_initials}>{initials}</div>
      <LoadingBubbles />
      <img
        src="/images/installation/hub/hub.png"
        alt="Lilli hub"
        className={styles.logo}
      />
    </div>
  );
};

export default InstallQRLoading;
