import React from "react";
import styles from "./styles.module.scss";

export const AvatarStaffToolTip = (props) => {
  const firstName = `${props.staff.forenames ? props.staff.forenames : ""}`;
  const lastName = `${props.staff.surname ? props.staff.surname : ""}`;
  const name = (firstName || lastName) && `${firstName} ${lastName}`;

  return (
    <div
      className={`
        ${styles.avatar_staff_tooltip_container}
        ${
          props.avatarGreen
            ? styles.avatar_background_green
            : styles.avatar_background_blue
        }
      `}
    >
      <div className={styles.avatar_name}>
        {name ? name : props.staff.username}
      </div>
    </div>
  );
};

export const TextStaffToolTip = ({ viewers, hasStaffMatch }) => (
  <div className={`${styles.text_staff_tooltip_container}`}>
    {viewers.map((staff, index) => {
      const firstname = staff.forenames ? staff.forenames : "";
      const lastname = staff.surname ? staff.surname : "";
      const staffName = (firstname || lastname) && `${firstname} ${lastname}`;
      return (
        <div
          key={`${staffName || staff.username}${index}`}
          className={`
                ${styles.staff_item} 
                ${
                  hasStaffMatch &&
                  !staff.highlight &&
                  styles.staff_does_not_match_results
                } 
              `}
        >
          {staffName || staff.username}
        </div>
      );
    })}
  </div>
);
