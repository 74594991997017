import React from "react";
import styles from "./styles.module.scss";
// Components
import RiskSquare from "../../Components/RiskSquare";
// Utils
import { cleanBehaviourData, RiskLevel } from "@intelligentlilli/lilli-utils";
import { format } from "date-fns";

const BehaviourHeatmap = ({
  movementData,
  sustenanceData,
  independenceData,
  sleepData,
  temperatureData,
  className,
  dates,
  behaviours,
}) => {
  return (
    <div>
      {behaviours?.includes("movement") && (
        <div className={`${styles.behaviour} ${className}`}>
          <div className={styles.label_behaviour}>Movement</div>
          {cleanBehaviourData(
            movementData?.flatMap((week) => week.dailyResults),
            dates
          ).map((day, index) => (
            <RiskSquare
              key={index}
              riskStatus={RiskLevel[day.dayRating]}
              className={styles.riskSquare}
            />
          ))}
        </div>
      )}
      {behaviours?.includes("sustenance") && (
        <div className={styles.behaviour}>
          <div className={styles.label_behaviour}>Eating & drinking</div>
          {cleanBehaviourData(
            sustenanceData?.flatMap((week) => week.dailyResults),
            dates
          )?.map((day, index) => (
            <RiskSquare
              key={index}
              riskStatus={RiskLevel[day.dayRating]}
              className={styles.riskSquare}
            />
          ))}
        </div>
      )}
      {behaviours?.includes("nighttime_activity") && (
        <div className={styles.behaviour}>
          <div className={styles.label_behaviour}>Night-time activity</div>
          {cleanBehaviourData(
            sleepData?.flatMap((week) => week.dailyResults),
            dates
          )?.map((day, index) => (
            <RiskSquare
              key={index}
              riskStatus={RiskLevel[day.dayRating]}
              className={styles.riskSquare}
            />
          ))}
        </div>
      )}
      {/* {behaviours?.includes("independence") && (
        <div className={styles.behaviour}>
          <div className={styles.label_behaviour}>Time Outside</div>
          {cleanBehaviourData(
            independenceData?.flatMap((week) => week.dailyResults),
            dates
          )?.map((day, index) => (
            <RiskSquare
              key={index}
              riskStatus={RiskLevel[day.dayRating]}
              className={styles.riskSquare}
            />
          ))}
        </div>
      )} */}
      {/* {behaviours?.includes("temperature") && (
        <div className={styles.behaviour}>
          <div className={styles.label_behaviour}>Temperature</div>
          {cleanBehaviourData(
            temperatureData?.flatMap((week) => week.dailyResults),
            dates
          )?.map((day, index) => (
            <RiskSquare
              key={index}
              riskStatus={RiskLevel[day.dayRating]}
              className={styles.riskSquare}
            />
          ))}
        </div>
      )} */}
      <div className={`${styles.days_of_the_week}`}>
        {dates.map((day, index) => (
          <div className={styles.label} key={index}>
            {format(day, "dd")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BehaviourHeatmap;
