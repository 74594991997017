import React from "react";
import { PropTypes } from "prop-types";
import styles from "./styles.module.scss";
// Components
import Backdrop from "../Backdrop";
import { CancelOutlinedIcon } from "../../Styles/Icons";

const Modal = (props) => (
  <div>
    <Backdrop show={props.show} onClick={props.hide} />
    {props.show && (
      <div className={`${styles.modal} ${props.className}`}>
        {props.title && (
          <div className={`${styles.modal_title} ${props.titleStyle}`}>
            {props.title}
          </div>
        )}
        {props.context && (
          <div className={styles.modal_context}>{props.context}</div>
        )}
        <CancelOutlinedIcon
          className={styles.modal_close}
          onClick={props.hide}
        />
        {props.children}
      </div>
    )}
  </div>
);

Modal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
