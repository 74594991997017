import React from "react";

export const Avatar4 = ({ width, primaryColour, secondaryColour }) => {
  return (
    <svg width={width || 40} viewBox="0 0 40 40" fill="none" id="Avatar4">
      <g clipPath="url(#clip0_290_17640)">
        <rect width="40" height="40" rx="3.27273" fill={primaryColour} />
        <circle cx="43.7172" cy="19.9999" r="23.5556" fill={secondaryColour} />
        <circle cx="-3.39391" cy="19.9999" r="23.5556" fill={secondaryColour} />
      </g>
      <defs>
        <clipPath id="clip0_290_17640">
          <rect width="40" height="40" rx="3.27273" />
        </clipPath>
      </defs>
    </svg>
  );
};
