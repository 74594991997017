import React, { useEffect } from "react";
import styles from "./styles.module.scss";
// Components
import Backdrop from "../Backdrop";
import { CloseIcon } from "../../Styles/Icons";

const RiskStatusExplainerModal = ({
  riskStatus,
  context,
  showAnimation,
  hide,
}) => {
  // Control the overflow css property of the body of the page when the modal is open. This prevents the background scrolling.
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const colorDictionary = {
    "At risk": styles.at_risk,
    Unexpected: styles.unexpected,
    Expected: styles.expected,
  };

  const riskStatusStyle = colorDictionary[riskStatus];

  return (
    <div className={`${styles.risk_status_outer}`}>
      <Backdrop show={true} onClick={hide} />
      <div
        className={`${styles.risk_status_inner} 
          // Must be expressed in this way, otherwise a modal that does not have the showAnimation property will default to null, which is falsy, and will incorreectly apply a fade_out style
          ${showAnimation === false ? styles.fade_out : styles.fade_in}
        `}
      >
        <div lassName={styles.icon_wrapper}>
          <CloseIcon className={styles.modal_close} onClick={hide} />
        </div>

        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.title}>
              Why are these users{" "}
              <span className={riskStatusStyle}>
                {riskStatus === "At risk" ? "At risk" : riskStatus}
              </span>
              ?
            </div>
          </div>
          <div className={styles.context}>{context}</div>
        </div>
      </div>
    </div>
  );
};

export default RiskStatusExplainerModal;
