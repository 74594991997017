import React from "react";

export const LilliLogoTealSvg = ({ width }) => {
  return (
    <svg
      width={width || 40}
      height="30"
      viewBox="0 0 40 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.05498 28.4539C5.05498 29.303 4.35984 29.9914 3.50263 29.9914H1.55235C0.695047 29.9914 0 29.303 0 28.4539V1.53748C0 0.688523 0.695047 0 1.55235 0H3.50263C4.35984 0 5.05498 0.688523 5.05498 1.53748V28.4539ZM18.7532 28.4539C18.7532 29.303 18.0582 29.9914 17.201 29.9914H15.2506C14.3934 29.9914 13.6983 29.303 13.6983 28.4539V1.53748C13.6983 0.688523 14.3934 0 15.2506 0H17.201C18.0582 0 18.7532 0.688523 18.7532 1.53748V28.4539ZM24.107 29.9914C24.9642 29.9914 25.6592 29.303 25.6592 28.4539V1.53748C25.6592 0.688523 24.9642 0 24.107 0H22.1566C21.2994 0 20.6043 0.688523 20.6043 1.53748V28.4539C20.6043 29.303 21.2994 29.9914 22.1566 29.9914H24.107ZM29.0626 11.737C28.2055 11.737 27.5104 12.4255 27.5104 13.2746V28.4455C27.5104 29.2946 28.2055 29.983 29.0626 29.983H30.8993C31.7574 29.983 32.4516 29.2946 32.4516 28.4455V13.2746C32.4516 12.4255 31.7574 11.737 30.8993 11.737H29.0626ZM6.90601 13.2746C6.90601 12.4255 7.60106 11.737 8.45836 11.737H10.295C11.153 11.737 11.8472 12.4255 11.8472 13.2746V28.4455C11.8472 29.2946 11.153 29.983 10.295 29.983H8.45836C7.60106 29.983 6.90601 29.2946 6.90601 28.4455V13.2746Z"
        fill="#143C46"
      />
      <path
        d="M39.7046 27.2925C39.7046 28.7705 38.4953 29.9683 37.0032 29.9683C35.5111 29.9683 34.3027 28.7705 34.3027 27.2925C34.3027 25.8148 35.5111 24.6168 37.0032 24.6168C38.4953 24.6168 39.7046 25.8148 39.7046 27.2925Z"
        fill="#F55A5A"
      />
    </svg>
  );
};
