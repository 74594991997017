import { formatDistance, fromUnixTime } from "date-fns";
import React from "react";
import styles from "./styles.module.scss";
import { capitalize } from "@intelligentlilli/lilli-utils";

const FriendsAndFamilyItem = ({ staff, navigate }) => {
  return (
    <div className={styles.table_row}>
      <div className={`${styles.bold} ${styles.table_row_element}`}>
        {staff.forenames} {staff.surname}
      </div>
      <div className={styles.service_users}>{staff?.serviceUsersString}</div>
      <div className={styles.table_row_element}>
        {staff?.lastActivity &&
          capitalize(
            formatDistance(
              fromUnixTime(staff?.lastActivity?.slice(0, -3)),
              new Date()
            )
          )}{" "}
        {staff?.lastActivity ? "ago" : "Unknown"}
      </div>
    </div>
  );
};

export default FriendsAndFamilyItem;
