import { Button } from "@mui/material";
import variables from "../../Styles/theme.scss";
import { styled } from "@mui/material/styles";

const CTAButtonStyled = styled(Button)(() => ({
  padding: "12.8px 16px",
  color: variables.neutral7,
  textTransform: "none",
  backgroundColor: variables.primary3,
  "&:disabled": {
    color: variables.neutral4,
    backgroundColor: variables.neutral2,
  },
  "&:active": {
    backgroundColor: variables.newprimary3,
    color: variables.white,
  },
  "&:hover": {
    backgroundColor: variables.primary4,
  },
  "& .MuiButton-startIcon": { marginRight: 4, marginBottom: 3 },
  "& .MuiButton-endIcon": { marginLeft: 4, marginBottom: 3 },
}));

const CTAButton = ({
  children,
  style,
  className,
  endIcon,
  onClick,
  startIcon,
  disabled,
  type,
  id,
}) => {
  return (
    <CTAButtonStyled
      style={style}
      className={className}
      endIcon={endIcon}
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled}
      type={type}
      id={id}
    >
      {children}
    </CTAButtonStyled>
  );
};
export default CTAButton;
