import { format } from "date-fns";
import { getHoursAndMinutesAsString } from "../getHoursAndMinutesAsString";
import { sensorDisplayNameDictionary } from "../../Constants";

export const dayTimeDayExpectedDuration = (
  movementData,
  serviceUserString,
  isCurrentDay,
  onClick,
  noMovementData
) => {
  if (noMovementData) {
    return {
      offline: true,
      title: "Day-time activity",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }
  const actualMovement =
    movementData?.day?.insights?.[movementData?.day?.insights?.length - 1]
      ?.durationMinutes;
  const minExpectedMovement =
    movementData?.day?.insights?.[movementData?.day?.insights?.length - 1]
      ?.expectedMinutesMin;

  const dayTimeDuration =
    getHoursAndMinutesAsString(actualMovement, false) || "";
  const dayTimeMin =
    getHoursAndMinutesAsString(minExpectedMovement, false) || "";

  const totalMovementsSentence = [
    {
      text: `${
        serviceUserString || "This person"
      } moved around the home for ${dayTimeDuration}. `,
      type: "normal",
    },
  ];

  const atRiskSentence = [
    { text: "This is", type: "normal" },
    { text: " less than usual. ", type: "bold" },
  ];

  const isModelReady =
    movementData?.day?.insights?.[movementData.day.insights.length - 1]
      ?.modelReady;

  // There are some discrepancies between the risk score the model calculates and what we want to display. A user can technically be simultaneously at risk and also expected.
  const riskScore =
    !actualMovement || !minExpectedMovement || !isModelReady
      ? 0
      : actualMovement >= minExpectedMovement
      ? 0
      : 1;

  if (riskScore === 1) {
    totalMovementsSentence.push(...atRiskSentence);
  }
  const insightContextPrevDay = !dayTimeMin
    ? totalMovementsSentence
    : [
        ...totalMovementsSentence,
        {
          text: `In a complete day, Lilli expects ${
            serviceUserString || "this person"
          } to move for a minimum of ${dayTimeMin}.`,
          type: "normal",
        },
      ];

  const insightContextCurrentDay = !dayTimeMin
    ? totalMovementsSentence
    : [
        ...totalMovementsSentence,
        {
          text: `By this time in the day, ${
            serviceUserString || "this person"
          } typically moves for at least ${dayTimeMin}.`,
          type: "normal",
        },
      ];

  const insightContextLearningPeriod = [
    ...totalMovementsSentence,
    {
      text: "Lilli is still learning their expected behaviour.",
      type: "normal",
    },
  ];

  return {
    title: "Day-time activity",
    context: !isModelReady
      ? insightContextLearningPeriod
      : isCurrentDay
      ? insightContextCurrentDay
      : insightContextPrevDay,
    icon: "MOVEMENT",
    riskLevel: riskScore,
    onClick: onClick,
    children: {
      type: "MOVEMENT_DONUT",
      totalMovements: actualMovement,
      totalMovementsString: dayTimeDuration,
      expectedMovements: isModelReady ? minExpectedMovement : null,
      expectedMovementsString: isModelReady ? `${dayTimeMin} expected` : null,
    },
  };
};

export const dayTimeDayFirstMovement = (movementData) => {
  const firstMovementString =
    movementData?.day?.firstEvent?.[1] &&
    format(new Date(movementData?.day?.firstEvent[1]), "h:mmaaa");
  return {
    title: "First day-time activity",
    headline: `${firstMovementString || "No events"}`,
    context: firstMovementString
      ? "was the time the first event occurred today (6am - 10pm)"
      : null,
    icon: "MOVEMENT",
  };
};

export const dayTimeDayMostRecent = (movementData) => {
  const lastMovementString =
    movementData?.day?.lastEvent?.[1] &&
    format(new Date(movementData?.day?.lastEvent[1]), "h:mmaaa");
  const lastMovementSensorString =
    movementData?.day?.lastEvent && movementData?.day?.lastEvent[0];
  return {
    title: "Most recent event",
    headline: `${lastMovementString || "No events"}`,
    context: lastMovementString
      ? sensorDisplayNameDictionary[lastMovementSensorString]
      : null,
    icon: "MOVEMENT",
  };
};

export const dayTimeDayNoRecentActivity = (
  numHoursWithNoActivity,
  isCurrentDay,
  serviceUserString
) => {
  const currentDayContextSentence = numHoursWithNoActivity
    ? `${
        serviceUserString || "this person"
      } has shown no movement for ${numHoursWithNoActivity} hours; this is unusual.
  You may want to check on this service user, or that the sensors are working correctly.`
    : `${
        serviceUserString || "this person"
      } has shown no movement today; this is unusual.
  You may want to check on this service user, or that the sensors are working correctly.`;

  const prevDayContextSentence = numHoursWithNoActivity
    ? `${
        serviceUserString || "this person"
      } had shown no movement for ${numHoursWithNoActivity} hours; this is unusual.`
    : `${
        serviceUserString || "this person"
      } had shown no movement for the day; this is unusual.`;

  return {
    title: "Activity",
    headline: "No recent activity",
    context: isCurrentDay ? currentDayContextSentence : prevDayContextSentence,
    riskLevel: 2,
    icon: "AT_RISK_INSIGHT",
  };
};
