import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
import { getOrganisation } from "@intelligentlilli/api-layer";
import DashboardJustReleased from "../../Components/DashboardJustReleased";
// import { useViewport } from "../../Components/ViewportProvider";

const DashboardHeader = () => {
  const videoSource =
    "https://www.youtube.com/embed/6wo-ZpMe_-I?si=CygC67mpW8W2GBF7";

  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const server = useSelector((state) => state.session.server);
  const [organisation, setOrganisation] = useState();

  // const { width } = useViewport();
  // const isMobile = width <= 1200;
  const showJustReleased = true;

  useEffect(() => {
    if (user.organisationId) {
      getOrganisation(server, user.organisationId, "web").then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
        } else {
          setOrganisation(res.body);
        }
      });
    }
  }, [server, user.organisationId, navigate]);

  return (
    <div className={styles.dashboard_header_container}>
      <div>
        <div className={styles.page_title}>Dashboard</div>
        <div className={styles.user_info}>
          {user?.forenames} {user?.surname} | {organisation?.name || ""}
        </div>
      </div>
      {showJustReleased && (
        <div className={styles.component_just_released}>
          <DashboardJustReleased
            releaseTitle={"Just Released: New Lilli reports"}
            releaseSubheading={
              "Why not try it out and create a report to offer insight into your users' behaviour?"
            }
            videoSourceURL={videoSource}
            // videoDescription="Some text describing the release"
            showButton={false}
            // pageToNavigateTo={"/reports"}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;
