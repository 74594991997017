import { DataType } from "../../types/NoData/DataType";

/*  
  Construct null flight path
  Any date that has a number, make it 'null'
  Any date that is null, give it a min and maxValue
*/
export const getNoDataBins = (
  data: DataType[] | undefined
): number[] => {
  if (data === undefined || data.length < 1) {
    return [];
  }

  // Using JSON.stringify allows for a deep copy of data. Using [...data] will not work.
  const copyOfData = JSON.parse(JSON.stringify([...data]));

  // 1- make an array containing only the y-values
  const yValues: (number | null)[] = data.map((item) => item.y);

  // 2- create bin values
  const bins: number[] = [];
  for (let i = 0; i < copyOfData.length; i++) {
    // if the first and last values are null, add their indices (+1) to the bin array
    if ((i === 0 || i === yValues.length - 1) && yValues[i] === null) {
      bins.push(i + 1);
    }

    // as long as the previous index is greater than 0...
    // if the previous index is null and the current index is NOT null
    // then give the current index a flight path
    if (i - 1 >= 0 && yValues[i - 1] === null && yValues[i] !== null) {
      bins.push(i + 1);
    }

    // similarly, as long as the next index is less than the length of the array...
    // if the next index is null and the current index is NOT null
    // then give it a flight path
    if (
      i + 1 < yValues.length &&
      yValues[i + 1] === null &&
      yValues[i] !== null
    ) {
      bins.push(i + 1);
    }
  }

  return bins;
};
