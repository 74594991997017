import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import { getInitials } from "@intelligentlilli/lilli-utils";
// Components
import ServiceUserDoughnut from "../ServiceUserDoughnut";
import Card from "../Card";

const ServiceUserCard = (props) => {
  const careID =
    props.hub?.serviceUserProperties?.mosaicID ||
    props.hub?.serviceUserProperties?.careId ||
    props.hub?.hubProperties?.mosaicId ||
    props.hub?.hubProperties?.careID ||
    "Not set";

  return (
    <Card onClick={props.onClick} variant="outlined" style={styles.card}>
      <div className={styles.card_top}>
        <ServiceUserDoughnut
          initials={getInitials(props.hub?.userstring)}
          profileImage={props.hub?.photo}
          weekRisks={props.hubData?.weekRisks}
          riskStatus={props.hubData?.riskStatus}
          altText={`Profile photo for ${props.hub?.userstring}`}
        />
        <div className={styles.card_right}>
          <div className={styles.card_right_name}>{props.hub?.userstring}</div>
          <div className={styles.card_right_id}>ID: {careID}</div>
        </div>
      </div>
      <div>
        <div className={styles.bold}>Conditions</div>
        <span>{props.hub?.serviceUserProperties?.conditions}</span>
      </div>
      <div>
        <div className={styles.bold}>Staff ({props.hub?.viewers?.length})</div>
        <div className={styles.staff}>
          <div className={styles.staff_staff}>
            {props.hub?.viewers?.map((viewer, index) => (
              <span key={index}>
                {index > 0 && index !== props.hub?.viewers?.length && ", "}{" "}
                {viewer.forenames && viewer.surname
                  ? `${viewer.forenames} ${viewer.surname}`
                  : viewer.username}
              </span>
            ))}
          </div>

          <div>...</div>
        </div>
      </div>
    </Card>
  );
};

ServiceUserCard.propTypes = {
  onClick: PropTypes.func,
  hub: PropTypes.object,
  hubData: PropTypes.object,
};

export default ServiceUserCard;
