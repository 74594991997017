const AudioContext = window.AudioContext || window.webkitAudioContext;

const createBeep = (audioContext, freq, start, stop) => {
  const gainNode = audioContext.createGain();
  gainNode.connect(audioContext.destination);
  gainNode.gain.value = 1;

  const oscillatorNode = audioContext.createOscillator();
  oscillatorNode.type = "square";
  oscillatorNode.frequency.value = freq;
  oscillatorNode.connect(gainNode);
  oscillatorNode.start(start);
  oscillatorNode.stop(stop);
};

export const playSuccessSoundEffect = () => {
  if (typeof AudioContext !== "undefined") {
    const audioContext = new AudioContext();
    const c6 = 1046.5; //Hz
    createBeep(
      audioContext,
      c6,
      audioContext.currentTime,
      audioContext.currentTime + 0.1
    );
  } else {
    console.log("Web Audio API is not supported in this browser.");
  }
};

export const playFailureSoundEffect = () => {
  if (typeof AudioContext !== "undefined") {
    const audioContext = new AudioContext();
    const d3 = 146.832; //Hz
    const a2 = 110.0; //Hz
    createBeep(
      audioContext,
      d3,
      audioContext.currentTime,
      audioContext.currentTime + 0.05
    );
    createBeep(
      audioContext,
      a2,
      audioContext.currentTime + 0.1,
      audioContext.currentTime + 0.15
    );
  } else {
    console.log("Web Audio API is not supported in this browser.");
  }
};
