import { isSameDay } from "date-fns";

interface DailyData {
  date: string;
  isComplete?: boolean;
}

interface HubPropertyData {
  dailyResults?: DailyData[] | null;
}

export const getCombinedBehaviourAnalysisData = (
  serviceUserData: { [key: string]: HubPropertyData | undefined } | null,
  hubProperty: string,
  resData: {
    [key: string]: {
      dailyResults?: DailyData[] | null;
    };
  }
) => {
  // Step 1 - Get the old state data
  const oldState = serviceUserData?.[hubProperty]?.dailyResults || [];

  // Step 2 - Get new daily results and filter out null and undefined values
  const newDR = resData?.[hubProperty]?.dailyResults?.filter((i) => i) || [];

  // Step 3 - Create a Map to store unique daily results by date
  const dailyResultsMap = new Map<string, DailyData>();

  // Step 4 - Add old daily results to the Map
  oldState.forEach((element) => dailyResultsMap.set(element.date, element));

  // Step 5 - Add new daily results to the Map, overwriting any duplicates with the ones from newDR
  newDR.forEach((item) => dailyResultsMap.set(item.date, item));

  // Step 6 - Extract the values from the Map to get the combined daily results
  const combinedDailyResults = Array.from(dailyResultsMap.values());

  // Step 7 - Add isComplete property
  const addIsCompleteProperty = combinedDailyResults.map((item) => ({
    ...item,
    isComplete: item.isComplete ?? !isSameDay(new Date(), new Date(item.date)),
  }));

  // Step 8 - Sort by date
  addIsCompleteProperty.sort((a, b) =>
    a.date > b.date ? 1 : a.date < b.date ? -1 : 0
  );

  // Step 9 - Return the updated state
  return {
    ...resData?.[hubProperty],
    dailyResults: addIsCompleteProperty,
  };
};
