import { useEffect, useState } from "react";

export function useFade(initialBoolean, miliSeconds) {
  const [showAnimation, setShowAnimation] = useState(initialBoolean);
  const [isVisible, setVisible] = useState(showAnimation);

  // Update visibility when showAnimation changes
  useEffect(() => {
    let timeoutId;

    if (showAnimation) {
      setVisible(true);
    }

    if (!showAnimation) {
      timeoutId = setTimeout(() => {
        setVisible(false);
      }, miliSeconds);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showAnimation, miliSeconds]);

  return [isVisible, setShowAnimation, showAnimation];
}
