import { useEffect, useState } from "react";
// UTILS
import { endOfDay } from "date-fns";

// This hook is used to generate data that are used in the Event Log
export const usePrepareBathroomData = (
  bathroomActivityInference,
  startDateString,
  endDateString,
  prevEndDateString,
  prevStartDateString,
  dateType
) => {
  const [bathroomInference, setBathroomInference] = useState([]);
  const [previousBathroomInferenceData, setPreviousBathroomInferenceData] =
    useState();

  useEffect(() => {
    const endDate = endDateString && endOfDay(new Date(endDateString));
    const startDate = startDateString && endOfDay(new Date(startDateString));

    const prevEndDate =
      prevEndDateString && endOfDay(new Date(prevEndDateString));
    const prevStartDate =
      prevStartDateString && endOfDay(new Date(prevStartDateString));

    // Get a subset of bathroom activity data
    setBathroomInference(
      getRelevantTimePeriod(bathroomActivityInference, startDate, endDate)
    );
    setPreviousBathroomInferenceData(
      getRelevantTimePeriod(
        bathroomActivityInference,
        prevStartDate,
        prevEndDate
      )
    );
  }, [
    bathroomActivityInference,
    startDateString,
    endDateString,
    prevEndDateString,
    prevStartDateString,
    dateType,
  ]);

  return {
    // Returning an empty array where there are no bathroom events for a day but the user does have a bathroom sensor.
    bathroomInference: bathroomInference,
    previousBathroomInferenceData: previousBathroomInferenceData,
  };
};

// Functions that help with extracting and processing bathroom activity data
const getRelevantTimePeriod = (array, startDate, endDate) => {
  return (
    array?.filter((event) => {
      return (
        endOfDay(new Date(event?.date)) >= new Date(startDate) &&
        endOfDay(new Date(event?.date)) <= new Date(endDate)
      );
    }) || []
  );
};
