import React from "react";
import styles from "../onboardingform.module.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import FormError from "../../Components/FormError";
import CTAButton from "../../Components/CTAButton";
// utils
import { hasWhiteSpace } from "@intelligentlilli/lilli-utils";
import { isValidEmail } from "../../Services/Utils";

const ChangeEmailForm = (props) => (
  <Form
    onSubmit={(values) => {
      props.onSubmit(values.email.trim());
    }}
    validate={(values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Required";
      }
      if (values.email && !isValidEmail(values.email.trim())) {
        errors.email = "Invalid email";
      }
      if (values.email && hasWhiteSpace(values?.email)) {
        errors.email = "Please remove whitespace";
      }
      return errors;
    }}
    render={({ handleSubmit, submitting, valid, values }) => (
      <form
        className={styles.form}
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <div>
          <Field name="email" initialValue={props.email}>
            {({ input, meta }) => (
              <div className={styles.input}>
                <label>
                  Email
                  <input {...input} type="text" />
                </label>
                {meta.error && meta.touched && (
                  <span className={styles.error}>{meta.error}</span>
                )}
              </div>
            )}
          </Field>

          {props.error && <FormError error={props.error} />}
        </div>

        <CTAButton
          type="submit"
          disabled={submitting || !valid || values.email === props.email}
          className={styles.submit}
          cypresstestid="Change-Email-Btn-Submit"
        >
          Update email
        </CTAButton>
      </form>
    )}
  />
);

export default ChangeEmailForm;
