import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
// Components
import ServiceUserImage from "../ServiceUserImage";
import { getInitialsFromNames } from "@intelligentlilli/lilli-utils";

const InstallerListItem = (props) => {
  const installerName =
    props.installer?.forenames && props.installer?.surname
      ? props.installer?.forenames + " " + props.installer?.surname
      : props.installer?.username;

  return (
    <div className={`${styles.card}`} onClick={props.onClick}>
      <div className={`${styles.serviceUser} ${styles.card_element}`}>
        <ServiceUserImage
          small
          profilePhotoURL={props.installer?.photo}
          initials={getInitialsFromNames(installerName)}
        />
        <div className={styles.serviceUser_name}>
          <div className={styles.serviceUser_name_name}>{installerName}</div>
        </div>
      </div>
    </div>
  );
};

InstallerListItem.propTypes = {
  onClick: PropTypes.func,
  installer: PropTypes.object,
  profileImage: PropTypes.string,
};

export default InstallerListItem;
