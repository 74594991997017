import React from "react";
import styles from "./styles.module.scss";

const FormContainer = ({ children }) => {
  return (
    <div className={styles.outer_container}>
      <div className={styles.inner_container}>{children}</div>
    </div>
  );
};

export default FormContainer;
