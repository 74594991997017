import React from "react";
import theme from "../../theme.scss";

export const CupboardIcon = ({ width, color }) => {
  const widthToHeightRatio = 512 / 478;
  const defaultColor = theme.neutral7;
  return (
    <svg
      width={width || 23}
      height={width * widthToHeightRatio || 23 * widthToHeightRatio}
      viewBox="0 0 512 478"
      fill="none"
      id="CupboardIcon"
    >
      <g clipPath="url(#clip0_434_488)">
        <path
          d="M286.181 207.924V261.711C286.181 266.177 289.8 269.795 294.265 269.795C298.731 269.795 302.349 266.176 302.349 261.711V207.924C302.349 203.458 298.731 199.84 294.265 199.84C289.8 199.84 286.181 203.459 286.181 207.924Z"
          fill={color || defaultColor}
        />
        <path
          d="M217.735 269.795C222.201 269.795 225.819 266.176 225.819 261.711V207.924C225.819 203.459 222.2 199.84 217.735 199.84C213.27 199.84 209.651 203.458 209.651 207.924V261.711C209.65 266.176 213.269 269.795 217.735 269.795Z"
          fill={color || defaultColor}
        />
        <path
          d="M489.51 0H473.962H460.68C456.214 0 452.596 3.619 452.596 8.084C452.596 12.549 456.215 16.168 460.68 16.168H473.962H489.51C492.996 16.168 495.832 19.004 495.832 22.489V426.697C495.832 430.182 492.996 433.018 489.51 433.018H472.648H264.084V16.168H295.29H433.342C437.808 16.168 441.426 12.549 441.426 8.084C441.426 3.619 437.808 0 433.343 0H295.29H256H22.49C10.089 0 0 10.088 0 22.489V426.697C0 439.098 10.089 449.186 22.49 449.186H31.267V457.268C31.267 468.858 40.696 478.287 52.286 478.287H145.162C149.628 478.287 153.246 474.668 153.246 470.203C153.246 465.737 149.628 462.119 145.162 462.119H52.287C49.613 462.119 47.436 459.943 47.436 457.268V449.186H256H464.564V457.268C464.564 459.942 462.388 462.119 459.713 462.119H173.548C169.082 462.119 165.464 465.737 165.464 470.203C165.464 474.669 169.082 478.287 173.548 478.287H459.714C471.304 478.287 480.733 468.858 480.733 457.268V449.186H489.51C501.911 449.186 512 439.098 512 426.697V22.489C512 10.088 501.911 0 489.51 0ZM247.916 433.019H39.352H22.49C19.004 433.019 16.168 430.183 16.168 426.698V22.489C16.168 19.004 19.004 16.168 22.49 16.168H247.915V433.019H247.916Z"
          fill={color || defaultColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_434_488">
          <rect width="512" height="478" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
