type DataType = {
  ts: Date;
  // Add other properties as needed
};

export const getDayViewData = (
  dataArray: DataType[],
  toDate: Date
): DataType[] => {
  const propstoDate = toDate.toDateString();
  const newArr = dataArray.filter((hours) => {
    const hourstoDate = hours.ts.toDateString();
    return hourstoDate.includes(propstoDate);
  });
  return newArr;
};
