interface RiskScoreData {
  date: string;
}

export const getCombinedRiskScoreData = (
  serviceUserData: { [key: string]: RiskScoreData[] } | null,
  hubProperty: string,
  responseDayRatingsArray: RiskScoreData[]
): RiskScoreData[] => {
  // Extract old state data or initialize as an empty array
  const oldState = serviceUserData?.[hubProperty] || [];

  // Create a map to store RiskScoreData objects by date
  const stateMap = new Map<string, RiskScoreData>();

  // Populate the map with items from oldState
  oldState.forEach((item) => stateMap.set(item.date, item));

  // Update or add items from responseDayRatingsArray to the map
  responseDayRatingsArray.forEach((item) => stateMap.set(item.date, item));

  // Convert the map values back to an array
  const combinedData = Array.from(stateMap.values());
  return combinedData;
};
