import { dateToTimestamp } from "../Dates/dateManipulations";
import { isSameDay } from "date-fns";

interface DayData {
  date: string;
  dailyData?: {
    [key: string]: any;
  };
}

export const extractEvents = (
  data: DayData[] | undefined,
  date: Date,
  key: string
): any => {
  // Firstly extracting the correct day from the array of days
  const extractedDay = data?.find((day) =>
    isSameDay(dateToTimestamp(day.date), date)
  );
  // Finally return the entrance door object within the independence data for the correct day
  return extractedDay?.dailyData?.[key];
};
