import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
// State
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../State/slices/session-slice";
// API
import { postForgottenPasswordReset } from "@intelligentlilli/api-layer";
// Components
import ResetPasswordForm from "../../Forms/ResetPasswordForm";
import GuestPage from "../../Components/GuestPage";

const UserOnboarding = () => {
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  // get the token from the URL
  const { token } = useParams();
  const navigate = useNavigate();

  // Redux State
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);

  // Handling user forgetting password
  const onSubmitResetPassword = (password) => {
    dispatch(setLoading(true));
    postForgottenPasswordReset(server, password, token, "web")
      .then((res) => {
        if (!res.ok) {
          dispatch(setLoading(false));
          if (res === undefined) {
            setResetPasswordError(
              "Lilli servers are down. Please try again later."
            );
          } else if (res.status === 400) {
            setResetPasswordError(res.body.error);
            console.log("res.body.error", res.body.error);
          } else if (res.status === 422) {
            setResetPasswordError(res.body.reason);
            console.log("res.body.reason", res.body.reason);
          } else {
            setResetPasswordError(
              "An unexpected error occured, password cannot be reset."
            );
            console.log(res.body.reason);
          }
        } else {
          console.log("User onboarding - PW reset success!");
          setResetPasswordSuccess(true);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        setResetPasswordError(
          "An unexpected error occured, password cannot be reset."
        );
        dispatch(setLoading(false));
      });
  };

  return (
    <GuestPage>
      <div className={styles.form_container}>
        <ResetPasswordForm
          title={"Welcome to Lilli"}
          onSubmit={onSubmitResetPassword}
          resetPasswordError={resetPasswordError}
          resetPasswordSuccess={resetPasswordSuccess}
          navigate={navigate}
          setResetPasswordError={setResetPasswordError}
          setResetPasswordSuccess={setResetPasswordSuccess}
        />
      </div>
    </GuestPage>
  );
};

export default UserOnboarding;
