import React from "react";
import styles from "./styles.module.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import EmailRequestMessage from "../../Components/EmailRequestMessage";
import FormError from "../../Components/FormError";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import FormInput from "../../Components/FormInput";
// utils
import { isValidEmail } from "../../Services/Utils";

const ForgotPasswordForm = ({
  onSubmit,
  forgotPasswordMessage,
  navigate,
  setEmail,
  email,
  isForgotPasswordSuccess,
}) => {
  const shouldShowMessage = isForgotPasswordSuccess || !!forgotPasswordMessage;

  return shouldShowMessage ? (
    <div className={styles.reset_password_message_container}>
      <EmailRequestMessage
        userEmail={email}
        isEmailSuccess={isForgotPasswordSuccess}
        message={forgotPasswordMessage}
      />
      <SecondaryButton
        style={{ marginTop: "15px" }}
        onClick={() => navigate("/")}
      >
        Back to login
      </SecondaryButton>
    </div>
  ) : (
    <Form
      onSubmit={(values) => {
        setEmail(values.email.trim());
        onSubmit(values.email.trim());
      }}
      validate={(values) => {
        let errors = {};
        if (!values.email) {
          errors.email = "Email required";
        }
        if (values?.email && !isValidEmail(values?.email.trim())) {
          errors.email = "Invalid email";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, pristine, valid }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.email_input_field}>
            <Field name="email">
              {({ input, meta }) => {
                return (
                  <>
                    <FormInput
                      input={input}
                      meta={meta}
                      placeholder="Enter your email"
                      label="Email"
                      type="text"
                      hasInput={meta.touched && input.value !== ""}
                      meetsAllCritera={!meta.error}
                      isActive={meta.active}
                    />
                    {!meta.active &&
                      meta.error &&
                      meta.touched &&
                      input.value !== "" && (
                        <FormError
                          error={meta.error}
                          className={styles.form_input_error}
                        />
                      )}
                  </>
                );
              }}
            </Field>
            {!isForgotPasswordSuccess && forgotPasswordMessage && (
              <FormError error={forgotPasswordMessage} />
            )}
          </div>
          <div className={styles.forgot_password_buttons}>
            <PrimaryButton
              type="submit"
              disabled={submitting || pristine || !valid}
              cypresstestid="Forgot-Password-Btn-Submit"
            >
              Send reset email
            </PrimaryButton>
            <SecondaryButton
              style={{ marginTop: "15px" }}
              onClick={() => navigate("/")}
            >
              Back to login
            </SecondaryButton>
          </div>
        </form>
      )}
    />
  );
};

export default ForgotPasswordForm;
