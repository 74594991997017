import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// Styles
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// Charts
import { AutoSizer } from "react-virtualized";
import {
  VictoryAxis,
  VictoryArea,
  VictoryBar,
  VictoryChart,
  VictoryHistogram,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  // VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
// Components
// import VictoryFlightPathTooltip from "../VictoryFlightpathTooltip";
// import VictoryNoDataTooltip from "../VictoryNoDataTooltip";
import { NoDataIcon } from "../../Styles/Icons/DesignSystem";
import { useViewport } from "../../Components/ViewportProvider";
// Utils
import {
  getBinIntervals,
  getNoDataBins,
  getNullFlightPath,
  getNullLines,
  deepCopyArray,
} from "@intelligentlilli/lilli-utils";

// Temperature Legend: Keys are either a dashed or solid line
const CustomSymbol = (props) => {
  // Resource: https://stackoverflow.com/questions/51711503/victory-chart-legend-custom-icon
  const { x, y, datum } = props; // VictoryScatter supplies x, y and datum

  const yTop = 5;
  const yBottom = -3;

  const line =
    datum.name === "Too hot/cold" ? (
      <g>
        {/* <rect 
          width="10" 
          height="10" 
          x1={x - 10}
          y1={y - yTop}
          x2={x + 12}
          y2={y - yTop}
          style={{
            // stroke: theme.secondary2,
            strokeWidth: 4,
            opacity: 1,
            fill: theme.risk4,
          }} 
        />
        <rect 
          width="10" 
          height="10" 
          x1={x}
          y1={y - yTop}
          x2={x + 10}
          y2={y - yTop}
          style={{
            // stroke: theme.secondary2,
            strokeWidth: 4,
            opacity: 1,
            fill: theme.secondary2,
          }} 
        /> */}
        <line
          x1={x - 10}
          y1={y - yTop}
          x2={x + 12}
          y2={y - yTop}
          style={{
            stroke: theme.risk1,
            strokeWidth: 7,
            opacity: 1,
          }}
        />
        <line
          x1={x - 10}
          y1={y - yBottom}
          x2={x + 12}
          y2={y - yBottom}
          style={{
            stroke: theme.secondary1,
            strokeWidth: 7,
            opacity: 1,
          }}
        />
      </g>
    ) : (
      <g>
        <line
          x1={x - 10}
          y1={y - yBottom}
          x2={x + 12}
          y2={y - yBottom}
          style={{
            stroke: theme.secondary2,
            strokeWidth: 3,
            opacity: 1,
            strokeDasharray: 3,
          }}
        />
        <line
          x1={x - 10}
          y1={y - yTop}
          x2={x + 12}
          y2={y - yTop}
          style={{
            stroke: theme.risk4,
            strokeWidth: 3,
            opacity: 1,
            strokeDasharray: 3,
          }}
        />
      </g>
    );
  return line;
};

const VictoryFlightpathChart = ({
  data,
  behaviour,
  dateType,
  showingTooltip,
  setShowingTooltip,
  yAxisTitle,
  changePage,
  activeTab,
  isPDFreport,
  pageNumber,
}) => {
  // Local state
  // The active point on the chart when the user presses
  const [activeX, setActiveX] = useState();
  const disableHover = false;

  // Get the width of the users screen for responsive js
  const { width } = useViewport();
  const isOnTabletOrLarger = width > 470;
  const onMobile = width <= 850; // 850px in line with BehaviourMovement, etc components

  // Temperature boundaries
  const isTemperature = behaviour === "temperature";
  const isIndependence = behaviour === "independence";
  const { id } = useParams(); // Get the hub id from the url parameter
  const temperatureData = useSelector(
    (state) => state.serviceUsersData?.[id]?.temperatureAnalysis
  );
  const temperatureRange = {
    unsafeHeat: temperatureData?.extremeTemperatureRange?.high,
    unsafeCold: temperatureData?.extremeTemperatureRange?.low,
    comfortableHeat: temperatureData?.safeTemperatureRange?.high,
    comfortableCold: temperatureData?.safeTemperatureRange?.low,
  };

  const unsafeHeat = deepCopyArray(data).map((i) => {
    return {
      ...i,
      value: temperatureRange.unsafeHeat,
      y: temperatureRange.unsafeHeat,
    };
  });

  const unsafeCold = deepCopyArray(data).map((i) => {
    return {
      ...i,
      value: temperatureRange.unsafeCold,
      y: temperatureRange.unsafeCold,
    };
  });

  const comfortableHeat = deepCopyArray(data).map((i) => {
    return {
      ...i,
      value: temperatureRange.comfortableHeat,
      y: temperatureRange.comfortableHeat,
    };
  });

  const comfortableCold = deepCopyArray(data).map((i) => {
    return {
      ...i,
      value: temperatureRange.comfortableCold,
      y: temperatureRange.comfortableCold,
    };
  });

  /*-----Too Cold & Too Hot Data -----*/
  const tooHotData = deepCopyArray([...data]).map((i) => {
    return {
      ...i,
      y: temperatureRange.unsafeHeat,
      y0: 35,
    };
  });
  const tooColdData = deepCopyArray([...data]).map((i) => {
    return {
      ...i,
      y: temperatureRange.unsafeCold,
      y0: 0,
    };
  });

  /* 
  The following section handles rendering a linear gradient on the VictoryLine component when a user presses the chart
  This helps to highlight the section they are looking at
  */
  // If the chart has a gap at the start or the end victory considers the rendered portion of the chart to be 100% for the purpose of the linear gradient.
  // Therefore we need the length of the data without any null values at the start or the end. This means that the index of the point being pressed on also changes
  const removeNulls = (dataArray) => {
    if (!dataArray) {
      return { length: 0, index: 100 };
    }

    let copiedIndex = activeX || 100;
    const copiedData = [...dataArray];

    let nullsAtStart = true;
    // remove any nulls at the start of the data and update the index
    while (nullsAtStart) {
      if (copiedData[0]?.y === null) {
        copiedData.shift();
        copiedIndex--;
      } else {
        nullsAtStart = false;
      }
    }
    let nullsAtEnd = true;
    // Remove any nulls at the end of the data
    while (nullsAtEnd) {
      if (copiedData[copiedData.length - 1]?.y === null) {
        copiedData.pop();
      } else {
        nullsAtEnd = false;
      }
    }
    return { length: copiedData.length, index: copiedIndex };
  };

  // Calculate the percentage of the chart that represents the gap between two points in the x direction.
  const percentageGapBetweenPoints = Math.round(
    100 / (removeNulls(data).length - 1)
  );
  // Find the center point of the linear gradient - the point the user is pressing on
  const gradientCenter =
    Math.round(
      ((removeNulls(data).index - 1) * 100) / (removeNulls(data).length - 1)
    ) || 0;
  // The gradient starts at the previous point
  const gradientStart =
    gradientCenter - percentageGapBetweenPoints > 0
      ? gradientCenter - percentageGapBetweenPoints
      : 0;
  // The gradient ends at the next point
  const gradientEnd =
    gradientCenter + percentageGapBetweenPoints < 100
      ? gradientCenter + percentageGapBetweenPoints
      : 100;
  // Add in a small fade/transition at the edge of the start and end points
  const gradientStartTransition = gradientStart - 3 > 0 ? gradientStart - 3 : 0;
  const gradientEndTransition = gradientEnd + 3 < 100 ? gradientEnd + 3 : 100;
  /*-----end-----*/

  // Generate the flightpath. n.b. Victory charts is smart enough to interpret null values as gaps in the chart.
  const flightPath = data?.map((reading) => {
    return {
      ...reading,
      y: reading.expectedMax !== undefined ? reading.expectedMax : null,
      y0: reading.expectedMin !== undefined ? reading.expectedMin : null,
    };
  });

  const dataWithoutNull = [...data].filter((datum) => datum.y !== null);
  const flightPathWithoutNull = [...flightPath].filter(
    (datum) => !isNaN(datum.y)
  );

  // Find the minimum and maximum values of both the data and the flightpath to constrain the y axis
  const minValuePoint =
    dataWithoutNull &&
    flightPathWithoutNull &&
    Math.min(
      ...dataWithoutNull.flat().map((d) => d.y),
      ...flightPathWithoutNull.flat().map((d) => d.y0)
    );
  const minValue = isTemperature
    ? minValuePoint < 10
      ? minValuePoint
      : 10
    : (dataWithoutNull &&
        flightPathWithoutNull &&
        Math.min(
          ...dataWithoutNull.flat().map((d) => d.y),
          ...flightPathWithoutNull.flat().map((d) => d.y0)
        )) ||
      0;

  const maxValuePoint =
    data &&
    flightPathWithoutNull &&
    Math.max(
      ...flightPathWithoutNull
        .flat()
        .map((d) => (d?.y >= 0 ? d?.y : d?.value >= 0 ? d?.value : 0)),
      ...data
        .flat()
        .map((d) => (d?.y >= 0 ? d?.y : d?.value >= 0 ? d?.value : 0))
    );

  const maxValue = isTemperature
    ? maxValuePoint > 30
      ? maxValuePoint
      : 30
    : data &&
      flightPathWithoutNull &&
      Math.max(
        ...flightPathWithoutNull
          .flat()
          .map((d) => (d?.y >= 0 ? d?.y : d?.value >= 0 ? d?.value : 0)),
        ...data
          .flat()
          .map((d) => (d?.y >= 0 ? d?.y : d?.value >= 0 ? d?.value : 0))
      );

  const topYLine = maxValue + (maxValue - minValue) * 0.2;
  const bottomYLine = minValue - (maxValue - minValue) * 0.1;

  // Function for determining what colour to render the points on the chart (expected/unexpected/atrisk)
  const getDotColourForTemperature = (datum) => {
    // Set our default colours
    let expected = theme.neutral7;
    let atRisk = theme.risk3;
    // When the user is hovering we want to make all but the hovered point 15% opaque
    if (datum._x !== activeX && showingTooltip) {
      // 26 is the AA alpha hex value for 15%
      expected = `${expected}26`;
      atRisk = `${theme.risk3}`;
    }
    if (datum.riskLevel === 2) {
      return atRisk;
    }
    // Default to a $neutral7 point
    return expected;
  };

  // Function for determining what colour to render the points on the chart (expected/unexpected/atrisk)
  const getDotColour = (datum, dateTypeSelected, behaviourSelected) => {
    // Set our default colours
    let expected = theme.expected;
    let unexpected = theme.unexpected;
    let atRisk = theme.risk3;
    // When the user is hovering we want to make all but the hovered point 15% opaque
    if (datum._x !== activeX && showingTooltip) {
      // 26 is the AA alpha hex value for 15%
      expected = `${expected}26`;
      unexpected = `${theme.unexpectedHover}`;
      atRisk = `${theme.risk3}`;
    }
    if (dateTypeSelected !== "day") {
      // For week/month view we use the daily risk rating to define the dot colour
      if (datum.dayRating === 1) {
        return unexpected;
      }
      if (datum.dayRating === 2) {
        return atRisk;
      }
    }
    // For movement day view we consider anything below the flightpath as unexpected movement
    if (dateTypeSelected === "day" && behaviourSelected === "movement") {
      if (datum.value < datum.expectedMin) {
        return unexpected;
      }
    }

    // For temperature day view we consider any temperature outside the flightpath as unexpected
    if (dateTypeSelected === "day" && behaviourSelected === "temperature") {
      if (datum.riskLevel === 1) {
        return unexpected;
      }
      if (datum.riskLevel === 2) {
        return atRisk;
      }
    }

    // For sleep day view we consider any movement unexpected
    if (dateTypeSelected === "day" && behaviourSelected === "sleep") {
      if (datum.value > 0) {
        return unexpected;
      }
    }
    // n.b. independence does not use the flightpath for day charts and sustenance does not have the concept of a hourly risk rating.

    // Default to a $neutral7 point
    return expected;
  };

  /*-----No Data Lines-----*/
  const copyOfData = deepCopyArray([...data]); // Deep copy of data -> use JSON.stringify. Using [...data] will not work.
  const nullFlightPath = getNullFlightPath(copyOfData, minValue, maxValue); // 1- update y and y0-values so they are either null or are the max-value
  const nullLines = getNullLines(nullFlightPath, topYLine); // 2- to be compatible for the bar chart, delete y0 and set max-values on y
  /*-----No Data Bins-----*/
  const getBins = getNoDataBins(data); // => custom bins using the Histogram => [10, 13, 17, 23]
  const hasBins = getBins?.length > 1;
  const binIntervals = getBinIntervals(getBins);
  /*-----end-----*/

  return (
    <AutoSizer>
      {({ width }) => {
        return (
          <VictoryChart
            minDomain={{ y: bottomYLine }}
            maxDomain={{ y: topYLine }}
            height={
              showingTooltip ? 420 : isPDFreport && pageNumber === 1 ? 290 : 320
            } // If the height of the stats summary box increases by X amount (see, for example, ".chartSummary" inside BehaviourMovement), then the RIGHT hand number has to increase by the same amount.
            // Adding in the width property when on mobile allows us to influence the aspect ratio as the svg scales down
            width={width}
            padding={{
              left: onMobile ? 30 : 70,
              right: 15,
              top: showingTooltip ? 130 : 30, // If the height of the stats summary box increases by X amount (see, for example, ".chartSummary" inside BehaviourMovement), then the LEFT hand number has to increase by the same amount.
              bottom: onMobile ? 60 : 80,
            }}
            containerComponent={
              <VictoryVoronoiContainer
                className={styles.chart}
                voronoiDimension="x" // N.B. the activated event is thrown before the deactivated event so we have to track total points activated in order to determine if the tooltip is showing.
                onActivated={(points, _) => {
                  setActiveX(points[0]._x);
                }}
                responsive={false}
                disable={disableHover}
              />
            }
          >
            {/* Temperature - safe and extreme temperature boundary lines */}
            {/* Extreme heat */}
            {isTemperature && (
              <VictoryLine
                data={unsafeHeat}
                style={{
                  data: {
                    stroke: theme.risk4,
                    strokeWidth: 1,
                  },
                }}
              />
            )}
            {/* Safe heat */}
            {isTemperature && (
              <VictoryLine
                data={comfortableHeat}
                style={{
                  data: {
                    stroke: theme.risk4,
                    strokeWidth: 1,
                    strokeDasharray: 10,
                  },
                }}
              />
            )}
            {/* Safe cold */}
            {isTemperature && (
              <VictoryLine
                data={comfortableCold}
                style={{
                  data: {
                    stroke: theme.secondary2,
                    strokeWidth: 1,
                    strokeDasharray: 10,
                  },
                }}
              />
            )}
            {/* Extreme cold */}
            {isTemperature && (
              <VictoryLine
                data={unsafeCold}
                style={{
                  data: {
                    stroke: theme.secondary2,
                    strokeWidth: 1,
                  },
                }}
              />
            )}

            {/* Extreme Heat Gradient */}
            {isTemperature && (
              <VictoryArea
                data={tooHotData}
                style={{
                  data: { fill: theme.risk1 },
                }}
              />
            )}

            {/* Extreme Cold Gradient */}
            {isTemperature && (
              <VictoryArea
                data={tooColdData}
                style={{
                  data: { fill: theme.secondary1 },
                }}
              />
            )}

            {isOnTabletOrLarger && !isTemperature && !isIndependence && (
              <VictoryArea
                data={flightPath}
                style={{ data: { fill: "#F4FAF0" } }}
              />
            )}

            {/* No Data Lines */}
            <VictoryBar
              style={{ data: { fill: "#e1e1e195" } }}
              data={nullLines}
              barWidth={1} // by giving it such a small width it looks like a line
            />
            <VictoryLine
              data={data}
              interpolation="monotoneX"
              style={{
                data: {
                  stroke: showingTooltip ? "url(#myGradient)" : theme.neutral7,
                  strokeWidth: 1,
                },
              }}
            />

            <VictoryAxis
              dependentAxis
              label={!onMobile && yAxisTitle}
              style={{
                tickLabels: { fill: theme.neutral5, fontSize: 16 },
                axis: { stroke: theme.neutral2 },
                axisLabel: { fontSize: 16, padding: 45, fill: theme.neutral5 },
                grid: {
                  stroke: theme.neutral4,
                  strokeWidth: 0.3,
                },
              }}
              tickFormat={(t) => (Number.isInteger(t) ? t : null)}
            />
            <VictoryAxis
              fixLabelOverlap={true}
              style={{
                tickLabels: { fill: theme.neutral5, fontSize: 16 },
                axis: { stroke: theme.neutral2 },
                ticks: { stroke: theme.neutral4, strokeWidth: 0.2 },
              }}
            />

            {/* No Data Icon Bins */}
            {hasBins &&
              binIntervals.map((interval, index) => {
                return (
                  <VictoryHistogram
                    key={index}
                    style={{
                      data: { fill: "#a5a5a530", stroke: "transparent" },
                      labels: { fill: "white" },
                    }}
                    bins={interval} // [10, 13]
                    labels={({ i }) => i}
                    dataComponent={
                      <NoDataIcon
                        width={30}
                        yShift={showingTooltip ? -320 : -260}
                        xShift={-10}
                        opaque={showingTooltip}
                      />
                    }
                    // labelComponent={
                    //   isPDFreport ? undefined : (
                    //     <VictoryTooltip
                    //       renderInPortal={false}
                    //       activateData={true}
                    //       flyoutComponent={
                    //         <VictoryNoDataTooltip
                    //           data={flightPath}
                    //           binInterval={interval}
                    //           setShowingTooltip={setShowingTooltip}
                    //           showingTooltip={showingTooltip}
                    //           dateType={dateType}
                    //         />
                    //       }
                    //     />
                    //   )
                    // }
                  />
                );
              })}

            {/* Data Points */}
            <VictoryScatter
              style={{
                data: {
                  fill: ({ datum }) =>
                    isIndependence
                      ? theme.neutral7
                      : isTemperature
                      ? getDotColourForTemperature(datum)
                      : getDotColour(datum, dateType, behaviour),
                  strokeWidth: onMobile ? 0 : 1,
                  stroke: ({ datum }) => {
                    if (isPDFreport) {
                      return theme.neutral7;
                    }
                    // Just like the dot colour when hovering we want a 15% opacity on the stroke
                    if (datum._x !== activeX && showingTooltip) {
                      return theme.neutral7;
                    }
                    return theme.neutral7;
                  },
                },
                labels: { opacity: 0 }, // Turning off the standard labels so that they can be rendered from the custom tooltip
              }}
              size={3}
              data={data}
              labels={() => {}}
              // labelComponent={
              //   isPDFreport ? undefined : (
              //     <VictoryTooltip
              //       // renderInPortal={false}
              //       flyoutComponent={
              //         <VictoryFlightPathTooltip
              //           setShowingTooltip={setShowingTooltip}
              //           showingTooltip={showingTooltip}
              //           dateType={dateType}
              //           behaviour={behaviour}
              //           getDotColour={getDotColour}
              //           getDotColourForTemperature={getDotColourForTemperature}
              //           changePage={changePage}
              //           activateTab={activeTab}
              //           setDisableHover={setDisableHover}
              //         />
              //       }
              //     />
              //   )
              // }
            />

            {!onMobile && !isPDFreport && !isIndependence && (
              <VictoryLegend
                // To center the legend the x position is the mid point (width/2) less half the width of the legend (282/2)
                x={isTemperature ? width / 2 - 282 / 3 : width / 2 - 282 / 2}
                y={showingTooltip ? 400 : 300}
                orientation="horizontal"
                gutter={20}
                // style={{ border: { stroke: "neutral7" } }}
                dataComponent={isTemperature ? <CustomSymbol /> : undefined}
                data={
                  isTemperature
                    ? [{ name: "Uncomfortable" }, { name: "Too hot/cold" }]
                    : [
                        {
                          name: "At risk",
                          symbol: { fill: theme.risk3 },
                        },
                        {
                          name: "Unexpected",
                          symbol: { fill: theme.unexpected },
                        },
                        {
                          name: "Expected",
                          symbol: { fill: theme.expected },
                        },
                      ]
                }
              />
            )}
            <defs>
              <linearGradient id="myGradient">
                <stop
                  offset="0%"
                  stopColor={theme.neutral7}
                  stopOpacity={0.15}
                />
                <stop
                  offset={`${gradientStartTransition}%`}
                  stopColor={theme.neutral7}
                  stopOpacity={0.15}
                />
                <stop offset={`${gradientStart}%`} stopColor={theme.neutral7} />
                <stop offset={`${gradientEnd}%`} stopColor={theme.neutral7} />
                <stop
                  offset={`${gradientEndTransition}%`}
                  stopColor={theme.neutral7}
                  stopOpacity={0.15}
                />
                <stop
                  offset="100%"
                  stopColor={theme.neutral7}
                  stopOpacity={0.15}
                />
              </linearGradient>
            </defs>
          </VictoryChart>
        );
      }}
    </AutoSizer>
  );
};

export default VictoryFlightpathChart;
