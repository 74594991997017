import React from "react";
import styles from "./styles.module.scss";
import { getInitialsFromNames } from "@intelligentlilli/lilli-utils";

const ProfileIcon = ({ onClick, user }) => {
  return (
    <div className={styles.icon} onClick={onClick}>
      {!user?.profilePhoto && (
        <div>{getInitialsFromNames(user.forenames, user.surname)}</div>
      )}
      {user?.profilePhoto && (
        <img
          src={user?.profilePhoto}
          alt={"User profile"}
          className={styles.profile_image}
        />
      )}
    </div>
  );
};

export default ProfileIcon;
