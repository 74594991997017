import React from "react";
import styles from "../styles.module.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import FormError from "../../Components/FormError";
import CTAButton from "../../Components/CTAButton";

const ChangePasswordForm = (props) => (
  <Form
    onSubmit={(values) =>
      props.onSubmit(values.currentPassword, values.newPassword)
    }
    validate={(values) => {
      const errors = {};
      if (!values.currentPassword) {
        errors.currentPassword = "Required";
      }
      if (values.currentPassword === values.newPassword) {
        errors.newPassword = "Password must be different to current password";
      }
      if (values.newPassword?.length < 12) {
        errors.newPassword = "Password must have at least 12 characters";
      }
      if (values.newPassword?.length > 60) {
        errors.newPassword = "Password is too long";
      }
      if (!RegExp(/[0-9]/).test(values.newPassword)) {
        errors.newPassword = "Password must contain at least one number";
      }
      if (!RegExp(/[a-zA-Z]/).test(values.newPassword)) {
        errors.newPassword = "Password must contain at least one letter";
      }
      if (!values.newPassword) {
        errors.newPassword = "Required";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
      }
      if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = "Passwords don't match";
      }
      return errors;
    }}
    render={({ handleSubmit, submitting, pristine, form, valid }) => (
      <form
        className={styles.form}
        // This event handler allows the form to be cleared when submitted
        onSubmit={(event) => {
          const promise = handleSubmit(event);
          promise && promise.then(() => form.restart());
          return promise;
        }}
      >
        <p>
          Passwords must be at least 12 characters, at least one number and one
          letter.
        </p>
        <Field name="currentPassword">
          {({ input, meta }) => (
            <div className={styles.input}>
              <label>
                Current password
                <input {...input} type="password" />
              </label>
              {meta.error && meta.touched && (
                <span className={styles.error}>{meta.error}</span>
              )}
            </div>
          )}
        </Field>
        <Field name="newPassword">
          {({ input, meta }) => (
            <div className={styles.input}>
              <label>
                New password
                <input {...input} type="password" />
              </label>
              {meta.error && meta.touched && (
                <span className={styles.error}>{meta.error}</span>
              )}
            </div>
          )}
        </Field>
        <Field name="confirmPassword">
          {({ input, meta }) => (
            <div className={styles.input}>
              <label>
                Confirm new password
                <input {...input} type="password" />
              </label>
              {meta.error && meta.touched && (
                <span className={styles.error}>{meta.error}</span>
              )}
            </div>
          )}
        </Field>
        {props.error && <FormError error={props.error} />}
        <CTAButton
          type="submit"
          disabled={submitting || pristine || !valid}
          className={styles.submit}
          cypresstestid="Change-Password-Btn-Submit"
        >
          Change password
        </CTAButton>
      </form>
    )}
  />
);

export default ChangePasswordForm;
