import { subDays } from "date-fns";
import {
  PreparedMovementV3DataType,
  PrepareMovementV3Interface,
  EventLog,
} from "../../types/Behaviours/prepareMovementV3";

export const prepareMovementV3 = ({
  startDateString,
  endDateString,
  movementV3Data,
}: PrepareMovementV3Interface): PreparedMovementV3DataType => {
  if (
    movementV3Data?.dailyResults &&
    movementV3Data.dailyResults.length > 0 &&
    startDateString &&
    endDateString
  ) {
    // Convert the date strings into date objects. 
    // N.b. we need to go back a day from the start date to get the night time window
    const endDate = new Date(endDateString);
    const startDate = subDays(new Date(startDateString), 1);
    
    // Filter out the relevant data
    const relevantData = movementV3Data.dailyResults.filter((day) => {
      return new Date(day.date) >= startDate && new Date(day.date) <= endDate;
    });

    // Sort the relevant data by date
    const sortedRelevantData = relevantData.sort((a, b) =>
      a.date < b.date ? -1 : 1
    );

    // If there's only one day's data in the relevant data then we need to add a blank day to the start of the array 
    // as it must be the first day of data (installation day)
    if (sortedRelevantData.length === 1) {
      sortedRelevantData.unshift({
        date: "",
        lastEvent: null,
        expectedDurationMinutes: {
          nighttimeMin: 0,
          nighttimeMax: 0,
          daytimeMax: 0,
          daytimeMin: 0,
        },
        firstEvent: null,
        nightTimeStart: "",
        eventLog: {},
        dayRating: 0,
        nightTimeEnd: "",
        insights: [],
      });
    }

    // Manipulate the data into day and night time windows
    const dayNightSplit = sortedRelevantData.map((day, index) => {
      // Skip the first day as it will not have a previous day to pull from and we added it in just for the night time window
      if (index === 0) return { blank: true }; // N.b. this is removed later

      // Get the previous day's data
      const prevDay = sortedRelevantData[index - 1];

      // Construct the timestamps of the day and night time windows
      const nightStart = `${prevDay.date}T${prevDay.nightTimeStart || "22:00"}:00Z`; // N.B. The night time window starts on the previous day
      const nightEnd = `${day.date}T${day.nightTimeEnd || "06:00"}:00Z`; // N.B. The night time window ends on the current day
      const dayStart = nightEnd;
      const dayEnd = `${day.date}T${day.nightTimeStart}:00Z`;

      // Split the insights into day and night time windows
      const prevDaysNightInsights =
        prevDay?.insights?.filter((insight) => insight.startAt >= nightStart) ||
        [];
      const currentDayNightInsights =
        day?.insights?.filter((insight) => insight.endAt <= nightEnd) || [];
      const dayInsights =
        day?.insights?.filter(
          (insight) => insight.startAt >= dayStart && insight.endAt <= dayEnd
        ) || [];
      const nightInsights = [
        ...prevDaysNightInsights,
        ...currentDayNightInsights,
      ];
      // Get the night time events from the previous day
      const prevDaysNightEvents: EventLog =
        prevDay.eventLog &&
        Object.keys(prevDay.eventLog).reduce((acc, curr) => {
          const sensorEvents = prevDay.eventLog?.[curr];
          // If the type of sensor data is an array then filter it for timestamps that are in the night time window
          // I.e. not door sensors
           if (Array.isArray(sensorEvents)) {
            return {
              ...acc,
              [curr]: sensorEvents.filter(
                (timestamp) => timestamp >= nightStart
              ),
            };
          }
          // Otherwise it's one of the sensors with openedAt and closedAt timestamp sub arrays. 
          // In this case we need to reduce these into the night window
          // I.e. These are door sensors (i.e. main door, fridge)
          const reducedSensorObject =
            sensorEvents &&
            Object.keys(sensorEvents).reduce((acc1, curr1) => {
              const openedOrClosedAtNightArray = sensorEvents?.[curr1]?.filter(
                (timestamp) => timestamp >= nightStart
              );
              return {
                ...acc1,
                [curr1]: openedOrClosedAtNightArray,
              };
            }, {});
          return {
            ...acc,
            [curr]: reducedSensorObject,
          };
        }, {});

      // Get the night time events from the current day
      const currentDaysNightEvents: EventLog =
        day.eventLog &&
        Object.keys(day.eventLog).reduce((acc, curr) => {
          const sensorData = day.eventLog?.[curr];
          // If the type of sensor data is an array then filter it for timestamps that are in the night time window
          // I.e. non-door sensors
          if (Array.isArray(sensorData)) {
            return {
              ...acc,
              [curr]: sensorData.filter((timestamp) => timestamp <= nightEnd),
            };
          }

           // Otherwise it's one of the sensors with openedAt and closedAt timestamp sub arrays. 
           // In this case we need to reduce these into the night window
           // I.e. door sensors
          const reducedSensorObject =
            sensorData &&
            Object.keys(sensorData).reduce((acc1, curr1) => {
              const subArray = sensorData?.[curr1]?.filter(
                (timestamp) => timestamp <= nightEnd
              );
              return {
                ...acc1,
                [curr1]: subArray,
              };
            }, {});
          return {
            ...acc,
            [curr]: reducedSensorObject,
          };
        }, {});

      // Combine the night time events from the previous day and the current day
      const nightEvents: EventLog =
        prevDaysNightEvents &&
        (Object.keys(prevDaysNightEvents)).reduce(
          (acc, curr) => {
            const prevDaySensorData = prevDaysNightEvents?.[curr];
            // If the type of sensor data is an array then filter it for timestamps that are in the night time window
            const currentDaySensorData = currentDaysNightEvents?.[curr];
            // door events
            if (Array.isArray(prevDaySensorData) && Array.isArray(currentDaySensorData)) {
              return {
                ...acc,
                [curr]: [...prevDaySensorData, ...(currentDaySensorData || [])],
              };
            }

            // Otherwise it's one of the sensors with openedAt and closedAt timestamp sub arrays. In this case we need to reduce these into the night window
            const reducedSensorObject =
              prevDaySensorData &&
              !Array.isArray(prevDaySensorData) && !Array.isArray(currentDaySensorData) &&
              Object.keys(prevDaySensorData).reduce((acc1, curr1) => {
                const subArray = [
                  ...prevDaySensorData?.[curr1],
                  ...(currentDaySensorData?.[curr1] || []),
                ];
                return {
                  ...acc1,
                  [curr1]: subArray,
                };
              }, {});
            return {
              ...acc,
              [curr]: reducedSensorObject,
            };
          },
          {}
        );
      
      // Get the day time events for the current day
      const dayEvents: EventLog =
        day.eventLog &&
        Object.keys(day.eventLog).reduce((acc, curr) => {
          const sensorData = day.eventLog[curr];
          // not a door event
          // If the type of sensor data is an array then filter it for timestamps that are in the night time window
          if (Array.isArray(sensorData)) {
            return {
              ...acc,
              [curr]: sensorData.filter(
                (timestamp) => timestamp >= dayStart && timestamp <= dayEnd
              ),
            };
          }
          // door events
          // Otherwise it's one of the sensors with openedAt and closedAt timestamp sub arrays. 
          // In this case we need to reduce these into the night window
          const reducedSensorObject =
            sensorData &&
            Object.keys(sensorData).reduce((acc1, curr1) => {
              const subArray = sensorData?.[curr1]?.filter(
                (timestamp) => timestamp >= dayStart && timestamp <= dayEnd
              );
              return {
                ...acc1,
                [curr1]: subArray,
              };
            }, {});
          return {
            ...acc,
            [curr]: reducedSensorObject,
          };
        }, {});

      // Get the first and last events from the dayEvents object
      let firstDayEvent: [string, string] | null = null;
      let lastDayEvent: [string, string] | null = null;

      dayEvents &&
        Object.keys(dayEvents).forEach((key) => {
          const sensorData = dayEvents[key];
          // If the type of sensor data is an array then filter it for timestamps that are in the night time window
          if (Array.isArray(sensorData)) {
            // get the first event
            if (!firstDayEvent || sensorData[0] < firstDayEvent[1]) {
              firstDayEvent = [key, sensorData[0]];
            }
            // get the last event
            if (
              !lastDayEvent ||
              sensorData[sensorData.length - 1] > lastDayEvent[1]
            ) {
              lastDayEvent = [key, sensorData[sensorData.length - 1]];
            }
            return;
          }
          // Otherwise it's one of the sensors with openedAt and closedAt timestamp sub arrays. 
          // In this case we need to reduce these into the night window
          sensorData &&
            Object.keys(sensorData).forEach((key1) => {

              const openOrCloseArray = sensorData?.[key1];
              // get the first event
              if (!firstDayEvent || openOrCloseArray[0] < firstDayEvent[1]) {
                firstDayEvent = [key, openOrCloseArray[0]];
              }
              // get the last event
              if (
                !lastDayEvent ||
                openOrCloseArray[openOrCloseArray.length - 1] > lastDayEvent[1]
              ) {
                lastDayEvent = [
                  key,
                  openOrCloseArray[openOrCloseArray.length - 1],
                ];
              }
            });
        });

      // Get the first and last events from the dayEvents object
      let firstNightEvent: [string, string] | null = null;
      let lastNightEvent: [string, string] | null = null;

      nightEvents &&
        Object.keys(nightEvents).forEach((key) => {
          // If the type of sensor data is an array then filter it for timestamps that are in the night time window
          const nightSensorData = nightEvents[key];
          if (Array.isArray(nightSensorData)) {
            // get the first event
            if (!firstNightEvent || nightSensorData[0] < firstNightEvent[1]) {
              firstNightEvent = [key, nightSensorData[0]];
            }
            // get the last event
            if (
              !lastNightEvent ||
              nightSensorData[nightSensorData.length - 1] > lastNightEvent[1]
            ) {
              lastNightEvent = [key, nightSensorData[nightSensorData.length - 1]];
            }
            return;
          }
          // Otherwise it's one of the sensors with openedAt and closedAt timestamp sub arrays. 
          // In this case we need to reduce these into the night window
          nightSensorData &&
            Object.keys(nightSensorData).forEach((key1) => {
              const openOrCloseArray = nightSensorData?.[key1];
              // get the first event
              if (
                !firstNightEvent ||
                openOrCloseArray[0] < firstNightEvent[1]
              ) {
                firstNightEvent = [key, openOrCloseArray[0]];
              }
              // get the last event
              if (
                !lastNightEvent ||
                openOrCloseArray[openOrCloseArray.length - 1] >
                  lastNightEvent[1]
              ) {
                lastNightEvent = [
                  key,
                  openOrCloseArray[openOrCloseArray.length - 1],
                ];
              }
            });
        });
        
      // Get the duration of the day and night time windows
      const dayDuration = dayInsights[dayInsights.length - 1]?.durationMinutes;
      const nightDuration =
        nightInsights[nightInsights.length - 1]?.durationMinutes;

      return {
        ...day,
        night: {
          insights: nightInsights,
          eventLog: nightEvents,
          firstEvent: firstNightEvent,
          lastEvent: lastNightEvent,
        },
        day: {
          insights: dayInsights,
          eventLog: dayEvents,
          firstEvent: firstDayEvent,
          lastEvent: lastDayEvent,
        },
        durationMinutes: {
          dayTime: dayDuration,
          nightTime: nightDuration,
        },
      };
    });

    dayNightSplit.shift();
    return dayNightSplit;
  } else {
    return [];
  }
};