import React from "react";
import styles from "./styles.module.scss";
import InstallationNextButton from "../../InstallationNextButton";
import InstallationGoBackButton from "../../InstallationGoBackButton";
import { useViewport } from "../../ViewportProvider";

const SensorMotionMethods = ({
  options,
  setMotionMethod,
  setShowInstructions,
  closeModal,
  motionMethod,
  isDesktop,
}) => {
  const { width } = useViewport();
  const isSmallScreen = width < 480;

  return (
    <div>
      {options.map((option, index) => (
        <div
          className={`${styles.option} ${
            motionMethod === option.key && styles.selected
          }`}
          key={index}
          onClick={() => setMotionMethod(option.key)}
        >
          {!isSmallScreen && (
            <div className={styles.image_container}>
              <img src={option.image} alt={option.key} />
            </div>
          )}
          <div>
            <div className={styles.option_title}>{option.key}</div>
            <div className={styles.option_body}>{option.value}</div>
          </div>
        </div>
      ))}
      <InstallationNextButton label="Next" onClick={setShowInstructions} />
      {!isDesktop && (
        <InstallationGoBackButton
          label="Go back"
          onClick={closeModal}
          marginTop="24px"
        />
      )}
    </div>
  );
};

export default SensorMotionMethods;
