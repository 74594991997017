import React from "react";

export const Avatar5 = ({ width, primaryColour, secondaryColour }) => {
  return (
    <svg width={width || 40} viewBox="0 0 40 40" fill="none" id="Avatar5">
      <rect width="40" height="40" rx="3.27273" fill={primaryColour} />
      <circle cx="20.3637" cy="19.9999" r="10.9091" fill={secondaryColour} />
    </svg>
  );
};
