import { getTemperatureFirstLine, getTemperatureSecondLine } from "./";
import { isTemperatureConcerning } from "../";

export const handleTemperature = (data) => {
  return data?.map((day) => {
    return {
      date: day?.date,
      dayRating: day?.dayRating,
      value: day?.value,
      expectedMin: day?.expectedMin,
      firstLineText: getTemperatureFirstLine(day),
      secondLineText: getTemperatureSecondLine(day?.value),
      isHighlighted: isTemperatureConcerning(day),
      highlightColour: null,
      noData: getTemperatureSecondLine(day?.value) === "No data",
    };
  });
};
