import React from "react";
import styles from "./styles.module.scss";

const AltSpinner = ({ style, logo, text, small, large }) => {
  return (
    <div
      className={`${
        small
          ? styles.alt_spinner_small
          : large
          ? styles.alt_spinner_large
          : styles.alt_spinner
      }`}
      style={style}
    >
      {logo && (
        <img
          src="/logo/lilli-128-teal.png"
          alt="Lilli"
          width="40"
          className={styles.logo}
        />
      )}
      <div
        className={`${
          small
            ? styles.spinner_small
            : large
            ? styles.spinner_large
            : styles.spinner
        }`}
      ></div>
      {text && <div className={styles.text}>Checking connection</div>}
    </div>
  );
};

export default AltSpinner;
