import CTAButton from "../CTAButton";
import theme from "../../Styles/theme.scss";

const InstallationNextButton = ({
  icon,
  label,
  onClick,
  disabled,
  type,
  cypresstestid,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
}) => {
  return (
    <CTAButton
      style={{
        width: "100%",
        padding: "12px",
        backgroundColor: disabled && theme.neutral2,
        display: "flex",
        borderRadius: "8px",
        color: `${theme.neutral7}`,
        marginTop: marginTop || "",
        marginBottom: marginBottom || "",
        marginLeft: marginLeft || "",
        marginRight: marginRight || "",
        // handle mobile fontWeight
      }}
      onClick={onClick}
      disabled={disabled}
      type={type}
      cypresstestid={cypresstestid}
    >
      {icon}
      {label || "Next"}
    </CTAButton>
  );
};

export default InstallationNextButton;
