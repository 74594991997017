import { differenceInDays } from "date-fns";

export const getDaysSinceRiskStatusChanged = (
  riskScores,
  firstEventTimestamp
) => {
  if (!riskScores || !firstEventTimestamp) return undefined;
  const diffInDays = differenceInDays(
    new Date(),
    new Date(firstEventTimestamp)
  );
  const isLessThan8DaysAgo = diffInDays < 8;
  if (isLessThan8DaysAgo) {
    return "Newly installed";
  }
  const prevRiskStatus = [...riskScores]
    .reverse()
    .map((score) => score.dayRating);
  // if all the risk scores are the same, we dont know when risk score changed so return undefined
  const isUnchanged = prevRiskStatus.every((val, _, arr) => val === arr[0]);
  if (isUnchanged) {
    return "No change";
  }
  const daysSinceChanged = prevRiskStatus?.reduce((acc, curr, index, arr) => {
    if (curr === prevRiskStatus[index + 1]) {
      return acc + 1;
    } else {
      arr.splice(1);
    }
    return acc;
  }, 0);
  return daysSinceChanged;
};
