import { getSustenanceSentence } from "./";

export const handleSustenance = (data, filterName) => {
  return data?.map((day) => {
    const commonSustenanceObj = {
      date: day?.date,
      dayRating: day?.dayRating,
      value: day?.value,
      expectedMin: day?.expectedMin,
      firstLineText: getSustenanceSentence(day),
      secondLineText: "",
      highlightColour: null,
      noData: getSustenanceSentence(day) === "No data",
    };
    switch (filterName) {
      case "missedAllMeals":
        return {
          ...commonSustenanceObj,
          isHighlighted: day?.sentences?.length === 3,
        };
      default:
        return {
          ...commonSustenanceObj,
          isHighlighted: day?.sentences?.length >= 1,
        };
    }
  });
};
