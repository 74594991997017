import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./slices/session-slice";
import userReducer from "./slices/user-slice";
import serviceUsersReducer from "./slices/serviceUsers-slice";
import serviceUsersDataReducer from "./slices/serviceUsersData-slice";
import staffReducer from "./slices/staff-slice";
import sensorsReducer from "./slices/sensors-slice";
import sensorLocationsReducer from "./slices/sensorLocations-slice";
import reportsReducer from "./slices/reports-slice";
import reportTypesReducer from "./slices/reportTypes-slice";

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    serviceUsers: serviceUsersReducer,
    user: userReducer,
    serviceUsersData: serviceUsersDataReducer,
    staff: staffReducer,
    sensors: sensorsReducer,
    sensorLocations: sensorLocationsReducer,
    reports: reportsReducer,
    reportTypes: reportTypesReducer,
  },
});
