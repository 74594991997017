import React from "react";
import { useSelector } from "react-redux";
// Components
import AddServiceUserForm from "../../Forms/AddServiceUserForm";
import NewStyleModal from "../NewStyleModal";
// Hooks
import { useAddNewServiceUser } from "../../Services/Hooks";

// This component is responsible for creating new lilli users and rendering the modal to do so.
const CreateLilliUserModal = ({
  addingServiceUserError,
  setAddingServiceUserError,
  setAddingServiceUser,
  showAnimation,
  justAnInstaller,
}) => {
  const { onAddServiceUser } = useAddNewServiceUser(
    setAddingServiceUserError,
    setAddingServiceUser
  );
  const nonFriendsAndFamilyStaff = useSelector((state) =>
    state.staff.filter((staff) => !staff.roles.includes("friend-or-family"))
  );
  const user = useSelector((state) => state.user);

  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={() => {
        setAddingServiceUser(false);
        setAddingServiceUserError(null);
      }}
      title="Create a new Lilli user"
      context="The user will be added to the list of people awaiting hardware installation"
      useFade={true}
      showAnimation={showAnimation}
    >
      <AddServiceUserForm
        onSubmit={onAddServiceUser}
        error={addingServiceUserError}
        staff={nonFriendsAndFamilyStaff}
        userId={user.key}
        justAnInstaller={justAnInstaller}
      />
    </NewStyleModal>
  );
};

export default CreateLilliUserModal;
