import React, { useState } from "react";
import { useViewport } from "../ViewportProvider";
// Styles
import styles from "./styles.module.scss";
// Utils
import { sortByTimestamp, getRiskDates } from "@intelligentlilli/lilli-utils";
import { startOfDay, endOfDay, parseISO, differenceInMinutes } from "date-fns";
import { getFormattedChartData } from "../../Services/Utils/getFormattedChartData";
// Components
import VictoryFlightpathChart from "../../Charts/VictoryFlightpathChart";
import DayIndependence from "../../Charts/DayIndependence";
import BehaviourDaysSortedByRisk from "../BehaviourDaysSortedByRisk";
import { InfoIcon } from "../../Styles/Icons/DesignSystem";
import SummaryWeekMonth from "../SummaryWeekMonth";

// Function to format the door events into a single array
const getDoorEvents = (data) => {
  // Get all the times the person went out or came back
  let timesOutOrCameBack = [];
  data
    ?.filter((hour) => hour?.periodsOutside?.length > 0)
    ?.forEach((hour) =>
      hour?.periodsOutside?.forEach((element) =>
        timesOutOrCameBack?.push(element)
      )
    );
  // Get all the times the person went out
  const wentOut =
    timesOutOrCameBack
      .filter((time) => time?.wentOutAt)
      .map((element) => ({
        event: "wentOut",
        ts: parseISO(element?.wentOutAt),
      })) || [];
  // Get all the times the person came back
  const cameBack =
    timesOutOrCameBack
      .filter((time) => time?.cameBackAt)
      .map((element) => ({
        event: "cameBack",
        ts: parseISO(element?.cameBackAt),
      })) || [];

  // Combine and sort the events by time
  const sortedEvents = sortByTimestamp([...wentOut, ...cameBack]);

  return {
    wentOut: wentOut,
    cameBack: cameBack,
    // Combine and sort the events by time
    doorEvents: sortedEvents,
  };
};

// Function to format the daily chart data into the required time outside format.
const formatDailyOutsideData = (data) => {
  let doorEvents = getDoorEvents(data)?.doorEvents;
  const firstHourOfDay = data?.[0];
  const firstDoorEvent = doorEvents?.[0];
  const lastDoorEvent = doorEvents?.[doorEvents?.length - 1];

  // If there are no door events then the person was either in or out the entire day. If the data has 60 for values then they were out
  if (doorEvents?.length === 0 && firstHourOfDay?.value === 60) {
    doorEvents = [
      {
        event: "wentOut",
        ts: startOfDay(firstHourOfDay?.ts),
        outText: "Was out overnight",
      },
      {
        event: "cameBack",
        ts: endOfDay(firstHourOfDay?.ts),
        backText: "Stayed out overnight",
      },
    ];
  }
  // If the first event in the day is cameBack then the person was out overnight. Therefore to chart this day we need to add a going out event just after midnight
  if (firstDoorEvent?.event === "cameBack") {
    doorEvents?.unshift({
      event: "wentOut",
      ts: startOfDay(firstDoorEvent?.ts),
      outText: "Was out overnight",
    });
  }
  // If the last event in the day is goneOut then the person was out overnight. Therefore to chart this day we need to add a came back at event at midnight
  if (lastDoorEvent?.event === "wentOut") {
    doorEvents?.push({
      event: "cameBack",
      ts: endOfDay(doorEvents?.[0]?.ts),
      backText: "Stayed out overnight",
    });
  }

  const wentOutEvents = doorEvents?.filter((_, index) => index % 2 !== 1);

  const updatedWentOutEvents = wentOutEvents.map((event, index) => {
    // Get the duration of the trip out
    // const durationUsingHourlyValues = data?.reduce((total, hour) => total + hour?.value || 0, 0);
    const durationUsingDoorEvents = differenceInMinutes(
      doorEvents[2 * index + 1]?.ts,
      event.ts
    );
    // Return the required x and y values along with the custom marker dependent on being expected or unexpected.
    return {
      x: doorEvents?.[2 * index + 1]?.ts,
      x0: event?.ts,
      value: durationUsingDoorEvents,
    };
  });

  return updatedWentOutEvents;
};

const BehaviourIndependence = ({
  independenceData,
  title,
  dateType,
  independenceDataPrevious,
  // daysIndependenceData,
  independenceRisk,
  presentRiskDates,
  activeTab,
  changePage,
}) => {
  // local state
  const [showingTooltip, setShowingTooltip] = useState(false);

  // Responsive design
  const { width } = useViewport();
  const onMobileView = width < 600;

  /* 
  The following section does the required data manipulation
  */
  // Bool to indicate whether we have data or not
  const hasData =
    independenceData?.filter((data) => data.value !== null)?.length > 0;

  // Format the day view data into periods outside
  const outsideDataDayView = formatDailyOutsideData(independenceData);

  // On day view we use the parsed data otherwise the raw independence data
  const outData = dateType === "day" ? outsideDataDayView : independenceData;

  // Get the max value for the time outside. This is used to decide whether to convert the values to hours rather than minutes for readability in the graph
  const maxValue = Math.max(...(independenceData || []).map((d) => d.value));
  const maxFlightpathValue = Math.max(
    ...(independenceData || []).map((d) => d?.expectedMax || 0)
  );
  const maxOverallValue = Math.max(maxValue, maxFlightpathValue);
  const convertToHours = maxOverallValue / 60 > 1;

  const flightpathData = convertToHours
    ? (independenceData || []).map((day) => ({
        ...day,
        value: day.value !== null ? day.value / 60 : null,
        expectedMin: day.expectedMin / 60,
        expectedMax: day.expectedMax / 60,
        hourUnits: true,
        hours: Math.floor(day.value / 60),
        minutes: Math.round(day.value % 60),
      }))
    : independenceData;

  const formattedChartData = hasData
    ? getFormattedChartData(flightpathData, dateType, "independence")
    : [];
  /*-----End------*/

  // The following variables are arrays of dates for different risk levels used in week/month view
  const daysUnexpected =
    (independenceData && getRiskDates(independenceData, 1)) || [];
  const daysAtRisk =
    (independenceData && getRiskDates(independenceData, 2)) || [];
  // const daysExpected =
  //   (independenceData && getRiskDates(independenceData, 0)) || [];
  const daysNoData =
    (independenceData && getRiskDates(independenceData, null)) || [];

  /* 
  The following section calculates the expected min and max time outdise  
  */
  // let expectedValuesInMinutes = true;

  // Get the expected min time outside
  // let expectedMin = (
  //   dateType === "day"
  //     ? daysIndependenceData?.map((data) => ({
  //         expectedMin: data?.dailyData?.minutesOutside?.expectedMin,
  //       }))
  //     : independenceData
  // )?.reduce((total, val) => total + val?.expectedMin || 0, 0);

  // // Get the expected max time outside
  // let expectedMax = (
  //   dateType === "day"
  //     ? daysIndependenceData?.map((data) => ({
  //         expectedMax: data?.dailyData?.minutesOutside?.expectedMax,
  //       }))
  //     : independenceData
  // )?.reduce((total, val) => total + val?.expectedMax || 0, 0);

  // // Decide whether to convert the min/max numbers to hours only if they are both greater than an hour or zero
  // if (expectedMax >= 60 && (expectedMin >= 60 || expectedMin === 0)) {
  //   expectedMin = expectedMin / 60;
  //   expectedMax = expectedMax / 60;
  //   expectedValuesInMinutes = false;
  // }
  // // Finally round the expected values for rendering
  // expectedMin = Math.round(expectedMin);
  // expectedMax = Math.round(expectedMax);
  /*-----End------*/

  /* 
  The following section generates the summary stats that are displayed above the charts 
  */
  // Get the total duration outside in minutes
  const minutesOutside = outData?.reduce((total, val) => total + val?.value, 0);
  const showHoursAndMinutes = minutesOutside >= 60;
  const durationOutsideHours = Math.floor(minutesOutside / 60);
  const durationOutsideRemainder = minutesOutside - 60 * durationOutsideHours;

  // Get the total duration outside the previous period
  const minOutsidePrev = independenceDataPrevious?.reduce(
    (total, val) => total + val?.value,
    0
  );
  const prevShowHoursAndMinutes = minOutsidePrev >= 60;
  const prevDurationOutsideHours = Math.floor(minOutsidePrev / 60);
  const prevDurationOutsideRemainder =
    minOutsidePrev - 60 * prevDurationOutsideHours;

  // Get the days with no time outside for the week view
  const noTimeOutside = outData?.filter((day) => day.value === 0).length;

  // Our summary section has the same first stat regardless of whether we are on day/week/month view
  const firstSummaryStat = {
    value: showHoursAndMinutes ? durationOutsideHours : minutesOutside,
    units: showHoursAndMinutes ? "Hrs" : "min",
    value2: showHoursAndMinutes ? durationOutsideRemainder : null,
    units2: showHoursAndMinutes ? "min" : null,
    label: "Total time outside",
  };

  // Pull together all the summary stats for week/month view
  const summaryStatsWeekMonth = [
    firstSummaryStat,
    // TO DO: Bring these back at a later date
    // {
    //   value: `${expectedMin}-${expectedMax}`,
    //   units: `${expectedValuesInMinutes ? "min" : "Hrs"}`,
    //   label: "Expected range",
    // },
  ];
  // This feels a little hacky but essentially we are only adding this third summary stat when there's room to do so.
  if (width < 850 || width > 1200) {
    summaryStatsWeekMonth.push({
      value: noTimeOutside,
      label: "Day(s) with no time outside",
    });
  }

  // Pull together all the summary stats for day view
  const summaryStatsDay = [
    firstSummaryStat,
    // TO DO: Bring these back at a later date
    // {
    //   value: `${expectedMin}-${expectedMax}`,
    //   label: "Expected range",
    //   units: `${expectedValuesInMinutes ? "min" : "Hrs"}`,
    // },
    {
      value: prevShowHoursAndMinutes
        ? prevDurationOutsideHours
        : minOutsidePrev,
      value2: prevShowHoursAndMinutes ? prevDurationOutsideRemainder : null,
      units: prevShowHoursAndMinutes ? "Hrs" : "min",
      units2: prevShowHoursAndMinutes ? "min" : null,
      label: "Previous day",
    },
  ];

  const INDEPENDENCE_TEXT =
    "Time outside measures the time spent outside of the home using the front door sensor plus in-home movements to determine if the service user is at home or out and about.";
  /*-----End------*/

  return (
    <div className={styles.behaviour}>
      <div className={styles.behaviour_context}>
        <h1>{title}</h1>
        <div className={styles.behaviour_explanation}>{INDEPENDENCE_TEXT}</div>
        {dateType === "day" && hasData && (
          <div>
            <h2 className={styles.dayDetails}>Details</h2>
            <div className={styles.dayDetails_info}>
              <InfoIcon />
              <div>Hover over time blocks for details</div>
            </div>
          </div>
        )}
        {/* Days At Risk, week & month view */}
        {!onMobileView && dateType !== "day" && (
          <BehaviourDaysSortedByRisk
            daysAtRisk={daysAtRisk}
            daysUnexpected={daysUnexpected}
            daysNoData={daysNoData}
            presentRiskDates={presentRiskDates}
          />
        )}
      </div>

      {/* ----- BEHAVIOUR CHART for week/month ----- */}
      {dateType !== "day" && hasData && (
        <div className={styles.behaviour_chart}>
          {!showingTooltip && (
            <div className={styles.chartSummary}>
              <SummaryWeekMonth
                behaviour={"independence"}
                data={summaryStatsWeekMonth}
                rating={independenceRisk}
                small
              />
            </div>
          )}
          <VictoryFlightpathChart
            data={formattedChartData}
            behaviour={"independence"}
            showingTooltip={showingTooltip}
            setShowingTooltip={setShowingTooltip}
            dateType={dateType}
            yAxisTitle={`Time outside (${convertToHours ? "hrs" : "mins"})`}
            changePage={changePage}
            activeTab={activeTab}
          />
        </div>
      )}

      {/* The chart won't show up if there is an empty array for 'went out' and 'came back' */}
      {dateType === "day" && hasData && (
        <div className={styles.behaviour_chart}>
          <div className={styles.chartSummary}>
            <SummaryWeekMonth
              behaviour={"independence"}
              data={summaryStatsDay}
              rating={independenceRisk}
              small
            />
          </div>

          <div className={styles.dayChart}>
            <DayIndependence outData={outData} />
          </div>
        </div>
      )}
      {/* Show a no data message where there's data on either day or week/month view */}
      {!hasData && (
        <div className={styles.behaviour_chart}>
          <h2 className={styles.behaviour_noData}>No data</h2>
        </div>
      )}
    </div>
  );
};

export default BehaviourIndependence;
