// Get the suggestions for any given input value.
type UserType = {
  [key: string]: string | undefined;
};

export const getSearchSuggestions = (
  value: string,
  data: UserType[] | undefined,
  key: string,
  key2?: string
): UserType[] => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return [];
  } else {
    if (key2) {
      return (
        data?.filter(
          (user) =>
            // Here we're interested in service users or staff that either have a username or condition that matches the search.
            `${user[key]} ${user[key2]}`
              ?.toLowerCase()
              .slice(0, inputLength) === inputValue
        ) || []
      );
    }
    return (
      data?.filter(
        (user) =>
          // Here we're interested in service users or staff that either have a username or condition that matches the search.
          user[key]?.toLowerCase().slice(0, inputLength) === inputValue
      ) || []
    );
  }
};
