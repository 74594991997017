import { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";

// The below is an adaptation of this resource:
// https://javascript.plainenglish.io/animating-child-component-on-mount-and-unmount-and-removing-itself-from-dom-after-unmount-65e6bb1cabab

const AnimationWrapper = ({
  show,
  children,
  from = { opacity: 0, transform: { translateY: 0 } },
  to = { opacity: 1, transform: { translateY: 10 } },
  unMountAnimation,
  optionsIn = { duration: 500, fill: "forwards" },
  optionsOut = { duration: 0, fill: "forwards" },
  delayInMiliseconds = 0,
}) => {
  const elementRef = useRef();

  const [removeState, setRemove] = useState(!show);

  useEffect(() => {
    const childElement = elementRef.current;
    if (show) {
      setTimeout(() => {
        setRemove(false);
        if (!childElement) return;
        childElement.animate([from, to], optionsIn);
      }, delayInMiliseconds);
    } else {
      if (!childElement) return;
      const animation = childElement.animate(
        unMountAnimation || [to, from],
        optionsOut
      );
      animation.onfinish = () => {
        setRemove(true);
      };
    }
  }, [
    show,
    removeState,
    delayInMiliseconds,
    from,
    to,
    optionsIn,
    optionsOut,
    unMountAnimation,
  ]);

  return (
    !removeState && (
      <span ref={elementRef} className={styles.animation_wrapper}>
        {children}
      </span>
    )
  );
};

export default AnimationWrapper;
