import { useEffect } from "react";
import {
  setQrCode,
  setInitialDeviceId,
} from "../../State/slices/session-slice";
import { getUrlParameter } from "@intelligentlilli/lilli-utils";
import { useDispatch } from "react-redux";

export const usePersistURLParameters = () => {
  const dispatch = useDispatch();
  // Get url parameters
  const deviceId = getUrlParameter("deviceId");
  const qr = getUrlParameter("qr");
  // Dispatch the Url parameters to redux
  useEffect(() => {
    dispatch(setInitialDeviceId(deviceId));
    dispatch(setQrCode(qr));
  }, [deviceId, dispatch, qr]);
};
