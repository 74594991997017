export const refreshUser = (
  server,
  dispatch,
  setUser,
  setLoading,
  setServiceUsers,
  getCheckAuthStateAPI,
  origin
) => {
  dispatch(setLoading(true));
  getCheckAuthStateAPI(server, origin)
    .then((res) => {
      if (res.ok) {
        dispatch(setUser(res.body)); // Persisting the user to state
        // If user isn't a manager then the hubs array is the list of SUs that the rest of the app will deal with. Persisting it in state. (managers get to see all organisation SUs)
        if (!res.roles?.includes("manager")) {
          dispatch(setServiceUsers(res.body.hubs));
        }
      }
      dispatch(setLoading(false));
    })
    .catch((err) => {
      // TODO handle error
      console.log(err);
      alert("Failed to update user preferences");
      dispatch(setLoading(false));
    });
};
