import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import { mobileBreakpoint } from "../../Services/config";
import { useViewport } from "../ViewportProvider";
// Components
import Header from "../Header";
import SideDrawer from "../SideDrawer";
import Backdrop from "../Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { setShowSideDrawer } from "../../State/slices/session-slice";

const Page = (props) => {
  // Get the history prop from react router
  const navigate = useNavigate();

  const session = useSelector((state) => state.session);
  const showSideDrawer = session?.showSideDrawer;
  // Get the width of the device
  const { width } = useViewport();
  const dispatch = useDispatch();

  return (
    <div className={styles.page}>
      <SideDrawer navigate={navigate} />
      <Backdrop
        show={showSideDrawer}
        onClick={() => dispatch(setShowSideDrawer(false))}
      />
      <Header navigate={navigate} />
      <div
        className={`${styles.page_content} ${props.className} ${
          width > mobileBreakpoint && styles.page_content_desktop
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

Page.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
};

export default Page;
