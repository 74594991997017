import React from "react";
import theme from "../../theme.scss";

export const TimeOutsideNighttimeIcon = ({ height }) => {
  return (
    <svg
      width={height * (21 / 23) || "21"}
      height={height || "23"}
      viewBox="0 0 21 23"
      fill="none"
      id="TimeOutsideNighttimeIcon"
    >
      <g id="Group 231">
        <path
          id="Vector"
          d="M18.0548 8.07576C17.128 8.07508 16.2448 7.65422 15.6238 6.91736C14.9979 6.17955 14.6978 5.18927 14.8001 4.19844C14.9675 2.63245 16.0814 1.37355 17.5325 1.11027C17.9891 1.0347 18.4551 1.05509 18.9045 1.1701C19.2692 1.27045 19.4889 1.66846 19.3952 2.05906C19.3636 2.19077 19.2983 2.31043 19.2066 2.40449C17.963 3.62122 18.071 5.51334 19.4267 6.61328C19.7229 6.86232 19.7746 7.32125 19.5422 7.63847C19.4629 7.74663 19.3567 7.82864 19.2359 7.87506C18.8545 8.00855 18.456 8.07617 18.0548 8.07576ZM18.0755 1.65011C17.9224 1.6497 17.7695 1.66216 17.6181 1.68721C16.4099 1.90599 15.4824 2.95378 15.3425 4.25744C15.2565 5.08466 15.5065 5.91161 16.029 6.52771C16.8014 7.41065 17.9872 7.72322 19.054 7.32508C19.1245 7.29674 19.1602 7.21268 19.1339 7.1371C19.1253 7.11273 19.1108 7.09124 19.0919 7.07481C17.4749 5.76689 17.3466 3.4279 18.8335 1.97829C18.8682 1.94365 18.8818 1.89094 18.8683 1.8422C18.8568 1.79045 18.8194 1.74978 18.7713 1.73677C18.5435 1.6779 18.3098 1.64888 18.0755 1.65011Z"
          fill={theme.neutral7}
          stroke={theme.neutral7}
        />
        <g id="Group 230">
          <g id="Group 230_2">
            <path
              id="Vector_2"
              d="M7.81221 12.9345C10.5736 12.9345 12.8123 10.6958 12.8123 7.93447C12.8123 5.17311 10.5736 2.93442 7.81221 2.93442C5.05085 2.93442 2.81216 5.17311 2.81216 7.93447C2.81489 10.6947 5.05202 12.9318 7.81221 12.9345ZM7.81221 4.60123C9.65325 4.60123 11.1454 6.09383 11.1454 7.93447C11.1454 9.77512 9.65286 11.2677 7.81221 11.2677C5.97157 11.2677 4.47897 9.77512 4.47897 7.93447C4.47897 6.09383 5.97157 4.60123 7.81221 4.60123Z"
              fill={theme.neutral7}
            />
            <path
              id="Vector_3"
              d="M7.81248 14.6011C3.6722 14.6058 0.317081 17.9609 0.312393 22.1012C0.312393 22.5614 0.685409 22.9344 1.14557 22.9344C1.60573 22.9344 1.97879 22.5614 1.97879 22.1012C1.97879 18.8797 4.59054 16.268 7.81206 16.268C11.0336 16.268 13.6453 18.8797 13.6453 22.1012C13.6453 22.5614 14.0184 22.9344 14.4785 22.9344C14.9387 22.9344 15.3118 22.5614 15.3118 22.1012C15.3071 17.9609 11.952 14.6058 7.81168 14.6011H7.81248Z"
              fill={theme.neutral7}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
