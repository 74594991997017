import React from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import Spinner from "../Spinners/Spinner";

const LoadingHOC = (props) => {
  const loading = useSelector((state) => state.session.loading);
  const loadingBaseData = useSelector((state) => state.session.loadingBaseData);
  return (
    <div>
      {(loading || loadingBaseData !== 0) && (
        <Spinner loadingBaseData={loadingBaseData} />
      )}
      {props.children}
    </div>
  );
};

LoadingHOC.propTypes = {
  children: PropTypes.node,
};

export default LoadingHOC;
