import React from "react";
import styles from "./styles.module.scss";
import SensorList from "../SensorList";

const ComparisonBreakdown = ({ data, dateType, isCurrentPeriod }) => {
  const {
    hasNoData,
    timePeriod,
    value,
    label,
    percentChange,
    // expected,
    sensors,
  } = data;

  const shouldShowSensors = false;

  const prettyTimePeriod = isCurrentPeriod
    ? `This ${dateType}`
    : `Last ${dateType}`;

  return (
    <div className={styles.bathroom_breakdown_container}>
      <div className={styles.top}>
        <div className={styles.last_period}>{prettyTimePeriod}</div>
        <div className={styles.dates}>{timePeriod}</div>
      </div>

      <hr></hr>

      <div className={styles.middle}>
        {hasNoData && (
          <div className={styles.expected_events}>
            There was no data {isCurrentPeriod ? "this" : "last"} {dateType}
          </div>
        )}
        {!hasNoData && (
          <div className={styles.actual_events}>
            <div className={styles.stats}>
              <div className={styles.main_stat}>{value}</div>
              <div className={styles.label}>{label}</div>
              <div
                className={`${
                  isCurrentPeriod ? styles.color : styles.no_color
                }`}
              >
                {percentChange}
              </div>
            </div>
          </div>
        )}
      </div>

      {sensors && shouldShowSensors && (
        <div className={styles.bottom}>
          <SensorList sensors={sensors} />
        </div>
      )}
    </div>
  );
};

export default ComparisonBreakdown;
