import React from "react";
import theme from "../../theme.scss";

export const BathtubIcon = ({ width, colour }) => {
  return (
    <svg width={width || 30} viewBox="0 0 512 512" id="BellIcon">
      <path
        d="m497 273.014h-19.4-357.74v-168.524c0-22.213 18.071-40.284 40.283-40.284 17.566 0 32.533 11.306 38.029 27.021-27.438 4.995-48.313 29.053-48.313 57.912v14.547c0 8.284 6.716 15 15 15h101.134c8.284 0 15-6.716 15-15v-14.547c0-30.117-22.732-55.009-51.937-58.457-6.434-32.165-34.885-56.476-68.913-56.476-38.754 0-70.283 31.529-70.283 70.284v168.524h-55.46-19.4c-8.284 0-15 6.716-15 15s6.716 15 15 15h7.25l14.047 66.194c5.12 24.132 18.497 45.972 37.665 61.499 1.157.937 2.333 1.846 3.525 2.732l-12.489 21.929c-4.1 7.198-1.588 16.357 5.611 20.457 2.343 1.335 4.893 1.969 7.409 1.969 5.215 0 10.283-2.725 13.048-7.58l12.769-22.421c12.115 4.569 25.032 6.993 38.105 6.993h228.12c13.073 0 25.99-2.424 38.105-6.993l12.769 22.421c2.766 4.856 7.833 7.58 13.048 7.58 2.517 0 5.066-.635 7.409-1.969 7.199-4.1 9.711-13.259 5.611-20.457l-12.489-21.929c1.192-.886 2.369-1.795 3.525-2.732 19.168-15.527 32.545-37.367 37.665-61.499l14.047-66.194h7.25c8.284 0 15-6.716 15-15s-6.716-15-15-15zm-246.011-124.329h-71.126c.243-15.716 13.103-28.427 28.875-28.427h13.375c15.773 0 28.633 12.711 28.876 28.427zm195.368 214.296c-7.6 35.813-39.688 61.806-76.298 61.806h-228.119c-36.61 0-68.698-25.993-76.298-61.806l-12.725-59.967h406.164z"
        fill={colour || theme.neutral7}
      />
      <path
        d="m244.62 199.685c-.013 0-.024 0-.037 0-8.284.02-14.983 6.751-14.964 15.036l.059 24.635c.02 8.271 6.731 14.964 14.999 14.964h.037c8.284-.02 14.983-6.751 14.964-15.036l-.059-24.635c-.019-8.272-6.731-14.964-14.999-14.964z"
        fill={colour || theme.neutral7}
      />
      <path
        d="m186.231 199.685c-8.267 0-14.979 6.692-14.999 14.964l-.059 24.635c-.02 8.285 6.68 15.017 14.964 15.036h.037c8.267 0 14.979-6.692 14.999-14.964l.059-24.635c.02-8.285-6.68-15.017-14.964-15.036-.012 0-.024 0-.037 0z"
        fill={colour || theme.neutral7}
      />
    </svg>
  );
};
