// Utility functions defining the immutable behaviours
import {
  IndependenceIcon,
  MovementIcon,
  SleepIcon,
  SustenanceIcon,
  TemperatureIcon,
  ToiletIcon,
  BathtubIcon,
  TimeOutsideDaytimeIcon,
  TimeOutsideNighttimeIcon,
} from "../../../Styles/Icons/DesignSystem";
import { DoorOpenIcon } from "../../../Styles/Icons";

export const BehaviourTypeIcon = {
  MOVEMENT: MovementIcon,
  SUSTENANCE: SustenanceIcon,
  INDEPENDENCE: IndependenceIcon,
  "INDEPENDENCE-DAY": TimeOutsideDaytimeIcon,
  "INDEPENDENCE-NIGHT": TimeOutsideNighttimeIcon,
  SLEEP: SleepIcon,
  TEMPERATURE: TemperatureIcon,
  BATHROOM: ToiletIcon,
  BATHING: BathtubIcon,
  DOOR: DoorOpenIcon,
};
