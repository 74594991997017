import {
  DayRatingType, 
  DailyRiskValesType
} from "../../types/RiskScore/getRiskDates";

export const getRiskDates = (
  dailyRiskValues: DailyRiskValesType | undefined,
  dayRating: DayRatingType
): string[] | undefined => {
  return dailyRiskValues
    ?.filter((day) => day.dayRating === dayRating)
    .map((day) => day.date);
};
