import React from "react";
import styles from "./styles.module.scss";
import AltSpinner from "../../Spinners/AltSpinner";

const CreatingReportSpinner = () => {
  return (
    <div className={styles.loading}>
      <AltSpinner large />
      <h1>Creating report...</h1>
      <p>Please wait while we create your report.</p>
    </div>
  );
};

export default CreatingReportSpinner;
