import React from "react";

export const Avatar6 = ({ width, primaryColour, secondaryColour }) => {
  return (
    <svg width={width || 40} viewBox="0 0 40 40" fill="none" id="Avatar6">
      <rect width="40" height="40" rx="3.27273" fill={primaryColour} />
      <circle
        cx="26.7071"
        cy="16.121"
        r="1.93939"
        transform="rotate(90 26.7071 16.121)"
        fill={secondaryColour}
      />
      <circle
        cx="19.9192"
        cy="16.121"
        r="1.93939"
        transform="rotate(90 19.9192 16.121)"
        fill={secondaryColour}
      />
      <circle
        cx="13.1313"
        cy="16.121"
        r="1.93939"
        transform="rotate(90 13.1313 16.121)"
        fill={secondaryColour}
      />
      <circle
        cx="26.707"
        cy="23.8784"
        r="1.93939"
        transform="rotate(90 26.707 23.8784)"
        fill={secondaryColour}
      />
      <circle
        cx="19.9192"
        cy="23.8784"
        r="1.93939"
        transform="rotate(90 19.9192 23.8784)"
        fill={secondaryColour}
      />
      <circle
        cx="13.1313"
        cy="23.8784"
        r="1.93939"
        transform="rotate(90 13.1313 23.8784)"
        fill={secondaryColour}
      />
    </svg>
  );
};
