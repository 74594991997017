import React from "react";
import styles from "./styles.module.scss";

const GoBackButton = ({ goBack, flex }) => {
  return (
    <div
      className={`${styles.go_back_button} ${flex ? styles.flex : undefined}`}
      onClick={goBack}
    >
      <div>Go back</div>
    </div>
  );
};

export default GoBackButton;
