import { useEffect, useState } from "react";
// UTILS
import {
  extractIndependenceRiskLevel,
  extractHourlyIndependenceData,
  extractDailyIndependenceData,
} from "@intelligentlilli/lilli-utils";
// DateTime
import { subDays, subMonths, endOfDay, isSameDay } from "date-fns";

export const usePrepareIndependenceData = (
  independenceAnalysis,
  mainDoorActivity,
  startDateString,
  endDateString,
  dateType
) => {
  const [independenceRisk, setIndependenceRisk] = useState();
  const [independenceData, setIndependenceData] = useState();
  const [independenceDataPrevious, setIndependenceDataPrevious] = useState();
  const [frontDoorEvents, setFrontDoorEvents] = useState();

  useEffect(() => {
    const endDate = endDateString && endOfDay(new Date(endDateString));
    const startDate = startDateString && endOfDay(new Date(startDateString));
    setIndependenceRisk(
      extractIndependenceRiskLevel(
        independenceAnalysis?.dailyResults,
        subDays(startDate, 1),
        endDate,
        "minutesOutside"
      )
    );
    setIndependenceData(
      dateType === "day"
        ? extractHourlyIndependenceData(
            independenceAnalysis?.dailyResults,
            subDays(startDate, 1),
            endDate
          )
        : extractDailyIndependenceData(
            independenceAnalysis?.dailyResults,
            subDays(startDate, 1),
            endDate
          )
    );

    // Get the independence data for the previous period (day, week or month). This is used to display the previous time outside element in the independence view
    setIndependenceDataPrevious(
      dateType === "day"
        ? extractHourlyIndependenceData(
            independenceAnalysis?.dailyResults,
            subDays(startDate, 2),
            subDays(endDate, 1)
          )
        : extractDailyIndependenceData(
            independenceAnalysis?.dailyResults,
            dateType === "week"
              ? subDays(startDate, 6)
              : subMonths(startDate, 1),
            dateType === "week" ? subDays(startDate, 6) : subMonths(endDate, 1)
          )
    );

    // Get the main door events
    setFrontDoorEvents(
      mainDoorActivity?.find((day) => isSameDay(new Date(day.date), endDate))
    );
  }, [
    independenceAnalysis,
    mainDoorActivity,
    endDateString,
    startDateString,
    dateType,
  ]);

  return {
    independenceRisk,
    independenceData,
    independenceDataPrevious,
    frontDoorEvents,
  };
};
