import React from "react";
import styles from "./styles.module.scss";
import SearchResultBucket from "../SearchResultBucket";
import { useGetServiceUserList } from "../../Services/Hooks/useGetServiceUserList";
import { getFormattedResultsForDashboardUsers } from "../../Services/Utils";

const DashboardUsers = ({ searchValue, selectedFilter }) => {
  const { serviceUsersToDisplay } = useGetServiceUserList(
    selectedFilter,
    searchValue
  );
  const results = getFormattedResultsForDashboardUsers(
    selectedFilter,
    serviceUsersToDisplay
  );
  return (
    <div className={styles.dashboard_users_container}>
      {results.map((category, index) => (
        <SearchResultBucket
          title={category?.title}
          tip={category?.tip}
          searchResultsData={category?.data}
          key={`${category?.title}-${index}`}
          numOfSUs={category?.numOfSUs}
        />
      ))}
    </div>
  );
};

export default DashboardUsers;
