import React from "react";
import theme from "../../theme.scss";

export const EyeIcon = ({ width, height, colour }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      id="EyeIcon"
    >
      <g id="Icon-Eye">
        <path
          id="Vector"
          d="M15.514 6.27934C14.48 4.59534 12.128 1.77 8.00003 1.77C3.87203 1.77 1.52003 4.59534 0.486034 6.27934C0.166386 6.79634 -0.00292969 7.39217 -0.00292969 8C-0.00292969 8.60784 0.166386 9.20367 0.486034 9.72067C1.52003 11.4047 3.87203 14.23 8.00003 14.23C12.128 14.23 14.48 11.4047 15.514 9.72067C15.8337 9.20367 16.003 8.60784 16.003 8C16.003 7.39217 15.8337 6.79634 15.514 6.27934ZM14.3774 9.02267C13.4894 10.4667 11.4794 12.8967 8.00003 12.8967C4.5207 12.8967 2.5107 10.4667 1.6227 9.02267C1.43279 8.71537 1.3322 8.36125 1.3322 8C1.3322 7.63876 1.43279 7.28464 1.6227 6.97734C2.5107 5.53334 4.5207 3.10334 8.00003 3.10334C11.4794 3.10334 13.4894 5.53067 14.3774 6.97734C14.5673 7.28464 14.6679 7.63876 14.6679 8C14.6679 8.36125 14.5673 8.71537 14.3774 9.02267Z"
          fill={colour || theme.neutral6}
        />
        <path
          id="Vector_2"
          d="M8.00008 4.66656C7.34081 4.66656 6.69635 4.86206 6.14818 5.22833C5.60002 5.5946 5.17278 6.1152 4.92048 6.72429C4.66819 7.33337 4.60218 8.0036 4.7308 8.6502C4.85942 9.2968 5.17689 9.89075 5.64306 10.3569C6.10924 10.8231 6.70318 11.1406 7.34978 11.2692C7.99639 11.3978 8.66661 11.3318 9.27569 11.0795C9.88478 10.8272 10.4054 10.4 10.7716 9.8518C11.1379 9.30364 11.3334 8.65917 11.3334 7.9999C11.3324 7.11617 10.9808 6.26894 10.3559 5.64404C9.73104 5.01915 8.88381 4.66762 8.00008 4.66656ZM8.00008 9.9999C7.60452 9.9999 7.21784 9.8826 6.88894 9.66284C6.56004 9.44308 6.3037 9.13072 6.15232 8.76527C6.00095 8.39981 5.96134 7.99768 6.03851 7.60972C6.11568 7.22176 6.30616 6.86539 6.58587 6.58568C6.86557 6.30598 7.22194 6.1155 7.6099 6.03833C7.99786 5.96116 8.4 6.00076 8.76545 6.15214C9.1309 6.30351 9.44326 6.55986 9.66302 6.88876C9.88278 7.21766 10.0001 7.60434 10.0001 7.9999C10.0001 8.53033 9.78937 9.03904 9.4143 9.41411C9.03922 9.78919 8.53052 9.9999 8.00008 9.9999Z"
          fill={colour || theme.neutral6}
        />
      </g>
      <defs>
        <clipPath id="eye-icon">
          <rect width={width || 16} height={height || 16} fill={theme.white} />
        </clipPath>
      </defs>
    </svg>
  );
};
