import { formatDistance, fromUnixTime } from "date-fns";
import React, { useState } from "react";
import Line from "../Line";
import Tooltip from "../Tooltip";
import styles from "./styles.module.scss";
import { capitalize } from "@intelligentlilli/lilli-utils";

const StaffTooltip = ({ staff }) => (
  <Tooltip top="50px" left="70px">
    <div className={styles.tooltip_title}>
      Service users attached to {staff.forenames} {staff.surname}
    </div>
    <Line className={styles.line} />
    {staff.hubs.map((hub) => (
      <div data-private>{hub.userstring}</div>
    ))}
  </Tooltip>
);

const StaffItem = ({ staff, navigate }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      className={styles.table_row}
      onClick={() => navigate(`/staff/${staff.id}`)}
    >
      <div className={`${styles.bold} ${styles.table_row_element}`}>
        {staff.forenames} {staff.surname}
      </div>
      <div className={styles.table_row_element}>{staff.rolesString}</div>
      <div
        className={styles.service_users}
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {staff?.serviceUsersString}
        {showTooltip && staff?.hubs?.length > 0 && (
          <StaffTooltip staff={staff} />
        )}
      </div>
      <div className={styles.table_row_element}>
        {staff?.lastActivity &&
          capitalize(
            formatDistance(
              fromUnixTime(staff?.lastActivity?.slice(0, -3)),
              new Date()
            )
          )}{" "}
        {staff?.lastActivity ? "ago" : "Unknown"}
      </div>
    </div>
  );
};

export default StaffItem;
