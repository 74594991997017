interface ReportStatusResponse {
  ok: boolean;
  body?: {
    publicationId?: string;
    location?: string;
  };
}

type PostPublishReportAPI = (
  server: string,
  id: string,
  origin: string
) => Promise<{ ok: boolean; body?: { publicationId?: string; } }>;


type GetReportStatusAPI = (
  server: string,
  id: string,
  publicationId: string | undefined,
  origin: string
) => Promise<ReportStatusResponse>;

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const downloadReport = (
  server: string,
  setDownloadingReport: (value: boolean) => void,
  id: string,
  setError: (value: boolean) => void,
  setReportURL: (value: string) => void,
  getReportStatusAPI: GetReportStatusAPI,
  postPublishReportAPI: PostPublishReportAPI,
  isMounted: boolean,
  origin: string
): void => {
  // Set the loading state from the component
  if (isMounted) {
    setDownloadingReport(true);
  }

  // Publish the report (this returns a publication id)
  postPublishReportAPI(server, id, origin)
    .then((res) => {
      // An OK response will have a publication Id, use this to repeatedly call the status of the report until it is ready or 10 attempts have been made
      if (res.ok) {
        // Number of times to call the report status endpoint
        let iterations = 15;
        // Set an interval to fetch the status
        const interval = window.setInterval(() => {
          if (iterations > 0) {
            getReportStatusAPI(
              server,
              id,
              res.body?.publicationId,
              origin
            ).then((res) => {
              if (res.ok) {
                // If the response comes back with a location we can stop looping and download the report
                if (res?.body?.location && isMounted) {
                  setDownloadingReport(false);
                  setReportURL(res.body.location);
                  // openInNewTab(res.body.location);
                  window.clearInterval(interval);
                }
                // Otherwise the report is still in the queue so iterate again and reduce the number by 1
                else {
                  iterations--;
                }
                // Once we get to zero iterations clear the interval stop the spinner and show the error message
                if (iterations === 0) {
                  window.clearInterval(interval);
                  isMounted && setDownloadingReport(false);
                  isMounted && setError(true);
                }
              } else {
                window.clearInterval(interval);
              }
            });
          }
        }, 1500);
      } else {
        isMounted && setDownloadingReport(false);
        isMounted && setError(true);
      }
    })
    .catch((err) => isMounted && setDownloadingReport(false));
};
