import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const CareId = ({ careID, style }) => (
  <p className={styles.id} style={style}>
    Care Record ID: {careID ? careID : "Not set"}
  </p>
);

CareId.propTypes = { careID: PropTypes.string };

export default CareId;
