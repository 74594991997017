const AT_RISK = {
  title: "At risk",
  tip: "Why are these users 'At risk'?",
  data: [],
};

const UNEXPECTED = {
  title: "Unexpected",
  tip: "Why are these users 'Unexpected'?",
  data: [],
};

const EXPECTED = {
  title: "Expected",
  tip: "Why are these users 'Expected'?",
  data: [],
};

const INSTALLATION_NOT_STARTED = {
  title: "Installation not started",
  data: [],
};

const INSTALLATION_STARTED = {
  title: "Installation started",
  data: [],
};

const INSTALLATION_INCOMPLETE = {
  title: "Installation incomplete",
  data: [],
};

const INSTALLATION_COMPLETE = {
  title: "Installation complete",
  data: [],
};

const FRIENDS_AND_FAMILY = {
  title: "Assigned Friend/Family",
  data: [],
};
const NOT_FRIENDS_AND_FAMILY = {
  title: "Not assigned Friend/Family",
  data: [],
};

const FILTERED_RESULTS = {
  AT_RISK: AT_RISK,
  UNEXPECTED: UNEXPECTED,
  EXPECTED: EXPECTED,
  complete: INSTALLATION_COMPLETE,
  incomplete: INSTALLATION_INCOMPLETE,
  "not started": INSTALLATION_NOT_STARTED,
  started: INSTALLATION_STARTED,
  friends_and_family: FRIENDS_AND_FAMILY,
  no_friends_and_family: NOT_FRIENDS_AND_FAMILY,
};

const getAndSortUsersByRiskCategory = (unformattedFilteredResults) => {
  const at_risk_users = unformattedFilteredResults?.filter(
    (i) => i.riskStatus === 2
  );
  const unexpected_users = unformattedFilteredResults?.filter(
    (i) => i.riskStatus === 1
  );
  const expected_users = unformattedFilteredResults?.filter(
    (i) => i.riskStatus === 0
  );
  const result = [
    {
      ...AT_RISK,
      data: at_risk_users,
      numOfSUs: at_risk_users.length,
    },
    {
      ...UNEXPECTED,
      data: unexpected_users,
      numOfSUs: unexpected_users.length,
    },
    {
      ...EXPECTED,
      data: expected_users,
      numOfSUs: expected_users.length,
    },
  ];
  return result;
};

const getAndSortUsersByInstallationStatus = (unformattedResults) => {
  const installation_started = unformattedResults?.filter(
    (i) => i.installationStatus === "in progress"
  );
  const installation_not_started = unformattedResults?.filter(
    (i) => i.installationStatus === "not started"
  );
  const installation_complete = unformattedResults?.filter(
    (i) => i.installationStatus === "complete"
  );
  const installation_results = [
    {
      ...INSTALLATION_STARTED,
      data: installation_started,
      numOfSUs: installation_started.length,
    },
    {
      ...INSTALLATION_NOT_STARTED,
      data: installation_not_started,
      numOfSUs: installation_not_started.length,
    },
    {
      ...INSTALLATION_COMPLETE,
      data: installation_complete,
      numOfSUs: installation_complete.length,
    },
  ];
  return installation_results;
};

export const getFormattedResultsForDashboardUsers = (
  selectedFilter,
  unformattedFilteredResults
) => {
  const BASE_RESULTS = FILTERED_RESULTS[selectedFilter];
  const numOfSUs = unformattedFilteredResults.length;

  switch (selectedFilter) {
    case "all":
      return getAndSortUsersByRiskCategory(unformattedFilteredResults);
    case "installation":
      return getAndSortUsersByInstallationStatus(unformattedFilteredResults);
    default:
      BASE_RESULTS.data = unformattedFilteredResults;
      BASE_RESULTS.numOfSUs = numOfSUs;
      return [BASE_RESULTS];
  }
};
