// Function for getting the number of occurances of each risk level within the daily risk values
type ItemType = {
  dayRating: number;
};

export const countOccurrences = (
  arr: ItemType[] | undefined,
  val: number
): number => {
  if (arr) {
    return arr.reduce((a, v) => (v.dayRating === val ? a + 1 : a), 0);
  }
  return 0;
};
