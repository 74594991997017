// This function takes an array of numbers and returns an array of arrays:
// Input1: [10, 13, 17, 23] => Output1: [[10, 13], [17, 23]]

export const getBinIntervals = (binsArray: number[]): [number, number][] => {
  if (binsArray === undefined || binsArray.length < 1) {
    return [];
  }

  // You need to make a copy of the bins as the below mutates the original array
  const copyOfBins: number[] = [...binsArray];
  const binIntervals: [number, number][] = [],
    size = 2;

  while (copyOfBins.length > 0) {
    binIntervals.push(copyOfBins.splice(0, size) as [number, number]);
  }

  return binIntervals;
};
