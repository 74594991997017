import { parseISO, addMinutes } from "date-fns";

// Function for sorting behaviour data by its timestamp
export interface Timestamped {
  ts: Date;
}
export function sortByTimestamp<T extends Timestamped>(array: T[]): T[] {
  return array?.sort((a, b) => (a.ts < b.ts ? -1 : a.ts > b.ts ? 1 : 0)) || [];
}

// these are stored as UTC but are really local 'wall' dates/times
export const dateToTimestamp = (date: string) => parseISO(date + "T00:00:00Z");

export const dateHourToTimestamp = (date: string, hour: number) => {
  // Take the date and the hour and turn it into a date object in the local timezone (the default for date-fns)
  const fullDate = parseISO(date + "T" + ("00" + hour).slice(-2) + ":00:00Z");
  // Adjust the timezone into UTC which is what the backend provides.
  // This prevents our data being offset by British summer time as the backend takes care of timezone changes.
  return addMinutes(fullDate, fullDate.getTimezoneOffset());
};
