export const NAVIGATION_TABS = [
  "overview",
  "event log",
  "day-time",
  "night-time",
  "sustenance",
  "independence",
  "temperature",
  "bathroom",
  "devices",
];
