import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import QrReader from "react-qr-reader";
import {
  playSuccessSoundEffect,
  playFailureSoundEffect,
} from "../../Services/Utils";
import styles from "./styles.module.scss";
import CTAButton from "../CTAButton";

// case 1: plain 16 hex digits code or a url ending in the same
// e.g. 02000001400001A3
// e.g. https://app.intelligentlilli.com/qr/hw/develco/02000001400001A3
const endingPattern = /([0-9A-Fa-f]{16})$/;

// case 2: extract serial number (SN) from block of identifiers
// e.g. [SN: 02000001400001A3, BLE: 0015BC253EFA, Eth: 0015BC253EF8, Inst code: 4CAA2DBF5AA7C4FD245E, Model: MGW221-DP47, WLAN: 0015BC253EF9, ZigBee: 0015BC002C109F91, wM-Bus: 0004102010B60036]
const embeddedPattern = /SN: ([0-9A-Fa-f]{16})/;

const extract = (data, pattern) => pattern.exec(data)?.[1];

const DeviceIdQrReader = ({ onSubmit, error }) => {
  const [scanError, setScanError] = useState();
  const [deviceId, setDeviceId] = useState(null);
  const [permission, setPermission] = useState();

  useEffect(() => {
    deviceId && (playSuccessSoundEffect() || onSubmit(deviceId));
  }, [onSubmit, deviceId]);

  useEffect(() => {
    scanError && playFailureSoundEffect();
  }, [scanError]);

  // When the page loads check whether we have permission to use the camera
  useEffect(() => {
    checkPermissions();
  }, []);

  const handleScan = (data) => {
    const deviceId =
      extract(data, endingPattern) || extract(data, embeddedPattern);
    if (deviceId) {
      setScanError(null);
      setDeviceId(deviceId);
    } else {
      setScanError(
        data ? "Unrecognised device ID. Did you scan the right QR code?" : null
      );
      setDeviceId(null);
    }
  };

  // Function for checking the permissions to use the camera
  const checkPermissions = () => {
    let constraints = (window.constraints = {
      audio: false,
      video: true,
    });
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((res) => {
        console.log("navigator", res);
        setPermission(true);
        res.getTracks().forEach((t) => t.stop());
      })
      .catch((err) => {
        if (err.message === "Permission denied") {
          setPermission(false);
        }
      });
  };

  return (
    <div className={styles.qr_reader}>
      {permission ? (
        <QrReader
          facingMode="environment"
          delay={300}
          onError={(err) => setScanError(err.message)}
          onScan={handleScan}
          className={styles.qr_reader_video}
        />
      ) : (
        <div className={styles.qr_placeholder}>
          {permission === false ? (
            <div className={styles.qr_placeholder_message}>
              You must enable your camera to scan the QR code
            </div>
          ) : (
            <CTAButton
              style={{ position: "absolute" }}
              onClick={() => checkPermissions()}
            >
              Enable camera
            </CTAButton>
          )}
        </div>
      )}

      <div className={styles.qr_scan_error}>
        {error} {scanError}
      </div>
    </div>
  );
};

DeviceIdQrReader.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};

export default DeviceIdQrReader;
