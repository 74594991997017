import { useEffect, useState } from "react";
// Utils
import {
  BehaviourType,
  extractDailySustenanceData,
  // extractDailyTemperatureData,
  extractDailyIndependenceData,
} from "@intelligentlilli/lilli-utils";
import { getFormattedChartData, extractDailyData } from "../Utils";
import { parseISO, addDays } from "date-fns";

export const usePrepareReportData = (data, dates) => {
  const [preparedData, setPreparedData] = useState();

  // 1- Extract data
  // 2- Format the data so that it works with Victory Charts (i.e. add in "x" and "y" properties)
  useEffect(() => {
    // Extracting behaviour data for each week that the report covers
    const movementData = data?.movementAnalysis?.weeklyData?.map((week) => ({
      ...week,
      dailyResults: getFormattedChartData(
        extractDailyData(
          "totalCount",
          week.dailyResults,
          parseISO(week.startDate),
          // Adding a day to the endDate here to prevent the function cutting off the last day of the week
          addDays(parseISO(week.endDate), 1)
        ),
        "week",
        "movement"
      ),
      behaviour: BehaviourType.MOVEMENT,
    }));

    // Originally this was used for V1; check that it works with V2
    // const temperatureData = data?.temperatureAnalysis?.weeklyData?.map(
    //   (week) => ({
    //     ...week,
    //     dailyResults: getFormattedChartData(
    //       extractDailyTemperatureData("averageTemperature",
    //         week.dailyResults,
    //         parseISO(week.startDate),
    //         // Adding a day to the endDate here to prevent the function cutting off the last day of the week
    //         addDays(parseISO(week.endDate), 1)
    //       ),
    //       "week",
    //       "temperature"
    //     ),
    //     behaviour: BehaviourType.TEMPERATURE,
    //   })
    // );

    const independenceData = data?.independenceAnalysis?.weeklyData?.map(
      (week) => ({
        ...week,
        dailyResults: getFormattedChartData(
          extractDailyIndependenceData(
            week.dailyResults,
            parseISO(week.startDate),
            // Adding a day to the endDate here to prevent the function cutting off the last day of the week
            addDays(parseISO(week.endDate), 1)
          ),
          "week",
          "independence"
        ),
        behaviour: BehaviourType.INDEPENDENCE,
      })
    );

    const sleepData = data?.sleepAnalysis?.weeklyData?.map((week) => ({
      ...week,
      dailyResults: getFormattedChartData(
        extractDailyData(
          "totalCount",
          week.dailyResults,
          parseISO(week.startDate),
          // Adding a day to the endDate here to prevent the function cutting off the last day of the week
          addDays(parseISO(week.endDate), 1)
        ),
        "week",
        "sleep"
      ),
      behaviour: BehaviourType.SLEEP,
    }));

    const fridgeData = data?.sustenanceAnalysis?.weeklyData?.map((week) => ({
      ...week,
      dailyResults: extractDailySustenanceData(
        ["totalFridgeCount"],
        week.dailyResults,
        parseISO(week.startDate),
        // Adding a day to the endDate here to prevent the function cutting off the last day of the week
        addDays(parseISO(week.endDate), 1)
      ),
    }));

    const kettleData = data?.sustenanceAnalysis?.weeklyData?.map((week) => ({
      ...week,
      // v1 sustenance = "kettleCount", "totalKettleCount", v2 sustenance = "smartplugCount", "totalSmartplugCount"
      dailyResults: extractDailySustenanceData(
        ["totalKettleCount", "totalSmartplugCount"],
        week.dailyResults,
        parseISO(week.startDate),
        // Adding a day to the endDate here to prevent the function cutting off the last day of the week
        addDays(parseISO(week.endDate), 1)
      ),
    }));

    // Combining the kettle and fridge events into one sustenance behaviour; simply as a sum of events
    const sustenanceData = kettleData?.map((kettleHour, weekIndex) => ({
      ...kettleHour,
      dailyResults: getFormattedChartData(
        kettleHour.dailyResults.map((j, jIndex) => {
          return {
            ...j,
            value:
              (j.value || 0) +
              (fridgeData?.[weekIndex]?.dailyResults?.[jIndex]?.value || 0),
          };
        }),
        "week",
        "sustenance"
      ),
      behaviour: BehaviourType.SUSTENANCE,
    }));

    setPreparedData({
      movementData: movementData,
      independenceData: independenceData,
      sleepData: sleepData,
      sustenanceData: sustenanceData,
      // temperatureData: temperatureData,
    });
  }, [data]);

  // Return the report data as an object
  return preparedData;
};
