import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { CloseIcon } from "../../Styles/Icons";

const AppliedFilter = (props) => (
  <div
    className={`${styles.filter} ${
      props.type === "user" ? styles.coral : styles.teal
    }`}
  >
    <div>{props.name}</div>
    <CloseIcon
      onClick={props.onClick}
      style={{ cursor: "pointer", marginLeft: 6 }}
    />
  </div>
);

AppliedFilter.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default AppliedFilter;
