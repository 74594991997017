import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
// Hooks
import {
  useFade,
  useOpenHubPairing,
  useGetSensorStatus,
} from "../../../Services/Hooks";
// State
import { useSelector } from "react-redux";
// Routing
import { useParams, useNavigate } from "react-router-dom";
// New Components
import ConfirmExitInstall from "../../../Components/InstallationNew/ConfirmExitInstall";
import SensorCard from "../../../Components/InstallationNew/SensorCard";
import SensorsHeader from "../../../Components/InstallationNew/SensorsHeader";
import SensorSquares from "../../../Components/InstallationNew/SensorSquares";
import MobileDrawer from "../../../Components/MobileDrawer";
import SensorGuide from "../../../Components/InstallationNew/SensorGuide";
// Components
import Page from "../../../Components/Page";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import NewStyleModal from "../../../Components/NewStyleModal";
import { useViewport } from "../../../Components/ViewportProvider";
// Constants
import {
  INSTALL_INSTRUCTIONS,
  INITAL_SENSORS,
} from "../../../Services/Constants";
// Icons
// import { VideoIcon } from "../../../Styles/Icons/DesignSystem";

const InstallSensorsNew = () => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  // sizing
  const { width } = useViewport();
  const isDesktop = width >= 900;
  const isLessThan450px = width <= 450;

  const sensors =
    useSelector((state) => state.sensors?.devices) || INITAL_SENSORS.devices;
  // TO DO: remove console log
  console.log({ sensors });

  const sensorLocations = useSelector((state) => state.sensorLocations);
  // TO DO: remove console log
  console.log({ sensorLocations });

  // Installation is complete when each sensor in the devices array has installed: true
  const installationComplete =
    sensors?.filter(
      (device) =>
        !device.name.includes("REMOVED") &&
        (!device.online ||
          (device.locationRequired && !sensorLocations?.[device.name]))
    ).length === 0;

  // Trigger the hub to activate pairing mode if the installation is not yet complete
  useOpenHubPairing({ hubId: id, installationComplete: installationComplete });

  // Trigger the sensor status fetching
  const { error, hubIsOnline, loading, fetchSensorStatus } = useGetSensorStatus(
    {
      devicestring: devicestring,
      id: id,
      continuous: false,
    }
  );

  // TO DO: remove console log
  console.log({
    error,
    hubIsOnline,
    loading,
  });

  const [selectedSensor, setSelectedSensor] = useState(sensors?.[0]);
  const instructions = INSTALL_INSTRUCTIONS[selectedSensor.label];
  const isMotion = selectedSensor.label.includes("m");

  // are you sure you want to exit install?
  const [isExitVisible, setShowExitAnimation, showExitAnimation] = useFade(
    false,
    150
  );
  // installation guide
  const [isGuideVisible, setShowGuideAnimation, showGuideAnimation] = useFade(
    false,
    150
  );

  return (
    <Page className={styles.page} cypresstestid="Installation-Sensors-8">
      <InstallationCard style={styles.card}>
        <div className={styles.card_top}>
          <div className={styles.desktop_right}>
            {!isDesktop && (
              <SensorsHeader
                isHub={selectedSensor.label === "h1"}
                loading={loading}
              />
            )}
            <SensorCard
              error={error}
              sensor={selectedSensor}
              loading={loading}
              onConnect={fetchSensorStatus}
              onViewGuide={() => {
                setShowGuideAnimation(true);
              }}
            />
          </div>
          <div className={styles.desktop_left}>
            {isDesktop && (
              <SensorsHeader isHub={selectedSensor.label === "h1"} />
            )}
            {hubIsOnline && (
              <SensorSquares
                selectedSensor={selectedSensor}
                sensors={sensors}
                onClick={setSelectedSensor}
              />
            )}
          </div>
        </div>
        <div className={styles.bottom_buttons}>
          <InstallationNextButton
            disabled={loading || !installationComplete}
            onClick={
              loading || !installationComplete
                ? () => {}
                : () => navigate(`/install/finish/${id}/${devicestring}`)
            }
            style={{
              borderRadius: "8px",
              lineHeight: 1.75,
              marginBottom: isDesktop ? "0px" : "16px",
            }}
            label="Complete install"
          />
          <InstallationGoBackButton
            onClick={() => setShowExitAnimation(true)}
            style={{
              borderRadius: "8px",
              lineHeight: 1.75,
              backgroundColor: "white",
              border: `1px solid ${theme.primary3}`,
            }}
            marginRight={isLessThan450px ? "0px" : "16px"}
            marginTop={isLessThan450px ? "16px" : "0px"}
            label="Exit install"
          />
        </div>
      </InstallationCard>
      {isGuideVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setShowGuideAnimation(false);
          }}
          showCloseIcon={true}
          useFade={false}
          showAnimation={showGuideAnimation}
          size={"medium"}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={isMotion}
            sensorLabel={selectedSensor.label}
            setShowGuide={() => setShowGuideAnimation(false)}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setShowGuideAnimation(false);
          }}
          showGuide={showGuideAnimation}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={isMotion}
            sensorLabel={selectedSensor.label}
            setShowGuide={() => setShowGuideAnimation(false)}
          />
        </MobileDrawer>
      )}
      {isExitVisible && (
        <NewStyleModal
          hide={() => {
            setShowExitAnimation(false);
          }}
          useFade={true}
          showAnimation={showExitAnimation}
        >
          <ConfirmExitInstall
            setShowAnimation={setShowExitAnimation}
            setExitInstall={() => navigate("/install")}
          />
        </NewStyleModal>
      )}
    </Page>
  );
};

export default InstallSensorsNew;
