import React from "react";
import styles from "./styles.module.scss";

const Chart = (props) => {
  return (
    <div
      className={`${styles.chart} ${props.small && styles.small} ${
        props.className
      }`}
    >
      {props.children}
    </div>
  );
};

export default Chart;
