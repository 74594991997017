import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import {
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
} from "../../Styles/Icons/Avatars";
import ServiceUserRiskStatusTooltip from "../ServiceUserRiskStatusTooltip";
import { useSelector } from "react-redux";
import { useViewport } from "../ViewportProvider";
import { getSentenceSeperator } from "../../Services/Utils";
import AnimationWrapper from "../AnimationWrapper";
import { NEWLY_INSTALLED } from "../../Services/Constants";

const avatarIcons = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6];
const avatarColourPairings = [
  { primary: theme.brandYellow1, secondary: theme.brandYellow },
  { primary: theme.brandOrange, secondary: theme.brandOrange1 },
  { primary: theme.brandBlue1, secondary: theme.brandBlue3 },
  { primary: theme.brandOrange1, secondary: theme.brandOrange },
  { primary: theme.brandBlue3, secondary: theme.brandBlue1 },
];
const avatarCombinations = avatarIcons.flatMap((avatar) =>
  avatarColourPairings.map((colour) => ({
    avatarIcon: avatar,
    primaryColour: colour.primary,
    secondaryColour: colour.secondary,
  }))
);

const SearchResultUserCard = ({ user }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const serviceUsersData = useSelector((state) => state.serviceUsersData);
  const serviceUserData = serviceUsersData?.[user?.id];
  const { width } = useViewport();
  const centerTooltip = width < 950;
  const isMobile = width <= 600;

  // Generate the status changed text
  const riskStatus = (() => {
    switch (user.daysSinceRiskStatusChanged) {
      case 0:
        return "Status changed: today";
      case 1:
        return "Status changed: yesterday";
      case "Newly installed":
        return NEWLY_INSTALLED;
      case "No change":
        return "Status changed: more than a week ago";
      case undefined:
        return "Status changed: unknown";
      default:
        return `Status changed: ${user.daysSinceRiskStatusChanged} days ago`;
    }
  })();

  //  Get the service user's avatar
  const avatar = avatarCombinations?.[user?.id % avatarCombinations.length];

  // Get the risk scores that contributed to the risk status for the tooltip
  const contributingRiskScoreData = serviceUserData?.riskScores?.slice(
    -user.daysSinceRiskStatusChanged - 1
  );

  // Extract the behaviours that contributed to the risk score
  const contributingBehaviours = contributingRiskScoreData?.flatMap(
    (riskScore) =>
      Object.keys(riskScore.details).filter(
        (behaviour) => riskScore.details[behaviour]?.dayRating > 0
      )
  );
  const uniqueContributingBehaviours = [...new Set(contributingBehaviours)];

  // Make tooltip behaviours readable
  const readableBehaviours = {
    independence: "Time outside",
    movement: "Movement",
    sleep: "Night-time activity",
    sustenance: "Eating & drinking",
    temperature: "Home temperature",
  };

  // Combine the unique contributing risk scores into a readable string replacing the elements with their corresponding readable behaviour
  let uniqueContributingBehavioursCombined = getSentenceSeperator(
    uniqueContributingBehaviours.map(
      (behaviour) => readableBehaviours[behaviour]
    ),
    "and"
  );
  uniqueContributingBehavioursCombined =
    uniqueContributingBehavioursCombined.replace(/and/g, ",");
  // Use this array below to render JSX where each behaviour is a clickable URL sitting inside a <span> tag
  const behavioursAffectingStatus =
    uniqueContributingBehavioursCombined.split(",");

  return (
    <div
      className={styles.search_result_user_card_container}
      style={{
        boxShadow: showTooltip && "0px 1px 5px rgba(0, 0, 0, 0.15)",
      }}
      onClick={() => navigate(`/lilli-users/${user?.id}`)}
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {avatar.avatarIcon && (
        <div className={styles.search_user_icon}>
          <avatar.avatarIcon
            primaryColour={avatar.primaryColour}
            secondaryColour={avatar.secondaryColour}
          />
        </div>
      )}
      <div className={styles.user_details}>
        <div data-private className={styles.userstring}>
          {user?.userstring}
        </div>
        {user?.installationStatus === "complete" ? (
          <div className={styles.risk_status_info}>{riskStatus}</div>
        ) : (
          <div className={styles.risk_status_info}>Installation incomplete</div>
        )}
      </div>
      {!isMobile && (
        <AnimationWrapper
          show={showTooltip}
          optionsIn={{ duration: 0, fill: "forwards" }}
          optionsOut={{ duration: 0, fill: "forwards" }}
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          delayInMiliseconds={450}
        >
          <ServiceUserRiskStatusTooltip
            navigate={navigate}
            uniqueContributingBehaviours={uniqueContributingBehaviours}
            centerTooltip={centerTooltip}
            user={user}
            behavioursAffectingStatus={behavioursAffectingStatus}
          />
        </AnimationWrapper>
      )}
    </div>
  );
};

export default SearchResultUserCard;
