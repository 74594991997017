import React from "react";
import styles from "./styles.module.scss";
import Donut from "../../Charts/Donut";

const InsightMovementDonut = ({
  totalMovements,
  totalMovementsString,
  expectedMovements,
  expectedMovementsString,
  riskScore,
}) => {
  return (
    <div className={styles.expected}>
      <div>
        <div className={styles.expected_number}>
          {totalMovementsString ?? "Unknown"}
        </div>
        <div className={styles.expected_hr} />
        <div className={styles.expected_expected}>
          {expectedMovementsString ?? ""}
        </div>
      </div>
      <Donut
        percentage={
          expectedMovements !== null
            ? expectedMovements === 0 && totalMovements >= 0
              ? 100
              : isNaN(Math.round((100 * totalMovements) / expectedMovements))
              ? 0
              : Math.round((100 * totalMovements) / expectedMovements)
            : 0
        }
        riskScore={expectedMovements ? riskScore : 0}
        isModelReady={expectedMovements !== null}
      />
    </div>
  );
};

export default InsightMovementDonut;
