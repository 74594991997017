export const getMissedMealContext = (
  oneDayOfSustenanceData,
  shouldShortenSentence
) => {
  const missedMeals = oneDayOfSustenanceData?.sentences?.length;
  let contextWhenMissedMeals = oneDayOfSustenanceData?.sentences?.reduce(
    (acc, curr, currentIndex) => {
      const isLastSentence =
        currentIndex === oneDayOfSustenanceData?.sentences?.length - 1;
      const isFirstSentence = currentIndex === 0;
      if (isFirstSentence) {
        return acc + curr;
      }
      if (isLastSentence) {
        return acc + " and " + curr.toLowerCase();
      }
      return acc + ", " + curr.toLowerCase();
    },
    ""
  ); // This returns a longer sentence: "Missed breakfast, missed lunch, and missed dinner"

  if (shouldShortenSentence) {
    if (missedMeals === 3) {
      contextWhenMissedMeals = "Missed breakfast, lunch, & dinner";
    } else if (missedMeals === 2) {
      const [firstMeal, secondMeal] = oneDayOfSustenanceData?.sentences;
      contextWhenMissedMeals = `${firstMeal}, ${secondMeal.split(" ")?.[1]}`; // "Missed lunch, dinner"
    } else if (missedMeals === 1) {
      contextWhenMissedMeals = `${contextWhenMissedMeals.split(",")[0]}`; // "Missed lunch"
    }
  }
  const missedMealsSentence = missedMeals === 0 ? "" : contextWhenMissedMeals;
  return missedMealsSentence;
};
