import React from "react";
import theme from "../../theme.scss";

export const FridgeIcon = ({ width, color }) => {
  return (
    <svg
      id="FridgeIcon"
      height={width || 23}
      width={width || 23}
      viewBox="0 0 128 128"
      fill={color || theme.neutral7}
    >
      <path d="m100.417 43v-36.135a6.758 6.758 0 0 0 -6.75-6.75h-59.334a6.758 6.758 0 0 0 -6.75 6.75v36.135a6.726 6.726 0 0 0 2.232 5 6.726 6.726 0 0 0 -2.232 5v61.13a6.758 6.758 0 0 0 6.75 6.75h.917v5.37a1.75 1.75 0 0 0 1.75 1.75h12.5a1.75 1.75 0 0 0 1.75-1.75v-5.37h25.5v5.37a1.75 1.75 0 0 0 1.75 1.75h12.5a1.75 1.75 0 0 0 1.75-1.75v-5.37h.917a6.758 6.758 0 0 0 6.75-6.75v-61.13a6.726 6.726 0 0 0 -2.232-5 6.726 6.726 0 0 0 2.232-5zm-52.667 81.5h-9v-3.62h9zm41.5 0h-9v-3.62h9zm7.667-71.5v61.13a3.254 3.254 0 0 1 -3.25 3.25h-59.334a3.254 3.254 0 0 1 -3.25-3.25v-61.13a3.254 3.254 0 0 1 3.25-3.25h59.334a3.254 3.254 0 0 1 3.25 3.25zm-62.584-6.75a3.254 3.254 0 0 1 -3.25-3.25v-36.135a3.254 3.254 0 0 1 3.25-3.25h59.334a3.254 3.254 0 0 1 3.25 3.25v36.135a3.254 3.254 0 0 1 -3.25 3.25z" />
      <path d="m88.658 12.682h-6.5a1.75 1.75 0 0 0 -1.75 1.75v21a1.75 1.75 0 0 0 1.75 1.75h6.5a1.75 1.75 0 0 0 1.75-1.75v-21a1.75 1.75 0 0 0 -1.75-1.75zm-1.75 21h-3v-17.5h3z" />
      <path d="m82.158 80.758h6.5a1.75 1.75 0 0 0 1.75-1.75v-21a1.75 1.75 0 0 0 -1.75-1.75h-6.5a1.75 1.75 0 0 0 -1.75 1.75v21a1.75 1.75 0 0 0 1.75 1.75zm1.75-21h3v17.5h-3z" />
    </svg>
  );
};
