import React from "react";
import { getRiskColour } from "@intelligentlilli/lilli-utils";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";

const RiskSquare = ({ riskStatus, small, className }) => (
  <div
    className={`${small ? styles.square_small : styles.square} ${className}`}
    style={{
      backgroundColor: `${
        riskStatus
          ? getRiskColour(riskStatus, {
              red: theme.risk3,
              orange: theme.unexpected,
              green: theme.green,
            })
          : theme.neutral4
      }`,
    }}
  />
);

export default RiskSquare;
