import { addHours, startOfDay, subDays } from "date-fns";

export const getNewNighttimeEvents = (nighttimeEvents, urlEndDateString) => {
  const formattedStart = "10pm";
  const formattedEnd = "6am";

  // The format required for the EventLogSection component
  return [
    {
      events: nighttimeEvents,
      start: subDays(
        addHours(startOfDay(new Date(urlEndDateString), 1), 22),
        1
      ), // 10pm yesterday
      end: addHours(startOfDay(new Date(urlEndDateString), 1), 6), // 6am of the current day
      sectionTitleFull: `Last night: ${formattedStart} - ${formattedEnd}`,
      sectionTitlePartial: `Last night: ${formattedStart} - now`,
    },
  ];
};
