import React from "react";
import styles from "./styles.module.scss";
import { InfoOutlined } from "../../Styles/Icons";

function TemperatureTooltip() {
  return (
    <div className={styles.temperature_tooltip_container}>
      <InfoOutlined />
      <div className={styles.temperature_info}>
        <div className={styles.temperature_info_top}>Temperature Tips</div>
        <div className={styles.temperature_info_bottom}>
          <div>
            Below 13° - If a home is this cold it may increase blood pressure
            and risk of cardiovascular disease.
          </div>
          <div>
            14-15° - If a home is this cold it could diminish resistance to
            respiratory diseases.
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemperatureTooltip;
