import React from "react";
import theme from "../../theme.scss";

export const ExpectedIcon = ({ width, colour }) => {
  return (
    <svg width={width || 11} viewBox="0 0 11 8" id="ExpectedIcon">
      <path
        d="M9.43053 1.47937L3.96872 6.94118L1.48608 4.45854"
        stroke={colour || theme.white}
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
