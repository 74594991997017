import React from "react";

export const DownArrow = ({
  color,
  percentChange,
  width,
  height,
  strokeWidth,
}) => {
  const WIDTH = 8;
  const HEIGHT = 5;

  return (
    <svg
      id="DownArrow"
      width={percentChange ? percentChange * WIDTH : width ? width : WIDTH}
      height={percentChange ? percentChange * HEIGHT : height ? height : HEIGHT}
      viewBox="0 0 8 5"
      stroke={color ? color : "none"}
      strokeWidth={strokeWidth ? strokeWidth : 0.5}
    >
      <path
        d="M4.61955 2.9792C4.57113 3.02802 4.51353 3.06676 4.45006 3.09321C4.38659 3.11965 4.31852 3.13326 4.24976 3.13326C4.18101 3.13326 4.11293 3.11965 4.04946 3.09321C3.98599 3.06676 3.92839 3.02802 3.87997 2.9792L1.49455 0.58858C1.44614 0.539763 1.38853 0.501016 1.32506 0.474574C1.26159 0.448132 1.19352 0.434519 1.12476 0.434519C1.05601 0.434519 0.987929 0.448132 0.924461 0.474574C0.860993 0.501016 0.803388 0.539763 0.75497 0.58858C0.657964 0.686165 0.603515 0.818171 0.603515 0.955767C0.603515 1.09336 0.657964 1.22537 0.75497 1.32295L3.1456 3.71357C3.43856 4.00618 3.8357 4.17053 4.24976 4.17053C4.66383 4.17053 5.06096 4.00618 5.35393 3.71357L7.74455 1.32295C7.84077 1.22594 7.89502 1.09501 7.89559 0.958371C7.89599 0.889826 7.88285 0.821876 7.85693 0.758419C7.83101 0.694962 7.79282 0.637246 7.74455 0.58858C7.69613 0.539763 7.63853 0.501016 7.57506 0.474574C7.51159 0.448132 7.44352 0.434519 7.37476 0.434519C7.30601 0.434519 7.23793 0.448132 7.17446 0.474574C7.11099 0.501016 7.05339 0.539763 7.00497 0.58858L4.61955 2.9792Z"
        fill={color || "#454545"}
      />
    </svg>
  );
};
