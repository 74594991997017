export const BATHROOM_QUESTIONS_AND_ANSWERS = [
  {
    key: "overview",
    question: "What sensors do we use?",
    answer: [
      "We use the motion sensor which, in addition to motion detection, also provides us with temperature data.",
    ],
  },
  {
    key: "Bathing_Detection",
    question: "How do we detect bathing events?",
    answer: [
      "If we see a relatively large temperature change in a short amount of time coupled with motion events of a certain duration of time we interpret this to be a bathing event.",
    ],
  },
  {
    key: "Bathing_summer",
    question: "Why am I not seeing as many bathing events in the summer?",
    answer: [
      "In the summer, usually people take showers that are not as hot  and so the temperature changes are less pronounced which is why we do not detect bathing events as often.",
      "If people have very hot showers in the summer then we would still detect bathing events because it would cause changes in temperature.",
      "We are working on improving this.",
    ],
  },
  {
    key: "toilet",
    question: "How do we know the bathroom is occupied?",
    answer: [
      "We detect bathroom visits by looking at the motion sensor. If the motion sensor is triggered, we assume that the person is in the bathroom.",
    ],
  },
];
