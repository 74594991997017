import React, { useState, useRef, useEffect } from "react";
import { DoorOpenIcon, DoorClosedIcon } from "../../Styles/Icons";
import EventLogTooltip from "../EventLogTooltip";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";

const getDoorIcon = (event) => {
  if (event?.[0].status === "opened") {
    return (
      <DoorOpenIcon width="10" height="14" style={{ color: theme.white }} />
    );
  }
  return (
    <DoorClosedIcon width="10" height="14" style={{ color: theme.white }} />
  );
};

const EventLogSquare = ({
  value,
  index,
  events,
  entranceDoor = false,
  time,
  closeTooltip,
  setCloseTooltip,
}) => {
  // Add a ref to the square to extract it's position on the screen so as to position the tooltip
  const squareRef = useRef();
  const coordinatesSquare = squareRef.current?.getBoundingClientRect();

  const [tooltipEvents, setTooltipEvents] = useState();
  // Figure out if it's an even square for different default colouring
  const isEven = index % 2 === 0;

  // If the user scrolls close the tooltip
  useEffect(() => {
    if (closeTooltip) {
      setTooltipEvents(null);
      // Return the closeTooltip state to false so others can be opened
      setCloseTooltip(false);
    }
  }, [closeTooltip, setCloseTooltip]);

  // Where there's no events return one of the two coloured squares
  if (!value) {
    return <div className={isEven ? styles.square_even : styles.square_odd} />;
  }
  // Where there are events return the number in the square

  return (
    <div
      className={`${styles.square} ${tooltipEvents && styles.square_selected}`}
      onMouseEnter={(e) => {
        // To prevent the onMouseEnter handler firing when the users hovers over the tooltip (a child element) check it's id
        if (e.target.id === "parent") {
          setTooltipEvents(events);
        }
      }}
      onMouseLeave={() => setTooltipEvents(null)}
      onClick={() => setTooltipEvents(events)}
      id={"parent"}
      ref={squareRef}
    >
      {value === 1 && entranceDoor ? getDoorIcon(events) : value}
      {tooltipEvents && (
        <EventLogTooltip
          entranceDoor={entranceDoor}
          tooltipEvents={tooltipEvents}
          coordinatesSquare={coordinatesSquare}
          time={time}
        />
      )}
    </div>
  );
};

export default EventLogSquare;
