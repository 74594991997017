import React from "react";
import styles from "./styles.module.scss";

const InsightBathingDuration = ({ hours, minutes }) => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <h2>
          {hours > 0 && `${hours} hour${hours === 1 ? "" : "s"} `}
          {`${minutes} min${minutes === 1 ? "" : "s"}`}
        </h2>
      </div>
    </div>
  );
};

export default InsightBathingDuration;
