import React from "react";
import theme from "../../theme.scss";

export const ApplianceIcon = ({ width, color }) => {
  return (
    <svg
      height={width || "23"}
      width={width || "23"}
      viewBox="0 0 512 512"
      id="ApplianceIcon"
    >
      <path
        fill={color || theme.neutral7}
        d="m437.02 76.713c-48.353-48.351-112.64-74.98-181.02-74.98s-132.667 26.629-181.02 74.98c-48.26 48.261-74.879 113.784-74.98 182.02 0 59.207 20.665 116.558 58.228 162.196 37.048 45.013 88.717 76.29 145.489 88.071 4.097.85 8.218 1.268 12.31 1.268 13.673-.001 26.999-4.67 37.837-13.489 14.056-11.436 22.119-28.388 22.123-46.51l.013-86.496c44.45-9.058 78.002-48.454 78.002-95.54v-51.5c0-11.046-8.954-20-20-20h-17v-51c0-11.046-8.954-20-20-20s-20 8.954-20 20v51h-40v-51c0-11.046-8.954-20-20-20s-20 8.954-20 20v51h-18c-11.046 0-20 8.954-20 20v51.5c0 46.73 33.048 85.881 76.998 95.324l-.013 86.705c-.001 8.263-4.617 13.252-7.368 15.49-4.707 3.829-10.821 5.318-16.774 4.082-99.464-20.64-171.689-109.375-171.866-211.101 0 0 .022.251.022 0 0-119.103 96.897-217 216-217s216 96.897 216 216c0 87.317-51.736 165.659-131.803 199.585-10.17 4.309-14.922 16.047-10.612 26.218s16.049 14.923 26.218 10.612c45.699-19.363 84.611-51.544 112.53-93.064 28.566-42.484 43.666-92.054 43.666-143.351 0-68.38-26.628-132.668-74.98-181.02zm-238.02 191.52v-31.5h115v31.5c0 31.706-25.794 57.5-57.5 57.5s-57.5-25.795-57.5-57.5z"
      />
    </svg>
  );
};
