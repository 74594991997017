import React from "react";
import styles from "./styles.module.scss";
import Lottie from "react-lottie";
import lilliAnimation from "../../../Styles/lilli.json";

const Spinner = ({ loadingBaseData }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lilliAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const updates = [
    "Loading user details...",
    "Loading service users...",
    "Analysing service users...",
    "Welcome!",
  ];

  return (
    <div className={styles.background}>
      <div
        className={styles.logo}
        style={{
          marginLeft: loadingBaseData ? -105 : -35,
        }}
      >
        <Lottie
          options={defaultOptions}
          height={50}
          width={70}
          isClickToPauseDisabled={true}
        />
        {loadingBaseData ? (
          <div className={styles.progressBar}>
            <div className={styles.progressBar_bar}>
              <div
                className={styles.progressBar_bar_fill}
                style={{ width: loadingBaseData * 52.5 }}
              />
            </div>
            <div className={styles.progressBar_message}>
              {updates[loadingBaseData - 1]}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Spinner;
