import React from "react";
import theme from "../../theme.scss";

export const EnvelopeIcon = ({ size, color }) => {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      id="Envelope-Icon"
    >
      <g id="fi-rr-envelope">
        <path
          id="icon-envelope"
          d="M19 1.00031H5C3.67441 1.00189 2.40356 1.52919 1.46622 2.46653C0.528882 3.40386 0.00158786 4.67471 0 6.00031L0 18.0003C0.00158786 19.3259 0.528882 20.5967 1.46622 21.5341C2.40356 22.4714 3.67441 22.9987 5 23.0003H19C20.3256 22.9987 21.5964 22.4714 22.5338 21.5341C23.4711 20.5967 23.9984 19.3259 24 18.0003V6.00031C23.9984 4.67471 23.4711 3.40386 22.5338 2.46653C21.5964 1.52919 20.3256 1.00189 19 1.00031ZM5 3.00031H19C19.5988 3.00148 20.1835 3.18182 20.679 3.51811C21.1744 3.8544 21.5579 4.33125 21.78 4.88731L14.122 12.5463C13.5584 13.1076 12.7954 13.4228 12 13.4228C11.2046 13.4228 10.4416 13.1076 9.878 12.5463L2.22 4.88731C2.44215 4.33125 2.82561 3.8544 3.32105 3.51811C3.81648 3.18182 4.40121 3.00148 5 3.00031ZM19 21.0003H5C4.20435 21.0003 3.44129 20.6842 2.87868 20.1216C2.31607 19.559 2 18.796 2 18.0003V7.50031L8.464 13.9603C9.40263 14.8966 10.6743 15.4223 12 15.4223C13.3257 15.4223 14.5974 14.8966 15.536 13.9603L22 7.50031V18.0003C22 18.796 21.6839 19.559 21.1213 20.1216C20.5587 20.6842 19.7956 21.0003 19 21.0003Z"
          fill={color || theme.expected2}
        />
      </g>
    </svg>
  );
};
