import React from "react";

export const Avatar3 = ({ width, primaryColour, secondaryColour }) => {
  return (
    <svg width={width || 40} viewBox="0 0 40 40" fill="none" id="Avatar3">
      <rect width="40" height="40" rx="3.27273" fill={primaryColour} />

      <rect
        x="-43.0276"
        y="1.78132"
        width="83.7229"
        height="109.107"
        rx="41.8614"
        stroke={secondaryColour}
        strokeWidth="2.67201"
      />
      <rect
        x="-32.5669"
        y="11.3514"
        width="63.2467"
        height="90.4121"
        rx="31.6233"
        stroke={secondaryColour}
        strokeWidth="2.21749"
      />
      <rect
        x="-22.0721"
        y="20.9555"
        width="41.8118"
        height="74.7665"
        rx="20.9059"
        stroke={secondaryColour}
        strokeWidth="1.83102"
      />
    </svg>
  );
};
