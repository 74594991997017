import React from "react";
import theme from "../../../Styles/theme.scss";
import styles from "./styles.module.scss";
import PrimaryButton from "../../PrimaryButton";
import { DeleteReportIcon } from "../../../Styles/Icons/DesignSystem";

const ErrorCreatingReport = ({ onClickCancel, onClickTryAgain }) => {
  return (
    <div className={styles.loading}>
      <DeleteReportIcon />
      <h1>Unable to create report</h1>
      <p>
        Sorry, we were unable to create this report. Please try again. If this
        problem persists please contact support.
      </p>
      <div>
        <PrimaryButton
          style={{ borderRadius: 8 }}
          className={styles.button}
          border={`1px solid ${theme.primary3}`}
          padding={"9px 15px"}
          onClick={onClickTryAgain}
        >
          Try again
        </PrimaryButton>
        <PrimaryButton
          onClick={onClickCancel}
          style={{ marginLeft: 16, borderRadius: 8 }}
          backgroundColour={theme.white}
          className={styles.backButton}
          border={`1px solid ${theme.primary3}`}
          padding={"9px 15px"}
        >
          Cancel
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ErrorCreatingReport;
