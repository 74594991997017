import {
  getMinAndMax,
  getHoursAndMinutesAsString,
  getBucketNumber,
  getColorGradient,
} from "../";
import theme from "../../../Styles/theme.scss";

const getIsMovementDaytimeHighlighted = (value, expectedMin, filterName) => {
  const hasSelectedBelow = filterName === "below";
  const belowExpected = value < expectedMin;
  const isHighlighted = hasSelectedBelow && belowExpected;
  return isHighlighted;
};

export const handleDaytime = (data, filterName) => {
  return data?.map((day) => {
    const daytimeDuration = day?.durationMinutes?.dayTime ?? null;
    const daytimeExpectedMin = day?.expectedDurationMinutes?.daytimeMin;
    const daytimeExpectedMax = day?.expectedDurationMinutes?.daytimeMax;

    const isDayTimeActivityFilter = filterName === "dayTimeActivity";

    const arrayOfNighttimeDisturbances = isDayTimeActivityFilter
      ? [...data]?.map((day) => day.durationMinutes).filter((i) => i)
      : [];

    const MAX_DAYTIME_DURATION = isDayTimeActivityFilter
      ? getMinAndMax(arrayOfNighttimeDisturbances, "dayTime").max
      : 0;
    const MIN_DAYTIME_DURATION = isDayTimeActivityFilter
      ? getMinAndMax(arrayOfNighttimeDisturbances, "dayTime").min
      : 0;

    return {
      date: day?.date,
      dayRating: null, // TO DO: add day rating
      value: daytimeDuration,
      expectedMin: daytimeExpectedMin,
      expectedMax: daytimeExpectedMax,
      firstLineText:
        daytimeDuration === null
          ? "No data"
          : getHoursAndMinutesAsString(daytimeDuration, true),
      secondLineText: "",
      isHighlighted: getIsMovementDaytimeHighlighted(
        daytimeDuration,
        daytimeExpectedMin,
        filterName
      ),
      highlightColour: !daytimeDuration
        ? null
        : isDayTimeActivityFilter
        ? getColorGradient(
            getBucketNumber(daytimeDuration, MAX_DAYTIME_DURATION),
            theme.primary3
          )
        : null,
      max: MAX_DAYTIME_DURATION,
      min: MIN_DAYTIME_DURATION,
      noData: daytimeDuration === null,
    };
  });
};
