import { sliceIntoChunks } from "@intelligentlilli/lilli-utils";
import { format } from "date-fns";
import { getRangeViewStats, fillInMissingDays } from "./";

export const getChunkedRangeViewStats = (
  data,
  behaviourName,
  filterName,
  from,
  to
) => {
  const fullData = fillInMissingDays(data, from, to);
  const rangeViewStats = !fullData
    ? []
    : getRangeViewStats(fullData, behaviourName, filterName);

  // Add blanks to the sorted array
  const firstDay = rangeViewStats?.[0]?.date;
  const lastDay = rangeViewStats?.[rangeViewStats?.length - 1]?.date;
  const firstDayOfWeek = firstDay ? format(new Date(firstDay), "i") : "1";
  const lastDayOfWeek = lastDay ? format(new Date(lastDay), "i") : "1";
  const startArray = Array(parseInt(firstDayOfWeek) - 1).fill({
    isBlank: true,
  });
  const endArray = Array(7 - parseInt(lastDayOfWeek)).fill({ isBlank: true });
  const result = [...startArray, ...rangeViewStats, ...endArray];
  const chunks = 7; // 7 days per week
  const chunkedData = sliceIntoChunks(result, chunks);
  return chunkedData;
};
