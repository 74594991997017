import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
  bathroomDayDuration,
  bathroomDayVisits,
  bathroomNightVisits,
  bathingDuration,
  learnMore,
} from "../../Services/Utils/Insights";
import HowTheBehaviourWorks from "../HowTheBehaviourWorks";
import SUDayView from "../SUDayView";

const BehaviourBathroomDay = ({
  howItWorks,
  events,
  bathroomInference,
  date,
}) => {
  // Check if there is no data
  const noData = bathroomInference[0]?.activityData.length === 0;
  const [showLearnMore, setShowLearnMore] = useState(false);

  // Get the insights
  const bathroomDayInsight = bathroomDayVisits(
    bathroomInference,
    "Bathroom events",
    null
  );
  const bathroomNightInsight = bathroomNightVisits(
    bathroomInference,
    "Bathroom events at night",
    null,
    date
  );
  const bathroomDayDurationInsight = bathroomDayDuration(
    bathroomInference,
    "Total time spent in the bathroom",
    null
  );
  const bathingDurationInsight = bathingDuration(
    bathroomInference,
    "Time spent bathing or showering",
    null
  );
  const bathroomLearnMore = learnMore(() => setShowLearnMore(true));
  const insights = [
    bathroomDayInsight,
    bathroomNightInsight,
    bathroomDayDurationInsight,
    bathingDurationInsight,
    bathroomLearnMore,
  ];

  return showLearnMore ? (
    <div className={styles.container}>
      <HowTheBehaviourWorks
        behaviour="Bathroom"
        questionsAndAnswers={howItWorks}
        hide={() => setShowLearnMore(false)}
      />
    </div>
  ) : (
    <SUDayView
      currentTab={"Bathroom"}
      events={events}
      noData={noData}
      isBathroom={true}
      insightsContext={
        "This data shows all bathroom events. Bathing events are events that are likely to be a shower or bath."
      }
      insights={insights}
      isNewVariant={false}
      isNighttime={false}
      noSplitting={false}
    ></SUDayView>
  );
};

export default BehaviourBathroomDay;
