type NotificationUserActions = Record<string, string>;

export const userHasReadNotification = (
  notification: { userActions: NotificationUserActions },
  userId: string
): boolean => {
  let readNotification = false;

  Object.keys(notification.userActions).forEach((key) => {
    // For some reason the user id we get from /authorised has all lower case letters, whereas the get triage-notificatoins endpoint puts the occasional uppercase letter in the userActions object keys
    if (key.toLowerCase() === userId.replace(/-/g, "").toLowerCase()) {
      readNotification = true;
    }
  });

  return readNotification;
};
