import styles from "./styles.module.scss";
import { getTimePeriodAndDetail } from "../../Services/Utils";
import { format } from "date-fns";

const BehaviourDetails = ({
  behaviourSentences,
  selectedBehaviour,
  endDate,
}) => {
  const formattedDate = format(new Date(endDate), "dd.MM.yy");

  const getSentences = (behaviourSentences, chosenBehaviour) => {
    if (chosenBehaviour === "bathroom") {
      return behaviourSentences;
    }

    const result = behaviourSentences?.map((sentence) => ({
      time: getTimePeriodAndDetail(sentence, chosenBehaviour).time,
      sentence:
        chosenBehaviour === "sleep"
          ? sentence
          : getTimePeriodAndDetail(sentence, chosenBehaviour).detail,
    }));
    return result;
  };

  const behaviourDetails = getSentences(behaviourSentences, selectedBehaviour);

  const allOkaySentence =
    selectedBehaviour === "bathroom"
      ? "No bathroom events"
      : selectedBehaviour === "sleep"
      ? "No unusually disturbed nights of sleep have been detected"
      : "No anomalies detected";

  return (
    <section className={styles.behaviour_details_container}>
      <div className={styles.behaviour_details_header}>
        <div>Details</div>
        <div className={styles.detail_date}>{formattedDate}</div>
      </div>
      <div className={styles.behaviour_details_body}>
        {/* Has sentences */}
        {behaviourDetails?.length > 0 &&
          behaviourDetails?.map((detail, index) => (
            <div className={styles.behaviour_detail_item} key={index}>
              {selectedBehaviour !== "sleep" && (
                <div className={styles.behaviour_details_time}>
                  {detail?.time}
                </div>
              )}
              <div className={styles.sentence_container}>
                <div className={styles.behaviour_details_sentence}>
                  {detail?.sentence}
                </div>
                {detail?.duration && (
                  <div className={styles.behaviour_duration}>
                    {detail?.duration}
                  </div>
                )}
              </div>
            </div>
          ))}

        {/* No sentences */}
        {behaviourDetails?.length === 0 && (
          <div className={styles.behaviour_detail_item}>
            <div className={styles.behaviour_details_sentence}>
              {allOkaySentence}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BehaviourDetails;
