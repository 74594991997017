import React from "react";
import styles from "./styles.module.scss";
import { DownArrow, SleepIcon, UpArrow } from "../../Styles/Icons/DesignSystem";
import theme from "../../Styles/theme.scss";

const InsightNighttimeComparison = ({
  noChange,
  isIncrease,
  isDecrease,
  cannotCalculate,
  amountChange,
  dateType,
  insightContext,
  insightCompareValues,
  daysWithNoData,
}) => {
  const daysWithNoDataText =
    daysWithNoData > 0
      ? `${daysWithNoData} day${daysWithNoData === 1 ? "" : "s"} with no data`
      : null;
  return (
    <div className={styles.insight}>
      <div className={styles.insight_top}>
        <SleepIcon size={22} />
        Compared to
        <span style={{ width: "8px" }} />
        <span className={styles.underline}>{` last ${dateType}`}</span>
      </div>
      <div className={styles.insight_data}>
        {!noChange && isIncrease && (
          <UpArrow color={theme.neutral7} percentChange={3} />
        )}
        {!noChange && isDecrease && (
          <DownArrow color={theme.neutral7} percentChange={3} />
        )}
        <div
          className={
            noChange || cannotCalculate
              ? styles.insight_no_change
              : styles.insight_amount_change
          }
        >
          {amountChange}
        </div>
      </div>
      <p>
        {insightContext}{" "}
        <span className={styles.underline}>{insightCompareValues}</span>
      </p>
      {!!daysWithNoData && (
        <p>
          There {daysWithNoData === 1 ? "was" : "were"}:{" "}
          <span className={styles.underline}>{daysWithNoDataText}</span>
        </p>
      )}
    </div>
  );
};

export default InsightNighttimeComparison;
