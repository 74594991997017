export const getServiceUserPageURL = (
  activeTab: {
    tab: string;
    tab1?: string | null;
    tab2?: string | null;
  },
  from: string,
  to: string,
  dateType?: string
): string => {
  const tab1 = activeTab?.tab1?.toLowerCase();
  const tab2 = activeTab?.tab2?.toLowerCase().includes("other") ? "other" : "risk";

  const timePeriod = dateType ? dateType : "week";

  switch (activeTab.tab) {
    case "devices":
      return `?tab=${activeTab.tab}`;
    case "sustenance":
      return `?tab=${activeTab.tab}&tab1=${tab1}&tab2=${tab2}&view=${timePeriod}&start=${from}&end=${to}`;
    default:
      return `?tab=${activeTab.tab}&view=${timePeriod}&start=${from}&end=${to}`;
  }
};
