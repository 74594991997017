import React from "react";
import styles from "./styles.module.scss";

function PageTitle({ title }) {
  return (
    <div className={styles.pageTitleContainer}>
      <h1 className={styles.pageTitle_title}>{title}</h1>
    </div>
  );
}

export default PageTitle;
