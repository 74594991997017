export const getSentenceSeperator = (array, finalSeperator) =>
  array?.reduce((acc, role, index) => {
    const seperator =
      index === array.length - 2
        ? ` ${finalSeperator} `
        : index === array.length - 1
        ? ""
        : ", ";
    return acc + role + seperator;
  }, "");
