import React from "react";
import theme from "../../theme.scss";

export const AtRiskIcon = ({ width, colour }) => {
  return (
    <svg width={width || 50} viewBox="0 0 50 50" id="AtRiskIcon">
      <path
        d="M25.0162 1.66016C24.1313 1.66016 23.2827 2.01168 22.6569 2.63741C22.0312 3.26313 21.6797 4.1118 21.6797 4.9967V31.6891C21.6797 32.574 22.0312 33.4227 22.6569 34.0484C23.2827 34.6741 24.1313 35.0256 25.0162 35.0256C25.9011 35.0256 26.7498 34.6741 27.3755 34.0484C28.0013 33.4227 28.3528 32.574 28.3528 31.6891V4.9967C28.3528 4.1118 28.0013 3.26313 27.3755 2.63741C26.7498 2.01168 25.9011 1.66016 25.0162 1.66016Z"
        fill={colour || theme.white}
      />
      <path
        d="M28.3528 45.0348C28.3528 43.1921 26.859 41.6982 25.0162 41.6982C23.1735 41.6982 21.6797 43.1921 21.6797 45.0348C21.6797 46.8775 23.1735 48.3713 25.0162 48.3713C26.859 48.3713 28.3528 46.8775 28.3528 45.0348Z"
        fill={colour || theme.white}
      />
    </svg>
  );
};
