import React from "react";
import styles from "./styles.module.scss";
import { capitalize } from "@intelligentlilli/lilli-utils";

// Notes:
// props.activeTab is a useState() variable (type = object) stored in the parent component "LilliUser"
// activeTab = {
//     tab: 'overview', 'sustenance', 'devices', etc.)
// }

const NavTab = (props) => {
  const activeTab = props.activeTab;
  const tabName = props.label;

  const tabDisplayName =
    tabName.toLowerCase() === "sleep"
      ? "Night-time"
      : tabName.toLowerCase() === "sustenance"
      ? "Eating & Drinking"
      : tabName.toLowerCase() === "independence"
      ? "Time Outside"
      : capitalize(props.label);

  const isActiveTab = activeTab.tab === tabName;

  return (
    <div
      className={`${styles.tab_name} ${
        !isActiveTab ? `${styles.inactive}` : `${styles.active}`
      }`}
      onClick={() => props.onChangeNavTab(tabName)}
    >
      {tabDisplayName}
    </div>
  );
};

export default NavTab;
