import { isTemperatureConcerning } from "../isTemperatureConcerning";

export const temperatureRangeInsight = (temperatureData) => {
  if (!temperatureData || temperatureData.length === 0) {
    return {
      offline: true,
      title: "Home temperature",
      icon: "TEMPERATURE",
      context: "No data available.",
    };
  }

  const daysWithConcerningTemp = temperatureData.filter((day) =>
    isTemperatureConcerning(day)
  ).length;

  return {
    title: "Concerning temperature",
    headline: `${daysWithConcerningTemp} day${
      daysWithConcerningTemp === 1 ? "" : "s"
    }`,
    icon: "TEMPERATURE",
    context:
      "Concerning temperature is a day where the average temperature was either above 26°C or below 18°C during the past 48 hours.",
    // onClick: () => null,
  };
};
