export const getHoursAndMinutesAsString = (value, shouldShorten) => {
  if (!value && value !== 0) {
    return null;
  }

  if (value === 0) {
    return shouldShorten ? "0m" : "0 min";
  }

  if (value === 60) {
    return shouldShorten ? "1h" : "1 hour";
  }

  if (value % 60 === 0 && value > 60) {
    return `${value / 60}${shouldShorten ? "h" : " hours"}`;
  }

  if (value >= 0 && value < 60) {
    return `${value}${shouldShorten ? "m" : " min"}`;
  }

  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  return `${hours}${
    shouldShorten ? "h" : hours === 1 ? " hour" : " hours"
  } ${minutes}${shouldShorten ? "m" : " min"}`;
};
