import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
// Components
import { CancelOutlinedIcon } from "../../Styles/Icons";
import Card from "../Card";
// Forms
import ChangePasswordForm from "../../Forms/ChangePasswordForm";
import ChangeNameForm from "../../Forms/ChangeNameForm";
import ChangeEmailForm from "../../Forms/ChangeEmailForm";
import ChangePhoneNumForm from "../../Forms/ChangePhoneNumForm";

const EditUserProfile = (props) => {
  return (
    <Card style={styles.profile}>
      <CancelOutlinedIcon
        className={styles.profile_close}
        onClick={() => props.close()}
      />
      <div className={styles.profile_element}>
        <div className={styles.profile_password}>
          <ChangeNameForm
            onSubmit={props.onSubmitNameChange}
            error={props.changeNameError}
            forenames={props.user?.forenames}
            surname={props.user?.surname}
          />
        </div>
        <div className={styles.profile_password}>
          <ChangeEmailForm
            onSubmit={props.onSubmitEmailChange}
            error={props.changeEmailError}
            email={props.user?.email}
          />
        </div>
        <div className={styles.profile_password}>
          <ChangePhoneNumForm
            onSubmit={props.onSubmitPhoneNumChange}
            error={props.changePhoneNumError}
            phone={props.user?.properties?.primaryPhone}
          />
        </div>
        <div className={styles.profile_password}>
          <ChangePasswordForm
            onSubmit={props.onSubmitPasswordChange}
            error={props.changePasswordError}
          />
        </div>
      </div>
    </Card>
  );
};

EditUserProfile.propTypes = {
  close: PropTypes.func,
  user: PropTypes.object,
  changeNameError: PropTypes.string,
  changeEmailError: PropTypes.string,
  changePasswordError: PropTypes.string,
  onSubmitPasswordChange: PropTypes.func,
};

export default EditUserProfile;
