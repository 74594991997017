import React from "react";
import styles from "./styles.module.scss";
import InstallationHint from "../../InstallationHint";

const DesktopTips = ({ instructions }) => {
  return (
    <div className={styles.desktop_instructions}>
      <div className={styles.top}>
        {instructions.map((item, index) => {
          if (item.hint) {
            return <InstallationHint hints={item.hint} key={index} />;
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default DesktopTips;
