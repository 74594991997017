import React, { useState } from "react";
import styles from "./styles.module.scss";
import DashboardFilter from "../DashboardFilter";
import DashboardSearchInput from "../DashboardSearchInput";
import DashboardTabs from "../DashboardTabs";
import DashboardButtons from "../DashboardButtons";

const DashboardFilterAndSearch = ({
  selectedTab,
  searchValue,
  selectedFilter,
  setSelectedTab,
  setSearchValue,
  setSelectedFilter,
  userIsJustAnInstaller,
}) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  return (
    <div className={styles.dashboard_filter_and_search_container}>
      <div className={styles.search_input_container}>
        {selectedTab === "users" && (
          <DashboardFilter
            selectedTab={selectedTab}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            setShowFilterMenu={setShowFilterMenu}
            showFilterMenu={showFilterMenu}
            userIsJustAnInstaller={userIsJustAnInstaller}
          />
        )}
        <DashboardSearchInput
          selectedTab={selectedTab}
          value={searchValue}
          setValue={setSearchValue}
        />
      </div>
      <div className={styles.search_tabs_and_buttons_container}>
        <DashboardTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <DashboardButtons selectedTab={selectedTab} />
      </div>
    </div>
  );
};

export default DashboardFilterAndSearch;
