import React from "react";
import styles from "./styles.module.scss";
import { useViewport } from "../ViewportProvider";

const DaysOfTheWeek = ({ isMobile }) => {
  const { width } = useViewport();
  const abbreviated = ["M", "T", "W", "Th", "F", "S", "S"];
  const shortened = ["Mon", "Tue", "Wed", "Th", "Fri", "Sat", "Sun"];
  const daysOfTheWeek = width <= 500 ? abbreviated : shortened;

  return (
    <div className={styles.days_of_the_week}>
      {daysOfTheWeek.map((day, index) => (
        <div
          className={
            isMobile
              ? styles.day_of_the_week_mobile
              : styles.day_of_the_week_desktop
          }
          key={index}
        >
          <p>{day}</p>
        </div>
      ))}
    </div>
  );
};

export default DaysOfTheWeek;
