import { format, addHours } from "date-fns";
import { getDaytimeEvents } from "./getDaytimeEvents";

export const getNewDaytimeEvents = (
  daytimeEvents,
  urlEndDateString,
  morningStart
) => {
  const {
    morningEvents,
    afternoonEvents,
    eveningEvents,
    afternoonStart,
    eveningStart,
  } = getDaytimeEvents(daytimeEvents, urlEndDateString, morningStart);

  const formattedMorningStartTime =
    format(new Date(morningStart), "haaa") || "6am";
  const formattedAfternoonStartTime =
    format(new Date(afternoonStart), "haaa") || "12pm";
  const formattedEveningStartTime =
    format(new Date(eveningStart), "haaa") || "6pm";

  const formattedEveningEndTime = "10pm";

  // The format required for the EventLogSection component
  const formattedMorningEvents = {
    events: morningEvents,
    start: new Date(morningStart),
    end: new Date(afternoonStart),
    sectionTitleFull: `Morning: ${formattedMorningStartTime} - ${formattedAfternoonStartTime}`,
    sectionTitlePartial: `Morning: ${formattedMorningStartTime} - now`,
  };
  const formattedAfternoonEvents = {
    events: afternoonEvents,
    start: new Date(afternoonStart),
    end: new Date(eveningStart),
    sectionTitleFull: `Afternoon: ${formattedAfternoonStartTime} - ${formattedEveningStartTime}`,
    sectionTitlePartial: `Afternoon: ${formattedAfternoonStartTime} - now`,
  };
  const formattedEveningEvents = {
    events: eveningEvents,
    start: new Date(eveningStart),
    end: addHours(new Date(eveningStart), 4),
    sectionTitleFull: `Evening: ${formattedEveningStartTime} - ${formattedEveningEndTime}`,
    sectionTitlePartial: `Evening: ${formattedEveningStartTime} - now`,
  };

  return [
    formattedMorningEvents,
    formattedAfternoonEvents,
    formattedEveningEvents,
  ];
};
