// This import adds IE9 and above polyfill. Further language features can be supported if required see:
// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import "react-app-polyfill/ie9";
import React from "react";
import { createRoot } from "react-dom/client";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// Redux state
import { Provider } from "react-redux";
import { store } from "./State/store";
// Components & styles
import App from "./App";
import LoadingHOC from "./Components/LoadingHOC";
import { ViewportProvider } from "./Components/ViewportProvider";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./Styles/material-ui-theme";
import "./Styles/reset.scss"; // This allows all default styling to be reset and actively managed thereafter.
import "./Styles/base.scss";
import ScrollToTop from "./Components/ScrollToTop";
import LogRocket from "logrocket";

if (
  process.env.NODE_ENV === "production" &&
  window.location.origin === "https://app.intelligentlilli.com"
) {
  LogRocket.init("nkaflr/lilli-web", {
    network: {
      isEnabled: false,
    },
  });
  Sentry.init({
    dsn: "https://effd6ce625ae4b31af8cc93f91e11e3a@o506894.ingest.sentry.io/5597206",
    autoSessionTracking: true,
    // TO DO: Browser tracing does not seem to have been set up, see docs here:
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/instrumentation/automatic-instrumentation/
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// Hack to allow us to host on S3 without having fully-fledged SSR on Lambda or something
// https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3

// Set up the browser history with the updated location
// (minus the # sign)
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  window.location.hash = "";
  window.history.replaceState({}, "", path);
}

// Creating the root node for react
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <React.StrictMode>
            <LoadingHOC>
              <ViewportProvider>
                <ScrollToTop />
                <App />
              </ViewportProvider>
            </LoadingHOC>
          </React.StrictMode>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results  (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// We can hook this up to Google analytics in the future for now just logging the output.
// reportWebVitals(console.log);
