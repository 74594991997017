import React from "react";
import theme from "../../theme.scss";

export const MicrowaveIcon = ({ color, width }) => {
  const defaultColor = theme.neutral7;
  return (
    <svg
      width={width || "26"}
      height={width || "26"}
      viewBox="0 0 26 26"
      fill="none"
      id="MicrowaveIcon"
    >
      <path
        d="M24.375 4.33325H1.625C0.729066 4.33325 0 5.06232 0 5.95825V20.0416C0 20.9375 0.729066 21.6666 1.625 21.6666H24.375C25.2709 21.6666 26 20.9375 26 20.0416V5.95825C26 5.06237 25.2709 4.33325 24.375 4.33325ZM19.5 20.5833H1.625C1.326 20.5833 1.08332 20.3395 1.08332 20.0416V5.95825C1.08332 5.65925 1.326 5.41657 1.625 5.41657H19.5V20.5833ZM24.9167 20.0416C24.9167 20.3396 24.674 20.5833 24.375 20.5833H20.5833V5.41662H24.375C24.674 5.41662 24.9167 5.6593 24.9167 5.9583V20.0416Z"
        fill={color || defaultColor}
      />
      <path
        d="M22.7499 8.66663C23.3482 8.66663 23.8333 8.18162 23.8333 7.58332C23.8333 6.98502 23.3482 6.5 22.7499 6.5C22.1516 6.5 21.6666 6.98502 21.6666 7.58332C21.6666 8.18162 22.1516 8.66663 22.7499 8.66663Z"
        fill={color || defaultColor}
      />
      <path
        d="M22.7499 11.9166C23.3482 11.9166 23.8333 11.4316 23.8333 10.8333C23.8333 10.235 23.3482 9.75 22.7499 9.75C22.1516 9.75 21.6666 10.235 21.6666 10.8333C21.6666 11.4316 22.1516 11.9166 22.7499 11.9166Z"
        fill={color || defaultColor}
      />
      <path
        d="M22.7499 15.1666C23.3482 15.1666 23.8333 14.6816 23.8333 14.0833C23.8333 13.485 23.3482 13 22.7499 13C22.1516 13 21.6666 13.485 21.6666 14.0833C21.6666 14.6816 22.1516 15.1666 22.7499 15.1666Z"
        fill={color || defaultColor}
      />
      <path
        d="M3.79168 18.4166H16.7917C17.0907 18.4166 17.3334 18.1739 17.3334 17.8749V8.12494C17.3334 7.82594 17.0907 7.58325 16.7917 7.58325H3.79168C3.49268 7.58325 3.25 7.82594 3.25 8.12494V17.8749C3.25 18.1739 3.49268 18.4166 3.79168 18.4166ZM4.33332 8.66662H16.25V17.3333H4.33332V8.66662Z"
        fill={color || defaultColor}
      />
    </svg>
  );
};
