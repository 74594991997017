import React from "react";
import styles from "./styles.module.scss";
// Components
import NewStyleModal from "../NewStyleModal";
import MovementBreakdown from "../MovementBreakdown";

const MovementComparisonModal = ({
  currentData,
  previousData,
  hide,
  showAnimation,
  dateType,
  behaviourName,
}) => {
  const title = `${behaviourName} compared to last ${dateType}`;
  const context = "Here you can explore the details of this insight";
  const footnote =
    "*All time spent moving are estimates based on events triggered";
  const shouldHideForNow = true;

  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={hide}
      title={title}
      context={context}
      useFade={true}
      showAnimation={showAnimation}
    >
      <div className={styles.comparison_modal_wrapper}>
        <div className={styles.comparison_modal}>
          <div className={styles.breakdown}>
            <MovementBreakdown data={previousData} dateType={dateType} />
          </div>
          <div className={styles.breakdown}>
            <MovementBreakdown
              isCurrentPeriod
              data={currentData}
              dateType={dateType}
            />
          </div>
        </div>
        {!shouldHideForNow && <p className={styles.footnote}>{footnote}</p>}
      </div>
    </NewStyleModal>
  );
};

export default MovementComparisonModal;
