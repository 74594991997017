import React from "react";
import styles from "./styles.module.scss";

const Backdrop = ({ show, className, onClick }) => {
  return (
    show && (
      <div
        className={`${styles.backdrop} ${className}`}
        onClick={onClick}
        data-test-id="backdrop"
      />
    )
  );
};

export default Backdrop;
