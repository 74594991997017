import React from "react";
import styles from "./styles.module.scss";

const Tooltip = ({ children, top, left }) => {
  return (
    <div
      className={styles.tooltip}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ top, left }}
    >
      {children}
    </div>
  );
};

export default Tooltip;
