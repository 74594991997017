import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import ServiceUserListItem from "../ServiceUserListItem";
import styles from "./styles.module.scss";
import SecondaryButton from "../SecondaryButton";
import { sortHigh, sortLow } from "@intelligentlilli/lilli-utils";
import {
  ImportExportIcon,
  ArrowDownwardIcon,
  ArrowUpwardIcon,
} from "../../Styles/Icons";

const AssignServiceUser = ({
  setAddServiceUserAssociation,
  addServiceUserAssociation,
  serviceUsersAvailable,
  assignServiceUser,
  serviceUsersData,
}) => {
  const [sortAZ, setSortAZ] = useState();
  const [sortStaff, setSortStaff] = useState();

  // Variable for sorting the hubs (service users)
  let sortedSUs = serviceUsersAvailable;
  if (sortAZ === true) {
    sortedSUs = sortHigh(serviceUsersAvailable, "userstring");
  } else if (sortAZ === false) {
    sortedSUs = sortLow(serviceUsersAvailable, "userstring");
  }
  if (sortStaff === true) {
    sortedSUs = sortHigh(serviceUsersAvailable, "viewers");
  } else if (sortStaff === false) {
    sortedSUs = sortLow(serviceUsersAvailable, "viewers");
  }

  return (
    <Modal
      show={addServiceUserAssociation}
      hide={() => setAddServiceUserAssociation(false)}
      className={styles.modal}
    >
      <div className={styles.addServiceUser}>
        <div className={styles.addServiceUser_filters}>
          <SecondaryButton
            startIcon={
              sortAZ === true ? (
                <ArrowDownwardIcon />
              ) : sortAZ === false ? (
                <ArrowUpwardIcon />
              ) : (
                <ImportExportIcon />
              )
            }
            onClick={() => {
              setSortStaff(null);
              setSortAZ(!sortAZ);
            }}
          >
            Sort by: A-Z
          </SecondaryButton>
          <SecondaryButton
            startIcon={
              sortStaff === true ? (
                <ArrowDownwardIcon />
              ) : sortStaff === false ? (
                <ArrowUpwardIcon />
              ) : (
                <ImportExportIcon />
              )
            }
            onClick={() => {
              setSortAZ(null);
              setSortStaff(!sortStaff);
            }}
          >
            Sort by: Num staff
          </SecondaryButton>
        </div>
        <div className={styles.addServiceUser_header}>
          <div>Service user</div>
          <div>Staff</div>
        </div>
        <div className={styles.addServiceUser_SUs}>
          {sortedSUs?.length > 0 ? (
            sortedSUs?.map((serviceUser, index) => (
              <ServiceUserListItem
                className={styles.addServiceUser_SU}
                key={index}
                serviceUser={serviceUser}
                onClick={() => assignServiceUser(serviceUser.id)}
                hubData={serviceUsersData[serviceUser.id]}
              />
            ))
          ) : (
            <h3>No service users available</h3>
          )}
        </div>
      </div>
    </Modal>
  );
};

AssignServiceUser.propTypes = {
  setAddServiceUserAssociation: PropTypes.func,
  addServiceUserAssociation: PropTypes.bool,
  serviceUsersAvailable: PropTypes.array,
  assignServiceUser: PropTypes.func,
  serviceUsersData: PropTypes.object,
};

export default AssignServiceUser;
