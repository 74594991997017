import React from "react";
import theme from "../../theme.scss";

export const AwaitingInstallIcon = ({ colour, width }) => {
  return (
    <svg
      width={width || 20}
      height={width || 20}
      fill="none"
      id="AwaitingInstallIcon"
    >
      <g>
        <path
          d="M10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.98891 19.8078 8.0491C19.422 6.10929 18.4696 4.32746 17.0711 2.92893C15.6725 1.53041 13.8907 0.577999 11.9509 0.192146C10.0111 -0.193706 8.00042 0.00432703 6.17316 0.761203C4.3459 1.51808 2.78412 2.7998 1.6853 4.44429C0.586487 6.08879 -3.2444e-06 8.02218 -3.41731e-06 10C0.00286392 12.6513 1.05735 15.1932 2.93209 17.0679C4.80684 18.9426 7.34871 19.9971 10 20ZM10 1.66666C11.6482 1.66667 13.2593 2.15541 14.6297 3.07109C16.0002 3.98676 17.0683 5.28825 17.699 6.81097C18.3297 8.33369 18.4948 10.0092 18.1732 11.6258C17.8517 13.2423 17.058 14.7271 15.8926 15.8926C14.7271 17.058 13.2423 17.8517 11.6257 18.1732C10.0092 18.4948 8.33368 18.3297 6.81097 17.699C5.28825 17.0683 3.98676 16.0002 3.07108 14.6298C2.15541 13.2593 1.66666 11.6482 1.66666 10C1.66909 7.79061 2.54784 5.6724 4.11012 4.11012C5.6724 2.54784 7.7906 1.66909 10 1.66666Z"
          fill={colour || theme.secondary3}
        />
        <path
          d="M10 15.833C10.2211 15.833 10.433 15.7452 10.5893 15.5889C10.7456 15.4326 10.8334 15.2207 10.8334 14.9997L10.8334 8.33301C10.8334 8.11199 10.7456 7.90003 10.5893 7.74375C10.433 7.58747 10.2211 7.49967 10 7.49967C9.77903 7.49967 9.56707 7.58747 9.41079 7.74375C9.25451 7.90003 9.16671 8.11199 9.16671 8.33301L9.16671 14.9997C9.16671 15.2207 9.2545 15.4326 9.41079 15.5889C9.56707 15.7452 9.77903 15.833 10 15.833Z"
          fill={colour || theme.secondary3}
        />
        <path
          d="M9.16671 4.99967C9.16671 5.45991 9.5398 5.83301 10 5.83301C10.4603 5.83301 10.8334 5.45991 10.8334 4.99967C10.8334 4.53944 10.4603 4.16634 10 4.16634C9.5398 4.16634 9.16671 4.53944 9.16671 4.99967Z"
          fill={colour || theme.secondary3}
        />
      </g>
    </svg>
  );
};
