import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
// Reports
import General from "../../Reports/General";
// Hooks
import { usePrepareReportData } from "../../Services/Hooks";
// Utils
import { addDays, parseISO } from "date-fns";

function PdfGenerator() {
  const [data, setData] = useState();

  // State readiness
  const hasData = data !== undefined || null;

  // Get the report data from local storage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("report"));
    setData(data);
  }, []);

  // to and from dates for the report
  const from =
    data?.report?.reportstartdate && parseISO(data?.report?.reportstartdate);
  const to = from && addDays(from, data?.report?.days - 1);

  // Get an array of the dates in the report date range
  const dates = [];
  let dateFrom = from;
  while (dateFrom <= to) {
    dates.push(new Date(dateFrom));
    dateFrom = addDays(dateFrom, 1);
  }

  // When the data has been fetched prepare it for the reports
  const preparedData = usePrepareReportData(data, dates);

  // Prepare the props that will get passed to the report
  const props = {
    user: data?.user,
    SU: data?.hub,
    SUPhoto: data?.photo?.location,
    SUProperties: data?.properties,
    SURiskScores: data?.dayRatings,
    pdfStyle: styles.pdf,
    movementData: preparedData?.movementData,
    independenceData: preparedData?.independenceData,
    sustenanceData: preparedData?.sustenanceData,
    sleepData: preparedData?.sleepData,
    // temperatureData: preparedData?.temperatureData,
    dates: dates,
    from: from,
    to: to,
    report: data?.report,
  };

  return (
    <div id="report" className={styles.page}>
      {hasData && <General {...props} />}
    </div>
  );
}

export default PdfGenerator;
