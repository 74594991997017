import React from "react";
import styles from "./styles.module.scss";

const DesktopInstructions = ({ instructions }) => {
  return (
    <div className={styles.desktop_instructions}>
      <ol className={styles.instructions_steps}>
        {instructions.map((item, index) => {
          if (item.text) {
            return <li key={index}>{item.text}</li>;
          }
          if (item.image) {
            return (
              <div className={styles.desktop_image_container}>
                <img
                  key={index}
                  src={item.image.image}
                  alt={item.image.alt}
                  style={{
                    maxHeight: `${item?.image.height}px`,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                />
              </div>
            );
          }
          if (item.note) {
            return (
              <div className={styles.note} key={index}>
                Note: <span>{item.note}</span>
              </div>
            );
          }
          if (item.description) {
            return (
              <div key={index} className={styles.description}>
                {item.description}
              </div>
            );
          }
          return null;
        })}
      </ol>
    </div>
  );
};

export default DesktopInstructions;
