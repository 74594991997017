import { useEffect, useState } from "react";
import {
  CalendarIcon,
  UsersIcon,
  ReportsIcon,
} from "../../Styles/Icons/DesignSystem";
import theme from "../../Styles/theme.scss";

const getSteps = (currentStepIndex, serviceUser, reportType) => {
  return [
    {
      label: serviceUser || "Select Lilli user",
      icon: <UsersIcon width={20} colour={theme.neutral7} />,
    },
    {
      label: currentStepIndex > 1 ? reportType : "Select report type",
      icon: (
        <ReportsIcon
          width={20}
          colour={currentStepIndex > 0 ? theme.neutral7 : theme.neutral5}
        />
      ),
    },
    {
      label: "Select timeframe",
      icon: (
        <CalendarIcon
          width={20}
          colour={currentStepIndex > 1 ? theme.neutral7 : theme.neutral5}
        />
      ),
    },
  ];
};

export default function useCreateReportSteps(
  serviceUser,
  reportType,
  skipSUSelection
) {
  const [currentStepIndex, setCurrentStepIndex] = useState(
    skipSUSelection ? 1 : 0
  );
  const [steps, setSteps] = useState(getSteps(currentStepIndex));

  useEffect(() => {
    setSteps(getSteps(currentStepIndex, serviceUser, reportType));
  }, [currentStepIndex, serviceUser, reportType]);

  return { steps, currentStepIndex, setCurrentStepIndex };
}
