import { format } from "date-fns";
import { getSortByObjectKey } from "../getSortByObjectKey";

export const getEventLogData = (events, includeBathroom = false) => {
  // Sort the events into a single array
  let formattedEvents = [];
  let bathroomEvents = [];
  let sortedBathroomTimeStamps = [];

  if (events?.["bathroom.wall.motion"]?.length > 0) {
    const bathroomCopy = [...events?.["bathroom.wall.motion"]];

    bathroomEvents = bathroomEvents.concat(
      bathroomCopy?.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "bathroom",
      }))
    );

    sortedBathroomTimeStamps = sortedBathroomTimeStamps.concat(
      bathroomCopy?.sort((a, b) =>
        new Date(a) < new Date(b) ? -1 : new Date(a) > new Date(b) ? 1 : 0
      )
    );
  }
  if (events?.["hall.wall.motion"]) {
    formattedEvents = formattedEvents.concat(
      events?.["hall.wall.motion"]?.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "movement",
      }))
    );
  }
  if (events?.["kitchen.fridge.door"]) {
    formattedEvents = formattedEvents.concat(
      events?.["kitchen.fridge.door"]?.openedAt.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "fridge",
      }))
    );
  }

  /// Front door
  if (events?.["hall.front.door"]) {
    formattedEvents = formattedEvents.concat(
      events?.["hall.front.door"]?.openedAt.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "door opened",
      }))
    );
  }

  if (events?.["hall.front.door"]) {
    formattedEvents = formattedEvents.concat(
      events?.["hall.front.door"]?.closedAt.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "door closed",
      }))
    );
  }

  /// Main door
  if (events?.["hall.main.door"]) {
    formattedEvents = formattedEvents.concat(
      events?.["hall.main.door"]?.openedAt.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "door opened",
      }))
    );
  }

  if (events?.["hall.main.door"]) {
    formattedEvents = formattedEvents.concat(
      events?.["hall.main.door"]?.closedAt.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "door closed",
      }))
    );
  }

  //// Back door
  if (events?.["hall.back.door"]) {
    formattedEvents = formattedEvents.concat(
      events?.["hall.back.door"]?.openedAt.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "door opened",
      }))
    );
  }

  if (events?.["hall.back.door"]) {
    formattedEvents = formattedEvents.concat(
      events?.["hall.back.door"]?.closedAt.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "door closed",
      }))
    );
  }

  if (events?.["bedroom.wall.motion"]) {
    formattedEvents = formattedEvents.concat(
      events?.["bedroom.wall.motion"]?.map((eventTime) => ({
        startedAt: eventTime,
        timePeriod: format(new Date(eventTime), "h:mmaaa"),
        type: "bedroom motion",
      }))
    );
  }

  // Bathroom
  if (includeBathroom) {
    formattedEvents = formattedEvents.concat(bathroomEvents);
  }
  // Sort the combined events by time
  const sortedEvents = getSortByObjectKey(formattedEvents, "startedAt");

  return {
    allMotionEvents: sortedEvents || [],
    bathroomTimeStamps: sortedBathroomTimeStamps || [],
  };
};
