import { getHoursAndMinutesAsString } from "../getHoursAndMinutesAsString";

const filterAfterHour = (arrayOfObjs, timeAsNumber) => {
  return arrayOfObjs?.filter((obj) => {
    const time = new Date(obj?.ts);

    return time?.getHours() >= timeAsNumber;
  });
};

const filterBeforeHour = (arrayOfObjs, timeAsNumber) => {
  return arrayOfObjs?.filter((obj) => {
    const time = new Date(obj?.ts);
    return time?.getHours() < timeAsNumber;
  });
};

export const getTimeOutsideAtNight = (
  independenceData,
  independenceDataPrevious,
  userString
) => {
  // peice together 10pm to 6am data using yesterday and today's data
  const tenPM = 22;
  const sixAM = 6;
  const yesterdayTenToMidnight =
    filterAfterHour(independenceDataPrevious, tenPM) || [];
  const todayUntilSixAm = filterBeforeHour(independenceData, sixAM) || [];
  const tenTillSixAM = yesterdayTenToMidnight.concat(todayUntilSixAm);
  // sum the time outside at night
  const timeOutsideAtNight = tenTillSixAM.reduce((acc, curr) => {
    return acc + (curr?.value || 0);
  }, 0);
  // convert the time outside at night to a string
  const timeOutsideAtNightString = getHoursAndMinutesAsString(
    timeOutsideAtNight,
    false
  );

  const didNotLeaveTheHouse = timeOutsideAtNight === 0;

  const dataPresent = !!independenceData;
  if (!dataPresent) {
    return {
      offline: true,
      title: "Time outside at night",
      icon: "OFFLINE",
      context: "No data available for this insight",
    };
  }

  return {
    title: "Time outside at night",
    headline: timeOutsideAtNightString,
    icon: "INDEPENDENCE-NIGHT",
    context: didNotLeaveTheHouse
      ? `Between 10pm yesterday and 6am today, ${userString} did not leave the house.`
      : `Between 10pm yesterday and 6am today, ${userString} left the house for ${timeOutsideAtNightString}.`,
  };
};
