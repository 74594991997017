export const nighttimeRangeDisturbances = (movementV3Data, onClick) => {
  // Calculate the number of disturbed nights
  const disturbedNights = movementV3Data?.filter(
    (day) =>
      day?.durationMinutes?.nightTime !== 0 &&
      day?.durationMinutes?.nightTime !== null
  )?.length;

  if (!movementV3Data || movementV3Data?.length === 0) {
    return {
      offline: true,
      title: "Disturbances at night",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }

  return {
    title: "Disturbed nights",
    headline: `${disturbedNights} night${disturbedNights === 1 ? "" : "s"}`,
    icon: "SLEEP",
    context: "with events between 10pm and 6am",
    onClick: onClick,
  };
};
