import React from "react";
import styles from "./styles.module.scss";

const TimeSelector = ({ time, setTime, setSettingsFetched }) => {
  return (
    <div className={styles.time_selector}>
      <input
        type="time"
        required
        onChange={(event) => {
          setTime(event.target.value);
          setSettingsFetched(false);
        }}
        value={time}
      ></input>
    </div>
  );
};

export default TimeSelector;
