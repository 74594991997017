import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
// State
import { useDispatch, useSelector } from "react-redux";
import { setInitialDeviceId } from "../../State/slices/session-slice";
// Components
import Page from "../../Components/Page";
import PageTitle from "../../Components/PageTitle";
import ServiceUserListItem from "../../Components/ServiceUserListItem";
import { SortArrows } from "../../Components/SortArrows";
import { FilterModal } from "../../Components/FilterModal";
import PrimaryButton from "../../Components/PrimaryButton";
import SearchInput from "../../Components/SearchInput";
import CreateLilliUserModal from "../../Components/CreateLilliUserModal";
// Icons
import { UpArrow, DownArrow } from "../../Styles/Icons/DesignSystem";
import { AddIcon } from "../../Styles/Icons";
// Utils
import { FILTERS, INSTALLER_FILTERS } from "../../Services/Constants";
import { useViewport } from "../../Components/ViewportProvider";
// Hooks
import {
  useFade,
  useGetUserRole,
  useGetServiceUserList,
} from "../../Services/Hooks";
import theme from "../../Styles/theme.scss";

function LilliUsers() {
  // Get any pre set filters
  const navigate = useNavigate();
  const location = useLocation();
  // Custom hooks
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)
  const { userIsJustAnInstaller } = useGetUserRole(); // If installer-only user, restrict what they can see
  // Redux state
  const dispatch = useDispatch();
  const initialDeviceId = useSelector((state) => state.session.initialDeviceId);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  // Get the width of the users screen for responsive js
  const { width } = useViewport();
  const isTablet = width < 769;
  // local state
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(
    location.state?.filter || userIsJustAnInstaller ? "installation" : "all"
  );
  const [searchValue, setSearchValue] = useState("");
  const [addingServiceUserError, setAddingServiceUserError] = useState(null);
  // Get search results
  const {
    serviceUsersToDisplay,
    sortRisk,
    sortAZ,
    sortInstall,
    setSortRisk,
    setSortInstall,
    setSortAZ,
  } = useGetServiceUserList(selectedFilter, searchValue);

  // A useful variable
  const hasSearchTerm = searchValue?.trim()?.length > 0;

  // Handle when user selects a service user
  const onSelectHub = (hubId, event) => {
    // If the user is cmd/ctrl clicking then open the SU page in another window
    if (event?.ctrlKey || event?.metaKey) {
      const win = window.open(`/lilli-users/${hubId}`, "_blank");
      win.focus();
    } else {
      navigate(`/lilli-users/${hubId}`);
    }
  };

  // If the user has arrived with an initial hub to view, routing them straight to that hub.
  useEffect(() => {
    if (initialDeviceId) {
      // Setting the initial device id back to null so they can subsequently navigate around to their hearts content.
      dispatch(setInitialDeviceId(null));
      navigate(`/lilli-users/${initialDeviceId}`);
    }
  }, [initialDeviceId, navigate, dispatch]);

  return (
    <Page className={styles.page}>
      {/* If there are no service users */}
      {serviceUsers.length === 0 && (
        <>
          <PageTitle title={"User List"} />
          <div className={styles.no_users_message}>
            <h3>You haven't been assigned anyone yet</h3>

            <PrimaryButton
              onClick={() => {
                setShowAnimation(true);
                setAddingServiceUserError(null);
              }}
              startIcon={<AddIcon />}
            >
              New user
            </PrimaryButton>
          </div>
        </>
      )}

      {/* If there ARE service users */}
      {serviceUsers.length > 0 && (
        <div className={styles.content}>
          <PageTitle title={"User List"} />
          {isTablet && (
            <div
              className={styles.mobile_search_filter_and_add_new_service_user}
            >
              <SearchInput
                className={`
                  ${styles.mobile_search_input}
                  ${styles.user_list_search_input}
                `}
                placeholder="Search Lilli User"
                value={searchValue}
                setValue={setSearchValue}
              />
              <div className={styles.mobile_filter_new_user_container}>
                <PrimaryButton
                  onClick={() => setShowFilterMenu((state) => !state)}
                  endIcon={
                    showFilterMenu ? (
                      <UpArrow color={theme.neutral7} />
                    ) : (
                      <DownArrow color={theme.neutral7} />
                    )
                  }
                  className={`
                    ${styles.inactive_filter_button}
                    ${styles.mobile_filter_button}
                  `}
                >
                  {userIsJustAnInstaller
                    ? INSTALLER_FILTERS.selectedFilter
                    : FILTERS.selectedFilter}
                </PrimaryButton>

                <PrimaryButton
                  onClick={() => {
                    setShowAnimation(true);
                    setAddingServiceUserError(null);
                  }}
                  startIcon={<AddIcon />}
                  className={styles.mobile_new_user_button}
                />
              </div>
            </div>
          )}

          {!isTablet && (
            <div
              className={styles.desktop_search_filter_and_add_new_service_user}
            >
              <div className={styles.desktop_filter_and_search_container}>
                <PrimaryButton
                  onClick={() => setShowFilterMenu((state) => !state)}
                  endIcon={
                    showFilterMenu ? (
                      <UpArrow color="white" />
                    ) : (
                      <DownArrow color={theme.neutral7} />
                    )
                  }
                  className={`
                    ${
                      showFilterMenu
                        ? styles.active_filter_button
                        : styles.inactive_filter_button
                    }
                    ${styles.filter_button}
                  `}
                >
                  {(userIsJustAnInstaller
                    ? INSTALLER_FILTERS[selectedFilter]
                    : FILTERS[selectedFilter]) || "All users"}
                </PrimaryButton>
                <SearchInput
                  className={styles.user_list_search_input}
                  placeholder="Search Lilli User"
                  value={searchValue}
                  setValue={setSearchValue}
                />
              </div>

              <div className={styles.new_user_button_container}>
                <PrimaryButton
                  onClick={() => {
                    setShowAnimation(true);
                    setAddingServiceUserError(null);
                  }}
                  startIcon={<AddIcon />}
                  className={styles.new_user_button}
                >
                  New user
                </PrimaryButton>
              </div>
            </div>
          )}

          {showFilterMenu && (
            <FilterModal
              choices={userIsJustAnInstaller ? INSTALLER_FILTERS : FILTERS}
              setSelectedFilter={setSelectedFilter}
              setShowFilterMenu={setShowFilterMenu}
              selectedFilter={selectedFilter}
            />
          )}

          {isTablet && (
            <table className={styles.mobile_table}>
              <thead>
                <th
                  className={styles.mobile_service_user}
                  onClick={() => {
                    setSortRisk(null);
                    setSortInstall(null);
                    setSortAZ(!sortAZ);
                  }}
                >
                  <div className={styles.mobile_th_service_user_and_arrows}>
                    <span className={styles.mobile_th_service_user_label}>
                      User
                    </span>
                    {sortAZ === true ? (
                      <DownArrow />
                    ) : sortAZ === false ? (
                      <UpArrow />
                    ) : (
                      <SortArrows />
                    )}
                  </div>
                </th>
                {!userIsJustAnInstaller && (
                  <th
                    className={styles.mobile_th_risk_status}
                    onClick={() => {
                      setSortAZ(null);
                      setSortInstall(null);
                      setSortRisk(!sortRisk);
                    }}
                  >
                    <div className={styles.mobile_th_risk_status_and_arrows}>
                      <span className={styles.mobile_th_risk_status_label}>
                        {width < 400 ? "Risk" : "Risk Status"}
                      </span>
                      {sortRisk === true ? (
                        <DownArrow />
                      ) : sortRisk === false ? (
                        <UpArrow />
                      ) : (
                        <SortArrows />
                      )}
                    </div>
                  </th>
                )}
                {userIsJustAnInstaller && (
                  <th
                    className={styles.mobile_th_risk_status}
                    onClick={() => {
                      setSortAZ(null);
                      setSortInstall(!sortInstall);
                      setSortRisk(null);
                    }}
                  >
                    <div className={styles.mobile_th_risk_status_and_arrows}>
                      <span className={styles.mobile_th_risk_status_label}>
                        Install
                      </span>
                      {sortInstall === true ? (
                        <DownArrow />
                      ) : sortInstall === false ? (
                        <UpArrow />
                      ) : (
                        <SortArrows />
                      )}
                    </div>
                  </th>
                )}
              </thead>
              <tbody>
                {serviceUsersToDisplay.map((serviceUser) => {
                  return (
                    <ServiceUserListItem
                      key={serviceUser.id}
                      serviceUser={serviceUser}
                      onClick={() => onSelectHub(serviceUser.id)}
                      justAnInstaller={userIsJustAnInstaller}
                    />
                  );
                })}
              </tbody>
            </table>
          )}

          {!isTablet && (
            <table className={styles.desktop_table}>
              <thead>
                <th
                  className={styles.th_service_user}
                  onClick={() => {
                    setSortRisk(null);
                    setSortInstall(null);
                    setSortAZ(!sortAZ);
                  }}
                >
                  <div className={styles.th_service_user_and_arrows}>
                    <span className={styles.th_service_user_label}>User</span>
                    {sortAZ === true ? (
                      <DownArrow />
                    ) : sortAZ === false ? (
                      <UpArrow />
                    ) : (
                      <SortArrows />
                    )}
                  </div>
                </th>
                <th className={styles.th_care_id}>Care ID</th>
                {!userIsJustAnInstaller && (
                  <th className={styles.th_staff}>Staff</th>
                )}
                <th
                  className={styles.th_installation_and_arrows}
                  onClick={() => {
                    setSortAZ(null);
                    setSortRisk(null);
                    setSortInstall(!sortInstall);
                  }}
                >
                  <span className={styles.th_installation_label}>Install</span>
                  {sortInstall === true ? (
                    <DownArrow />
                  ) : sortInstall === false ? (
                    <UpArrow />
                  ) : (
                    <SortArrows />
                  )}
                </th>
                {!userIsJustAnInstaller && (
                  <th
                    className={styles.th_risk_status}
                    onClick={() => {
                      setSortAZ(null);
                      setSortInstall(null);
                      setSortRisk(!sortRisk);
                    }}
                  >
                    <div className={styles.th_risk_status_and_arrows}>
                      <span className={styles.th_risk_status_label}>
                        Risk Status
                      </span>
                      {sortRisk === true ? (
                        <DownArrow />
                      ) : sortRisk === false ? (
                        <UpArrow />
                      ) : (
                        <SortArrows />
                      )}
                    </div>
                  </th>
                )}
              </thead>
              <tbody>
                {serviceUsersToDisplay.map((serviceUser) => {
                  return (
                    <ServiceUserListItem
                      key={serviceUser.id}
                      serviceUser={serviceUser}
                      onClick={() => onSelectHub(serviceUser.id)}
                      hasSearchTerm={hasSearchTerm}
                      justAnInstaller={userIsJustAnInstaller}
                    />
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {isVisible && (
        <CreateLilliUserModal
          addingServiceUserError={addingServiceUserError}
          setAddingServiceUserError={setAddingServiceUserError}
          setAddingServiceUser={setShowAnimation}
          showAnimation={showAnimation}
          justAnInstaller={userIsJustAnInstaller}
        />
      )}
    </Page>
  );
}

export default LilliUsers;
