import { format, addHours } from "date-fns";

export const getFormattedChartData = (behaviourData, dateType, behaviour) => {
  let formattedData = [];
  if (dateType === "day") {
    formattedData = behaviourData?.map((item) => ({
      ...item,
      x: `${format(new Date(item?.ts), "haaa")}`,
      y: item?.value,
    }));
    // If we receive day view data that is incomplete: i.e. looking at today's data, then we want to fill in the remaining hours with nulls so that the chart expands the full 24hrs
    const expectedArrayLength = behaviour === "sleep" ? 6 : 23;
    while (
      formattedData.length < expectedArrayLength &&
      behaviourData.length > 0
    ) {
      const prevPoint = formattedData[formattedData.length - 1];
      const nextHour = addHours(new Date(prevPoint?.ts), 1);
      formattedData.push({
        ...prevPoint,
        ts: nextHour,
        x: format(nextHour, "haaa"),
        y: null,
        expectedMin: null,
        expectedMax: null,
      });
    }
  }

  if (dateType === "week") {
    formattedData = behaviourData?.map((item) => ({
      ...item,
      x: format(new Date(item?.ts), "eee"),
      y: item.value,
    }));
  }
  // Otherwise the date type must be month so format the data accordingly
  if (dateType === "month") {
    formattedData = behaviourData?.map((item) => ({
      ...item,
      x: format(new Date(item?.ts), "d LLL"),
      y: item.value,
    }));
  }

  return formattedData;
};
