import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// Components
import TimeSelector from "../TimeSelector";
import ToggleOnOff from "../ToggleOnOff";
// Hooks
import { useViewport } from "../ViewportProvider";
// API
import {
  getNighttimeAlertSettings,
  postSetNighttimeAlerts,
  postDisableNighttimeAlerts,
  postEnableNighttimeAlerts,
} from "@intelligentlilli/api-layer";
// State
import { useSelector } from "react-redux";
// Utils
import { isValidStartAndEndTime } from "../../Services/Utils";

const ServiceUserNotificationSettings = () => {
  const { id } = useParams(); // Get the service user id from the url parameter
  const server = useSelector((state) => state.session.server);
  const navigate = useNavigate();
  const { width } = useViewport();
  const isLargeScreen = width > 812;

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [notificationSettingsError, setNotificationSettingsError] =
    useState(null);
  // Prevents the second useEffect hook from updating night time alerts when
  // the settings are first fetched
  const [settingsFetched, setSettingsFetched] = useState(false);

  // When first arrival onto theh page, get the current nighttime notification settings
  useEffect(() => {
    getNighttimeAlertSettings(server, id)
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
          setNotificationSettingsError(
            "Could not load settings. Refresh the screen to try again."
          );
          return;
        }
        if (res?.body) {
          setStartTime(res.body.startTime);
          setEndTime(res.body.endTime);
          setIsEnabled(res.body.enabled);
          setSettingsFetched(true);
        }
      })
      .catch((err) => {
        console.log("getNighttimeAlertSettings error:", err);
        setNotificationSettingsError(
          "Could not load settings. Refresh the screen to try again."
        );
      });
  }, [server, id, navigate]);

  useEffect(() => {
    // Skip if settings were successfully fetched or if times are not set
    if (settingsFetched || !startTime || !endTime) {
      return;
    }
    // When the user changes the start or end time, update the nighttime notification settings
    const updateNighttimeAlertTimes = ({ timeStart, timeEnd }) => {
      if (!isValidStartAndEndTime(timeStart, timeEnd)) {
        return setNotificationSettingsError("Please select a valid time");
      }
      if (timeStart === timeEnd) {
        return setNotificationSettingsError(
          "Start and end times must be different"
        );
      }
      const errorMessage = "Could not save changes. Please try again.";
      postSetNighttimeAlerts(server, id, timeStart, timeEnd, "web")
        .then((res) => {
          if (res.ok) {
            setNotificationSettingsError(""); // reset the error message
          } else {
            console.log(
              "updateNighttimeAlertTimes response is not OK:",
              res.body
            );
            setNotificationSettingsError(errorMessage);
          }
        })
        .catch((err) => {
          console.log("updateNighttimeAlertTimes error:", err);
          setNotificationSettingsError(errorMessage);
        });
    };

    // Trigger the update function when start or end time changes and when settingsFetched is False
    updateNighttimeAlertTimes({ timeStart: startTime, timeEnd: endTime });
  }, [server, id, startTime, endTime, settingsFetched]);

  const toggleOn = () => {
    setIsEnabled(true);
    postEnableNighttimeAlerts(server, id, "web")
      .then((res) => {
        if (!res.ok) {
          console.log("toggleOn response is not OK:", res.body);
          setIsEnabled(false);
          setNotificationSettingsError(
            "Could not enable notifications. Please try again."
          );
        }
        setNotificationSettingsError(""); // reset the error message
      })
      .catch((err) => {
        console.log("postEnableNighttimeAlerts error:", err);
        setIsEnabled(false);
        setNotificationSettingsError(
          "Could not enable notifications. Please try again."
        );
      });
  };

  const toggleOff = () => {
    setIsEnabled(false);
    postDisableNighttimeAlerts(server, id, "web")
      .then((res) => {
        if (!res.ok) {
          console.log("toggleOff response is not OK:", res.body);
          setIsEnabled(true);
          setNotificationSettingsError(
            "Could not disable notifications. Please try again."
          );
        }
        setNotificationSettingsError(""); // reset the error message
      })
      .catch((err) => {
        console.log("postDisableNighttimeAlerts error:", err);
        setIsEnabled(true);
        setNotificationSettingsError(
          "Could not disable notifications. Please try again."
        );
      });
  };

  return (
    <div className={styles.notifications_container}>
      <div className={styles.outer_container}>
        <div className={styles.subheading_medium}>Notifications</div>
        <div className={styles.notification_explanation}>
          The settings below are for this user only
        </div>
      </div>
      {isLargeScreen ? (
        <div className={styles.inner_container}>
          <div className={styles.top_section}>
            <div className={styles.column_1}>Type of notification</div>
            <div className={styles.column_2}>How we will notify you</div>
            <div className={styles.column_3}>When we will notify you</div>
            <div className={styles.column_4}></div>{" "}
            {/* TOGGLE - intentionally empty */}
          </div>
          <div className={styles.bottom_section}>
            <div className={styles.column_1}>
              <div className={styles.title}>Night-time notifications</div>
              <div className={styles.description}>
                Includes front-door openings at night and high activity between:
              </div>
              <div className={styles.time_selector}>
                <div className={styles.time}>
                  <TimeSelector
                    time={startTime}
                    setTime={setStartTime}
                    setSettingsFetched={setSettingsFetched}
                  />
                </div>
                <div className={styles.time}> - </div>
                <div>
                  <TimeSelector
                    time={endTime}
                    setTime={setEndTime}
                    setSettingsFetched={setSettingsFetched}
                  />
                </div>
              </div>
              <div className={styles.notifications_error_message}>
                {notificationSettingsError ? notificationSettingsError : ""}
              </div>
            </div>
            <div className={styles.column_2}>Email</div>
            <div className={styles.column_3}>
              Within 15 minutes of the detected event
            </div>
            <div className={styles.column_4}>
              <ToggleOnOff
                isEnabled={isEnabled}
                onToggleOn={() => toggleOn()}
                onToggleOff={() => toggleOff()}
                backgroundColor={theme.primary3}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.mobile_section}>
            <div className={styles.mobile_title}>Night-time notifications</div>
            <div className={styles.mobile_description}>
              Includes front-door opening at night and high activity between:
            </div>
            <div className={styles.mobile_time_selector}>
              <div className={styles.mobile_time}>
                <TimeSelector
                  time={startTime}
                  setTime={setStartTime}
                  setSettingsFetched={setSettingsFetched}
                />
              </div>
              <div className={styles.mobile_time}> - </div>
              <div>
                <TimeSelector
                  time={endTime}
                  setTime={setEndTime}
                  setSettingsFetched={setSettingsFetched}
                />
              </div>
            </div>
          </div>
          <div className={styles.mobile_notification_delivery}>
            <div className={styles.mobile_details}>
              You will receive an email notification within 15 minutes of the
              detected event.
            </div>
            <div className={styles.notifications_error_message}>
              {notificationSettingsError ? notificationSettingsError : ""}
            </div>
          </div>
          <div className={styles.mobile_toggle}>
            <ToggleOnOff
              isEnabled={isEnabled}
              onToggleOn={() => toggleOn()}
              onToggleOff={() => toggleOff()}
              backgroundColor={theme.primary3}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceUserNotificationSettings;
