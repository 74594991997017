import { formatDistance, fromUnixTime } from "date-fns";
import React from "react";
import styles from "./styles.module.scss";

const StaffItemMobile = ({ staff, navigate }) => (
  <div className={styles.grid} onClick={() => navigate(`/staff/${staff.id}`)}>
    <div className={styles.bold}>
      {staff.forenames} {staff.surname}
    </div>
    <div className={styles.grid_roles}>
      {staff?.roles?.map((role) => (
        <div className={styles.grid_roles_role}>{role}</div>
      ))}
    </div>
    <div className={styles.grid_item}>{staff?.serviceUsersString}</div>
    <div className={styles.grid_item_lastSeen}>
      Last seen:{" "}
      {staff?.lastActivity &&
        formatDistance(
          fromUnixTime(staff?.lastActivity?.slice(0, -3)),
          new Date()
        )}{" "}
      {staff?.lastActivity ? "ago" : "unknown"}
    </div>
  </div>
);

export default StaffItemMobile;
