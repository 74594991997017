import { dateToTimestamp } from "../Dates/dateManipulations";

interface DailyResult {
  date: string;
  riskLevel?: number;
  dayRating?: number;
  dailyData?: {
    minutesOutside?: {
      dayRating?: number;
    };
  };
}

export const extractRiskLevel = (
  dailyResults: DailyResult[] | undefined,
  from: Date,
  to: Date
): number | undefined => {
  // 1- Getting the data in the to - from range
  const dataInRange =
    dailyResults?.filter(({ date }) => {
      const timestamp = dateToTimestamp(date);
      return timestamp >= from && timestamp <= to;
    }) || [];

   // 2- Converting into an array of the dayRatings
  const dayRatingArray = dataInRange
    .map((day) => {
      if (day.riskLevel !== undefined && day.riskLevel >= 0) {
        return day.riskLevel;
      } else if (day.dayRating !== undefined && day.dayRating >= 0) {
        return day.dayRating;
      }
      return null;
    })
    .filter((dayRating) => dayRating !== null) as number[];
  // 3- Return undefined if there are no valid ratings
  if (dayRatingArray.length === 0) {
    return undefined;
  }
  // 4- Else return the worst of the day ratings
  const result = Math.max(...dayRatingArray);
  return result;
};

export const extractIndependenceRiskLevel = (
  dailyResults: DailyResult[] | undefined,
  from: Date,
  to: Date
): number | undefined => {
  // First getting the data in the to - from range
  const dataInRange =
    dailyResults?.filter(({ date }) => {
      const timestamp = dateToTimestamp(date);
      return timestamp >= from && timestamp <= to;
    }) || [];
  // Converting to an array of the dayRatings
  const dayRatingArray = dataInRange
    .map((day) => {
      return day.dailyData?.minutesOutside?.dayRating !== undefined &&
        day.dailyData.minutesOutside.dayRating !== null
        ? day.dailyData.minutesOutside.dayRating
        : null;
    })
    .filter((dayRating) => dayRating !== null) as number[];
  // If there are no actual ratings then returning undefined
  if (dayRatingArray?.length === 0) {
    return undefined;
  }
  // Else return the worst of the day ratings
  const result = Math.max(...dayRatingArray);
  return result;
};