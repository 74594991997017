type HubType = {
  id: number;
};

export const getServiceUserById = (
  hubs: HubType[] | undefined,
  id: string
): HubType | undefined => {
  return hubs?.find((hub) => hub.id.toString() === id);
};
