import styles from "./styles.module.scss";
import classNames from "classnames";

const InstallationCard = ({ style, children, className }) => {
  return (
    <div className={classNames(styles.card, style, className)}>{children}</div>
  );
};

export default InstallationCard;
