import React from "react";
import theme from "../../theme.scss";

export const IndependenceIcon = ({ colour, size }) => {
  return (
    <svg
      id="IndependenceIcon"
      width={size || 20}
      height={size || 20}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g>
        <path
          d="M14 16H12V12.6667C12 12.313 11.8595 11.9739 11.6095 11.7239C11.3594 11.4738 11.0203 11.3333 10.6667 11.3333H5.33333C4.97971 11.3333 4.64057 11.4738 4.39052 11.7239C4.14048 11.9739 4 12.313 4 12.6667V16H2V12.6667C2.00106 11.7829 2.35259 10.9357 2.97748 10.3108C3.60237 9.68592 4.4496 9.33439 5.33333 9.33333H10.6667C11.5504 9.33439 12.3976 9.68592 13.0225 10.3108C13.6474 10.9357 13.9989 11.7829 14 12.6667V16Z"
          fill={colour || theme.neutral7}
        />
        <path
          d="M8 8C7.20888 8 6.43552 7.7654 5.77772 7.32588C5.11992 6.88635 4.60723 6.26164 4.30448 5.53073C4.00173 4.79983 3.92252 3.99556 4.07686 3.21964C4.2312 2.44372 4.61216 1.73098 5.17157 1.17157C5.73099 0.612163 6.44372 0.2312 7.21964 0.0768588C7.99557 -0.0774821 8.79983 0.00173124 9.53074 0.304482C10.2616 0.607232 10.8864 1.11992 11.3259 1.77772C11.7654 2.43552 12 3.20888 12 4C11.9989 5.06054 11.5772 6.07734 10.8273 6.82726C10.0773 7.57717 9.06054 7.99894 8 8ZM8 2C7.60444 2 7.21776 2.1173 6.88886 2.33706C6.55996 2.55682 6.30362 2.86918 6.15224 3.23463C6.00087 3.60009 5.96126 4.00222 6.03843 4.39018C6.1156 4.77814 6.30608 5.13451 6.58579 5.41421C6.86549 5.69392 7.22186 5.8844 7.60982 5.96157C7.99778 6.03874 8.39992 5.99914 8.76537 5.84776C9.13082 5.69638 9.44318 5.44004 9.66294 5.11114C9.8827 4.78224 10 4.39556 10 4C10 3.46957 9.78929 2.96086 9.41422 2.58579C9.03914 2.21071 8.53044 2 8 2Z"
          fill={colour || theme.neutral7}
        />
      </g>
    </svg>
  );
};
