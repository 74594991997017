import React from "react";
import styles from "./styles.module.scss";
// Components
import InstallationNextButton from "../InstallationNextButton";
import InstallationGoBackButton from "../InstallationGoBackButton";
import PropTypes from "prop-types";

const InstallationSplash = ({
  isDesktop,
  icon,
  headers,
  message,
  email,
  goBackOnClick,
  goBackButtonLabel,
  nextButtonOnClick,
  nextButtonLabel,
  cypresstestid,
  installSUCard,
  installNotes,
}) => {
  return (
    <div className={styles.splash_container} cypresstestid={cypresstestid}>
      <div className={styles.splash_top}>
        <div className={styles.icon}>{icon}</div>
        {headers.map((header, i) => (
          <div className={styles.installation_header} key={i}>
            {header}
          </div>
        ))}
        {message && (
          <div className={styles.installation_message}>
            {message}
            {email && (
              <span>
                <a
                  href={`mailto: ${email}`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.email}
                >
                  {email}
                </a>
              </span>
            )}
            {installSUCard ? installSUCard : null}
            {installNotes ? installNotes : null}
          </div>
        )}
      </div>
      <div className={styles.splash_bottom}>
        {goBackButtonLabel && (
          <InstallationGoBackButton
            onClick={goBackOnClick}
            isDesktop={isDesktop}
            label={goBackButtonLabel}
          />
        )}
        <InstallationNextButton
          onClick={nextButtonOnClick}
          label={nextButtonLabel}
        />
      </div>
    </div>
  );
};

InstallationSplash.propTypes = {
  isDesktop: PropTypes.bool,
  icon: PropTypes.node,
  headers: PropTypes.array,
  message: PropTypes.string,
  email: PropTypes.string,
  installSUCard: PropTypes.node,
  installNotes: PropTypes.node,
  goBackOnClick: PropTypes.func,
  goBackButtonLabel: PropTypes.string,
  nextButtonOnClick: PropTypes.func,
  nextButtonLabel: PropTypes.string,
  cypresstestid: PropTypes.string,
};

export default InstallationSplash;
