// Utility functions defining the immutable behaviours

export const BehaviourType = Object.freeze({
  MOVEMENT: "MOVEMENT",
  SUSTENANCE: "SUSTENANCE",
  INDEPENDENCE: "INDEPENDENCE",
  SLEEP: "SLEEP",
  TEMPERATURE: "TEMPERATURE",
  BATHROOM: "BATHROOM",
});

export const BehaviourTypeText = {
  MOVEMENT: "Movement",
  SUSTENANCE: "Sustenance",
  INDEPENDENCE: "Independence",
  SLEEP: "Night-time activity",
  TEMPERATURE: "Temperature",
  BATHROOM: "Bathroom activity",
};

export const BehaviourContext = {
  MOVEMENT:
    "This data shows the amount of recorded movement for an individual. Movement is analogous to Ambulation: a person’s ability to change from one position to the other and to walk indpendently.",
  SUSTENANCE:
    "Sustenance in the form of food and drink is regarded as a source of strength; nourishment. It also provides energy and maintains well being. Eating healthily can also improve an immune system and reduce the risk of disease.",
  INDEPENDENCE:
    "Independence means having choices and allows people to feel they can control as certain aspects of their life change with age or condition. Additionally, maintaining independence promotes a sense of achievement that for many as it generates a great sense of self-worth and well-being.",
  SLEEP:
    "Sleep is a vital, often neglected, component of every person's overall health and well-being. Sleep is important because it enables the body to repair and be fit and ready for another day. Getting adequate rest may also help prevent excess weight gain, heart disease, and increased illness duration.",
  TEMPERATURE:
    "Temperatures in the house should always be comfortable and liveable.",
  BATHROOM:
    "This data shows how frequently the bathroom is used. You can slide the grip to select the period you're looking at.",
};
