import React from "react";
import theme from "../../theme.scss";

export const TemperatureIcon = ({ colour, size }) => {
  return (
    <svg
      id="TemperatureIcon"
      width={size || 24}
      height={size || 24}
      viewBox="0 0 17 17"
      fill="none"
    >
      <g>
        <path
          d="M9.20833 10.0385V5.66666H7.79166V10.0385C7.31905 10.2056 6.92072 10.5344 6.66709 10.9668C6.41345 11.3991 6.32083 11.9072 6.4056 12.4013C6.49036 12.8954 6.74706 13.3436 7.13032 13.6667C7.51357 13.9898 7.99871 14.167 8.49999 14.167C9.00127 14.167 9.48641 13.9898 9.86967 13.6667C10.2529 13.3436 10.5096 12.8954 10.5944 12.4013C10.6792 11.9072 10.5865 11.3991 10.3329 10.9668C10.0793 10.5344 9.68094 10.2056 9.20833 10.0385ZM12.0417 8.57862V3.54166C11.8922 -1.13971 5.10637 -1.13334 4.95833 3.54166V8.57862C4.27502 9.27602 3.81281 10.1596 3.62967 11.1187C3.44653 12.0777 3.5506 13.0694 3.92883 13.9696C4.30706 14.8697 4.94262 15.6381 5.75582 16.1784C6.56901 16.7188 7.52364 17.0071 8.49999 17.0071C9.47635 17.0071 10.431 16.7188 11.2442 16.1784C12.0574 15.6381 12.6929 14.8697 13.0712 13.9696C13.4494 13.0694 13.5535 12.0777 13.3703 11.1187C13.1872 10.1596 12.725 9.27602 12.0417 8.57862ZM8.49999 15.5833C7.78708 15.5753 7.09304 15.3531 6.50805 14.9456C5.92306 14.538 5.47415 13.964 5.21964 13.298C4.96513 12.632 4.91679 11.9049 5.08089 11.211C5.24498 10.5172 5.61394 9.88878 6.13983 9.40737L6.37499 9.19487V3.54166C6.37499 2.97808 6.59888 2.43757 6.99739 2.03906C7.39591 1.64054 7.93641 1.41666 8.49999 1.41666C9.06358 1.41666 9.60408 1.64054 10.0026 2.03906C10.4011 2.43757 10.625 2.97808 10.625 3.54166V9.19629L10.8602 9.40879C11.3855 9.8903 11.754 10.5186 11.9178 11.2122C12.0816 11.9058 12.0332 12.6326 11.7787 13.2982C11.5243 13.9639 11.0756 14.5377 10.4909 14.9452C9.90625 15.3527 9.2126 15.575 8.49999 15.5833Z"
          fill={colour || theme.neutral7}
        />
      </g>
    </svg>
  );
};
