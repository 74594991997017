import React from "react";

export const Avatar1 = ({ width, primaryColour, secondaryColour }) => {
  return (
    <svg width={width || 40} viewBox="0 0 40 40" fill="none" id="Avatar1">
      <rect width="40" height="40" rx="3.26531" fill={primaryColour} />
      <circle
        cx="20.5442"
        cy="20.0004"
        r="9.82222"
        stroke={secondaryColour}
        strokeWidth="4.40998"
      />
    </svg>
  );
};
