import React from "react";
import theme from "../../theme.scss";

export const MoonIcon = ({ colour, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 13" id="MoonIcon">
      <path
        d="M7.50007 12.5581C6.65982 12.5575 5.82904 12.3807 5.06135 12.0391C4.29366 11.6976 3.60611 11.1988 3.04308 10.5751C2.47579 9.95068 2.04778 9.21274 1.78745 8.41027C1.52711 7.6078 1.44041 6.75915 1.53308 5.92061C1.68248 4.61561 2.25177 3.39447 3.15526 2.44103C4.05874 1.48759 5.2475 0.853456 6.54257 0.634103C7.37978 0.50479 8.23415 0.539604 9.05807 0.736603C9.27381 0.792033 9.47082 0.904106 9.62872 1.06122C9.78661 1.21834 9.89965 1.4148 9.95615 1.63027C10.0126 1.84573 10.0105 2.07238 9.95004 2.28676C9.88954 2.50114 9.77286 2.69545 9.61207 2.84961C7.33207 4.93261 7.53007 8.17161 10.0156 10.0546C10.1913 10.1925 10.3269 10.3751 10.4081 10.5832C10.4894 10.7913 10.5134 11.0173 10.4777 11.2379C10.442 11.4584 10.3478 11.6653 10.205 11.8371C10.0621 12.0089 9.87587 12.1392 9.66557 12.2146C8.96647 12.443 8.23554 12.559 7.50007 12.5581ZM7.53807 1.5581C7.25735 1.55743 6.97699 1.57866 6.69957 1.6216C5.62133 1.80396 4.6315 2.33163 3.87908 3.12517C3.12666 3.91872 2.65236 4.9352 2.52758 6.02161C2.44958 6.72163 2.52146 7.43024 2.73845 8.10034C2.95543 8.77044 3.31255 9.38668 3.78607 9.90812C4.47924 10.648 5.37221 11.1707 6.35666 11.413C7.3411 11.6553 8.3747 11.6067 9.33207 11.2731C9.37334 11.2577 9.40979 11.2316 9.43772 11.1975C9.46566 11.1634 9.48409 11.1225 9.49114 11.079C9.4982 11.0355 9.49363 10.9909 9.4779 10.9498C9.46217 10.9086 9.43584 10.8723 9.40157 10.8446C6.43707 8.60561 6.20157 4.60161 8.92757 2.12011C8.95885 2.09106 8.98141 2.05387 8.99272 2.01271C9.00402 1.97155 9.00362 1.92805 8.99157 1.8871C8.98118 1.84365 8.9591 1.80386 8.92773 1.77204C8.89636 1.74023 8.85688 1.7176 8.81357 1.7066C8.39594 1.60587 7.96767 1.55601 7.53807 1.5581Z"
        fill={colour || theme.white}
      />
    </svg>
  );
};
