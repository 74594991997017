export const isTemperatureConcerning = (data) => {
  const isTooCold = data?.reason?.includes("cold");
  const isTooHot = data?.reason?.includes("hot");
  const isAtRisk = data?.riskLevel === 2;
  return isAtRisk && (isTooCold || isTooHot);
};

export const isTemperatureTooHot = (data) => {
  const isTooHot = data?.reason?.includes("hot");
  const isAtRisk = data?.riskLevel === 2;
  return isAtRisk && isTooHot;
};

export const isTemperatureTooCold = (data) => {
  const isTooCold = data?.reason?.includes("cold");
  const isAtRisk = data?.riskLevel === 2;
  return isAtRisk && isTooCold;
};
