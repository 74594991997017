import { useEffect, useState } from "react";
// UTILS
import { prepareMovementV3 } from "@intelligentlilli/lilli-utils";

export const usePrepareMovementV3Data = ({
  movementV3Data,
  startDateString,
  endDateString,
  prevStartDateString,
  prevEndDateString,
  dateType,
}) => {
  const [preparedMovementV3Data, setPreparedMovementV3Data] = useState([]);
  const [prevPreparedMovementV3Data, setPreparedPreviousMovementV3Data] =
    useState([]);

  useEffect(() => {
    if (
      movementV3Data?.dailyResults?.length > 0 &&
      startDateString &&
      endDateString &&
      prevStartDateString &&
      prevEndDateString
    ) {
      setPreparedMovementV3Data(
        prepareMovementV3({
          movementV3Data,
          startDateString,
          endDateString,
        })
      );
      setPreparedPreviousMovementV3Data(
        prepareMovementV3({
          movementV3Data,
          startDateString: prevStartDateString,
          endDateString: prevEndDateString,
        })
      );
    } else {
      setPreparedMovementV3Data([]);
      setPreparedPreviousMovementV3Data([]);
    }
  }, [
    movementV3Data,
    prevStartDateString,
    prevEndDateString,
    endDateString,
    startDateString,
    dateType,
  ]);

  return {
    preparedMovementV3Data,
    prevPreparedMovementV3Data,
  };
};
