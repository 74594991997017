// Taking the hub ids as keys and passing them an empty object as a value. This object will then be updated when hub data is fetched.
export const formatUserHubs = (hubs: { id: string }[] | undefined): Record<string, Record<string, never>> => {
  const hubObject: Record<string, Record<string, never>> = {};
  if (hubs) {
    for (const hub of hubs) {
      hubObject[hub.id] = {};
    }
  }
  return hubObject;
};
