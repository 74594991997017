import { getDoorOpenedAtNightFirstLine, getNoTimeOutsideFirstLine } from "./";

export const handleIndependence = (data, filterName) => {
  const noTimeOutside = data?.map((day) => {
    return {
      date: day?.date,
      dayRating: day?.dayRating,
      value: day?.value === null ? null : day?.value,
      expectedMin: day?.expectedMin,
      firstLineText: getNoTimeOutsideFirstLine(day?.value),
      secondLineText: null,
      isHighlighted: day?.value === null ? null : day?.value === 0,
      highlightColour: null,
      noData: getNoTimeOutsideFirstLine(day?.value) === "No data",
    };
  });

  switch (filterName) {
    case "noTimeOutside":
      return noTimeOutside;
    case "doorOpenedAtNight":
      return data?.map((day) => {
        const numberOfDoorEventsAtNight = day?.hourlyData?.reduce(
          (totalEvents, currentHour) => {
            // if it is outside of the night time range (12-6am) then don't add to the count
            if (currentHour.hour > 5) {
              return totalEvents;
            }
            return totalEvents + currentHour.doorEvents.count;
          },
          0
        );
        return {
          date: day?.date,
          dayRating: day?.dayRating,
          value: day?.value,
          expectedMin: day?.expectedMin,
          firstLineText: getDoorOpenedAtNightFirstLine(
            numberOfDoorEventsAtNight
          ),
          secondLineText: null,
          isHighlighted: numberOfDoorEventsAtNight > 0,
          highlightColour: null,
          noData:
            getDoorOpenedAtNightFirstLine(numberOfDoorEventsAtNight) ===
            "No data",
        };
      });
    default:
      return noTimeOutside;
  }
};
