import React, { useState } from "react";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import PrimaryButton from "../../Components/PrimaryButton";
import { CopyIcon } from "../../Styles/Icons/DesignSystem";
import theme from "../../Styles/theme.scss";
import CareActChart from "./CareActChart";

const LineBreak = () => {
  return <div className={styles.line_break}></div>;
};

const PageNumber = ({ number, total }) => (
  <div className={styles.pageNumber} key={number}>
    Page {number} of {total}
  </div>
);

const Question = ({
  question,
  isPdf,
  firstName,
  startDates,
  formattedDateRange,
}) => {
  let sentences = [];
  question.sentences.data && sentences.push(question.sentences.data);
  question.sentences.lowerbound_risk &&
    sentences.push(question.sentences.lowerbound_risk);
  question.sentences.upperbound_risk &&
    sentences.push(question.sentences.upperbound_risk);
  question.sentences.trend && sentences.push(question.sentences.trend);
  const sentencesWithNames = sentences.map((sentence) =>
    sentence?.replace("{name}", firstName)
  );
  const questionText = question.question_text.replace(
    "<placeholder_name>",
    firstName
  );

  return (
    <div className={styles.section}>
      {question.sectionTitle && (
        <h2>
          {question.sectionTitle.replace("<placeholder_name>", firstName)}
        </h2>
      )}
      <div className={styles.section_question}>
        <div className={styles.section_question_text}>
          <h3>
            {question.question_id.slice(1)}.&nbsp;
            {questionText}
          </h3>
          <ul>
            {sentencesWithNames.map((sentence, index) => (
              <li key={index}>{sentence}</li>
            ))}
          </ul>
          <PrimaryButton
            onClick={() =>
              navigator.clipboard.writeText(
                `• ${sentencesWithNames.join("\n• ")}`
              )
            }
            startIcon={
              <CopyIcon colour={isPdf ? theme.white : theme.neutral7} />
            }
            style={{ borderRadius: "8px" }}
            backgroundColour={isPdf ? theme.white : theme.mint}
            hoverColour={isPdf ? theme.neutral7 : theme.green3}
            colour={isPdf ? theme.white : theme.neutral7}
            padding="6px 16px"
            iconMargin={8}
            iconMarginBottom={"0px"}
          >
            Copy text
          </PrimaryButton>
        </div>
        <CareActChart
          chartData={question.chart}
          startDates={startDates}
          questionText={questionText}
          formattedDateRange={formattedDateRange}
        />
      </div>
    </div>
  );
};

const CareAct = ({ reportData, isPdf }) => {
  const [processedReportData, setProcessedReportData] = useState();

  const firstName =
    reportData?.entity_properties?.forenames ||
    reportData?.entity_properties?.fullName?.split(" ")[0] ||
    "Unknown";
  const surname = reportData?.entity_properties?.surname;
  const fullName =
    reportData?.entity_properties?.fullName || `${firstName} ${surname}`;

  // Function to split report data into different pages
  const splitReportData = (reportData) => {
    // Get the sections from the report data
    const questions = reportData.sections
      .flatMap((section) =>
        section.questions.map((question, index) =>
          index === 0
            ? { ...question, sectionTitle: section.section_title }
            : question
        )
      )
      .map((question, index) => ({
        ...question,
        question_id: `Q${index + 1}`,
      }));
    // Split off the first two questions to be on the first page
    const firstPageQuestions = questions.slice(0, 2);
    questions.splice(0, 2);

    // Split the questions into groups of 3
    const pages = questions.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 3);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

    return { pages: pages, firstPageQuestions: firstPageQuestions };
  };

  if (!processedReportData && reportData) {
    setProcessedReportData(splitReportData(reportData));
  }

  const totalPages = (processedReportData?.pages?.length || 0) + 1;

  const formattedDateRange = `${format(
    new Date(reportData?.start_at),
    "dd MMMM yyyy"
  )} - ${format(new Date(reportData?.end_at), "dd MMMM yyyy")}`;

  const userDetailsToRender = [
    "Full Name:",
    reportData?.entity_properties?.fullName || "Unknown",
    "Care ID:",
    reportData?.entity_properties?.careId || "Unknown",
    "Date of Birth:",
    reportData?.entity_properties?.dateOfBirth || "Unknown",
    "Age:",
    reportData?.entity_properties?.age || "Unknown",
    "Tel. No:",
    reportData?.entity_properties?.phoneNumber || "Unknown",
  ];

  const reportDetailsToRender = [
    "Report created:",
    format(new Date(reportData?.created_at), "dd/MM/yy"),
    "Created by:",
    reportData?.entity_properties?.createdBy || "Unknown",
  ];

  return (
    <>
      {/* This is the first page of the pdf document - it is always present */}
      <div className={`${styles.pdf} ${styles.flex_start}`}>
        <img src="/logo/lilli-128-teal.png" alt="Lilli" width="60" />
        <h1>{`${fullName} - ${reportData?.report_title}`}</h1>
        <p className={styles.dateRange}>Report for: {formattedDateRange}</p>
        <div className={styles.details}>
          <div className={styles.details_section}>
            <h3>Lilli user details</h3>
            <div className={styles.details_section_grid}>
              {userDetailsToRender.map((item, index) => (
                <div
                  className={index % 2 ? styles.gridItem : styles.gridItem_bold}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.details_section}>
            <h3>Report details</h3>
            <div className={styles.details_section_grid}>
              {reportDetailsToRender.map((item, index) => (
                <div
                  className={index % 2 ? styles.gridItem : styles.gridItem_bold}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
        {processedReportData?.firstPageQuestions?.map((question, index) => (
          <>
            {index !== 0 && <LineBreak />}
            <Question
              question={question}
              isPdf={isPdf}
              key={index}
              firstName={firstName}
              startDates={reportData?.start_dates}
              formattedDateRange={formattedDateRange}
            />
          </>
        ))}
        <PageNumber number={1} total={totalPages} />
      </div>

      {/* These are the subsequent pages of the pdf document */}
      {processedReportData?.pages?.map((page, index) => (
        <div
          className={`${styles.pdf} ${
            page.length === 3 ? styles.space_evenly : styles.flex_start
          } ${styles.page}`}
          key={index}
        >
          {page.map((question, index) => (
            <>
              {index !== 0 && <LineBreak />}
              <Question
                question={question}
                isPdf={isPdf}
                key={index}
                firstName={firstName}
                startDates={reportData?.start_dates}
              />
            </>
          ))}
          <PageNumber number={index + 2} total={totalPages} />
        </div>
      ))}
    </>
  );
};

export default CareAct;
