import React from "react";
import theme from "../../theme.scss";

export const CloseIcon = ({ width, color }) => {
  const heightToWidthRatio = 38 / 39;

  return (
    <svg
      width={width || 39}
      height={width * heightToWidthRatio || 38}
      viewBox="0 0 39 38"
      fill="none"
      id="CloseIcon"
    >
      <path
        d="M24.7567 13.7103C24.242 13.1955 23.4078 13.1955 22.8924 13.7103L19.4833 17.1194L16.0741 13.7103C15.5507 13.2048 14.7159 13.219 14.2098 13.7424C13.7167 14.2534 13.7167 15.063 14.2098 15.574L17.6189 18.9831L14.2098 22.3923C13.7043 22.9157 13.7186 23.7505 14.2419 24.2566C14.753 24.7497 15.5625 24.7497 16.0735 24.2566L19.4827 20.8475L22.8918 24.2566C23.4152 24.7621 24.25 24.7479 24.7561 24.2245C25.2492 23.7135 25.2492 22.9039 24.7561 22.3929L21.347 18.9837L24.7561 15.5746C25.2709 15.0598 25.2709 14.2256 24.7561 13.7103H24.7567Z"
        fill={color || theme.risk3}
      />
      <path
        d="M19.4833 3.16406C10.7469 3.16406 3.66406 10.2469 3.66406 18.9833C3.66406 27.7197 10.7469 34.8025 19.4833 34.8025C28.2197 34.8025 35.3025 27.7197 35.3025 18.9833C35.2932 10.2506 28.216 3.17333 19.4833 3.16406ZM19.4833 32.1657C12.2027 32.1657 6.3008 26.2638 6.3008 18.9833C6.3008 11.7027 12.2027 5.8008 19.4833 5.8008C26.7638 5.8008 32.6657 11.7027 32.6657 18.9833C32.6577 26.2607 26.7601 32.1577 19.4833 32.1657Z"
        fill={color || theme.risk3}
      />
    </svg>
  );
};
