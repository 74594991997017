import React from "react";
import styles from "../onboardingform.module.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import FormError from "../../Components/FormError";
import CTAButton from "../../Components/CTAButton";
// utils
import { isPhoneNumber } from "../../Services/Utils";

const ChangePhoneNumForm = (props) => {
  return (
    <Form
      onSubmit={(values) => {
        // The tweak to the onSubmit is necessary to work with both User & Service User
        props.onSubmit(values.phone, props.fieldName, props.indexOfSU); // props.fieldName is required to update Service User, but not required to update the Dashboard User Profile
      }}
      validate={(values) => {
        const errors = {};
        if (!values.phone) {
          errors.phone = "Required";
        }
        if (values.phone && !isPhoneNumber(values.phone)) {
          errors.phone = "Invalid phone number";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, valid, values }) => (
        <form
          className={styles.form}
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <div>
            <Field name="phone" initialValue={props.phone}>
              {({ input, meta }) => (
                <div className={styles.input}>
                  <label>
                    {props.title ? props.title : "Phone number"}
                    <input {...input} type="text" />
                  </label>
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>

            {props.error && <FormError error={props.error} />}
          </div>

          <CTAButton
            type="submit"
            disabled={submitting || !valid || values.phone === props.phone}
            className={styles.submit}
            cypresstestid="Change-PhoneNumber-Btn-Submit"
          >
            Update
          </CTAButton>
        </form>
      )}
    />
  );
};

export default ChangePhoneNumForm;
