import React from "react";
import styles from "./styles.module.scss";

export const FilterModal = ({
  choices,
  selectedFilter,
  setSelectedFilter,
  setShowFilterMenu,
  className,
}) => {
  const getFilteredList = (choice) => {
    setShowFilterMenu(false);
    setSelectedFilter(choice);
  };

  return (
    <div
      className={`${styles.filter_modal_container} ${className}`}
      onMouseLeave={() => setShowFilterMenu(false)}
    >
      {Object.entries(choices).map(([key, value]) => (
        <div
          className={`
            ${styles.filter_choice_item} 
            ${selectedFilter === key && styles.filter_is_selected}
          `}
          onClick={() => getFilteredList(key)}
          key={key}
        >
          <p className={styles.choice_label}>{value}</p>
        </div>
      ))}
    </div>
  );
};
