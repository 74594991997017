import React from "react";
import theme from "../../Styles/theme.scss";
import styles from "./styles.module.scss";
import { CircledTickIcon } from "../../Styles/Icons/DesignSystem/CircledTickIcon";

const PasswordChecklist = ({
  isPasswordTooShort,
  isPasswordWithNumber,
  isPasswordWithLetter,
  isMatchingPasswords,
}) => {
  return (
    <div className={styles.password_checklist_container}>
      <div className={styles.password_container}>
        <CircledTickIcon
          color={isPasswordTooShort ? theme.neutral6 : theme.expected2}
        />
        <p
          style={{
            color: theme.neutral6,
          }}
        >
          Min 12 characters
        </p>
      </div>

      <div className={styles.password_container}>
        <CircledTickIcon
          color={!isPasswordWithNumber ? theme.neutral6 : theme.expected2}
        />
        <p
          style={{
            color: theme.neutral6,
          }}
        >
          1 number
        </p>
      </div>
      <div className={styles.password_container}>
        <CircledTickIcon
          color={!isPasswordWithLetter ? theme.neutral6 : theme.expected2}
        />
        <p
          style={{
            color: theme.neutral6,
          }}
        >
          1 letter
        </p>
      </div>
      <div className={styles.password_container}>
        <CircledTickIcon
          color={!isMatchingPasswords ? theme.neutral6 : theme.expected2}
        />
        <p
          style={{
            color: theme.neutral6,
          }}
        >
          Passwords match
        </p>
      </div>
    </div>
  );
};

export default PasswordChecklist;
