export const filterEventsByTimeStamp = (
  series,
  timeKey,
  startTime,
  endTime
) => {
  const result = series?.filter(
    (event) => event?.[timeKey] >= startTime && event?.[timeKey] <= endTime
  );
  return result;
};
