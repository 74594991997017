import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import PrimaryButton from "../PrimaryButton";
import { FilterModal } from "../FilterModal";
import { UpArrow, DownArrow } from "../../Styles/Icons/DesignSystem";
import { FILTERS, INSTALLER_FILTERS } from "../../Services/Constants";

const DashboardFilter = ({
  selectedTab,
  selectedFilter,
  setSelectedFilter,
  setShowFilterMenu,
  showFilterMenu,
  userIsJustAnInstaller,
}) => {
  return (
    <div className={styles.dashboard_filter_container}>
      <PrimaryButton
        backgroundColour={theme.white}
        colour={theme.neutral7}
        hoverColour={theme.white}
        onClick={() => setShowFilterMenu((state) => !state)}
        endIcon={
          showFilterMenu ? (
            <UpArrow color="white" />
          ) : (
            <DownArrow color={theme.neutral7} />
          )
        }
        className={`
          ${showFilterMenu ? styles.active_filter : styles.inactive_filter}
          ${styles.filter_button}
        `}
      >
        {userIsJustAnInstaller
          ? INSTALLER_FILTERS[selectedFilter]
          : FILTERS[selectedFilter]}
      </PrimaryButton>

      {selectedTab === "users" && showFilterMenu && (
        <FilterModal
          choices={userIsJustAnInstaller ? INSTALLER_FILTERS : FILTERS}
          setSelectedFilter={setSelectedFilter}
          setShowFilterMenu={setShowFilterMenu}
          selectedFilter={selectedFilter}
          className={styles.dashboard_filter_modal}
        />
      )}
    </div>
  );
};

export default DashboardFilter;
