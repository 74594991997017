export const MOTION_SENSOR_INSTALLATION_OPTIONS = [
  {
    key: "Motion sensor stand",
    value:
      "Where there is a suitbale shelf or mantelpeice to place the sensor.",
    image: "/images/installation/sensors/motion/motion-stand.png",
  },
  {
    key: "Adhesive strip",
    value: "Where there is a smooth surface to stick the sensor.",
    image: "/images/installation/sensors/motion/motion-adhesive.png",
  },
  {
    key: "Mounting bracket",
    value: "A more permanent installation that requires screws and drilling.",
    image: "/images/installation/sensors/motion/motion-mount.png",
  },
];

const HINTS = [
  "Keep this sensor away from sources of heat like heating vents, space heaters, fireplaces, or windows facing direct sunlight.",
];

const LAST_STEP =
  "Check the motion sensor is working by waving a hand in front of it. It should flash green.";

export const MOTION_METHOD_HEADERS = {
  "Mounting bracket": {
    alt: "Mounting bracket",
    image: "/images/installation/sensors/motion/motion-mount.png",
    width: 180,
    title: "Mounting Bracket",
  },
  "Adhesive strip": {
    alt: "Adhesive strip",
    image: "/images/installation/sensors/motion/motion-adhesive.png",
    title: "Adhesive Strip",
    width: 180,
  },
  "Motion sensor stand": {
    alt: "Motion sensor stand",
    image: "/images/installation/sensors/motion/motion-stand.png",
    title: "Motion Sensor Stand",
    height: "50%",
  },
};

export const MOTION_INSTRUCTIONS = {
  "Mounting bracket": [
    {
      hint: HINTS,
    },
    {
      text: "1. Open the casing of the device by pushing the fastening on the top of the device to remove the front cover from the back cover.",
    },
    {
      text: "2. With a pencil make a mark on the wall through the oval holes on the left and right hand side of the sensor base.",
    },
    {
      text: "3. Mount the sensor on the wall with the screws from the bag marked 'A'. Screw one through each oval hole.",
    },
    {
      text: "4. Once attached to the wall, add batteries and replace front cover.",
    },
    {
      text: LAST_STEP,
    },
  ],
  "Adhesive strip": [
    {
      hint: HINTS,
    },
    {
      text: "1. Open the casing of the device by pushing the fastening on the top of the device to remove the front cover from the back cover.",
    },
    {
      text: "2. Insert the enclosed batteries into the sensor and close the casing.",
    },
    { text: "3. Remove the backing paper to expose the adhesive pads." },
    { text: "4. Stick the motion sensor to the wall." },
    {
      text: LAST_STEP,
    },
  ],
  "Motion sensor stand": [
    {
      hint: HINTS,
    },
    {
      text: "1. Open the casing of the device by pushing the fastening on the top of the device to remove the front cover from the back cover.",
    },
    {
      text: "2. Insert the enclosed batteries into the sensor and close the casing.",
    },
    {
      text: "3. Insert the plastic stand in the opening on the back of the sensor.",
    },
    {
      text: "4. Place the motion sensor facing into the room in an area where it is unlikely to be disturbed.",
    },
    {
      text: LAST_STEP,
    },
  ],
};
