import { getSortByObjectKey } from "../getSortByObjectKey";
import { format, formatDuration, intervalToDuration, endOfDay } from "date-fns";

const getOneDayOfBathroomData = (array, endDate) => {
  const oneDayOfActivities = array?.filter(
    (day) => endOfDay(new Date(day?.date)) !== endOfDay(new Date(endDate))
  );
  return oneDayOfActivities?.[0];
};

const convertToMinutes = (time) => {
  const hours = time?.split(":")[0]; // 3:15 ==> 3
  const minutes = time?.split(":")[1]; // 3:15 ==> 15
  const result = parseInt(hours * 60) + parseInt(minutes);
  return result;
};

const getFormattedEventLogData = (oneDayOfData) => {
  return oneDayOfData?.activityData?.map((activity) => ({
    ...activity,
    timePeriod: `${format(
      new Date(activity?.originalStart),
      "h:mmaaa"
    )} - ${format(new Date(activity?.originalEnd), "h:mmaaa")}`,
    duration: formatDuration(
      intervalToDuration({
        start: new Date(activity?.originalStart),
        end: new Date(activity?.originalEnd),
      }),
      {
        format: ["days", "hours", "minutes"],
      }
    ),
    y: convertToMinutes(format(new Date(activity?.startedAt), "HH:mm")),
    y0: convertToMinutes(format(new Date(activity?.endedAt), "HH:mm")),
    x: 1, // an arbitrary number but if you decide to change it you must also change the range of the variable "X_DOMAIN = [0, 2];" inside the VictoryBathroomLog.
  }));
};

export const getBathroomEventLogDataFromBathroomInference = (
  bathroomInference,
  urlEndDate
) => {
  const oneDayOfBathroomInferenceData = getOneDayOfBathroomData(
    bathroomInference,
    urlEndDate
  );

  // format as needed for the scrolling chart so there are x, y, and y0 values
  const formattedBathroomInferenceData =
    getFormattedEventLogData(oneDayOfBathroomInferenceData, urlEndDate) || [];

  // format as needed for the <Event/> component
  const formattedEventLogData = formattedBathroomInferenceData?.map((event) => {
    return {
      ...event,
      timePeriod: event.timePeriod,
      type: event.eventType,
    };
  });

  // Sort the combined events by time
  const sortedEvents = getSortByObjectKey(formattedEventLogData, "startedAt");

  return sortedEvents;
};
