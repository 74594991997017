import React from "react";
import styles from "./styles.module.scss";

const ToggleOnOff = ({
  isEnabled,
  onToggleOn,
  onToggleOff,
  backgroundColor,
}) => {
  return (
    <div className={styles.toggle_box}>
      <div
        className={`${styles.toggle_container}`}
        style={{ backgroundColor: isEnabled ? backgroundColor : "" }}
        onClick={() => {
          isEnabled ? onToggleOff() : onToggleOn();
        }}
      >
        <div
          className={`${styles.slider} ${isEnabled ? styles.toggled : ""}`}
        ></div>
      </div>
      <div className={styles.toggle_text}>
        {isEnabled ? "Enabled" : "Disabled"}
      </div>
    </div>
  );
};

export default ToggleOnOff;
