import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { Form, Field } from "react-final-form";
// Components
import FormError from "../../Components/FormError";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import FormInput from "../../Components/FormInput";
// Notes:
// "meta" refers to the properties related to the form
// "meta.error" are the form related errors (i.e. validation)
// "meta.touched" means the input field has been interacted with
// "props.error" are things like API response error messages

const LoginForm = ({
  forgotPassword,
  error,
  onSubmit,
  setUsername,
  setPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form
      // Whitespaces are not allowed on username creation, they shouldn't exist on login
      onSubmit={(values) => {
        setUsername(values?.username?.trim());
        setPassword(values.password);
        onSubmit({
          username: values?.username?.trim(),
          password: values.password,
        });
      }}
      validate={(values) => {
        const errors = {};
        if (!values.username) {
          errors.username = "Username is required";
        }
        if (!values.password) {
          errors.password = "Password is required";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, pristine, valid }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.form}>
            <Field name="username">
              {({ input, meta }) => {
                return (
                  <FormInput
                    input={input}
                    label="Username"
                    placeholder="e.g. JSmith"
                    isPassword={false}
                    setValue={setUsername}
                    hasInput={!meta.error && meta.touched}
                    meetsAllCritera={!!input.value}
                    // NOTE!
                    // Do not pass the "isActive" prop as this does not work correectly with autofill
                  />
                );
              }}
            </Field>
            <Field name="password">
              {({ input, meta }) => (
                <FormInput
                  input={input}
                  label="Password"
                  placeholder="Enter your password"
                  isPassword={true}
                  setValue={setPassword}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  hasInput={!meta.error && meta.touched}
                  meetsAllCritera={!!input.value}
                  // NOTE!
                  // Do not pass "isActive" prop as this does not work correectly with autofill
                />
              )}
            </Field>
            {error && <FormError error={error} className={styles.error} />}
            <div className={styles.forgot_password}>
              <SecondaryButton
                onClick={() => forgotPassword()}
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Forgot password
              </SecondaryButton>
            </div>
            <div className={styles.submit_login}>
              <PrimaryButton
                type="submit"
                disabled={submitting || pristine || !valid}
                style={{
                  width: "100%",
                  alignSelf: "flex-end",
                  justifyContent: "center",
                  fontSize: theme.bodySmall,
                  fontWeight: theme.regular,
                  transition: "none",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Login
              </PrimaryButton>
            </div>
          </form>
        );
      }}
    />
  );
};

export default LoginForm;
