import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import SecondaryButton from "../SecondaryButton";
import { useNavigate } from "react-router-dom";

const GuestPageFooter = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.guestPage_footer}>
      <p>
        New to Lilli?{" "}
        <a
          href="https://www.intelligentlilli.com/"
          target="_blank"
          rel="noreferrer"
        >
          Learn more here
        </a>
        .
      </p>

      <p>
        By clicking the "Login" button, you are agreeing to the Lilli{" "}
        <SecondaryButton onClick={() => navigate("/privacy")}>
          Privacy Policy
        </SecondaryButton>
      </p>
    </div>
  );
};
const GuestPage = (props) => {
  return (
    <div
      className={`${styles.guestPage} ${
        props.removeSandBackground && styles.whiteBackground
      }`}
    >
      {(props.title || props.subtitle) && (
        <div className={styles.guestPage_header}>
          {!props.noTitles && <div>{props.title}</div>}
          {!props.noTitles && <div>{props.subtitle}</div>}
        </div>
      )}
      <div className={styles.guestPage_content}>
        <div className={styles.guestPage_content_innner}>{props.children}</div>
      </div>
      <GuestPageFooter />
    </div>
  );
};

GuestPage.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default GuestPage;
