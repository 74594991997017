import React from "react";
import styles from "./styles.module.scss";
import { AddIcon } from "../../Styles/Icons";
import PrimaryButton from "../PrimaryButton";
import CreateStaffSplash from "../CreateStaffSplash";
import NewStyleModal from "../NewStyleModal";
import { useViewport } from "../ViewportProvider";
// forms
import AddUserForm from "../../Forms/AddUserForm";
// hooks
import { useGetUserRole, useAddNewStaff, useFade } from "../../Services/Hooks";

const DashboardButtonsStaff = () => {
  const { width } = useViewport();
  const isMobile = width <= 1200;
  // custom hooks
  const { userIsManagerOrAdmin } = useGetUserRole();
  const {
    newUserDescription,
    newUserRoles,
    onAddStaffMember,
    resetFormState,
    setNewUserRoles,
    serviceUsersAssigned,
    showSplashScreen,
    isProcessingForm,
    formSuccess,
    staffCreated,
    createStaffEmailError,
    setCreateStaffError,
    addingUser,
    setAddingUser,
    serviceUsersToSelect,
  } = useAddNewStaff();
  // Custom hooks - Modal to fade in and fade out
  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  const closeForm = () => {
    setShowAnimation(false);
  };
  const buttons = [
    { key: "Installer", value: "installer" },
    { key: "Staff", value: "installer|user" },
    {
      key: "Manager",
      value: "installer|manager",
    },
  ];

  switch (userIsManagerOrAdmin) {
    case true:
      return (
        <div className={styles.dashboard_buttons}>
          {buttons.map((button, index) => (
            <PrimaryButton
              key={`${button.key}${index}`}
              onClick={() => {
                setAddingUser(true);
                setShowAnimation(true);
                resetFormState();
                setNewUserRoles(button);
              }}
              startIcon={<AddIcon />}
              className={styles.dashboard_button}
            >
              {isMobile ? "" : "New "}
              {button.key}
            </PrimaryButton>
          ))}
          {isVisible && (
            <NewStyleModal
              showCloseIcon={showSplashScreen ? false : true}
              hide={() => {
                // Works in conjunction with the "CloseIcon" inside the modal
                setAddingUser(false);
                setShowAnimation(false);
                setCreateStaffError("");
                resetFormState();
              }}
              title={
                addingUser && !showSplashScreen
                  ? `Add new ${newUserRoles.key}`
                  : ""
              }
              context={
                addingUser && !showSplashScreen ? newUserDescription : ""
              }
              useFade={true}
              showAnimation={showAnimation}
            >
              {!showSplashScreen && (
                <AddUserForm
                  onSubmit={onAddStaffMember}
                  createStaffEmailError={createStaffEmailError}
                  serviceUsersToSelect={serviceUsersToSelect}
                  isLoading={isProcessingForm}
                  typeOfUser={newUserRoles?.key}
                />
              )}
              {showSplashScreen && (
                <CreateStaffSplash
                  onSubmit={closeForm}
                  staff={staffCreated}
                  serviceUsersAssigned={serviceUsersAssigned}
                  isSuccess={formSuccess}
                  typeOfUser={newUserRoles?.key}
                />
              )}
            </NewStyleModal>
          )}
        </div>
      );
    default:
      return <></>;
  }
};

export default DashboardButtonsStaff;
