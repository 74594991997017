export const SMARTPLUG_INSTRUCTIONS = [
  {
    hint: ["Plug in the most used kitchen appliance for eating or drinking."],
  },
  { text: "1. Unplug the device" },
  { text: "2. Plug the smart plug into the wall." },
  {
    image: {
      image: "/images/installation/sensors/plug/plug-in.png",
      alt: "Plugged in power sensor",
      height: 120,
    },
  },
  {
    text: "3. Plug the device into the smart plug.",
  },
  {
    text: "4. The LED on the side of the smart plug should turn green, if not push the LED button.",
  },
  {
    text: "5. Once you can see the green light test the connection.",
  },
  {
    image: {
      image: "/images/installation/sensors/plug/plug-on.png",
      alt: "Connected power sensor",
      height: 120,
    },
  },
];
