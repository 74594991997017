import React from "react";
import styles from "./styles.module.scss";

const InsightSplitLeftRight = ({ left, right }) => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <h2>{left.number}</h2>
        <div>
          {left.context}
          {left.number === 1 ? "" : "s"}
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.item}>
        <h2>{right.number}</h2>
        <div>
          {right.context}
          {right.number === 1 ? "" : "s"}
        </div>
      </div>
    </div>
  );
};

export default InsightSplitLeftRight;
