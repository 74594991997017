import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { CalendarIcon } from "../../Styles/Icons/DesignSystem";
import { ForwardIcon } from "../../Styles/Icons/DesignSystem";
import { DateRangePicker as BaseDateRangePicker } from "react-date-range";
import "./datePickerStyles.scss"; // css file for the date picker
import { format } from "date-fns";
import theme from "../../Styles/theme.scss";
import { createStaticRanges } from "./getStaticDateRanges";
import PrimaryButton from "../PrimaryButton";

const DateRangePicker = ({
  dateRange,
  setDateRange,
  showPicker,
  setShowPicker,
  minDate,
  maxDate,
  highlightRequired,
}) => {
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [endDateSelected, setEndDateSelected] = useState(false);

  // if showPicker is false deselect the date range inputs
  useEffect(() => {
    if (!showPicker) {
      setStartDateSelected(false);
      setEndDateSelected(false);
    }
  }, [showPicker]);

  const getInputStyles = (showPicker, selected, required) => {
    // Where we want to highlight the input as required regardless of whether it is selected add a red border
    if (required) {
      return styles.container_range_input_required;
    }
    // If the date range picker is open and the input is selected add a primary border
    if (showPicker && selected) {
      return styles.container_range_input_selected;
    }
    return styles.container_range_input;
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_range}>
        <div>
          <label>Report start date</label>
          <div
            onClick={() => {
              setShowPicker(true);
              setStartDateSelected(true);
            }}
            className={getInputStyles(
              showPicker,
              startDateSelected,
              highlightRequired
            )}
          >
            <CalendarIcon
              colour={
                showPicker && startDateSelected
                  ? theme.neutral6
                  : theme.neutral4
              }
            />
            <p>
              {dateRange.dateYetToBeSelected
                ? "Add date"
                : format(dateRange.startDate, "dd/MM/yyyy")}
            </p>
          </div>
        </div>
        <div className={styles.arrow}>
          <ForwardIcon />
        </div>
        <div>
          <label>Report end date</label>
          <div
            onClick={() => {
              setShowPicker(true);
              setEndDateSelected(true);
            }}
            className={getInputStyles(
              showPicker,
              endDateSelected,
              highlightRequired
            )}
          >
            <CalendarIcon
              colour={
                showPicker && endDateSelected ? theme.neutral6 : theme.neutral4
              }
            />
            <p>
              {dateRange.dateYetToBeSelected
                ? "Add date"
                : format(dateRange.endDate, "dd/MM/yyyy")}
            </p>
          </div>
        </div>
      </div>
      {showPicker && (
        <div className={styles.container_picker}>
          <BaseDateRangePicker
            months={2}
            direction="horizontal"
            ranges={[{ ...dateRange, key: "selection" }]}
            showMonthAndYearPickers={false}
            showDateDisplay={false}
            inputRanges={[]}
            minDate={minDate}
            maxDate={maxDate}
            shownDate={maxDate}
            preventSnapRefocus
            staticRanges={createStaticRanges(minDate, maxDate)}
            onChange={(range) => {
              setDateRange({
                startDate: range.selection.startDate,
                endDate: range.selection.endDate,
              });
            }}
          />
          <PrimaryButton
            disabled={!dateRange.endDate || !dateRange.startDate}
            onClick={() => setShowPicker(false)}
          >
            Select dates
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};
export default DateRangePicker;
