import React from "react";
import styles from "./styles.module.scss";
import NewStyleModal from "../NewStyleModal";
import { DeleteReportIcon } from "../../Styles/Icons/DesignSystem";
import DeleteButton from "../DeleteButton";
import PrimaryButton from "../PrimaryButton";
import theme from "../../Styles/theme.scss";

function ConfirmDeleteReportModal({
  setCloseConfirmModal,
  reportToDelete,
  deleteSelectedReport,
}) {
  return (
    <NewStyleModal
      showCloseIcon={false}
      hide={() => setCloseConfirmModal(true)}
      contextClass={styles.contextClass}
      cypresstestid="Delete-Report-Btn-Submit"
    >
      <div className={styles.delete}>
        <DeleteReportIcon />
        <h1>
          Delete {reportToDelete?.typeid} report for{" "}
          {reportToDelete?.userstring}?
        </h1>
        <p>
          You are about to delete this report, this cannot be undone. Are you
          sure you want to delete it?
        </p>
        <div>
          <PrimaryButton
            backgroundColour={theme.white}
            padding={"9.25px 16px"}
            border={`1px solid ${theme.primary3}`}
            onClick={() => setCloseConfirmModal(true)}
            style={{ borderRadius: "8px", marginRight: "16px" }}
          >
            Cancel
          </PrimaryButton>
          <DeleteButton
            onClick={() => deleteSelectedReport(reportToDelete)}
            label="Delete"
            padding={"7.25px 16px"}
          />
        </div>
      </div>
    </NewStyleModal>
  );
}

export default ConfirmDeleteReportModal;
