import React from "react";
import styles from "./styles.module.scss";
import { useViewport } from "../ViewportProvider";
import { mobileBreakpoint } from "../../Services/config";
import { parseISO, formatDistance } from "date-fns";
import { getInitialsFromNames } from "@intelligentlilli/lilli-utils";
import {
  getIsUserJustAnInstaller,
  getUserHasInstallerRole,
  getUserRoleAsString,
} from "../../Services/Utils";
// Components
import { CalendarTodayIcon, MailOutlineIcon } from "../../Styles/Icons";
import ServiceUserImage from "../ServiceUserImage";
import PrimaryButton from "../PrimaryButton";

const StaffMemberData = ({ staffMember }) => (
  <div className={styles.userBio_info_data}>
    <div className={styles.userBio_info_data_item}>
      <CalendarTodayIcon />
      <span>
        <b>Active: </b>
        {formatDistance(parseISO(staffMember?.ts), new Date())}
      </span>
    </div>
    <div className={styles.userBio_info_data_item}>
      <MailOutlineIcon />
      <span>{staffMember.email}</span>
    </div>
  </div>
);

const StaffInfo = ({
  staffMember,
  thisIsYou,
  userIsManagerOrAdmin,
  addServiceUser,
  profilePhoto,
}) => {
  // Getting the width of the page for conditional rendering
  const { width } = useViewport();

  const staffName =
    staffMember?.forenames && staffMember?.surname
      ? staffMember?.forenames + " " + staffMember?.surname
      : staffMember?.username;

  const prettyStaffName = thisIsYou ? `${staffName} (this is you)` : staffName;

  const addAServiceUserFor = `${
    thisIsYou
      ? "Assign yourself another service user"
      : `Assign a service user to ${staffMember?.forenames ?? staffName}`
  }`;

  const staffRoles = staffMember?.roles;
  const staffRole = getUserRoleAsString(staffRoles);
  const isInstaller = getUserHasInstallerRole(staffRoles);
  const staffIsJustAnInstaller = getIsUserJustAnInstaller(staffRoles);
  const displayUserRole = staffIsJustAnInstaller
    ? "Installer"
    : `${staffRole}${isInstaller ? " & Installer" : ""}`;

  return (
    <div className={styles.userBio}>
      <div className={styles.userBio_left}>
        <ServiceUserImage
          medium={width <= mobileBreakpoint}
          initials={getInitialsFromNames(
            staffMember?.forenames,
            staffMember?.surname
          )}
          profilePhotoURL={profilePhoto}
          staff
          largePill={width > mobileBreakpoint}
        />
        <div className={styles.userBio_info}>
          <h1 className={styles.userBio_info_name}>{prettyStaffName}</h1>
          <div className={styles.userBio_info_id}>Role: {displayUserRole}</div>
          {width > mobileBreakpoint && staffMember && (
            <StaffMemberData staffMember={staffMember} />
          )}
        </div>
      </div>
      {width <= mobileBreakpoint && staffMember && (
        <StaffMemberData staffMember={staffMember} />
      )}

      {/* By default, if you're not manager/admin, then you're Staff */}
      {/* This is because only staff members can see this page (this is set inside the SideDrawer component) */}
      {/* We do allow staff to assign SUs to other colleagues */}
      {/* But, if you're staff & this is your own staff page, you cannot add SUs to yourself */}
      {/* Reason being - all the SUs you can assign will already be assigned to you */}
      {(userIsManagerOrAdmin || !thisIsYou) && (
        <PrimaryButton
          onClick={addServiceUser}
          style={{ marginTop: "18px" }}
          className={styles.userBio_button}
        >
          {addAServiceUserFor}
        </PrimaryButton>
      )}
    </div>
  );
};

export default StaffInfo;
