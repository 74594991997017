import React from "react";
import theme from "../theme.scss";

export const NotificationsUnderline = () => {
  return (
    <svg width="293" height="1" fill="none">
      <line y1="0.5" x2="293" y2="0.5" stroke={theme.neutral2} />
    </svg>
  );
};
