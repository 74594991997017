import React from "react";
import styles from "./styles.module.scss";
import DashboardNotificationItem from "../DashboardNotificationItem";
import { NotificationsUnderline } from "../../Styles/Shapes/NotificationsUnderline";

const DashboardNotificationsList = ({ notificationsArray }) => {
  return (
    <div className={styles.notifications_list_container}>
      {notificationsArray?.map((notification, index) => (
        <DashboardNotificationItem
          hubID={notification.hubId}
          behaviour={notification.behaviour}
          notificationText={notification.sentence}
          timestamp={notification.analysisDate}
          key={index}
          riskStatus={notification.riskStatus}
        />
      ))}
      <div className={styles.underline_container}>
        <NotificationsUnderline />
      </div>
      <div className={styles.no_more_notifications_text}>
        No more notifications today
      </div>
    </div>
  );
};

export default DashboardNotificationsList;
