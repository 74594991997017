import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SERVER } from "../config";
import { updateUser } from "../../State/slices/user-slice";
// utils
import { formatNotificationsResponse } from "../Utils";
// api
import { getUserNotifications } from "@intelligentlilli/api-layer";

// This is a custom hook to handle fetching notifications while the app is open
export const useFetchNotifications = () => {
  // Redux state
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    // Gets the latest triage notifications
    const fetchNotifications = async () => {
      try {
        const notificationsResponse = await getUserNotifications(
          SERVER,
          user.key,
          new Date().toISOString(),
          "7",
          "web"
        );
        if (notificationsResponse.ok) {
          /* 
          The API sends back notifications that can have multiple sentences about different behaviours for a service user. 
          We need to split each of these sentences into a notification in it's own right 
        */
          const rawNotifications = notificationsResponse.body || [];
          const parsedNotifications =
            formatNotificationsResponse(rawNotifications);
          const sortedParsedNotifications = parsedNotifications.sort((a, b) =>
            a.analysisDate < b.analysisDate ? 1 : -1
          );
          // update notifications in state
          dispatch(
            updateUser({
              notifications: {
                notificationsData: sortedParsedNotifications,
                lastUpdated: new Date().toISOString(),
              },
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    // Call the async fetch notifications function
    if (user?.key) {
      fetchNotifications().catch((e) => console.log(e));
    }

    // Re-fetch notifications every 2 minutes (120,000ms)
    const interval = setInterval(() => {
      fetchNotifications().catch((e) => console.log(e));
    }, 120000);
    // Clear up the interval when the app unmounts
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, user?.key]);
};
