import React from "react";
import DashboardButtonsReports from "../DashboardButtonsReports";
import DashboardButtonsUsers from "../DashboardButtonsUsers";
import DashboardButtonsStaff from "../DashboardButtonsStaff";

const DashboardButtons = ({ selectedTab }) => {
  switch (selectedTab) {
    case "staff":
      return <DashboardButtonsStaff />;
    case "friends & family":
      return;
    case "reports":
      return <DashboardButtonsReports />;
    default:
      return <DashboardButtonsUsers />;
  }
};

export default DashboardButtons;
