export const HUB_INSTRUCTIONS = [
  {
    text: "1. The biggest box in your package is the Lilli hub.",
  },
  {
    text: "2. Plug in the Lilli hub in a central location in the home (see tips).",
  },
  {
    hint: [
      "Try to find a spot for the Lilli hub that isn't behind any large objects or in a cupboard, as this will make it harder to receive the signals from the sensor network.",
    ],
  },
];
