import React from "react";
import styles from "./styles.module.scss";
// Components
import ServiceUserImage from "../ServiceUserImage";
// Utils
import { getInitialsFromNames } from "@intelligentlilli/lilli-utils";
import {
  getIsUserJustAnInstaller,
  getUserHasInstallerRole,
  getUserRoleAsString,
} from "../../Services/Utils";

const StaffListItem = ({ staffMember, onClick }) => {
  const staffName =
    staffMember?.forenames && staffMember?.surname
      ? staffMember?.forenames + " " + staffMember?.surname
      : staffMember?.username;
  const staffRoles = staffMember?.roles;
  const staffRoleAsString = getUserRoleAsString(staffRoles);
  const staffHasInstallerRole = getUserHasInstallerRole(staffRoles);
  const staffIsJustInstaller = getIsUserJustAnInstaller(staffRoles);
  const displayStaffRole = staffIsJustInstaller
    ? "Installer"
    : `${staffRoleAsString}${staffHasInstallerRole ? " & Installer" : ""}`;

  return (
    <>
      {/* Greater than 700px */}
      <div onClick={onClick} className={styles.list_container_lrg}>
        <div
          className={`${styles.staff_member_lrg} ${styles.list_element_lrg}`}
        >
          <ServiceUserImage
            small
            staff
            initials={getInitialsFromNames(
              staffMember?.forenames,
              staffMember?.surname
            )}
            profilePhotoURL={staffMember.photo}
          />
          <div className={styles.staff_details}>{staffName}</div>
        </div>

        <div className={styles.list_element_lrg}>{displayStaffRole}</div>
        <div className={styles.list_element_lrg}>{staffMember.hubs.length}</div>
      </div>

      {/* Smaller than 700px */}
      <div onClick={onClick} className={styles.list_container_sm}>
        <div className={styles.list_element_sm}>
          <ServiceUserImage
            small
            staff
            initials={getInitialsFromNames(
              staffMember.forenames,
              staffMember.surname
            )}
            profilePhotoURL={staffMember.photo}
          />
          <div className={`${styles.staff_member_sm} ${styles.staff_details}`}>
            {staffName}
          </div>
          <div className={styles.staff_details}>{displayStaffRole}</div>
        </div>
        <div className={styles.list_element_sm}>{staffMember.hubs.length}</div>
      </div>
    </>
  );
};

export default StaffListItem;
