import React from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import DashboardNotificationsList from "../../Components/DashboardNotificationsList";
import AwaitingInstallButton from "../../Components/AwaitingInstallButton";
// Utils
import {
  getNotificationsForAGivenDate,
  getIsUserJustAnInstaller,
} from "../../Services/Utils";
// Hooks
import { useGetServiceUserList } from "../../Services/Hooks";

const DashboardNotificationsSection = ({
  setSelectedFilter,
  setSelectedTab,
}) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const userRoles = user?.roles;
  const userIsJustAnInstaller = getIsUserJustAnInstaller(userRoles);

  // number awaiting install
  const { serviceUsersToDisplay } = useGetServiceUserList("incomplete", "");
  // show all of today's notifications (including those marked as 'read')
  const notifications = useSelector(
    (state) => state.user?.notifications?.notificationsData || []
  );
  const todaysNotifications = getNotificationsForAGivenDate(
    new Date(),
    notifications
  );
  return (
    <div className={styles.notifications_container}>
      <div className={styles.notifications_header_container}>
        <div className={styles.notifications_title_text}>Notifications</div>
        <div
          className={styles.view_all_text}
          onClick={() => navigate("/notifications")}
        >
          View all
        </div>
      </div>
      <AwaitingInstallButton
        numberAwaitingInstall={serviceUsersToDisplay?.length || 0}
        setSelectedFilter={setSelectedFilter}
        setSelectedTab={setSelectedTab}
      />
      <div className={styles.today_title_text}>Today</div>
      {!userIsJustAnInstaller && (
        <DashboardNotificationsList notificationsArray={todaysNotifications} />
      )}
    </div>
  );
};

export default DashboardNotificationsSection;
