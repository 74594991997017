import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import HowItWorksQuestions from "../HowItWorksQuestions";
import HowItWorksAnswers from "../HowItWorksAnswers";
import { CloseIcon } from "../../Styles/Icons";

const HowTheBehaviourWorks = ({ behaviour, questionsAndAnswers, hide }) => {
  const [selectedQandA, setSelectedQandA] = useState(questionsAndAnswers[0]);

  return (
    <div className={styles.how_it_works_container}>
      <div className={styles.how_it_works_header}>
        <h2>How the {behaviour} behaviour works</h2>
        <div onClick={hide}>
          <CloseIcon color={theme.neutral4} className={styles.modal_close} />
        </div>
      </div>

      <div className={styles.how_it_works_content}>
        <div className={styles.questions}>
          <HowItWorksQuestions
            questions={questionsAndAnswers}
            selected={selectedQandA}
            setSelected={setSelectedQandA}
          />
        </div>
        <div className={styles.answers}>
          <HowItWorksAnswers selected={selectedQandA} />
        </div>
      </div>
    </div>
  );
};

export default HowTheBehaviourWorks;
