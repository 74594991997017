import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { MapInteractionCSS } from "react-map-interaction";
// State
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../State/slices/session-slice";
// Reports
import CareAct from "../../Reports/CareAct";
// Components
import Page from "../../Components/Page";
import NoDataBox from "../../Components/NoDataBox";
import { useViewport } from "../../Components/ViewportProvider";
// Utils
import { getReport } from "@intelligentlilli/api-layer";
import PrimaryButton from "../../Components/PrimaryButton";

function PdfPreviewV2() {
  // Get the service user from redux
  const server = useSelector((state) => state.session.server);
  const dispatch = useDispatch();
  // Dimensions
  const { width } = useViewport();
  // Get the report ID from the parameters
  const { id } = useParams();
  // Local state
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  const reportSizeBreakpoint = 1150;

  // Get the behaviour data for the hub
  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      getReport(server, id, "web")
        .then((res) => {
          dispatch(setLoading(false));
          if (res.ok) {
            setData(res.body);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          dispatch(setLoading(false));
          setError(true);
        });
    }
  }, [id, server, dispatch]);

  const Survey = () => (
    <div className={styles.survey}>
      <h1>We'd like to ask you a few questions</h1>
      <p>Help us improve our reports by answering a few questions</p>
      <PrimaryButton
        onClick={() =>
          window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSc8nQREJRG7cWJcqjP93YhZPklLPEYFY2sq8AQes5nvewxqUg/viewform",
            "_blank"
          )
        }
      >
        Take the survey
      </PrimaryButton>
    </div>
  );

  return (
    <Page className={styles.page}>
      {data && width > reportSizeBreakpoint && !error && (
        <div className={styles.report}>
          <Survey />
          <CareAct reportData={data} isPdf={false} />
        </div>
      )}
      {(error || !data) && (
        <div className={styles.error_fetching_report}>
          <NoDataBox
            title={"Error fetching report"}
            text={"Check back later"}
          />
        </div>
      )}
      {data && width <= reportSizeBreakpoint && !error && (
        <div className={`${styles.report}`}>
          <Survey />
          <MapInteractionCSS
            defaultValue={{ scale: 0.5, translation: { x: 0, y: 0 } }}
            translationBounds={{
              xMin: -700,
              xMax: 300,
              yMin: -10000, // This is an arbitrary translation bound. It may be we need to adjust it for longer reports
              yMax: 100,
            }}
            minScale={0.3}
            maxScale={1}
          >
            <CareAct reportData={data} isPdf={false} />
          </MapInteractionCSS>
        </div>
      )}
    </Page>
  );
}

export default PdfPreviewV2;
