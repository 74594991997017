import React from "react";
import theme from "../../theme.scss";

export const AtRiskInsightIcon = ({ size }) => {
  return (
    <svg
      width={size || "22"}
      height={size || "22"}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.7805 0.83733 6.79048C0.00476615 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9968 8.08359 20.8369 5.28753 18.7747 3.22531C16.7125 1.16309 13.9164 0.00315432 11 0V0ZM11 20.1667C9.18701 20.1667 7.41473 19.6291 5.90728 18.6218C4.39983 17.6146 3.22491 16.1829 2.53111 14.5079C1.83731 12.8329 1.65578 10.9898 2.00947 9.21167C2.36317 7.43351 3.23621 5.80017 4.51819 4.51819C5.80017 3.23621 7.43352 2.36317 9.21168 2.00947C10.9898 1.65577 12.8329 1.8373 14.5079 2.5311C16.1829 3.22491 17.6146 4.39982 18.6218 5.90727C19.6291 7.41472 20.1667 9.18701 20.1667 11C20.164 13.4303 19.1974 15.7604 17.4789 17.4789C15.7604 19.1974 13.4303 20.164 11 20.1667Z"
        fill={theme.risk3}
      />
      <path
        d="M11.0002 4.58301C10.757 4.58301 10.5239 4.67959 10.352 4.85149C10.1801 5.0234 10.0835 5.25656 10.0835 5.49967V12.833C10.0835 13.0761 10.1801 13.3093 10.352 13.4812C10.5239 13.6531 10.757 13.7497 11.0002 13.7497C11.2433 13.7497 11.4764 13.6531 11.6483 13.4812C11.8203 13.3093 11.9168 13.0761 11.9168 12.833V5.49967C11.9168 5.25656 11.8203 5.0234 11.6483 4.85149C11.4764 4.67959 11.2433 4.58301 11.0002 4.58301Z"
        fill={theme.risk3}
      />
      <path
        d="M11.9168 16.4997C11.9168 15.9934 11.5064 15.583 11.0002 15.583C10.4939 15.583 10.0835 15.9934 10.0835 16.4997C10.0835 17.0059 10.4939 17.4163 11.0002 17.4163C11.5064 17.4163 11.9168 17.0059 11.9168 16.4997Z"
        fill={theme.risk3}
      />
    </svg>
  );
};
