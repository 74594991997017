import React, { useState, useEffect, useRef } from "react";
import theme from "../../Styles/theme.scss";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// API calls
import {
  getReportStatusV1,
  postPublishReport,
} from "@intelligentlilli/api-layer";
// Components
import ButtonSpinner from "../Spinners/ButtonSpinner";
import GoBackButton from "../GoBackButton";
import DatePicker from "../DatePicker";
import { useViewport } from "../ViewportProvider";
// Icons
import { LinkIcon, DownloadIcon } from "../../Styles/Icons/DesignSystem";
// Hooks
import { useReportsHook } from "../../Services/Hooks";
// Utils
import { format, addDays, subDays, parseISO } from "date-fns";
import {
  downloadReport,
  openInNewTab,
  capitalize,
} from "@intelligentlilli/lilli-utils";
import {
  removeUnderscore,
  updateOptions,
  getSelectedOptions,
  getArrayOfReportOptions,
} from "../../Services/Utils";

const Bottom = ({ children }) => {
  return <div className={styles.bottom}>{children}</div>;
};

const BottomLeft = ({ children }) => {
  return <div className={styles.bottom_left}>{children}</div>;
};

const BottomButtons = ({ children }) => {
  return <div className={styles.bottom_buttons}>{children}</div>;
};

const FreeTextSummary = ({ summary, onInputChange }) => {
  return (
    <div className={styles.text_area_container}>
      <label className={styles.summary_label}>Summary</label>
      <textarea
        data-private
        className={styles.text_area}
        value={summary}
        onChange={onInputChange}
        name="summary"
        placeholder="Enter your summary here..."
        maxLength={600}
        style={{
          resize: "none",
        }}
      />
    </div>
  );
};

const BehaviourOptions = ({
  noOptionsSelected,
  toggleCheckBox,
  removeUnderscore,
  behaviours,
}) => {
  const behaviourError = "You must select at least one behaviour.";

  return (
    <div className={styles.behaviour_container}>
      <label className={styles.behaviours_label}>Behaviours:</label>
      <div className={styles.behaviour_option}>
        {behaviours?.map((option, index) => {
          const optionName = Object.keys(option).join(""); // => { 'sustenance' : true } => ['sustenance'] => 'sustenance'
          const rawOptionValue = Object.values(option).join(""); // => { nighttime_activity: true }, { 'sustenance' : true } => [true] => "true" => true / false (boolean)
          const optionValue = rawOptionValue === ("true" || true);
          const optionLabel =
            optionName === "sustenance"
              ? "Eating & drinking"
              : optionName === "independence"
              ? "Time outside"
              : removeUnderscore(capitalize(optionName));
          const isDisabled =
            optionName === "temperature" || optionName === "bathroom_activity";
          return (
            <div
              className={
                isDisabled
                  ? styles.disabled_selected_behaviour
                  : styles.selected_behaviour
              }
              key={`${index}-${optionName}`}
              id={styles.tickbox}
            >
              <input
                className={styles.behaviour_tickbox}
                type="checkbox"
                id={optionName}
                name={optionName}
                checked={!isDisabled ? optionValue : false}
                value={optionValue}
                onChange={toggleCheckBox}
                disabled={isDisabled}
              ></input>
              <label htmlFor={optionName}>
                {optionLabel === "Nighttime activity"
                  ? "Night-time activity"
                  : optionLabel}
              </label>
            </div>
          );
        })}
      </div>
      <div className={styles.no_behaviour_selected}>
        {noOptionsSelected ? behaviourError : " "}
      </div>
    </div>
  );
};

const Calender = ({
  isDesktop,
  label,
  name,
  isStartDate,
  reportEndDate,
  form,
  onChange,
  minDate,
  maxDate,
}) => {
  const inputValue = isStartDate ? form?.reportStartDate : reportEndDate;
  return (
    <div
      className={
        isDesktop
          ? styles.desktop_datepicker_group
          : styles.mobile_calendar_group
      }
    >
      <label
        className={
          isDesktop
            ? styles.desktop_datepicker_label
            : styles.mobile_calendar_label
        }
      >
        {label}
      </label>
      <div
        id={isDesktop ? styles.select_date : undefined}
        className={isDesktop ? undefined : styles.mobile_calendar_box}
      >
        <DatePicker
          name={name}
          min={minDate}
          max={maxDate}
          value={inputValue || ""}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const DateRange = ({
  isDesktop,
  onInputChange,
  onChangeEndDate,
  reportEndDate,
  form,
  startMinDate,
  startMaxDate,
  endMinDate,
  endMaxDate,
}) => {
  const startDateRef = React.useRef();
  const endDateRef = React.useRef();

  return (
    <div
      className={
        isDesktop ? styles.desktop_calendar_range : styles.mobile_date_range
      }
    >
      <Calender
        isDesktop={isDesktop}
        isStartDate={true} // Start Date has different fields
        label="Start:"
        name="reportStartDate"
        onChange={onInputChange}
        form={form}
        reportEndDate={reportEndDate}
        minDate={startMinDate}
        maxDate={startMaxDate}
        startRef={startDateRef}
      />
      <Calender
        isDesktop={isDesktop}
        isStartDate={false}
        label="End:"
        name="reportEndDate"
        onChange={onChangeEndDate}
        form={form}
        reportEndDate={reportEndDate}
        minDate={endMinDate}
        maxDate={endMaxDate}
        endRef={endDateRef}
      />
    </div>
  );
};

const DownloadReportButton = ({
  error,
  smallScreen,
  server,
  downloadingReport,
  setDownloadingReport,
  id,
  setError,
  disabled,
  isMounted,
}) => {
  const [reportURL, setReportURL] = useState(null);

  const getButtonText = () => {
    if (error) {
      return "Failed to process";
    }
    switch (downloadingReport) {
      case true:
        return "Downloading...";
      case false:
        if (smallScreen) {
          return "Download";
        }
        return "Download Report";
      default:
        return "Generate Report";
    }
  };
  return (
    <div
      className={`${styles.download_report} ${disabled && styles.disabled}`}
      onClick={() => {
        if (downloadingReport === true || disabled || error) {
          return;
        }
        downloadingReport === false
          ? openInNewTab(reportURL)
          : downloadReport(
              server,
              setDownloadingReport,
              id,
              setError,
              setReportURL,
              getReportStatusV1,
              postPublishReport,
              isMounted
            );
      }}
    >
      <div className={downloadingReport && styles.loading_spinner}>
        {downloadingReport ? (
          <ButtonSpinner />
        ) : (
          <DownloadIcon colour={theme.primary6} />
        )}
      </div>
      <div>{getButtonText()}</div>
    </div>
  );
};

const CopyReportLinkButton = ({ smallestScreen, smallScreen }) => {
  return (
    <div
      className={styles.copy_report_link}
      onClick={() => {
        navigator.clipboard.writeText(window.location.href);
      }}
    >
      <LinkIcon />
      <div>
        {smallestScreen
          ? "Copy"
          : smallScreen
          ? "Copy link"
          : "Copy link to report"}
      </div>
    </div>
  );
};

const SaveChangesButton = ({
  isSavingChanges,
  server,
  form,
  editReport,
  icon,
  label,
  invalidForm,
  reportEndDate,
  setData,
  setError,
}) => {
  return (
    <div
      disabled={invalidForm}
      className={`${styles.save_changes_button} ${
        invalidForm ? styles.disabled : undefined
      }`}
      onClick={() => editReport(form, server, reportEndDate, setError, setData)}
    >
      {isSavingChanges ? icon : <div>{label}</div>}
    </div>
  );
};

const PreviewReportForm = ({
  server,
  id,
  error,
  setError,
  propsToPass,
  setData,
  formIsOpen,
}) => {
  const navigate = useNavigate();

  // initial data to populate the form
  const reportValues = propsToPass?.report;

  const formBehaviourOptions = getSelectedOptions(reportValues);
  formBehaviourOptions.push({
    bathroom_activity: false,
  });
  const initialFormValues = {
    reportID: propsToPass?.id,
    reportType: reportValues?.typeid,
    reportName: reportValues?.name,
    numberOfDays: reportValues?.days,
    reportStartDate: reportValues?.reportstartdate,
    suID: propsToPass?.SU?.id,
    options: formBehaviourOptions,
    summary: reportValues?.summary,
  };

  // local state
  const [form, setForm] = useState(initialFormValues);
  const [reportEndDate, setReportEndDate] = useState(
    format(propsToPass?.to, "yyyy-MM-dd")
  );
  const [downloadingReport, setDownloadingReport] = useState();

  // custom hooks
  const { editAndPreviewReport, isSavingChanges } = useReportsHook(
    server,
    navigate
  );

  /* 
    The following isMounted ref and the useEffect with cleanup function make sure the async call to publish the report does not produce 
    memory leaks should the component be ummounted before the promise resolves
  */
  const isMounted = useRef(true);
  // Showing an alert when an error occurs when attempting to download a report
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Form handlers
  const toggleCheckBox = (e) => {
    var { name, value } = e.target;
    value = !(value === ("true" || true));
    setForm({ ...form, options: updateOptions(form.options, name, value) });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onChangeEndDate = (e) => {
    setReportEndDate(e.target.value);
  };

  // Sizing
  const { width } = useViewport();
  const isDesktop = width > 600;
  const smallScreen = width < 500;
  const smallestScreen = width < 360;
  const previewDescription =
    "This a preview of your report. You can edit the summary section and the behaviours included below.";

  /* 
   calendar min and max values: depend on whether the input is the start or end date
   TODO: also link this to user's device installation date?
  */
  // minimum start date is 30 days before the end date.
  // maximum start date is 7 days before the end date.
  const startMinDate =
    reportEndDate && format(subDays(parseISO(reportEndDate), 29), "yyyy-MM-dd");
  const startMaxDate =
    reportEndDate && format(subDays(parseISO(reportEndDate), 6), "yyyy-MM-dd");

  // minimum end date is 7 days after the start date.
  // maximum end date is 30 days after the start date.
  const endMinDate =
    form?.reportStartDate &&
    format(addDays(parseISO(form?.reportStartDate), 6), "yyyy-MM-dd");
  const endMaxDate =
    form?.reportStartDate &&
    format(addDays(parseISO(form?.reportStartDate), 29), "yyyy-MM-dd");

  // form validation
  const noOptionsSelected = getArrayOfReportOptions(form?.options).length === 0;

  const disableShareLink = true; // TO DO: We need the Cloud team to enable PDF sharing priveleges

  return (
    <div
      className={`${styles.preview_report_form_container}
      ${!formIsOpen ? styles.slide_down : styles.slide_up}
    `}
    >
      {isDesktop && (
        <>
          <div className={styles.top}>
            <div className={styles.preview_title}>Preview</div>
            <div className={styles.top_right}>
              <DownloadReportButton
                error={error}
                server={server}
                downloadingReport={downloadingReport}
                setDownloadingReport={setDownloadingReport}
                id={id}
                setError={setError}
                disabled={isSavingChanges}
                isMounted={isMounted.current}
              />
              {!downloadingReport && !disableShareLink && (
                <CopyReportLinkButton
                  smallestScreen={smallestScreen}
                  smallScreen={smallScreen}
                  isSavingChanges={isSavingChanges}
                />
              )}
            </div>
          </div>
          <div className={styles.preview_description}>{previewDescription}</div>
        </>
      )}

      {!isDesktop && (
        <>
          <div className={styles.top_mobile}>
            <div className={styles.preview_title}>Preview</div>
            <div className={styles.preview_description}>
              {previewDescription}
            </div>
            <div className={styles.top_buttons_mobile}>
              <DownloadReportButton
                error={error}
                disabled={isSavingChanges}
                smallScreen={smallScreen}
                server={server}
                downloadingReport={downloadingReport}
                setDownloadingReport={setDownloadingReport}
                id={id}
                setError={setError}
                isMounted={isMounted.current}
              />
              {!downloadingReport && !disableShareLink && (
                <CopyReportLinkButton
                  smallestScreen={smallestScreen}
                  smallScreen={smallScreen}
                />
              )}
            </div>
          </div>
        </>
      )}

      <Bottom>
        <BottomLeft>
          <DateRange
            onInputChange={onInputChange}
            onChangeEndDate={onChangeEndDate}
            form={form}
            reportEndDate={reportEndDate}
            isDesktop={isDesktop}
            startMinDate={startMinDate}
            startMaxDate={startMaxDate}
            endMinDate={endMinDate}
            endMaxDate={endMaxDate}
            isSavingChanges={isSavingChanges}
          />
          <BehaviourOptions
            updateOptions={updateOptions}
            setForm={setForm}
            form={form}
            toggleCheckBox={toggleCheckBox}
            removeUnderscore={removeUnderscore}
            behaviours={form?.options}
            isDesktop={isDesktop}
            noOptionsSelected={noOptionsSelected}
            isSavingChanges={isSavingChanges}
          />
        </BottomLeft>
        <FreeTextSummary
          summary={form.summary}
          onInputChange={onInputChange}
          isSavingChanges={isSavingChanges}
        />
      </Bottom>
      <BottomButtons>
        <GoBackButton
          flex={width <= 600}
          isSavingChanges={isSavingChanges}
          goBack={() => navigate("/reports")}
          className={styles.go_back_custom}
        />
        <SaveChangesButton
          server={server}
          form={form}
          editReport={editAndPreviewReport}
          isSavingChanges={isSavingChanges}
          icon={<ButtonSpinner />}
          label={width < 311 ? "Save" : "Save changes"}
          invalidForm={noOptionsSelected}
          reportEndDate={reportEndDate}
          setData={setData}
          setError={setError}
        />
      </BottomButtons>
    </div>
  );
};

export default PreviewReportForm;
