import React from "react";
import styles from "./styles.module.scss";
import { NoDataNewIcon } from "../../Styles/Icons/DesignSystem";

function NoDataAvailable({ text, iconColor, iconPercentChange, style, red }) {
  return (
    <div
      style={style}
      className={`${red ? styles.red_container : styles.no_data_container}`}
    >
      <NoDataNewIcon
        color={red ? "white" : iconColor}
        percentChange={iconPercentChange}
      />
      <div
        className={`${red ? styles.red_container_text : styles.no_data_text}`}
      >
        {text || "No data available"}
      </div>
    </div>
  );
}

export default NoDataAvailable;
