import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { toggleFilter } from "../../Services/Utils";
// Components
import Modal from "../Modal";
import {
  NavigateNextIcon,
  ArrowBackIcon,
  RadioButtonUncheckedIcon,
  CheckCircleOutlineIcon,
} from "../../Styles/Icons";

const FilterCategory = (props) => (
  <div onClick={props.onClick} className={styles.filter_category}>
    <div>{props.name}</div>
    <NavigateNextIcon />
  </div>
);

const FilterItem = (props) => (
  <div className={styles.filter_item}>
    <div>{props.item?.name}</div>
    {props.item?.filtered ? (
      <CheckCircleOutlineIcon
        onClick={props.onClick}
        style={{ cursor: "pointer" }}
      />
    ) : (
      <RadioButtonUncheckedIcon
        onClick={props.onClick}
        style={{ cursor: "pointer" }}
      />
    )}
  </div>
);

const Filter = (props) => {
  const [filterSelected, setFilterSelected] = useState("");

  return (
    <Modal
      show={props.show}
      hide={props.hide}
      title="Filter your lilli users"
      context="Filters allow you to investigate a subset of your users, e.g. those at risk"
    >
      <div className={styles.container}>
        <div className={styles.title}>
          {filterSelected ? (
            <ArrowBackIcon
              onClick={() => {
                setFilterSelected("");
              }}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <div></div>
          )}
          <h3>{filterSelected}</h3>
          <div />
        </div>

        {!filterSelected && (
          <div className={styles.filters}>
            {props.filters.map((filter) => (
              <FilterCategory
                onClick={() => setFilterSelected(filter.type)}
                name={filter.type}
              />
            ))}
          </div>
        )}
        {/* Slightly unfortunate naming here with the variable "filter" and the method "filter" */}
        {filterSelected && (
          <div className={styles.results}>
            {props.filters
              .filter((filter) => filter.type === filterSelected)[0]
              .filters.map((filter, index) => (
                <FilterItem
                  key={index}
                  item={filter}
                  onClick={() =>
                    toggleFilter(filter.id, filterSelected, props.setFilters)
                  }
                />
              ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

Filter.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  setFilter: PropTypes.func,
  filter: PropTypes.object,
};

export default Filter;
