import { useEffect, useState } from "react";
// UTILS
import { extractEvents, extractRiskLevel } from "@intelligentlilli/lilli-utils";
import { extractDailyData } from "../../Utils";
// DateTime
import { subDays, addDays, endOfDay, startOfDay } from "date-fns";

export const usePrepareMovementData = (
  movementAnalysis,
  startDateString,
  endDateString,
  prevEndDateString,
  prevStartDateString,
  dateType
) => {
  const [movementRisk, setMovementRisk] = useState();
  const [movementData, setMovementData] = useState();
  const [previousMovementData, setPreviousMovementData] = useState();
  const [movementEvents, setMovementEvents] = useState();

  useEffect(() => {
    const startDate = startDateString && endOfDay(new Date(startDateString));
    const endDate = endDateString && endOfDay(new Date(endDateString));

    const prevStart =
      prevStartDateString && startOfDay(new Date(prevStartDateString));
    const prevEnd = prevEndDateString && endOfDay(new Date(prevEndDateString));

    setMovementRisk(
      extractRiskLevel(
        movementAnalysis?.dailyResults,
        subDays(startDate, 1),
        endDate
      )
    );
    // Extracting the front door events for a particular day from within the independence data
    setMovementEvents(
      extractEvents(movementAnalysis?.dailyResults, endDate, "movementEvents")
    );
    // Extracting behaviour and activity data
    setMovementData(
      extractDailyData(
        "totalCount",
        movementAnalysis?.dailyResults,
        subDays(startDate, 1),
        endDate
      )
    );
    setPreviousMovementData(
      extractDailyData(
        "totalCount",
        movementAnalysis?.dailyResults,
        prevStart,
        addDays(prevEnd, 1)
      )
    );
  }, [
    movementAnalysis,
    endDateString,
    startDateString,
    prevStartDateString,
    prevEndDateString,
    dateType,
  ]);

  return {
    movementRisk,
    movementData,
    previousMovementData,
    movementEvents,
  };
};
