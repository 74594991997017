import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import AppliedFilter from "../AppliedFilter";
import { toggleFilter } from "../../Services/Utils";

const AppliedFilters = (props) => {
  return (
    <div className={styles.appliedFilters}>
      <div className={styles.appliedFilters_title}>APPLIED FILTERS:</div>
      <div className={styles.appliedFilters_filters}>
        {props.filters.map((filterType) =>
          filterType.filters
            .filter((item) => item.filtered)
            .map((item, index) => (
              <AppliedFilter
                name={item.name}
                type={"condition"}
                key={index}
                onClick={() =>
                  toggleFilter(item.id, filterType.type, props.setFilters)
                }
              />
            ))
        )}
      </div>
    </div>
  );
};

AppliedFilters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default AppliedFilters;
