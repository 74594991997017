import React from "react";
import styles from "./styles.module.scss";
// icons
import {
  FridgeIcon,
  KettleIcon,
  ApplianceIcon,
  MicrowaveIcon,
  CupboardIcon,
  ToasterIcon,
  MovementIcon,
  ToiletIcon,
  BathtubIcon,
} from "../../Styles/Icons/DesignSystem";
import { DoorClosedIcon, DoorOpenIcon } from "../../Styles/Icons";

const Event = ({ event, frequency, timePeriod, duration }) => {
  const getEventName = (event, frequency) => {
    let name = "";
    switch (event) {
      case "movement":
        name = "Movement";
        break;
      case "kettle":
        name += "Kettle used";
        break;
      case "smartplug":
        name += "Kettle used";
        break;
      case "fridge":
        name += "Fridge opened";
        break;
      case "cupboard":
        name += "Cupboard opened";
        break;
      case "microwave":
        name += "Microwave used";
        break;
      case "toaster":
        name += "Toaster used";
        break;
      case "appliance":
        name += "Appliance used";
        break;
      case "bathroom":
        name += "Bathroom used";
        break;
      case "toilet":
        name += "Bathroom occupied";
        break;
      case "hygiene":
        name += "Bath/Shower";
        break;
      case "door opened":
        name += "Front door opened";
        break;
      case "door closed":
        name += "Front door closed";
        break;
      case "bedroom motion":
        name += "Bedroom movement";
        break;
      default:
        name += "Event";
    }
    if (frequency > 1) {
      name += ` x${frequency}`;
    }
    return name;
  };

  const icon =
    event === "movement" ? (
      <MovementIcon />
    ) : event === "kettle" ? (
      <KettleIcon />
    ) : event === "smartplug" ? (
      <KettleIcon />
    ) : event === "fridge" ? (
      <FridgeIcon />
    ) : event === "cupboard" ? (
      <CupboardIcon />
    ) : event === "microwave" ? (
      <MicrowaveIcon />
    ) : event === "toaster" ? (
      <ToasterIcon />
    ) : event === "bathroom" || event === "toilet" ? (
      <ToiletIcon />
    ) : event === "hygiene" ? (
      <BathtubIcon />
    ) : event === "door opened" ? (
      <DoorOpenIcon />
    ) : event === "door closed" ? (
      <DoorClosedIcon />
    ) : event === "bedroom motion" ? (
      <MovementIcon />
    ) : (
      <ApplianceIcon />
    );
  return (
    <div
      className={duration ? styles.bigger_container : styles.normal_container}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.info}>
        <div className={styles.name}>{getEventName(event, frequency)}</div>
        <div className={styles.time}>{timePeriod}</div>
        {duration && <div className={styles.time}>{duration}</div>}
      </div>
    </div>
  );
};

export default Event;
