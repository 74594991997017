import { startOfDay, isSameDay, subDays } from "date-fns";

const defineds = {
  lastCompleteDay: startOfDay(subDays(new Date(), 1)),
  last7Days: startOfDay(subDays(new Date(), 7)),
  last14Days: startOfDay(subDays(new Date(), 14)),
  last28Days: startOfDay(subDays(new Date(), 28)),
  last6Weeks: startOfDay(subDays(new Date(), 42)),
  last12Weeks: startOfDay(subDays(new Date(), 84)),
  last52Weeks: startOfDay(subDays(new Date(), 364)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(minDate, maxDate) {
  const baseRanges = [
    {
      label: "Last 7 days",
      range: () => ({
        startDate: defineds.last7Days,
        endDate: defineds.lastCompleteDay,
      }),
    },
    {
      label: "Last 14 days",
      range: () => ({
        startDate: defineds.last14Days,
        endDate: defineds.lastCompleteDay,
      }),
    },
    {
      label: "Last 28 days",
      range: () => ({
        startDate: defineds.last28Days,
        endDate: defineds.lastCompleteDay,
      }),
    },
    {
      label: "Last 6 weeks",
      range: () => ({
        startDate: defineds.last6Weeks,
        endDate: defineds.lastCompleteDay,
      }),
    },
    {
      label: "Last 12 weeks",
      range: () => ({
        startDate: defineds.last12Weeks,
        endDate: defineds.lastCompleteDay,
      }),
    },
    {
      label: "Last 52 weeks",
      range: () => ({
        startDate: defineds.last52Weeks,
        endDate: defineds.lastCompleteDay,
      }),
    },
  ];
  const eligibleBaseRanges = baseRanges.filter(
    (range) =>
      range.range().endDate <= maxDate && range.range().startDate >= minDate
  );
  // add a range range to the end of the list that represents the entire range
  eligibleBaseRanges.push({
    label: "All time",
    range: () => ({ startDate: minDate, endDate: maxDate }),
  });
  return eligibleBaseRanges.map((range) => ({
    ...staticRangeHandler,
    ...range,
  }));
}
