import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDevelcoGatewaySensors } from "@intelligentlilli/api-layer";
import { setSensors } from "../../State/slices/sensors-slice";

export const useGetSensorStatus = ({
  devicestring,
  id,
  continuous = false,
}) => {
  // redux state
  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);
  const sensors = useSelector((state) => state.sensors);
  console.log("useGetSensorStatus sensors:", sensors);

  // local state
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const hubIsOnline = sensors?.hub?.online;

  // Create a callback function that fetches the sensor status from a device
  const fetchSensorStatus = useCallback(
    (callback) => {
      setLoading(true);
      setError(null);
      getDevelcoGatewaySensors(server, devicestring, "web")
        .then((res) => {
          if (res.ok && res.body?.data) {
            setLoading(false);
            setData(res.body?.data);
            typeof callback === "function" && callback();
          } else {
            setLoading(false);
            setError(
              "Could not detect sensors. You can either try again or contact support: support@intelligentlilli.com"
            );
          }
        })
        .catch(() => {
          setLoading(false);
          setError(
            "Sorry, we could not make a connection with the hub. You can either try again or contact support: support@intelligentlilli.com"
          );
        });
    },
    [devicestring, server]
  );

  // Upon load fetch the sensor status
  useEffect(() => {
    if (continuous) {
      fetchSensorStatus();
    }

    // For pages that need to continuously poll the hub (while waiting for it to be provisioned) add that here
    const interval = window.setInterval(() => {
      if (continuous && !hubIsOnline) {
        fetchSensorStatus();
      }
    }, 15000); // every 15 seconds

    // Once the hub is online or the timer has run down there is no need to continue polling the API
    if (hubIsOnline || !continuous) {
      interval && window.clearInterval(interval);
    }
    // Remove polling when the component unmounts but the hub is not yet online
    return () => interval && window.clearInterval(interval);
  }, [continuous, fetchSensorStatus, hubIsOnline]);

  // Hook listening for changes in the data and persisting them to state
  useEffect(() => {
    // Sometimes the response comes back as updating the hub. Ignore these responses as they give no information
    if (data?.[0]?.name !== "update" && data?.length > 0) {
      // Save the results in redux
      dispatch(
        setSensors({
          devices: data,
          hubId: id,
          devicestring: devicestring,
        })
      );
    }
  }, [data, dispatch, id, devicestring]);

  return { loading, fetchSensorStatus, error, hubIsOnline };
};
