// Filters for the Service User List table header drop-down filter menu
export const INSTALLATION = {
  installation: "Installation status",
  complete: "Installation completed",
  started: "Installation started",
  "not started": "Installation not started",
};

export const FILTERS = {
  all: "All users",
  AT_RISK: "At risk users",
  UNEXPECTED: "Unexpected users",
  EXPECTED: "Expected users",
  friends_and_family: "Assigned Friend/Family",
  no_friends_and_family: "Not assigned Friend/Family",
  ...INSTALLATION,
  incomplete: "Installation incomplete (started & not started)",
};

export const INSTALLER_FILTERS = {
  ...INSTALLATION,
};
