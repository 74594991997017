import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
// components
import NoDataBox from "../../Components/NoDataBox";

const NavTabBody = ({
  children,
  style,
  activeTab,
  dateType,
  noData,
  noDeviceString,
  component,
  devices,
  notifications,
  noDeviceStringBox,
}) => {
  const onOverviewTab = activeTab?.tab === "overview";
  const onDevicesTab = activeTab?.tab === "devices";
  const onEventLogTab = activeTab?.tab === "event log";
  const onNotificationsTab = activeTab?.tab === "notifications";
  const onBehavioursTab =
    !onDevicesTab && !onOverviewTab && !onEventLogTab && !onNotificationsTab;
  const hasData = !noData; // (specific to the Tab we are looking at)
  const noDataText =
    "If this user has recently installed the Lilli hub it may take up to 48 hours to start seeing data. If install was more than 48 hours ago please contact your Lilli Administrator. If you think there should be data and there is not, please email us at: support@intelligentlilli.com";
  if (hasData) {
    if (onOverviewTab || onBehavioursTab) {
      // 'has data' for Behaviours tab means there is data for that specific behaviour
      return (
        <div className={`${styles.nav_tab_body} ${style}`}>
          <div className={styles.behaviour_wrapper}>{component}</div>
        </div>
      );
    } else if (onEventLogTab) {
      return (
        <div className={`${styles.nav_tab_body} ${style}`}>
          <div className={styles.behaviour_wrapper}>{component}</div>
        </div>
      );
    } else if (onDevicesTab) {
      // 'has data' for Devices tab is the same as the Overview tab
      if (noDeviceString) {
        // but no longer has a device string
        return (
          <div className={`${styles.nav_tab_body} ${style}`}>
            {noDeviceStringBox}
          </div>
        );
      } else {
        // has data and device string
        return (
          <div className={`${styles.nav_tab_body} ${style}`}>{devices}</div>
        );
      }
    } else if (onNotificationsTab) {
      return (
        <div className={`${styles.nav_tab_body} ${style}`}>{children}</div>
      );
    }
  } else {
    // No data scenario
    if (onOverviewTab || onBehavioursTab) {
      return <NoDataBox title={"No data available"} text={noDataText} />;
    }
    if (onEventLogTab) {
      // If there is no data for the event log or the user is on week/month tab
      return (
        <NoDataBox
          title={"Event log not available"}
          text={
            dateType === "day"
              ? noDataText
              : "Please switch to day view to view the event log"
          }
        />
      );
    }
    if (onDevicesTab) {
      // If there is no data on ANY of the behaviours... (same as overview tab)
      if (noDeviceString) {
        // and no devicestring
        return (
          <div className={`${styles.nav_tab_body} ${style}`}>
            {noDeviceStringBox}
          </div>
        );
      } else {
        // No data for any of the behaviours, but devicestring exists
        return (
          <div className={`${styles.nav_tab_body} ${style}`}>{devices}</div>
        );
      }
    }
  }
};

NavTabBody.propTypes = {
  children: PropTypes.node,
  style: PropTypes.string,
  activeTab: PropTypes.object,
  dateType: PropTypes.string,
  noData: PropTypes.bool,
  noDeviceString: PropTypes.bool,
  riskSummary: PropTypes.node,
  dailyOverview: PropTypes.node,
  graph: PropTypes.node,
  overviewBehavioursGraph: PropTypes.node,
  devices: PropTypes.node,
  noDeviceStringBox: PropTypes.node,
};

export default NavTabBody;
