import {
  hasSustenanceData,
  getHoursAndMinutesAsString,
  isTemperatureTooHot,
  isTemperatureTooCold,
} from "../";

export const getNoTimeOutsideFirstLine = (value) => {
  if (value === 0) {
    return "No time outside";
  }
  if (value === null) {
    return "No data";
  }
  return getHoursAndMinutesAsString(value, false);
};

export const getDoorOpenedAtNightFirstLine = (numberOfDoorEventsAtNight) => {
  if (numberOfDoorEventsAtNight === 0) {
    return "No door events";
  }
  return numberOfDoorEventsAtNight > 0
    ? `${numberOfDoorEventsAtNight} door event${
        numberOfDoorEventsAtNight === 1 ? "" : "s"
      }`
    : "No data";
};

export const getTemperatureSecondLine = (value) => {
  return value > 0 ? `${value}°C` : "No data";
};

export const getTemperatureFirstLine = (dataObject) => {
  return isTemperatureTooHot(dataObject)
    ? "Too hot"
    : isTemperatureTooCold(dataObject)
    ? "Too cold"
    : "";
};

export const getBathroomEventsFirstLine = (
  numberOfBathingEvents,
  eventType
) => {
  if (numberOfBathingEvents === 0) {
    return `No visits`;
  }
  return numberOfBathingEvents > 0
    ? `${numberOfBathingEvents} ${eventType} visit${
        numberOfBathingEvents === 1 ? "" : "s"
      }`
    : "No data";
};

export const getBathOrShowerEventsFirstLine = (
  numberOfBathingEvents,
  eventType
) => {
  if (numberOfBathingEvents === 0) {
    return "No events";
  }
  return numberOfBathingEvents > 0
    ? `${numberOfBathingEvents} ${eventType} event${
        numberOfBathingEvents === 1 ? "" : "s"
      }`
    : "No data";
};

export const getBathroomNighttimeFirstLine = (numberOfBathingEvents) => {
  if (numberOfBathingEvents === 0) {
    return `No visits`;
  }
  return numberOfBathingEvents > 0
    ? `${numberOfBathingEvents} visit${numberOfBathingEvents === 1 ? "" : "s"}`
    : "No data";
};

export const getSustenanceSentence = (dayObj) => {
  const missedMeals = `${dayObj?.sentences?.length} missed`;
  if (hasSustenanceData(dayObj.dailyData) === false) {
    return "No data";
  }
  return missedMeals;
};
