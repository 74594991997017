import React from "react";
import theme from "../../theme.scss";

export const BellIcon = ({ width, colour }) => {
  return (
    <svg width={width || 30} viewBox="0 0 46 51" id="BellIcon">
      <path
        d="M44.9357 28.4578L40.9774 14.2161C39.8172 10.0439 37.2956 6.37922 33.8135 3.8046C30.3314 1.22998 26.0886 -0.106826 21.7596 0.00667464C17.4305 0.120175 13.2637 1.67747 9.92126 4.43101C6.57887 7.18456 4.25281 10.9763 3.3128 15.2036L0.248213 28.9849C-0.0902971 30.5077 -0.0824707 32.087 0.271114 33.6064C0.624699 35.1257 1.31501 36.5463 2.29109 37.7631C3.26717 38.98 4.50409 39.962 5.91053 40.6369C7.31697 41.3117 8.85701 41.662 10.417 41.662H12.7378C13.216 44.0167 14.4935 46.1338 16.3539 47.6544C18.2144 49.175 20.5433 50.0057 22.9461 50.0057C25.349 50.0057 27.6779 49.175 29.5383 47.6544C31.3988 46.1338 32.6763 44.0167 33.1545 41.662H34.9003C36.5062 41.6621 38.0903 41.2909 39.529 40.5774C40.9676 39.864 42.2219 38.8276 43.1937 37.5492C44.1655 36.2708 44.8286 34.785 45.1311 33.2079C45.4337 31.6308 45.3675 30.0051 44.9378 28.4578H44.9357ZM22.9461 45.8286C21.6581 45.8233 20.4032 45.4201 19.353 44.6743C18.3029 43.9284 17.5088 42.8763 17.0795 41.662H28.8128C28.3835 42.8763 27.5894 43.9284 26.5393 44.6743C25.4891 45.4201 24.2342 45.8233 22.9461 45.8286ZM39.8753 35.0265C39.2948 35.7967 38.5426 36.4208 37.6786 36.8494C36.8147 37.2779 35.8626 37.4991 34.8982 37.4953H10.417C9.4811 37.4951 8.55721 37.2848 7.71351 36.8798C6.8698 36.4749 6.12781 35.8856 5.5423 35.1555C4.95679 34.4254 4.54271 33.5732 4.33063 32.6616C4.11855 31.7501 4.11388 30.8026 4.31696 29.889L7.37946 16.1057C8.1177 12.7853 9.94469 9.80696 12.57 7.64414C15.1953 5.48132 18.4683 4.25814 21.8686 4.16906C25.2689 4.07999 28.6014 5.13011 31.3364 7.15251C34.0713 9.17491 36.0517 12.0535 36.9628 15.3307L40.9211 29.5724C41.1827 30.5002 41.2243 31.4763 41.0428 32.423C40.8613 33.3698 40.4615 34.2612 39.8753 35.0265Z"
        fill={colour || theme.white}
      />
    </svg>
  );
};
