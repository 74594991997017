import React from "react";
import theme from "../../theme.scss";

export const LunchWindowIcon = ({ color }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <mask
        id="mask0_192_877"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="15"
        height="15"
      >
        <circle
          cx="7.5"
          cy="7.5"
          r="7.375"
          fill="#D9D9D9"
          stroke={theme.neutral7}
          strokeWidth="0.25"
        />
      </mask>
      <g mask="url(#mask0_192_877)">
        <path
          d="M7.27854 7.05805L3.63199 1.87185C3.59202 1.815 3.56434 1.75045 3.55071 1.68231L3.2938 0.397749C3.24743 0.165897 3.3698 -0.0667417 3.58713 -0.159881L10.248 -3.01456C10.5044 -3.12444 10.8011 -3.00329 10.9073 -2.74536L13.0738 2.51593C13.1573 2.71874 13.0989 2.9523 12.9297 3.09192L8.00583 7.15607C7.78114 7.34153 7.44611 7.29637 7.27854 7.05805Z"
          fill={color || theme.primary3}
        />
      </g>
      <circle cx="7.5" cy="7.5" r="7" stroke={theme.neutral4} />
    </svg>
  );
};
