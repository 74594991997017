import React, { useState } from "react";
import styles from "./styles.module.scss";
import DashboardSearchResults from "../DashboardSearchResults";
import DashboardFilterAndSearch from "../DashboardFilterAndSearch";

const DashboardSearch = ({
  userIsJustAnInstaller,
  setSelectedFilter,
  selectedFilter,
  setSelectedTab,
  selectedTab,
}) => {
  // Search
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className={styles.dashboard_search}>
      <div className={styles.search_title}>Search</div>
      <DashboardFilterAndSearch
        selectedTab={selectedTab}
        searchValue={searchValue}
        selectedFilter={selectedFilter}
        setSelectedTab={setSelectedTab}
        setSearchValue={setSearchValue}
        setSelectedFilter={setSelectedFilter}
        userIsJustAnInstaller={userIsJustAnInstaller}
      />
      <DashboardSearchResults
        selectedTab={selectedTab}
        searchValue={searchValue}
        selectedFilter={selectedFilter}
      />
    </div>
  );
};

export default DashboardSearch;
