import React from "react";
import styles from "./styles.module.scss";
import HowTheBehaviourWorks from "../HowTheBehaviourWorks";
import SUDayView from "../SUDayView";
import { getEventLogData } from "../../Services/Utils/EventLog";

const BehaviourDaytimeDayView = ({
  daytimeMovementData,
  movementData,
  howItWorks,
  insights,
  showLearnMore,
  setShowLearnMore,
}) => {
  const oneDayOfData = movementData?.[0];

  const { allMotionEvents } = getEventLogData(
    daytimeMovementData?.eventLog,
    true
  );

  // Check if there is no data
  const noData = oneDayOfData?.durationMinutes?.dayTime >= 0 ? false : true;

  return (
    <>
      {showLearnMore && (
        <div className={styles.container}>
          <HowTheBehaviourWorks
            behaviour="Day-time"
            questionsAndAnswers={howItWorks}
            hide={() => setShowLearnMore(false)}
          />
        </div>
      )}
      {!showLearnMore && (
        <SUDayView
          currentTab={"Day-time"}
          events={allMotionEvents}
          noData={noData}
          insightsContext={
            "Day-time activity is the time spent moving this day between 6am - 10pm."
          }
          insights={insights}
          isNewVariant={true}
          isNighttime={false}
          noSplitting={false}
        />
      )}
    </>
  );
};

export default BehaviourDaytimeDayView;
