import React from "react";
import theme from "../../theme.scss";

export const UsersIcon = ({ width, colour }) => {
  return (
    <svg width={width || 30} viewBox="0 0 20 20" id="UsersIcon">
      <path
        d="M10 10C10.9889 10 11.9556 9.70677 12.7779 9.15736C13.6001 8.60796 14.241 7.82706 14.6194 6.91343C14.9978 5.9998 15.0969 4.99447 14.9039 4.02457C14.711 3.05466 14.2348 2.16375 13.5355 1.46448C12.8363 0.765221 11.9454 0.289017 10.9755 0.0960911C10.0055 -0.096835 9.00021 0.00218169 8.08658 0.38062C7.17295 0.759058 6.39206 1.39992 5.84265 2.22217C5.29324 3.04441 5 4.01111 5 5.00002C5.00132 6.32569 5.52853 7.59669 6.46593 8.53409C7.40332 9.47148 8.67432 9.99869 10 10ZM10 1.66668C10.6593 1.66668 11.3037 1.86218 11.8519 2.22845C12.4001 2.59472 12.8273 3.11532 13.0796 3.72441C13.3319 4.33349 13.3979 5.00371 13.2693 5.65032C13.1407 6.29692 12.8232 6.89086 12.357 7.35704C11.8908 7.82321 11.2969 8.14068 10.6503 8.2693C10.0037 8.39792 9.33348 8.33191 8.72439 8.07961C8.1153 7.82732 7.59471 7.40008 7.22844 6.85192C6.86216 6.30375 6.66667 5.65929 6.66667 5.00002C6.66667 4.11596 7.01786 3.26812 7.64298 2.64299C8.2681 2.01787 9.11595 1.66668 10 1.66668Z"
        fill={colour || theme.white}
      />
      <path
        d="M10 11.6667C8.01155 11.6689 6.10518 12.4598 4.69914 13.8658C3.29309 15.2719 2.50221 17.1782 2.5 19.1667C2.5 19.3877 2.5878 19.5997 2.74408 19.7559C2.90036 19.9122 3.11232 20 3.33333 20C3.55435 20 3.76631 19.9122 3.92259 19.7559C4.07887 19.5997 4.16667 19.3877 4.16667 19.1667C4.16667 17.6196 4.78125 16.1358 5.87521 15.0419C6.96917 13.9479 8.4529 13.3333 10 13.3333C11.5471 13.3333 13.0308 13.9479 14.1248 15.0419C15.2188 16.1358 15.8333 17.6196 15.8333 19.1667C15.8333 19.3877 15.9211 19.5997 16.0774 19.7559C16.2337 19.9122 16.4457 20 16.6667 20C16.8877 20 17.0996 19.9122 17.2559 19.7559C17.4122 19.5997 17.5 19.3877 17.5 19.1667C17.4978 17.1782 16.7069 15.2719 15.3009 13.8658C13.8948 12.4598 11.9884 11.6689 10 11.6667Z"
        fill={colour || theme.white}
      />
    </svg>
  );
};
