import React from "react";
import theme from "../../theme.scss";

export const DownloadIcon = ({ width, colour }) => {
  return (
    <svg
      id="DownloadIcon"
      width={width || 20}
      height={width || 20}
      viewBox="0 0 20 20"
    >
      <path
        d="M18.9375 14.0767C18.8472 14.0766 18.7578 14.0944 18.6743 14.1289C18.5909 14.1634 18.5151 14.2141 18.4512 14.2779C18.3874 14.3418 18.3368 14.4176 18.3022 14.501C18.2677 14.5844 18.2499 14.6739 18.25 14.7642V17.7807C18.2499 17.963 18.1774 18.1378 18.0485 18.2667C17.9196 18.3956 17.7448 18.4681 17.5625 18.4682H2.4375C2.2552 18.4681 2.0804 18.3956 1.9515 18.2667C1.82259 18.1378 1.75012 17.963 1.75 17.7807V14.7642C1.75 14.5818 1.67757 14.407 1.54864 14.278C1.4197 14.1491 1.24484 14.0767 1.0625 14.0767C0.880164 14.0767 0.705295 14.1491 0.576364 14.278C0.447433 14.407 0.375 14.5818 0.375 14.7642V17.7807C0.375597 18.3275 0.593087 18.8518 0.979752 19.2384C1.36642 19.6251 1.89067 19.8426 2.4375 19.8432H17.5625C18.1093 19.8426 18.6336 19.6251 19.0202 19.2384C19.4069 18.8518 19.6244 18.3275 19.625 17.7807V14.7642C19.6251 14.6739 19.6073 14.5844 19.5728 14.501C19.5382 14.4176 19.4876 14.3418 19.4238 14.2779C19.3599 14.2141 19.2841 14.1634 19.2007 14.1289C19.1172 14.0944 19.0278 14.0766 18.9375 14.0767Z"
        fill={colour || theme.white}
      />
      <path
        d="M9.47832 15.6665C9.54399 15.7399 9.62439 15.7986 9.71429 15.8387C9.80418 15.8789 9.90153 15.8997 10 15.8997C10.0985 15.8997 10.1958 15.8789 10.2857 15.8387C10.3756 15.7985 10.456 15.7398 10.5217 15.6665L13.9686 11.6509C14.0832 11.5121 14.1387 11.3338 14.1233 11.1544C14.1079 10.975 14.0227 10.8088 13.8861 10.6915C13.7494 10.5742 13.5722 10.5152 13.3926 10.5271C13.2129 10.5391 13.0451 10.621 12.9252 10.7553L10.6875 13.3623V0.844299C10.6875 0.661963 10.6151 0.487095 10.4861 0.358163C10.3572 0.229232 10.1823 0.156799 9.99999 0.156799C9.81765 0.156799 9.64278 0.229232 9.51385 0.358163C9.38492 0.487095 9.31249 0.661963 9.31249 0.844299V13.3623L7.07476 10.7553C6.95482 10.621 6.78702 10.5392 6.60738 10.5272C6.42774 10.5153 6.2506 10.5744 6.114 10.6916C5.9774 10.8089 5.89223 10.9751 5.8768 11.1544C5.86137 11.3338 5.91689 11.5121 6.03145 11.651L9.47832 15.6665Z"
        fill={colour || theme.white}
      />
    </svg>
  );
};
