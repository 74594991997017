import {
  sortByTimestamp,
  dateToTimestamp,
  dateHourToTimestamp,
} from "@intelligentlilli/lilli-utils";

export const extractDailyData = (countKey, dailyResults, from, to) => {
  if (!dailyResults || !countKey) return [];
  return sortByTimestamp(
    dailyResults
      ?.map((day) => ({
        ...day,
        ts: dateToTimestamp(day?.date),
        value: day?.dailyData?.[countKey],
        expectedMin: day?.dailyData?.expectedMin,
        expectedMax: day?.dailyData?.expectedMax,
        dayRating: day?.dayRating,
        date: day?.date,
      }))
      .filter(({ ts }) => ts >= from && ts <= to) || []
  );
};

export const extractHourlyData = (countKey, dailyResults, from, to) => {
  if (!dailyResults || !countKey) return [];
  const updated = dailyResults?.flatMap(({ date, hourlyData }) => {
    return hourlyData?.map((data) => {
      return {
        ts: dateHourToTimestamp(date, data?.hour),
        value: data?.[countKey],
        expectedMin: data?.["expectedMin"],
        expectedMax: data?.["expectedMax"],
        expected: data?.["expected?"],
        riskLevel: data?.["riskLevel"],
      };
    });
  });
  const filtered =
    updated?.filter((day) => day?.ts >= from && day?.ts <= to) || [];
  const result = sortByTimestamp(filtered);
  return result;
};
