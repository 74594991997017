import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const serviceUsersSlice = createSlice({
  name: "serviceUsers",
  initialState,
  reducers: {
    setServiceUsers(_, action) {
      return action.payload;
    },
    updateServiceUser(state, action) {
      const { index, key, value } = action.payload;
      state[index][key] = value;
    },
    updateServiceUserProperties(state, action) {
      const { index, key, value } = action.payload;
      state[index].serviceUserProperties[key] = value;
    },
  },
});

export const {
  setServiceUsers,
  updateServiceUser,
  updateServiceUserProperties,
} = serviceUsersSlice.actions;
export default serviceUsersSlice.reducer;
