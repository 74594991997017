import { format, addHours, startOfDay } from "date-fns";

export const formatMealTime = (hourAsInteger) => {
  if (hourAsInteger >= 0 && hourAsInteger < 12) {
    return `${hourAsInteger}am`;
  } else {
    const time = addHours(startOfDay(new Date()), hourAsInteger);
    return `${format(time, "h")}pm`;
  }
};
