import { format } from "date-fns";
import { getRangeViewStats } from "../RangeView";
import {
  DUMMY_SENSOR_DATA_1,
  DUMMY_SENSOR_DATA_2,
} from "../../../Components/BehaviourDayTime/dummydata";

export const bathroomRangeComparison = (
  bathroomInference,
  dateType,
  previousDataPeriod,
  prevStartDate,
  prevEndDate,
  from,
  to,
  onClick
) => {
  // Calculate insights
  const condensedCurrent =
    getRangeViewStats(bathroomInference, "bathroom", "total") || [];
  const condensedPrevious =
    getRangeViewStats(previousDataPeriod, "bathroom", "total") || [];

  const totalPrev = condensedPrevious?.reduce(
    (total, item) => total + (item?.value || 0),
    0
  );
  const totalCurrent = condensedCurrent?.reduce(
    (total, item) => total + (item?.value || 0),
    0
  );

  // Amount & Direction of change
  const totalChange = totalCurrent - totalPrev;
  const isIncrease = totalChange > 0;
  const isDecrease = totalChange < 0;

  // Handle percent change when the denominator is 0
  const totalPercentChange =
    totalPrev === 0 && isIncrease
      ? 100
      : totalPrev === 0 && isDecrease
      ? -100
      : ((totalCurrent - totalPrev) / totalPrev) * 100;
  // Format values for display
  const bathroomChange = isNaN(totalPercentChange)
    ? "?"
    : totalPercentChange >= 1 && totalPercentChange <= -1
    ? totalPercentChange
    : totalPercentChange;
  const cannotCalculate = bathroomChange === "?";
  const absoluteChange = Math.abs(bathroomChange);
  const prettyAmountChange = Math.round(absoluteChange);

  const noChange = totalCurrent === totalPrev;
  const amountChange = cannotCalculate
    ? "Not enough data"
    : noChange
    ? "No change"
    : `${prettyAmountChange}%`;

  const insightContext = cannotCalculate
    ? `There is not enough data to analyse how much bathroom activity has changed since last ${dateType}.`
    : noChange
    ? `Total bathroom activity has not changed compared to last ${dateType}: ${totalPrev} vs ${totalCurrent} events: `
    : `Total bathroom activity is ${
        isIncrease ? "up" : "down"
      } ${prettyAmountChange}% compared to last ${dateType}: `;
  const insightCompareValues = `${totalPrev} vs ${totalCurrent} events`;

  const daysWithNoData = bathroomInference?.filter(
    (i) => i?.activityData.length === 0
  )?.length;
  const daysWithData = bathroomInference?.filter(
    (i) => i?.activityData.length > 0
  )?.length;

  // Modal data
  const currentDataModal = {
    hasNoData: daysWithData === 0,
    timePeriod:
      from &&
      to &&
      `${format(new Date(from), "dd MMM")} - ${format(new Date(to), "dd MMM")}`,
    label: "total events",
    value: totalCurrent,
    percentChange: cannotCalculate
      ? "Not enough data"
      : noChange
      ? "No change"
      : `${isIncrease ? "+" : "-"}${prettyAmountChange}%`,
    expected: null, // expectedCurrentMin,
    sensors: DUMMY_SENSOR_DATA_1,
  };
  const previousDataModal = {
    hasNoData:
      previousDataPeriod?.filter((i) => i?.activityData.length > 0)?.length ===
      0,
    timePeriod:
      prevStartDate &&
      prevEndDate &&
      `${format(new Date(prevStartDate), "dd MMM")} - ${format(
        new Date(prevEndDate),
        "dd MMM"
      )}`,
    label: "total events",
    value: totalPrev,
    percentChange: 0,
    expected: null, // expectedPreviousMin,
    sensors: DUMMY_SENSOR_DATA_2,
  };

  if (!true) {
    return {
      offline: true,
      title: `Compared to last ${dateType}`,
      icon: "OFFLINE",
      context: "No data available for this insight",
    };
  }

  return {
    onClick: onClick,
    icon: "BATHROOM",
    children: {
      type: "BATHROOM_COMPARISON",
      noChange,
      isIncrease,
      isDecrease,
      cannotCalculate,
      amountChange,
      dateType,
      insightContext,
      insightCompareValues,
      daysWithNoData,
    },
    previousDataModal,
    currentDataModal,
  };
};
