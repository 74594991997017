import React from "react";
import theme from "../../theme.scss";

export const TimerIcon = ({ width, colour }) => {
  return (
    <svg
      width={width || "33"}
      height={width || "33"}
      viewBox="0 0 33 33"
      id="TimerIcon"
    >
      <path
        d="M29.8339 16.4997C29.8339 23.8634 23.864 29.8333 16.5003 29.8333C9.13659 29.8333 3.16669 23.8634 3.16669 16.4997C3.16669 15.8861 3.66405 15.3887 4.2776 15.3887C4.89115 15.3887 5.38856 15.8861 5.38856 16.4997C5.38856 22.6363 10.3631 27.6109 16.4997 27.6109C22.6363 27.6109 27.6109 22.6363 27.6109 16.4997C27.6109 10.3631 22.6363 5.38855 16.4997 5.38855C15.8862 5.38855 15.3888 4.89114 15.3888 4.27759C15.3888 3.66403 15.8862 3.16663 16.4997 3.16663C23.8603 3.17444 29.8255 9.13966 29.8333 16.5002L29.8339 16.4997ZM14.5856 15.3887H12.0554C11.4418 15.3887 10.9444 15.8861 10.9444 16.4997C10.9444 17.1133 11.4418 17.6107 12.0554 17.6107H14.5856C15.196 18.6711 16.5508 19.0357 17.6112 18.4253C18.6716 17.8148 19.0363 16.4601 18.4258 15.3997C18.231 15.0611 17.9497 14.7799 17.6112 14.5851V10.9439C17.6112 10.3303 17.1138 9.8329 16.5003 9.8329C15.8867 9.8329 15.3893 10.3303 15.3893 10.9439V14.5851C15.0565 14.7788 14.7794 15.0554 14.5862 15.3882L14.5856 15.3887ZM5.1964 12.9267C5.80995 12.9267 6.30736 12.4293 6.30736 11.8158C6.30736 11.2022 5.80995 10.7048 5.1964 10.7048C4.58285 10.7048 4.08544 11.2022 4.08544 11.8158C4.08544 12.4293 4.58285 12.9267 5.1964 12.9267ZM7.85636 8.95216C8.46992 8.95216 8.96728 8.45475 8.96728 7.8412C8.96728 7.22764 8.46992 6.73024 7.85636 6.73024C7.24281 6.73024 6.7454 7.22764 6.7454 7.8412C6.7454 8.45475 7.24281 8.95216 7.85636 8.95216ZM11.8095 6.32346C12.4231 6.32346 12.9205 5.82605 12.9205 5.2125C12.9205 4.59895 12.4231 4.10154 11.8095 4.10154C11.196 4.10154 10.6986 4.59895 10.6986 5.2125C10.6986 5.82605 11.196 6.32346 11.8095 6.32346Z"
        fill={colour || theme.black}
      />
    </svg>
  );
};
