import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
// Components
import ServiceUserImage from "../../Components/ServiceUserImage";
import PdfBehaviourSummary from "../../Components/PdfBehaviourSummary";
// Charts
import BehaviourHeatmap from "../../Charts/BehaviourHeatmap";
import BehaviourHeatmapKey from "../../Charts/BehaviourHeatmapKey";
// Utils
import { getInitials } from "@intelligentlilli/lilli-utils";
import { parse, differenceInCalendarYears, format } from "date-fns";

const LineBreak = () => {
  return <div className={styles.line_break}></div>;
};

const PageNumber = ({ number, total }) => (
  <div className={styles.pageNumber} key={number}>
    Page {number} of {total}
  </div>
);

const General = ({
  SU,
  user,
  SUProperties,
  SUPhoto,
  pdfStyle,
  movementData,
  independenceData,
  sustenanceData,
  sleepData,
  // temperatureData,
  from,
  to,
  dates,
  report,
}) => {
  // Variables for holding the behaviour data and total pages
  const [firstSummary, setFirstSummary] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [pages, setPages] = useState();

  // Get the behaviours selected for the report
  const behaviours = report?.options?.[0]?.split(",");

  // Create a regex to ensure the date is in the format YYYY-MM-DD
  const dateRegex = new RegExp(
    /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
  );
  const isValidDOB = dateRegex.test(SUProperties?.dateOfBirth);

  // Get the SU age from their DoB
  const serviceUserAge = isValidDOB
    ? differenceInCalendarYears(
        new Date(),
        parse(SUProperties?.dateOfBirth, "yyyy-MM-dd", new Date())
      )
    : "?";

  // Split the behaviour data into pages
  useEffect(() => {
    // Get the behaviours selected for the report. Redefining it here so that we don't have to pass an array in the dependencies array
    const behaviours = report?.options?.[0]?.split(",");

    // Object to define the mapping between behaviour names (keys) and their corresponding data (values)
    const behaviourData = {
      nighttime_activity: sleepData,
      sustenance: sustenanceData,
      movement: movementData,
      independence: independenceData,
      // temperature: temperatureData,
    };

    // Generate an array of the behaviours split by week. Preserving the week number as the index in the original behaviour data arrays.
    // // Movement data seems to return an extra day when it shouldn't. This fixes it.
    // Only include dates that are earlier than "to".
    // TO DO: backend to fix (this is what we get in the network response)
    let weekBehaviours = [];
    behaviours?.forEach((behaviour) =>
      weekBehaviours.push(
        behaviourData[behaviour]
          ?.filter(
            (data) => new Date(data?.dailyResults?.[0]?.ts) <= new Date(to)
          )
          ?.map((data, index) => ({
            ...data,
            weekNumber: index + 1,
          }))
      )
    );

    // flatten the array so it's iterable over
    // filter out any undefined objects
    weekBehaviours = weekBehaviours.flat().filter((i) => i !== undefined);

    // We need the first behaviour chart for page 1
    setFirstSummary(weekBehaviours[0]);

    // Then remove it so it's not displayed twice
    weekBehaviours.shift();

    // Build an array of the pages to be rendered
    const pages = [];
    // Split the behaviours into groups of two per page
    while (weekBehaviours.length > 0) {
      pages.push(weekBehaviours.splice(0, 2));
    }

    // We manually build the first page so add one to the length of the pages array to get the total pages
    const totalPages = pages.length + 1;

    // Save the remaining data we need
    setTotalPages(totalPages);
    setPages(pages);
  }, [
    to,
    movementData,
    sleepData,
    sustenanceData,
    independenceData,
    // temperatureData,
    report?.options,
  ]);

  const careID =
    SUProperties?.mosaicID ||
    SUProperties?.careId ||
    SUProperties?.mosaicId ||
    SUProperties?.careID ||
    "Not set";

  const hasDOB = SUProperties?.dateOfBirth !== undefined || null;
  const birthday =
    hasDOB && isValidDOB
      ? format(new Date(SUProperties?.dateOfBirth), "dd MMM yyyy")
      : "unknown";

  return (
    <>
      {/* This is the first page of the pdf document - it is always present */}
      <div className={`${pdfStyle} ${styles.flex_start} ${styles.page}`}>
        <div className={styles.header}>
          <img src="/logo/lilli-128-teal.png" alt="Lilli" width="60" />
          <div className={styles.header_right}>
            <div>
              {user?.forenames} {user?.surname}
            </div>
            <div>Report created: {format(new Date(), "dd/MM/yyyy")}</div>
          </div>
        </div>
        <div data-private className={styles.service_user}>
          <div className={styles.service_user_left}>
            <ServiceUserImage
              profilePhotoURL={SUPhoto}
              className={styles.service_user_left_image}
              // If we have a profile photo for the service user then remove the border
              removeBorder={!!SUPhoto}
              initials={getInitials(SU?.userstring)}
              style={{
                height: 80,
                width: 80,
                minWidth: 80,
              }}
            />
            <div className={styles.service_user_left_name}>
              {SU?.userstring}
            </div>

            <div>Care ID:</div>
            <div className={styles.user_detail}>{careID}</div>
            <div>Birthday:</div>
            <div className={styles.user_detail}>{birthday}</div>
            <div>Age:</div>
            <div className={styles.user_detail}>{serviceUserAge}</div>
          </div>
          {to && from && (
            <div className={styles.service_user_date_range}>
              {format(from, "dd LLL")} - {format(to, "dd LLL")}
            </div>
          )}
        </div>
        <div className={styles.summary}>
          <h2 className={styles.headings}>Summary</h2>
          <div data-private className={styles.summary_text}>
            <div>{report?.summary || "Nothing written yet"}</div>
            <div className={styles.summary_text_author}>
              -{user?.forenames} {user?.surname} |{" "}
              {format(new Date(), "dd.MM.yy")}
            </div>
          </div>
        </div>

        <div className={styles.overview}>
          <h2 className={styles.headings}>Overview</h2>
          <BehaviourHeatmap
            className={styles.heatmap}
            movementData={movementData}
            sustenanceData={sustenanceData}
            // independenceData={independenceData}
            sleepData={sleepData}
            // temperatureData={temperatureData}
            dates={dates}
            behaviours={behaviours}
          />
          <BehaviourHeatmapKey />
        </div>

        <PdfBehaviourSummary week={firstSummary} pageNumber={1} />
        <PageNumber number={1} total={totalPages} />
      </div>

      {/* This is the second page of the pdf document */}
      {pages?.map((page, index) => (
        <div
          className={`${pdfStyle} ${
            page?.length === 2 ? styles.space_evenly : styles.flex_start
          } ${styles.page}`}
          key={index}
        >
          {page.map(
            (week, index) =>
              week && (
                <>
                  {index % 2 !== 0 && <LineBreak />}
                  <PdfBehaviourSummary week={week} key={index} />
                </>
              )
          )}
          <PageNumber number={index + 2} total={totalPages} />
        </div>
      ))}
    </>
  );
};

export default General;
