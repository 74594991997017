import React, { useState } from "react";
// Styles
import styles from "./styles.module.scss";
// Utils
import { deepCopyArray } from "@intelligentlilli/lilli-utils";
import {
  getFormattedChartData,
  getAvgOrCountOfEvents,
} from "../../Services/Utils";
import { temperatureRangeInsight } from "../../Services/Utils/Insights";
// Charts
import { useViewport } from "../ViewportProvider";
import VictoryFlightpathChart from "../../Charts/VictoryFlightpathChart";
// Components
import BehaviourDetails from "../BehaviourDetails";
import SummaryWeekMonth from "../SummaryWeekMonth";
import TemperatureTooltip from "../TemperatureTooltip";
import BehaviourRange from "../BehaviourRange";

const BehaviourTemperature = ({
  activeTab,
  changePage,
  temperatureData,
  temperatureRisk,
  temperatureSentences,
  dateType,
  from,
  to,
  title,
}) => {
  const filtersToRender = [
    {
      key: "temperature",
      value: "Concerning temperature",
      relatedBehaviour: "temperature",
    },
  ];
  // bool to determine whether to show charting tooltip
  const [showingTooltip, setShowingTooltip] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filtersToRender[0]);

  // sizing
  const { width } = useViewport();
  const onMobileView = width < 900;
  // bool to indicate whether we have data or not
  const copiedData = deepCopyArray(temperatureData) || [];

  // Charts
  // only show the charts if there is data
  const hasData =
    copiedData?.filter((data) => data?.value !== null)?.length > 0;
  // format data as required for victory charts
  const formattedChartData =
    getFormattedChartData(copiedData, dateType, "temperature") || [];

  // Get average, min and max temperature
  const avgTemperature = Math.round(
    getAvgOrCountOfEvents(temperatureData, "value", "average", "temperature")
  );
  const dataWithoutNulls = deepCopyArray(formattedChartData).filter(
    (day) => day.y !== null
  );
  const minTemp = Math.round(Math.min(...dataWithoutNulls.map((day) => day.y)));
  const maxTemp = Math.round(Math.max(...dataWithoutNulls.map((day) => day.y)));
  // Summary statistics
  const summaryStats = [
    { value: avgTemperature, label: "Avg. Temperature", units: "°C" },
    { value: minTemp, label: "Min. Temp.", units: "°C" },
    { value: maxTemp, label: "Max. Temp.", units: "°C" },
  ];

  // Generate the required insights
  const insight1 = temperatureRangeInsight(temperatureData);

  return dateType === "day" ? (
    <div className={styles.behaviour}>
      <div className={styles.behaviour_context}>
        <div className={styles.behaviour_title_container}>
          <h1>{title}</h1>
        </div>

        {/* Sentences: Tablet/Desktop */}
        {/* note: the position of the details section changes on mobile v desktop */}
        {!onMobileView && hasData && (
          <>
            <BehaviourDetails
              behaviourSentences={temperatureSentences}
              selectedBehaviour="temperature"
              endDate={to}
            />
            <TemperatureTooltip />
          </>
        )}
      </div>

      {/* Show the summary stats & the chart only for DAY view */}
      {hasData && (
        <div className={styles.behaviour_chart}>
          {!showingTooltip && (
            <div className={styles.chartSummary}>
              <SummaryWeekMonth
                data={summaryStats}
                rating={temperatureRisk}
                behaviour={"temperature"}
              />
            </div>
          )}
          <VictoryFlightpathChart
            data={formattedChartData}
            behaviour={"temperature"}
            showingTooltip={showingTooltip}
            setShowingTooltip={setShowingTooltip}
            dateType={dateType}
            yAxisTitle="Temperature"
            changePage={changePage}
            activeTab={activeTab}
          />
        </div>
      )}

      {/* Sentences: Mobile */}
      {onMobileView && hasData && (
        <>
          <BehaviourDetails
            behaviourSentences={temperatureSentences}
            selectedBehaviour="temperature"
            endDate={to}
          />
          <TemperatureTooltip />
        </>
      )}
    </div>
  ) : (
    <BehaviourRange
      data={temperatureData}
      from={from}
      to={to}
      activeTab={activeTab}
      filtersToRender={filtersToRender}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      changePage={changePage}
      isMobile={onMobileView}
      dateType={dateType}
      insights={[insight1]}
    />
  );
};

export default BehaviourTemperature;
