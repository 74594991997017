import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const sensorLocationsSlice = createSlice({
  name: "sensorLocations",
  initialState,
  reducers: {
    setSensorLocations(_, action) {
      return action.payload;
    },
  },
});

export const { setSensorLocations } = sensorLocationsSlice.actions;

export default sensorLocationsSlice.reducer;
