import React from "react";
import styles from "./styles.module.scss";

const SensorGuideHeader = ({ instructions, isDesktop }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: `${isDesktop ? "flex-start" : "center"}`,
        }}
      >
        <img
          src={instructions.image}
          alt={instructions.alt}
          style={{
            maxWidth: `${instructions?.width}px`,
            maxHeight: `${instructions?.height}px` || "auto",
          }}
          className={styles.header_image}
        />
      </div>
      <div className={styles.title}>{instructions.title}</div>
      {instructions.description && (
        <div className={styles.description}>{instructions.description}</div>
      )}
    </div>
  );
};

export default SensorGuideHeader;
