import {
  handleDaytime,
  handleNighttime,
  handleIndependence,
  handleTemperature,
  handleBathroom,
  handleSustenance,
} from "./";

// Main functionality for getting condensed stats based on behavior
export const getRangeViewStats = (data, behaviourName, filterName) => {
  const sortedData = data?.sort((a, b) => (a.date > b.date ? 1 : -1));

  switch (behaviourName) {
    case "day-time":
      return handleDaytime(sortedData, filterName);
    case "night-time":
      return handleNighttime(sortedData, filterName);
    case "independence":
      return handleIndependence(sortedData, filterName);
    case "temperature":
      return handleTemperature(sortedData);
    case "bathroom":
      return handleBathroom(sortedData, filterName);
    case "sustenance":
      return handleSustenance(sortedData, filterName);
    default:
      return [];
  }
};
