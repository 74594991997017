export const sensorDisplayNameDictionary = {
  "hall.front.door": "Front door",
  "hall.back.door": "Back door",
  "hall.main.door": "Main door",
  "kitchen.fridge.door": "Fridge",
  "kitchen.cupboard.door": "Cupboard",
  "hall.wall.motion": "Hallway movement",
  "bedroom.wall.motion": "Bedroom event",
  "kitchen.kettle.power": "Kettle used",
  "kitchen.toaster.power": "Toaster used",
  "kitchen.appliance.power": "Appliance used",
  "kitchen.microwave.power": "Microwave used",
  "bathroomInference.toilet": "Bathroom occupied",
  "bathroomInference.bathing": "Bath/Shower",
};
