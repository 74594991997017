export const sortByObjectProperty = (
  arrayOfObjects: any[],
  fieldName: string,
  isDescending = true
): any[] => {
  let sortDirection: number;

  isDescending ? (sortDirection = -1) : (sortDirection = 1);

  return arrayOfObjects.sort((a, b) => {
    let nameA: string | number | boolean;
    let nameB: string | number | boolean;

    if (fieldName === "nextdue") {
      let today = new Date().getTime().toString();
      nameA = a[fieldName] == null ? today : a[fieldName];
      nameB = b[fieldName] == null ? today : b[fieldName];
    } else {
       // If the property value is equal to null, set its value to 'n/a'. If it is a boolean or a number, leave the value alone. 
      // Finally, if the property's value is neither 'null' or 'boolean', then you can lowercase the value.
      nameA =
        a[fieldName] == null
          ? 'n/a'
          : typeof a[fieldName] === "boolean"
          ? a[fieldName]
          : typeof a[fieldName] === "number"
          ? a[fieldName]
          : a[fieldName].toLowerCase();
      nameB =
        b[fieldName] == null
          ? 'n/a'
          : typeof b[fieldName] === "boolean"
          ? b[fieldName]
          : typeof b[fieldName] === "number"
          ? b[fieldName]
          : b[fieldName].toLowerCase();
    }

    if (nameA < nameB) {
      return 1 * sortDirection;  // if isDescending = true, then it remains positive, otherwise, if it is false, it is multiplied by a negative value, and reverses the sort
    }
    if (nameA > nameB) {
      return -1 * sortDirection;
    }
    return 0;
  });
};
