export const hasSustenanceData = (dayObj) => {
  // if any of the values are a number, return true
  if (
    !isNaN(dayObj?.totalFridgeCount) ||
    !isNaN(dayObj?.totalSmartplugCount) ||
    !isNaN(dayObj?.totalKettleCount)
  ) {
    return true;
  }
  return false;
};
