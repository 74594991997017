import { DataType } from "../../types/NoData/DataType";

/*  
  Construct null flight path
  Any date that has a number, make it 'null'
  Any date that is null, give it a min and maxValue
*/
export const getNullLines = (
  data: DataType[] | undefined,
  maxValue: number
): DataType[] => {
  if (data === undefined) {
    return [];
  }

  // 1- make an array containing only the y-values
  const yValues: (number | null)[] = [];
  for (let i = 0; i < data.length; i++) {
    yValues.push(data[i].y);
  }

  // 2- make a copy of the original data
  const copiedData: DataType[] = [];
  for (let i = 0; i < data.length; i++) {
    copiedData.push(data[i]);
  }

  // 3- iterate over the above array and then mutate the original array
  for (let i = 0; i < yValues.length; i++) {
    // initially give all indices a null flight path
    // these values will be overwritten by the following rules below
    if (copiedData[i].value === undefined) {
      copiedData[i].y = null;
    }

    // if the first and last values are null, give it a flight path
    if ((i === 0 || i === yValues.length - 1) && yValues[i] === null) {
      copiedData[i].y = maxValue;
    }

    // as long as the previous index is greater than 0...
    // if the previous index is null and the current index is NOT null
    // then give the current index a flight path
    if (i - 1 >= 0 && yValues[i - 1] === null && yValues[i] !== null) {
      copiedData[i].y = maxValue;
    }

    // similarly, as long as the next index is less than the length of the array...
    // if the next index is null and the current index is NOT null
    // then give it a flight path
    if (
      i + 1 < yValues.length &&
      yValues[i + 1] === null &&
      yValues[i] !== null
    ) {
      copiedData[i].y = maxValue;
    }
  }

  const result: DataType[] = copiedData.map((item) => {
    delete item.y0;
    return item;
  });

  return result;
};
