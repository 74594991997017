import React from "react";
import styles from "./styles.module.scss";

const LeftContainer = ({ children }) => {
  return <div className={styles.summary_left_container}>{children}</div>;
};

const SummaryContainer = ({ children }) => {
  return <div className={styles.summary_item_container}>{children}</div>;
};

const Number = ({ datum, behaviour, small, isPDF }) => (
  <div className={styles.number}>
    <div
      className={`${styles.number_value} ${
        small && styles.number_value_small
      } ${isPDF && styles.number_value_xsmall}`}
    >
      {datum.value}
    </div>
    {datum.units && (
      <div
        className={`
        ${styles.number_units} 
        ${behaviour === "temperature" && styles.number_celcius}`}
      >
        {datum.units}
      </div>
    )}
    {datum.value2 && (
      <>
        <div
          className={`${styles.number_value} ${
            (small || isPDF) && styles.number_value_xsmall
          }
          `}
        >
          {datum.value2}
        </div>
        {datum.units2 && (
          <div className={styles.number_units}>{datum.units2}</div>
        )}
      </>
    )}
  </div>
);

const Label = ({ label, small }) => {
  return (
    <div
      className={`${styles.summary_label} ${
        small && styles.summary_label_small
      }`}
    >
      {label}
    </div>
  );
};

const SummaryWeekMonth = ({ data, behaviour, small, isPDF = false }) => {
  return (
    <div className={`${styles.container} ${isPDF && styles.pdf_container}`}>
      <LeftContainer>
        {data.map((datum, index) => (
          <SummaryContainer key={index}>
            <Number
              datum={datum}
              behaviour={behaviour}
              small={small}
              isPDF={isPDF}
            />
            <Label label={datum.label} small={small} />
          </SummaryContainer>
        ))}
      </LeftContainer>
    </div>
  );
};

export default SummaryWeekMonth;
