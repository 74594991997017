import React from "react";
import theme from "../../theme.scss";

export const CircledTickIcon = ({ width, color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="Group 224">
        <path
          id="Vector"
          d="M8 0C3.58187 0 0 3.58187 0 8C0 12.4181 3.58187 16 8 16C12.4181 16 16 12.4181 16 8C15.9953 3.58375 12.4163 0.0046875 8 0ZM8 14.6666C4.31812 14.6666 1.33344 11.6819 1.33344 8C1.33344 4.31812 4.31812 1.33344 8 1.33344C11.6819 1.33344 14.6665 4.31812 14.6665 8C14.6625 11.6803 11.68 14.6625 8 14.6666Z"
          fill={color || theme.neutral6}
        />
        <path
          id="Vector_2"
          d="M11.5832 5.46284C11.3228 5.20253 10.9009 5.20253 10.6403 5.46284L6.8891 9.21471L5.0972 7.44597C4.83939 7.1819 4.41628 7.17659 4.15222 7.4344C3.88816 7.69221 3.88284 8.11535 4.14065 8.37941L5.97003 10.1794C6.20191 10.4294 6.52564 10.5738 6.86658 10.5794H6.88846C7.22252 10.5806 7.54316 10.4478 7.77847 10.2106L11.5832 6.40535C11.8435 6.14504 11.8435 5.72316 11.5832 5.46254V5.46284Z"
          fill={color || theme.neutral6}
        />
      </g>
    </svg>
  );
};
