import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
// Utils
import { getInitialsFromNames } from "@intelligentlilli/lilli-utils";
// Components
import Card from "../Card";
import ServiceUserImage from "../ServiceUserImage";

const InstallerCard = ({ installer, onClick }) => {
  const staffName =
    installer?.forenames && installer?.surname
      ? installer?.forenames + " " + installer?.surname
      : installer?.username;

  return (
    <Card onClick={onClick} variant="outlined" style={styles.card}>
      <div className={styles.card_top}>
        <ServiceUserImage
          medium
          initials={getInitialsFromNames(
            installer.forenames,
            installer.surname
          )}
          profilePhotoURL={installer.photo}
        />
        <div className={styles.card_right}>
          <div className={styles.card_right_name}>{staffName}</div>
          <div>Installer</div>
        </div>
      </div>
    </Card>
  );
};

InstallerCard.propTypes = {
  onClick: PropTypes.func,
  installer: PropTypes.object,
};

export default InstallerCard;
