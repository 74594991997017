import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { DashboardIcon, ListIcon } from "../../Styles/Icons";

const LayoutToggle = (props) => {
  return (
    <div className={styles.layout}>
      <div
        className={
          props.layoutPreference === "grid" ? styles.layout_active : null
        }
      >
        <IconButton
          onClick={() => props.dispatch(props.setLayoutPreference("grid"))}
          aria-label="Switch to grid view"
          size="large"
        >
          <DashboardIcon />
        </IconButton>
      </div>
      <div
        className={
          props.layoutPreference === "list" ? styles.layout_active : null
        }
      >
        <IconButton
          onClick={() => props.dispatch(props.setLayoutPreference("list"))}
          aria-label="Switch to list view"
          className={
            props.layoutPreference === "list" ? styles.layout_active : null
          }
          size="large"
        >
          <ListIcon />
        </IconButton>
      </div>
    </div>
  );
};

LayoutToggle.propTypes = {
  dispatch: PropTypes.func,
  layoutPreference: PropTypes.string,
  setLayoutPreference: PropTypes.func,
};

export default LayoutToggle;
