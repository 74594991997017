import React from "react";
import styles from "./styles.module.scss";

const SettingsHeader = () => {
  return (
    <div>
      <div className={styles.dashboard_header_container}>
        <div>
          <div className={styles.page_title}>Settings</div>
          <div className={styles.user_info}>Update your own settings</div>
        </div>
      </div>
    </div>
  );
};

export default SettingsHeader;
