import React from "react";
import styles from "./styles.module.scss";
import InstallationHint from "../../InstallationHint";
import InstallationNextButton from "../../InstallationNextButton";
import InstallationGoBackButton from "../../InstallationGoBackButton";

const MobileGuideBody = ({
  instructions,
  setShowGuide,
  motionMethod,
  isMotion,
  goBacktoMotionInstallOptions,
}) => {
  return (
    <div>
      <ol className={styles.instructions_steps}>
        {instructions.map((item, index) => {
          if (item.text) {
            return <li key={index}>{item.text}</li>;
          }
          if (item.hint) {
            return <InstallationHint hints={item.hint} key={index} />;
          }
          if (item.image) {
            return (
              <div style={{ marginTop: "12px", marginBottom: "16px" }}>
                <img
                  key={index}
                  src={item.image.image}
                  alt={item.image.alt}
                  style={{
                    maxHeight: `${item?.image.height}px`,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                />
              </div>
            );
          }
          if (item.note) {
            return (
              <div className={styles.note} key={index}>
                Note: <span>{item.note}</span>
              </div>
            );
          }
          if (item.description) {
            return (
              <div key={index} className={styles.description}>
                {item.description}
              </div>
            );
          }
          return null;
        })}
      </ol>
      <InstallationNextButton
        label="Back to install"
        onClick={setShowGuide}
        marginTop="16px"
      />
      {isMotion && motionMethod && (
        <InstallationGoBackButton
          label="Install options"
          onClick={goBacktoMotionInstallOptions}
          marginTop="16px"
        />
      )}
    </div>
  );
};

export default MobileGuideBody;
