import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import SensorGuideHeader from "../SensorGuideHeader";
import MobileGuideBody from "../MobileGuideBody";
import SensorMotionMethods from "../SensorMotionMethods";
import DesktopTips from "../DesktopTips";
import DesktopInstructions from "../DesktopInstructions";
import InstallationGoBackButton from "../../InstallationGoBackButton";
import { MOTION_METHOD_HEADERS } from "../../../Services/Constants";

const SensorGuide = ({ isDesktop, instructions, isMotion, setShowGuide }) => {
  const [motionMethod, setMotionMethod] = useState(null);
  const [showInstructions, setShowInstructions] = useState(null);
  const [motionInstructions, setMotionInstructions] = useState(null);

  useEffect(() => {
    if (motionMethod) {
      setMotionInstructions(instructions?.guide.instructions?.[motionMethod]);
    }
  }, [motionMethod, instructions?.guide.instructions]);

  // always show the instructions if it is not a motion sensor
  // otherwise, only show the motion instructions if the user has selected a motion method and clicked 'next' button
  const showSensorGuideBody = !isMotion || (motionMethod && showInstructions);

  const instructionsToDisplay = !motionMethod
    ? instructions.guide.header
    : MOTION_METHOD_HEADERS[motionMethod];

  return (
    <div className={styles.sensor_guide_container}>
      {isDesktop ? (
        <div className={styles.desktop}>
          <div className={styles.desktop_top}>
            <div className={styles.desktop_left}>
              <SensorGuideHeader
                instructions={
                  !motionMethod
                    ? instructions.guide.header
                    : MOTION_METHOD_HEADERS[motionMethod]
                }
                isDesktop={isDesktop}
              />
              {showSensorGuideBody && (
                <DesktopTips
                  instructions={
                    isMotion
                      ? motionInstructions
                      : instructions.guide.instructions
                  }
                />
              )}
            </div>
            <div className={styles.desktop_right}>
              {showSensorGuideBody && (
                <DesktopInstructions
                  instructions={
                    isMotion
                      ? motionInstructions
                      : instructions.guide.instructions
                  }
                />
              )}
              {isMotion && !showSensorGuideBody && (
                <SensorMotionMethods
                  options={instructions.guide.options}
                  motionMethod={motionMethod}
                  setMotionMethod={setMotionMethod}
                  setShowInstructions={setShowInstructions}
                  closeModal={() => setShowGuide(false)}
                  isDesktop={isDesktop}
                />
              )}
            </div>
          </div>
          <div className={styles.desktop_bottom_buttons}>
            {isMotion && motionMethod && showSensorGuideBody && (
              <InstallationGoBackButton
                label="Install options"
                onClick={() => setMotionMethod(null)}
                marginTop="16px"
              />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.mobile}>
          <div className={styles.mobile_header}>
            <SensorGuideHeader instructions={instructionsToDisplay} />
          </div>
          <div className={styles.mobile_body}>
            {isMotion && !showSensorGuideBody && (
              <SensorMotionMethods
                options={instructions.guide.options}
                motionMethod={motionMethod}
                setMotionMethod={setMotionMethod}
                setShowInstructions={setShowInstructions}
                closeModal={() => setShowGuide(false)}
              />
            )}
            {showSensorGuideBody && (
              <MobileGuideBody
                motionMethod={motionMethod}
                goBacktoMotionInstallOptions={() => setMotionMethod(null)}
                isMotion={isMotion}
                setShowGuide={setShowGuide}
                instructions={
                  isMotion
                    ? motionInstructions
                    : instructions.guide.instructions
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SensorGuide;
