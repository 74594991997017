import { differenceInDays, parseISO } from "date-fns";
// Linear weighted risk status per behaviour
// For a full explanation reffer to https://docs.google.com/spreadsheets/d/14fFXw6QIvBFJqNb2AeugPEQBs_f3llgNp565yMfob9E/edit#gid=0

interface RiskData {
  date: string;
  details: Record<string, { dayRating: number | null }>; // This notation is a shorthand way of expressing an object type in TypeScript.
  // It essentially says that details is an object where the keys are strings
}

export const calcBehaviourRisk = (
  riskData: RiskData[],
  behaviourKey: string
): number | null => {
  // if all the day ratings are null return null
  if (
    riskData.filter((day) => !(day.details[behaviourKey]?.dayRating === null))
      .length === 0
  ) {
    return null;
  }
  // First get the total weight by multiplying the risk rating by the weight of the day (more recent has more weight)
  const totalWeight = riskData.reduce(
    (total, day) =>
      total +
      (day.details[behaviourKey]?.dayRating || 0) *
        (7 - differenceInDays(new Date(), parseISO(day.date + "T00:00:00Z"))),
    0
  );
  // Get the total of the weights
  const weightFactors = riskData.reduce(
    (total, day) =>
      total +
      (7 - differenceInDays(new Date(), parseISO(day.date + "T00:00:00Z"))),
    0
  );

  return Math.round(totalWeight / weightFactors);
};
