import React from "react";
import theme from "../../theme.scss";

export const DashboardIcon = ({ width, colour }) => {
  return (
    <svg width={width || 30} viewBox="0 0 50 50" id="DashboardIcon">
      <path
        d="M48.1688 18.8492L32.3667 3.04505C30.411 1.09503 27.7618 0 25 0C22.2382 0 19.5891 1.09503 17.6334 3.04505L1.83128 18.8492C1.24883 19.4279 0.787046 20.1165 0.472697 20.875C0.158348 21.6335 -0.00231333 22.4469 2.51656e-05 23.268V43.7201C2.51656e-05 45.3777 0.658505 46.9674 1.83061 48.1395C3.00271 49.3116 4.59242 49.9701 6.25003 49.9701H43.75C45.4076 49.9701 46.9973 49.3116 48.1694 48.1395C49.3415 46.9674 50 45.3777 50 43.7201V23.268C50.0024 22.4469 49.8417 21.6335 49.5274 20.875C49.213 20.1165 48.7512 19.4279 48.1688 18.8492V18.8492ZM31.25 45.8034H18.75V37.6076C18.75 35.95 19.4085 34.3602 20.5806 33.1881C21.7527 32.016 23.3424 31.3576 25 31.3576C26.6576 31.3576 28.2473 32.016 29.4194 33.1881C30.5915 34.3602 31.25 35.95 31.25 37.6076V45.8034ZM45.8334 43.7201C45.8334 44.2726 45.6139 44.8025 45.2232 45.1932C44.8325 45.5839 44.3026 45.8034 43.75 45.8034H35.4167V37.6076C35.4167 34.8449 34.3192 32.1954 32.3657 30.2419C30.4122 28.2884 27.7627 27.1909 25 27.1909C22.2374 27.1909 19.5878 28.2884 17.6343 30.2419C15.6808 32.1954 14.5834 34.8449 14.5834 37.6076V45.8034H6.25003C5.69749 45.8034 5.16759 45.5839 4.77689 45.1932C4.38619 44.8025 4.16669 44.2726 4.16669 43.7201V23.268C4.16862 22.7158 4.38792 22.1867 4.77711 21.7951L20.5792 5.99714C21.7536 4.82824 23.3431 4.17202 25 4.17202C26.657 4.17202 28.2465 4.82824 29.4209 5.99714L45.2229 21.8013C45.6106 22.1914 45.8298 22.718 45.8334 23.268V43.7201Z"
        fill={colour || theme.white}
      />
    </svg>
  );
};
