export const getBucketNumber = (datapoint, max) => {
  if (!datapoint || !max) {
    return 0;
  }
  const CHUNKS = 7;
  const bucketWidth = max / CHUNKS;
  const bucketNumber = Math.floor(datapoint / bucketWidth);
  return bucketNumber;
};

export const getColorGradient = (bucketNumber, baseColorMax) => {
  const result =
    bucketNumber === 7
      ? `${baseColorMax}`
      : bucketNumber === 0
      ? "white"
      : `${baseColorMax}${bucketNumber}0`;
  return result;
};

export const getMinAndMax = (array, key) => {
  if (!array) {
    return {
      min: 0,
      max: 0,
    };
  }
  const rawMin = Math.min(...array?.map((i) => i?.[key]));
  const rawMax = Math.max(...array?.map((i) => i?.[key]));
  const min = rawMin === Infinity ? 0 : rawMin;
  const max = rawMax === Infinity ? 0 : rawMax;
  return {
    min,
    max,
  };
};
