import { useEffect, useState } from "react";
// UTILS
import {
  fixBehaviourData,
  extractRiskLevel,
  dateToTimestamp,
  extractDailySustenanceData,
  // extractHourlySustenanceData,
} from "@intelligentlilli/lilli-utils";
// DateTime
import { subDays, endOfDay } from "date-fns";

export const usePrepareSustenanceData = (
  sustenanceAnalysis,
  startDateString,
  endDateString,
  dateType
) => {
  const [sustenanceRisk, setSustenanceRisk] = useState();
  const [sustenanceData, setSustenanceData] = useState();
  const [sustenanceDataPrevious, setSustenanceDataPrevious] = useState();
  const [fridgeData, setFridgeData] = useState();
  const [kettleData, setKettleData] = useState();

  useEffect(() => {
    const endDate = endDateString && endOfDay(new Date(endDateString));
    const startDate = startDateString && endOfDay(new Date(startDateString));
    const fridgeData = fixBehaviourData(
      dateType === "day"
        ? extractDailySustenanceData(
            ["fridgeCount"],
            sustenanceAnalysis?.dailyResults,
            subDays(startDate, 1),
            endDate
          )
        : extractDailySustenanceData(
            ["totalFridgeCount"],
            sustenanceAnalysis?.dailyResults,
            subDays(startDate, 1),
            endDate
          )
    );

    // v1 sustenance = "kettleCount", "totalKettleCount", v2 sustenance = "smartplugCount", "totalSmartplugCount"
    const kettleData = fixBehaviourData(
      dateType === "day"
        ? extractDailySustenanceData(
            ["kettleCount", "smartplugCount"],
            sustenanceAnalysis?.dailyResults,
            subDays(startDate, 1),
            endDate
          )
        : extractDailySustenanceData(
            ["totalKettleCount", "totalSmartplugCount"],
            sustenanceAnalysis?.dailyResults,
            subDays(startDate, 1),
            endDate
          )
    );

    // New sustenance data
    setSustenanceData(
      sustenanceAnalysis?.dailyResults?.filter(
        (i) =>
          dateToTimestamp(i?.date) >= subDays(startDate, 1) &&
          dateToTimestamp(i?.date) <= endDate
      ) || []
    );

    // Previous perios sustenance data
    setSustenanceDataPrevious(
      sustenanceAnalysis?.dailyResults?.filter(
        (i) =>
          dateToTimestamp(i?.date) >= subDays(startDate, 2) &&
          dateToTimestamp(i?.date) <= subDays(endDate, 1)
      ) || []
    );

    setSustenanceRisk(
      extractRiskLevel(
        sustenanceAnalysis?.dailyResults,
        subDays(startDate, 1),
        endDate
      )
    );
    setKettleData(kettleData);
    setFridgeData(fridgeData);
  }, [sustenanceAnalysis, endDateString, startDateString, dateType]);

  return {
    sustenanceRisk,
    sustenanceData,
    sustenanceDataPrevious,
    fridgeData,
    kettleData,
  };
};
