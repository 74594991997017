import { format, addHours, subHours, subDays, addDays } from "date-fns";
import { DataType } from "../../types/NoData/DataType";
import { DateTypes } from "../../types/Dates/dateTypes";

const dateOrHour = (
  datumTimeStamp: Date,
  dateType: DateTypes,
  showEndHour = false
) => {
  const weekOrMonthString = `${format(datumTimeStamp, "EEE, LLL dd")}`;
  const dayString = `${format(datumTimeStamp, "MMM d, haaa")}`;
  const dayStringWithEndHour = `${dayString} - ${format(
    addHours(datumTimeStamp, 1),
    "haaa"
  )}`;
  const result =
    dateType !== "day"
      ? weekOrMonthString
      : showEndHour
      ? dayStringWithEndHour
      : dayString;
  return result;
};

export const getDatesWithNoData = (
  binInterval: number[],
  dateType: DateTypes,
  data: DataType[]
) => {
  // binIntervals do not start with an index of 0, they start at 1
  // as such, subtract 1, so they are in line with the data array
  const startIndex = binInterval[0] - 1;
  const endIndex = binInterval[1] - 1;
  const gap = endIndex - startIndex;

  const startValue = data?.[startIndex]?.value;
  const endValue = data?.[endIndex]?.value;

  const startTime = data?.[startIndex]?.ts;
  const endTime = data?.[endIndex]?.ts;

  const atTheStartOfTheArray = startIndex < 1;
  const atTheEndOfTheArray = endIndex === data.length - 1;
  const noDataAllDay = gap === 24;
  const noDataAllWeek = gap === 7;

  if (dateType === "day" && noDataAllDay) {
    return "No data for the day";
  }
  if (dateType === "week" && noDataAllWeek) {
    return "No data for the week";
  }

  /* 
  If only one hour / day is missing data 
     If you're at the start/end of the array, then show either the start or the end date
     Else show both start and end dates
  */

  if (gap <= 2) {
    if (atTheStartOfTheArray) {
      if (startValue !== null) {
        return dateType === "day"
          ? dateOrHour(addHours(startTime, 1), dateType, true)
          : dateOrHour(addDays(startTime, 1), dateType);
      } else {
        return dateType === "day"
          ? dateOrHour(startTime, dateType, true)
          : dateOrHour(startTime, dateType);
      }
    } else if (atTheEndOfTheArray) {
      if (endValue !== null) {
        return dateType === "day"
          ? dateOrHour(subHours(endTime, 1), dateType, true)
          : dateOrHour(subDays(endTime, 1), dateType);
      } else {
        return dateType === "day"
          ? dateOrHour(endTime, dateType, true)
          : dateOrHour(endTime, dateType);
      }
    } else {
      return `${dateOrHour(addDays(startTime, 1), dateType)}`;
    }
  }
  /* ELSE, if more than 1 hour/day is missing
    and if there's no data at the start of the array, then show the startTimeDate (as is) and the endTimeDate (minus 1 day/hour)
    else if there IS data at the start of the array, then show the startTimeDate (plus 1 hour/day) and the endTimeDate (as is)
    ...ditto for the end of the array
  */
  if (atTheStartOfTheArray) {
    if (startValue === null) {
      if (dateType === "day") {
        return `${dateOrHour(startTime, dateType)} - ${dateOrHour(
          subHours(endTime, 1),
          dateType
        )}`;
      } else {
        return `${dateOrHour(startTime, dateType)} - ${dateOrHour(
          subDays(endTime, 1),
          dateType
        )}`;
      }
    } else {
      if (dateType === "day") {
        return `${dateOrHour(addHours(startTime, 1), dateType)} - ${dateOrHour(
          subHours(endTime, 1),
          dateType
        )}`;
      } else {
        return `${dateOrHour(addDays(startTime, 1), dateType)} - ${dateOrHour(
          subDays(endTime, 1),
          dateType
        )}`;
      }
    }
  }

  if (atTheEndOfTheArray) {
    if (endValue === null) {
      if (dateType === "day") {
        return `${dateOrHour(addHours(startTime, 1), dateType)} - ${dateOrHour(
          endTime,
          dateType
        )}`;
      } else {
        return `${dateOrHour(addDays(startTime, 1), dateType)} - ${dateOrHour(
          endTime,
          dateType
        )}`;
      }
    } else {
      if (dateType === "day") {
        return `${dateOrHour(addHours(startTime, 1), dateType)} - ${dateOrHour(
          subHours(endTime, 1),
          dateType
        )}`;
      } else {
        return `${dateOrHour(addDays(startTime, 1), dateType)} - ${dateOrHour(
          subDays(endTime, 1),
          dateType
        )}`;
      }
    }
  }

  if (dateType === "day") {
    return `${dateOrHour(addHours(startTime, 1), dateType)} - ${dateOrHour(
      subHours(endTime, 1),
      dateType
    )}`;
  } else {
    return `${dateOrHour(addDays(startTime, 1), dateType)} - ${dateOrHour(
      subDays(endTime, 1),
      dateType
    )}`;
  }
};
