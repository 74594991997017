import { isAfter, startOfDay, isBefore, addHours } from "date-fns";

// This function is used to split the events into time periods
// It is a helper function that is used in both 'getMidnightToMidnightEvents' and 'getNewDaytimeEvents'
export const getDaytimeEvents = (allEvents, urlEndDateString, morningStart) => {
  // Construct the time periods
  const afternoonStart = addHours(startOfDay(new Date(urlEndDateString)), 12);
  const eveningStart = addHours(startOfDay(new Date(urlEndDateString)), 18);
  const morningEvents = allEvents?.filter(
    (event) =>
      isAfter(new Date(event?.["startedAt"]), morningStart) &&
      isBefore(new Date(event?.["startedAt"]), afternoonStart)
  );
  const afternoonEvents = allEvents?.filter(
    (event) =>
      isAfter(new Date(event?.["startedAt"]), afternoonStart) &&
      isBefore(new Date(event?.["startedAt"]), eveningStart)
  );
  const eveningEvents = allEvents?.filter((event) =>
    isAfter(new Date(event?.["startedAt"]), eveningStart)
  );

  return {
    morningEvents,
    afternoonEvents,
    eveningEvents,
    afternoonStart,
    eveningStart,
  };
};
