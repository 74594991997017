import React from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { getServiceUserById } from "@intelligentlilli/lilli-utils";
import { useNavigate } from "react-router-dom";
import { NotificationItemIcon } from "../../Styles/Icons/DesignSystem/NotificationItemIcon";

const DashboardNotificationItem = ({
  hubID,
  behaviour,
  notificationText,
  riskStatus,
  timestamp,
}) => {
  const navigate = useNavigate();

  const getDetails = (hubID, behaviour, timestamp) => {
    const url = `/lilli-users/${hubID}?tab=${behaviour}&view=day&start=${timestamp}&end=${timestamp}`;
    return behaviour ? navigate(url) : {};
  };

  const serviceUsers = useSelector((state) => state.serviceUsers);
  const userstring =
    getServiceUserById(serviceUsers, hubID.toString())?.userstring ||
    "This individual";

  const isRiskSentence = riskStatus && behaviour;

  return (
    <div
      className={behaviour ? styles.clickable : styles.not_clickable}
      onClick={() => getDetails(hubID, behaviour, timestamp)}
    >
      <div className={styles.notification_item_icon_container}>
        <NotificationItemIcon />
      </div>
      {isRiskSentence && (
        <div className={styles.notification_item_title}>
          <span className={styles.bold}>{userstring}</span> moved to{" "}
          <span className={styles.bold}>{riskStatus}</span> because of{" "}
          <span className={styles.bold}>{behaviour}</span>
        </div>
      )}
      {!isRiskSentence && (
        <div className={styles.notification_item_title}>{notificationText}</div>
      )}
    </div>
  );
};

export default DashboardNotificationItem;
