import React from "react";
import styles from "./styles.module.scss";
import { CalendarIcon } from "../../Styles/Icons/DesignSystem";
const DatePicker = ({ name, min, max, value, onChange }) => {
  const dateRef = React.useRef(null);
  return (
    <div
      className={styles.date_input_group}
      onClick={() => dateRef.current.showPicker()}
    >
      <CalendarIcon />
      <input
        className={styles.date_selection}
        type="date"
        name={name}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        ref={dateRef}
      />
    </div>
  );
};

export default DatePicker;
