import {
  sortByTimestamp,
  dateToTimestamp,
  dateHourToTimestamp,
} from "../Dates/dateManipulations";

type TemperatureHourlyDataType =
  | {
      hour: number;
      averageTemperature: number | null; // decimal
      riskLevel: number | null;
    }[]
  | [];

type TemperatureDailyResult = {
  date: string; // "YYYY-MM-DD"
  hourlyData: TemperatureHourlyDataType;
  reason?: string; // "cold" | "hot"
  riskLevel: number | null;
  sentences: string[] | [];
}[];


export const extractHourlyTemperatureData = (countKey: "averageTemperature", dailyResults: TemperatureDailyResult, from: string, to: string) => {
  if (!dailyResults || !from || !to || !countKey) return [];

  const fromDate = new Date(from);
  const toDate = new Date(to);

  const updated =
    dailyResults
      ?.flatMap(({ date, hourlyData }) =>
        hourlyData?.map(({ hour, averageTemperature, riskLevel }) => ({
          ts: dateHourToTimestamp(date, hour),
          value: averageTemperature,
          riskLevel,
        }))
      )
      ?.filter(({ ts }) => ts >= fromDate && ts <= toDate) || [];

  const result = sortByTimestamp(updated);
  return result;
};

// note: this function should only be run on the temperature v2 endpoint
export const extractDailyTemperatureData = (countKey: "averageTemperature", dailyResults: TemperatureDailyResult, from: string, to: string) => {
  if (!dailyResults || !countKey || !from || !to) return [];

  const fromDate = new Date(from);
  const toDate = new Date(to);

  const updated = dailyResults?.map((day) => {
    const hourlyDataWithoutNulls = day?.hourlyData.filter(
      (i) => i?.averageTemperature !== null
    );

    const sumOfHourlyTemp = hourlyDataWithoutNulls.reduce(
      (total, hour) => total + (hour?.averageTemperature || 0), // Ensure safety with null checks
      0
    );

    const avgTemp =
      hourlyDataWithoutNulls.length > 0
        ? parseFloat(
            (sumOfHourlyTemp / hourlyDataWithoutNulls?.length).toFixed(1)
          )
        : null;

    return {
      ...day,
      averageTemp: avgTemp,
      value: avgTemp,
      ts: dateToTimestamp(day?.date),
      dayRating: day?.riskLevel,
    };
  });

  const filtered = updated?.filter(({ ts }) => ts >= fromDate && ts <= toDate);
  const result = sortByTimestamp(filtered);
  return result;
};
