import React from "react";
import theme from "../../theme.scss";

export const DeleteReportIcon = ({ width, height, colour }) => {
  return (
    <svg
      width={width || 40}
      height={height || 40}
      viewBox="0 0 40 40"
      fill={colour || theme.atrisk2}
    >
      <path d="M26.6673 13.3335C26.0165 12.6828 24.9618 12.6828 24.3103 13.3335L20.0001 17.6437L15.69 13.3335C15.0282 12.6945 13.9728 12.7124 13.3329 13.3741C12.7095 14.0202 12.7095 15.0437 13.3329 15.6898L17.6431 20L13.3329 24.3101C12.6939 24.9718 12.7119 26.0273 13.3736 26.6671C14.0197 27.2906 15.0431 27.2906 15.6892 26.6671L19.9994 22.357L24.3095 26.6671C24.9712 27.3062 26.0267 27.2883 26.6665 26.6265C27.29 25.9805 27.29 24.957 26.6665 24.3109L22.3564 20.0007L26.6665 15.6906C27.3173 15.0398 27.3173 13.9851 26.6665 13.3335H26.6673Z" />
      <path d="M20 0C8.95469 0 0 8.95469 0 20C0 31.0453 8.95469 40 20 40C31.0453 40 40 31.0453 40 20C39.9883 8.95937 31.0406 0.0117188 20 0ZM20 36.6664C10.7953 36.6664 3.33359 29.2047 3.33359 20C3.33359 10.7953 10.7953 3.33359 20 3.33359C29.2047 3.33359 36.6663 10.7953 36.6663 20C36.6562 29.2008 29.2 36.6563 20 36.6664Z" />
    </svg>
  );
};
