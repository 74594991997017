import React from "react";
import theme from "../../Styles/theme.scss";
import { RadialChart, makeVisFlexible } from "react-vis";
import Chart from "../Chart";
import PropTypes from "prop-types";

const FlexRadialChart = makeVisFlexible(RadialChart);

const PieChart = (props) => {
  const myData = [
    { angle: props.atRisk, label: "At risk", color: theme.risk3 },
    { angle: props.unexpected, label: "Unexpected", color: theme.unexpected },
    { angle: props.expected, label: "Expected", color: theme.green },
  ];

  // Filter the data array based on whether the risk status is zero or not. This is to fix a bug in the pie chart where a segment
  // with zero angle shows as a line.
  const filteredData = myData.filter((risk) => risk.angle > 0);

  return (
    <Chart small={props.small} className={props.className}>
      <FlexRadialChart
        data={filteredData}
        innerRadius={props.innerRadius || (props.small ? 12 : 40)}
        radius={props.radius || (props.small ? 25 : 85)}
        padAngle={0.07}
        colorType="literal"
      ></FlexRadialChart>
    </Chart>
  );
};

PieChart.propTypes = {
  atRisk: PropTypes.number,
  unexpected: PropTypes.number,
  expected: PropTypes.number,
};

PieChart.defaultProps = {
  atRisk: 0,
  unexpected: 0,
  expected: 1,
};

export default PieChart;
