import React from "react";
import styles from "./styles.module.scss";

const RiskKey = ({ label }) => {
  return (
    <div
      className={`${styles.risk_key_container} ${
        label === "At risk"
          ? styles.atRisk
          : label === "Unexpected"
          ? styles.unexpected
          : label === "Expected"
          ? styles.expected
          : styles.noData
      }`}
    >
      <div className={styles.label}>{label}</div>
    </div>
  );
};

const BehaviourHeatmapKey = () => {
  return (
    <div className={styles.keys_container}>
      <div className={styles.title}>Key:</div>
      <div className={styles.keys}>
        <div className={styles.key}>
          <div className={styles.key_item}>
            <RiskKey label="Expected" />
          </div>
          <div className={styles.key_item}>
            <RiskKey label="Unexpected" />
          </div>
          <div className={styles.key_item}>
            <RiskKey label="At risk" />
          </div>
          <div className={styles.key_item}>
            <RiskKey label="No Data" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BehaviourHeatmapKey;
