import {
  sortByTimestamp,
  dateToTimestamp,
  dateHourToTimestamp,
} from "../Dates/dateManipulations";

export type IndependenceDailyDataType = {
  entranceDoor: {
    openedAt?: string[] | []; // "2022-03-08T12:32:59.932Z"
    closedAt?: string[] | [];
  };
  doorEvents: {
    count: number | null;
    dailyAvg: number | null;
    dailyMax: number | null;
    dailyMin: number | null;
    dayRating: number | null;
    expectedMax: number | null;
    expectedMin: number | null;
    sentences: string[] | [];
    stddev: number | null;
  };
  minutesOutside: {
    count: number | null;
    dailyAvg: number | null;
    dailyMax: number | null;
    dailyMin: number | null;
    dayRating: number | null;
    expectedMax: number | null;
    expectedMin: number | null;
    sentences: string[] | [];
    stddev: number | null;
  };
};

export type PeriodsOutside = {
  wentOutAt: string | null;
  cameBackAt: string | null;
  minutesOutside: number;
};
export type IndependenceHourlyDataType = {
  hour: number;
  periodsOutside: [] | PeriodsOutside[];
  doorEvents: {
    expectedMax: number | null;
    expectedMin: number | null;
    count: number | null;
    "expected?": "within-range" | "above-max" | "below-min" | null;
  };
  minutesOutside: {
    expectedMax: number | null;
    expectedMin: number | null;
    count: number | null;
    "expected?": "within-range" | "above-max" | "below-min" | null;
  };
}[];
export type DailyIndependenceDataType = {
  date: string; // "YYYY-MM-DD"
  hourlyData: IndependenceHourlyDataType;
  dailyData: IndependenceDailyDataType;
}[];

export const extractHourlyIndependenceData = (
  dailyResults: DailyIndependenceDataType,
  from: string,
  to: string
) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const filteredData =
    dailyResults
      ?.map(({ date, hourlyData }) =>
        hourlyData?.map(({ hour, periodsOutside, minutesOutside }) => ({
          ts: dateHourToTimestamp(date, hour),
          value: minutesOutside?.count,
          periodsOutside,
          expectedMin: minutesOutside?.expectedMin,
          expectedMax: minutesOutside?.expectedMax,
          hourlyRating:
            minutesOutside?.count === null ||
            minutesOutside?.expectedMin === null
              ? null
              : minutesOutside?.expectedMin >= minutesOutside?.count
              ? 0
              : 1,
        }))
      )
      ?.flat()
      ?.filter((res) => res?.ts >= fromDate && res?.ts <= toDate) || [];
  const result = sortByTimestamp(filteredData);
  return result;
};

// Function for extracting the daily behaviour data from the API response
export const extractDailyIndependenceData = (
  dailyResults: DailyIndependenceDataType,
  from: string,
  to: string
) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const filteredData =
    dailyResults
      ?.map((day) => ({
        ...day,
        totalDoorEvents: day?.dailyData?.doorEvents?.count,
        ts: dateToTimestamp(day?.date),
        value: day?.dailyData?.minutesOutside?.count,
        expectedMin: day?.dailyData?.minutesOutside?.expectedMin,
        expectedMax: day?.dailyData?.minutesOutside?.expectedMax,
        date: day?.date,
        dayRating:
          day?.dailyData?.minutesOutside?.dayRating &&
          (day?.dailyData?.minutesOutside?.count === null ||
            day?.dailyData?.minutesOutside?.expectedMin === null)
            ? null
            : day?.dailyData?.minutesOutside?.dayRating,
      }))
      ?.filter((res) => res?.ts >= fromDate && res?.ts <= toDate) || [];
  const result = sortByTimestamp(filteredData);
  return result;
};
