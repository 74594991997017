import { differenceInDays, parseISO } from "date-fns";

type RiskData = {
  dayRating: number | null;
  date: string;
};

export const calcOverallRisk = (riskData: RiskData[]): number => {
  // First get the total weight by multiplying the risk rating by the weight of the day (more recent has more weight)
  const totalWeight = riskData.reduce(
    (total, day) =>
      total +
      (day.dayRating || 0) *
        (7 - differenceInDays(new Date(), parseISO(day.date + "T00:00:00Z"))),
    0
  );
  // Get the total of the weights
  const weightFactors = riskData.reduce(
    (total, day) =>
      total +
      (7 - differenceInDays(new Date(), parseISO(day.date + "T00:00:00Z"))),
    0
  );

  return Math.round(totalWeight / weightFactors);
};
