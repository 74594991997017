import React from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import PrimaryButton from "../../PrimaryButton";
import SensorSignal from "../../SensorSignal";
import InstallSensorLocation from "../../InstallSensorLocation";
import AltSpinner from "../../Spinners/AltSpinner";
import Spline from "@splinetool/react-spline";
import { useViewport } from "../../ViewportProvider";
import { INSTALL_INSTRUCTIONS } from "../../../Services/Constants";

const SensorCard = ({ error, sensor, loading, onConnect, onViewGuide }) => {
  const instructions = INSTALL_INSTRUCTIONS[sensor?.label];
  const { width } = useViewport();
  const isVerySmallScreen = width <= 290;

  return (
    <div
      className={error ? styles.sensor_card_error : styles.sensor_card_default}
    >
      <div className={styles.top_sensor_card}>
        <div>{instructions?.title}</div>
        <SensorSignal online={sensor?.online} loading={sensor?.loading} />
      </div>
      <div className={styles.location_instructions}>
        Location: <span>{instructions?.locationInstructions}</span>
      </div>
      <div>
        <div>
          <Spline scene="https://prod.spline.design/yP-C7dvDG9uYmz4p/scene.splinecode" />
        </div>

        {/* <Spline
          scene={sensor.spline ?? ""}
          className={styles.spline_container}
        />

        <spline-viewer
          loading-anim-type="spinner-small-light"
          url="https://prod.spline.design/rZb4GgP0qCHGKPiI/scene.splinecode"
        ></spline-viewer> */}
      </div>
      <InstallSensorLocation sensor={sensor} />
      <div className={styles.sensor_card_buttons}>
        <PrimaryButton
          onClick={() => onViewGuide(true)}
          style={{
            width: "100%",
            marginRight: "12px",
            backgroundColor: "white",
            borderRadius: "8px",
            border: `1px solid ${theme.primary3}`,
            lineHeight: 1.75,
            padding: "6px 8px",
          }}
        >
          {isVerySmallScreen ? "Guide" : " View guide"}
        </PrimaryButton>
        <PrimaryButton
          disabled={loading}
          onClick={onConnect}
          style={{ width: "100%", borderRadius: "8px" }}
          loading={loading}
          startIcon={loading && <AltSpinner small />}
        >
          Connect
        </PrimaryButton>
      </div>
      {error && <div className={styles.sensor_connection_error}>{error}</div>}
    </div>
  );
};

export default SensorCard;
