import React from "react";
import theme from "../../theme.scss";

export const DinnerWindowIcon = ({ width, color }) => {
  return (
    <svg
      width={"19" || width}
      height={"19" || width}
      viewBox="0 0 19 19"
      fill="none"
      id="DinnerWindowIcon"
    >
      <mask
        id="mask0_268_1643"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="16"
        height="16"
      >
        <circle
          cx="9.24504"
          cy="9.24532"
          r="7.375"
          transform="rotate(-165 9.24504 9.24532)"
          fill="#D9D9D9"
          stroke={theme.neutral7}
          strokeWidth="0.25"
        />
      </mask>
      <g mask="url(#mask0_268_1643)">
        <path
          d="M9.29781 9.73553L10.8672 15.527C10.9547 15.8498 10.8322 16.1929 10.5601 16.3873L6.15392 19.5345C5.88586 19.726 5.52784 19.7336 5.25191 19.5536L0.395527 16.3864C0.101556 16.1947 -0.0331274 15.8337 0.0633876 15.4963L1.93672 8.94685C2.04589 8.56519 2.4184 8.32131 2.81187 8.3739L8.63166 9.15182C8.95098 9.19451 9.21355 9.42457 9.29781 9.73553Z"
          fill={color || theme.primary3}
        />
      </g>
      <circle cx="9.24512" cy="9.24512" r="7" stroke={theme.neutral4} />
    </svg>
  );
};
