import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useParams } from "react-router-dom";
import { addDays, startOfDay, parseISO } from "date-fns";
import { MapInteractionCSS } from "react-map-interaction";
// State
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../State/slices/session-slice";
// API calls
import { getReportPreview } from "@intelligentlilli/api-layer";
// Reports
import General from "../../Reports/General";
// Forms
import PreviewReportForm from "../../Components/PreviewReportForm";
// Components
import Page from "../../Components/Page";
import NoDataBox from "../../Components/NoDataBox";
import { useViewport } from "../../Components/ViewportProvider";
import Backdrop from "../../Components/Backdrop";
// Icons
import { DownArrow, UpArrow } from "../../Styles/Icons/DesignSystem";
// Utils / Hooks
import { usePrepareReportData } from "../../Services/Hooks/usePrepareReportData";
import { mobileBreakpoint } from "../../Services/config";

const PullTab = ({ setShowForm, formIsOpen }) => {
  return (
    <div className={styles.pull_tab} onClick={() => setShowForm(!formIsOpen)}>
      {formIsOpen ? (
        <DownArrow color={theme.neutral2} width={16} height={12} />
      ) : (
        <UpArrow color={theme.neutral2} width={16} height={12} />
      )}
    </div>
  );
};

function PdfPreview() {
  // Get the service user from redux
  const server = useSelector((state) => state.session.server);
  const dispatch = useDispatch();
  // Dimensions
  const { width } = useViewport();
  // Get the report ID from the parameters
  const { id } = useParams();
  // Local state
  const [formIsOpen, setShowForm] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  // State readiness
  const hasData = data !== undefined || null;

  // to and from dates for the report
  const from =
    data?.report?.reportstartdate && parseISO(data?.report?.reportstartdate);
  const to = from && addDays(from, data?.report?.days - 1);

  // Get an array of the dates in the report date range
  const dates = [];
  let dateFrom = from;
  while (dateFrom <= to) {
    dates.push(startOfDay(new Date(dateFrom)));
    dateFrom = addDays(dateFrom, 1);
  }

  // Get the behaviour data for the hub
  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      getReportPreview(server, id, "web")
        .then((res) => {
          dispatch(setLoading(false));
          if (res.ok) {
            setData(res.body);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          dispatch(setLoading(false));
          setError(true);
        });
    }
  }, [id, server, dispatch]);

  // When the data has been fetched prepare it for the reports
  const preparedData = usePrepareReportData(data, dates);

  const propsToPass = {
    user: data?.user,
    SU: data?.hub,
    SUPhoto: data?.photo?.location,
    SUProperties: data?.properties,
    SURiskScores: data?.dayRatings,
    id: id,
    pdfStyle: styles.pdf,
    movementData: preparedData?.movementData,
    independenceData: preparedData?.independenceData,
    sustenanceData: preparedData?.sustenanceData,
    sleepData: preparedData?.sleepData,
    // temperatureData: preparedData?.temperatureData,
    dates: dates,
    from: from,
    to: to,
    report: data?.report,
  };

  return (
    <Page className={styles.page}>
      <Backdrop show={formIsOpen} className={styles.backdrop_position} />
      {hasData && (
        <div
          className={`${styles.preview_form_group}
          ${formIsOpen ? undefined : styles.close_form}
        `}
        >
          <div
            className={`${styles.pull_tab_container}
              ${formIsOpen ? undefined : styles.increase_height}
            `}
          >
            <PullTab setShowForm={setShowForm} formIsOpen={formIsOpen} />
          </div>
          <PreviewReportForm
            error={error}
            setError={setError}
            server={server}
            id={id}
            propsToPass={propsToPass}
            setData={setData}
            formIsOpen={formIsOpen}
          />
        </div>
      )}
      {hasData && !error && width > mobileBreakpoint && (
        <div className={styles.report}>
          <General {...propsToPass} />
        </div>
      )}
      {(error || !data) && (
        <div className={styles.error_fetching_report}>
          <NoDataBox
            title={"Error fetching report"}
            text={"Check back later"}
          />
        </div>
      )}
      {hasData && !error && width <= mobileBreakpoint && (
        <div className={`${styles.report}`}>
          <MapInteractionCSS
            defaultValue={{ scale: 0.5, translation: { x: 0, y: 0 } }}
            translationBounds={{
              xMin: -700,
              xMax: 300,
              yMin: -10000, // This is an arbitrary translation bound. It may be we need to adjust it for longer reports
              yMax: 100,
            }}
            minScale={0.3}
            maxScale={1}
          >
            <General {...propsToPass} />
          </MapInteractionCSS>
        </div>
      )}
    </Page>
  );
}

export default PdfPreview;
