import { dateToTimestamp } from "../Dates/dateManipulations";
import { startOfDay, endOfDay } from "date-fns";

type HubRiskScore = {
  date: string;
};

export const getDailyRiskValues = (
  hubRiskScores: HubRiskScore[] | undefined,
  dateFrom: Date,
  dateTo: Date
): HubRiskScore[] | [] => {
  if (!hubRiskScores || hubRiskScores?.length === 0 ) return [];
  return (
    hubRiskScores?.filter(
      (element) =>
        dateToTimestamp(element?.date) >= startOfDay(dateFrom) &&
        dateToTimestamp(element?.date) <= endOfDay(dateTo)
    )
  );
};
