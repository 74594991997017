import { useEffect, useState } from "react";
// UTILS
import { extractEvents } from "@intelligentlilli/lilli-utils";
// DateTime
import { endOfDay } from "date-fns";

export const usePrepareBedroomData = (
  bedroomAnalysis,
  startDateString,
  endDateString,
  dateType
) => {
  const [bedroomEvents, setBedroomEvents] = useState();

  useEffect(() => {
    const endDate = endDateString && endOfDay(new Date(endDateString));

    // Extracting the bedroom events for a particular day
    setBedroomEvents(
      extractEvents(
        bedroomAnalysis?.dailyResults,
        endDate,
        "bedroom.wall.motion"
      ) // in contrast to movement-analysis and bathroom-analysis, the backend does not give us the "movementEvents" property
    );
  }, [bedroomAnalysis, endDateString, startDateString, dateType]);

  return {
    bedroomEvents,
  };
};
