import {
  sortByTimestamp,
  dateToTimestamp,
  dateHourToTimestamp,
} from "../Dates/dateManipulations";

type SustenanceHourlyCountKeys =
  | "fridgeCount"
  | "smartplugCount"
  | "hour"
  | "totalCount";
type SustenanceDailyCountKeys = "totalFridgeCount" | "totalSmartplugCount";
type SustenanceDailyResult = {
  date: string; // "YYYY-MM-DD"
  hourlyData: {
    hour: number;
    fridgeCount: number | null;
    smartplugCount: number | null;
    totalCount: number | null;
  }[];
  dailyData: {
    totalFridgeCount: number | null;
    totalSmartplugCount: number | null;
  };
  dayRating: number | null;
  sentences: string[] | [];
};
type SustenanceDailyResults = SustenanceDailyResult[];

export type ProcessedSustenanceDayData = {
  ts: Date;
  value: number;
  hour: number;
  fridgeCount: number | null;
  smartplugCount: number | null;
  totalCount: number | null;
};

export const extractHourlySustenanceData = (
  arrayOfHourlyCountKeys: SustenanceHourlyCountKeys[],
  dailyResults: SustenanceDailyResults,
  from: string,
  to: string
): ProcessedSustenanceDayData[] => {
  if (
    !dailyResults ||
    !arrayOfHourlyCountKeys ||
    arrayOfHourlyCountKeys.length === 0 ||
    !from ||
    !to
  )
    return [];

  const fromDate = new Date(from);
  const toDate = new Date(to);

  const updated = dailyResults?.flatMap(({ date, hourlyData }) => {
    return hourlyData?.map((data) => {
      const values: Record<SustenanceHourlyCountKeys, number> =
        arrayOfHourlyCountKeys.reduce((acc, key) => {
          acc[key] = data?.[key] || 0;
          return acc;
        }, {} as Record<SustenanceHourlyCountKeys, number>);

      const sumOfValues = arrayOfHourlyCountKeys.reduce((sum, key) => {
        const value = values[key] === null ? 0 : values[key];
        return sum + value;
      }, 0);

      return {
        ...data,
        ts: dateHourToTimestamp(date, data.hour),
        value: sumOfValues,
      };
    });
  });
  const filtered = updated?.filter(
    (day) => day?.ts >= fromDate && day?.ts <= toDate
  );
  const result = sortByTimestamp(filtered);
  return result;
};

export interface ProcessedSustenanceRangeData extends SustenanceDailyResult {
  ts: Date;
  value: number;
  dayRating: number | null;
  date: string;
}

export const extractDailySustenanceData = (
  arrayOfDailyCountKeys: SustenanceDailyCountKeys[],
  dailyResults: SustenanceDailyResults,
  from: string,
  to: string
): ProcessedSustenanceRangeData[] => {
  if (
    !dailyResults ||
    !arrayOfDailyCountKeys ||
    arrayOfDailyCountKeys.length === 0 ||
    !from ||
    !to
  )
    return [];

  const fromDate = new Date(from);
  const toDate = new Date(to);
  const result = sortByTimestamp(
    dailyResults
      ?.filter(({ date }) => {
        const ts = dateToTimestamp(date);
        return ts >= fromDate && ts <= toDate;
      })
      ?.map((day) => {
        const values = arrayOfDailyCountKeys.reduce((acc, key) => {
          acc[key] = day?.dailyData?.[key] || 0;
          return acc;
        }, {} as Record<SustenanceDailyCountKeys, number>);

        const sumOfValues = arrayOfDailyCountKeys.reduce((sum, key) => {
          const value = values[key] === null ? 0 : values[key];
          return sum + value;
        }, 0);

        return {
          ...day,
          ts: dateToTimestamp(day?.date),
          value: sumOfValues,
          dayRating: day?.dayRating,
          date: day?.date,
        };
      })
  );
  return result;
};
