import React from "react";
import styles from "./styles.module.scss";

const HowItWorksQuestions = ({ questions, selected, setSelected }) => {
  return (
    <div className={styles.questions_container}>
      {questions?.map((questionItem, index) => {
        const isSelected = selected.key === questionItem.key;
        const question = questionItem["question"];
        return (
          <div
            className={styles.question_wrapper}
            onClick={() => setSelected(questionItem)}
            key={`question-${index}`}
          >
            <div
              className={
                isSelected
                  ? styles.selected_question
                  : styles.unselected_question
              }
            >
              {question}
            </div>
            <hr></hr>
          </div>
        );
      })}
    </div>
  );
};

export default HowItWorksQuestions;
