import React from "react";
import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";

const FormError = (props) => {
  return (
    <div className={`${styles.error} ${props.className}`}>{props.error}</div>
  );
};

FormError.propTypes = {
  error: PropTypes.string,
};

export default FormError;
