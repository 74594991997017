// This function takes an ISO date format ("YYYY-MM-DD") or a UK date format ("DD-MM-YYYY") and turns it into the other format
export const reverseDate = (date: string) => {
  const parts = date?.split("-");
  const reversed = parts?.reverse();
  // Standardise the date so that "2012-02-1" becomes "2012-02-01"
  const standardised = reversed?.map((date) =>
    parseInt(date) < 10 ? `0${parseInt(date)}` : date
  );
  const joined = standardised?.join("-");
  return joined;
};
