export const NIGHTTIME_QUESTIONS_AND_ANSWERS = [
  {
    key: "overview",
    question: "Overview",
    answer: [
      "Night-time activity is measured as the time someone has spent at home during typical night-time hours.",
      "By understanding their usual movement patterns over time, you can detect any changes that could indicate a change in their care needs.",
    ],
  },
  {
    key: "unexpected",
    question: "What would Unexpected night-time activity look like?",
    answer: [
      "Lilli flags 'Unexpected' when the service user's night-time movement is different than usual. For example:",
      "John has had Lilli for a few weeks and the usual amount of time he spends moving around his house during the night is around 2 hours. One day, Lilli flagged John as Unexpected when he moved for 4 hours during night-time.",
    ],
  },
];
