import React from "react";
import styles from "./styles.module.scss";

const SquareAvatar = ({ photo }) => {
  if (photo) {
    return (
      <div className={styles.avatar}>
        <img src={photo} alt={"User profile"} className={styles.avatar_photo} />
      </div>
    );
  }

  return (
    <div className={styles.avatar}>
      <div className={styles.avatar_outerCircle}>
        <div className={styles.avatar_innerCircle} />
      </div>
    </div>
  );
};

export default SquareAvatar;
