import React from "react";

export const SleepIcon = ({ colour, size }) => {
  return (
    <svg
      id="SleepIcon"
      width={size || 20}
      height={size || 20}
      viewBox="0 0 14 14"
      fill="none"
    >
      <g>
        <path
          d="M7.40001 7.05333C5.84667 4.04666 7.06667 1.4 7.75334 0.339996C4.18001 0.466663 1.32001 3.39333 1.32001 7C1.32001 7.09333 1.33334 7.18666 1.33334 7.28C1.74667 7.1 2.19334 7 2.66667 7C3.77334 7 4.78667 7.55333 5.40001 8.43333C6.51334 8.75333 7.33334 9.78 7.33334 11C7.33334 12.0133 6.75334 12.8867 5.92001 13.34C6.57334 13.5533 7.27334 13.6733 7.99334 13.6733C10.3267 13.6733 12.38 12.4733 13.5733 10.66C12 10.8133 8.92001 10.0133 7.40001 7.05333Z"
          fill={colour || "#323232"}
        />
        <path
          d="M4.66666 9.66665H4.54666C4.26666 8.89331 3.53332 8.33331 2.66666 8.33331C1.55999 8.33331 0.666656 9.22665 0.666656 10.3333C0.666656 11.44 1.55999 12.3333 2.66666 12.3333C3.07999 12.3333 4.32666 12.3333 4.66666 12.3333C5.39999 12.3333 5.99999 11.7333 5.99999 11C5.99999 10.2666 5.39999 9.66665 4.66666 9.66665Z"
          fill={colour || "#323232"}
        />
      </g>
    </svg>
  );
};
