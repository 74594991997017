import { format, differenceInDays, addDays } from "date-fns";

export const fillInMissingDays = (data, from, to) => {
  if (!data) {
    return [];
  }
  if (differenceInDays(new Date(to), new Date(from)) === data?.length - 1) {
    return data;
  }
  const fromDay = new Date(from);
  const toDay = new Date(to);
  const numberOfDays = differenceInDays(toDay, fromDay);
  const days = Array(numberOfDays + 1)
    .fill()
    .map((_, index) => {
      const day = format(addDays(fromDay, index), "yyyy-MM-dd");
      const dayData = data?.find((dayData) => dayData?.date === day);
      return dayData || { date: day, value: null };
    });
  return days;
};
