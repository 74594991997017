// Function for removing 'undefined' or 'null' from a behaviour dataset and replacing with zeros. Important as the charts do not render with missing data

export interface FixBehaviourData {
  value?: number;
}
// Turns the property "value" into 0 if it is null or undefined
export function fixBehaviourData <T extends FixBehaviourData>(data: T[] | undefined): T[] {
  return !data ? [] : data?.map((dataPoint) => {
    if (dataPoint.value !== undefined) {
      return { ...dataPoint };
    } else { 
      return {
        ...dataPoint,
        value: 0,
      };
    }
  });
}